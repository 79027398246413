import { Component, Input, OnInit } from '@angular/core';
import { AccessoriDepuratoreDto } from 'src/app/modules/classes/accessori-depuratore-dto';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AccessoriDepuratoreService } from 'src/app/services/accessori-depuratore/accessori-depuratore.service';
@Component({
  selector: 'app-accessori-depuratore',
  templateUrl: './accessori-depuratore.component.html',
  styleUrls: ['./accessori-depuratore.component.scss']
})
export class AccessoriDepuratoreComponent implements OnInit {

  @Input() form: any;
  @Input() trascodificheAcc: Trascodifica[];

  orderId: number;
  accessori: AccessoriDepuratoreDto[] = [];
  
  constructor(
    private accessoriDepuratore: AccessoriDepuratoreService
  ) { }

  ngOnInit(): void {
    this.orderId = this.form.idOrder;  
    this.accessoriDepuratore.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(( res:AccessoriDepuratoreDto[]) => {	
        if(res && res.length > 0) {
          this.form.sections.accessoriDepuratore = res;
        }else {
          this.form.sections.accessoriDepuratore = this.trascodificheAcc.map(el => {
            return { ...new AccessoriDepuratoreDto(), ordineId:this.orderId, tipologiaId: el.id };
        })
      }
	  })
  }

  updateQuantita(acc: AccessoriDepuratoreDto, quantity: number): void {
    acc.quantita = quantity;
  }

  getDescByTrascId(trascodificaId: number): string {
    return this.trascodificheAcc.find(el => el.id == trascodificaId)?.descrizione;
  }
}