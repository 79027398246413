import { Component,Input, OnInit,ViewChild } from '@angular/core';
import { PaintableComponent } from 'paintablejs/angular';
import { FormControllerService } from '../../../services/controller/form-controller.service';
import { DisegnoTecnicoService }  from '../../../services/disegnoTecnico/disegno-tecnico.service';
import * as _ from 'lodash';
import * as $ from 'jquery';
@Component({
  selector: 'app-disegno-tecnico',
  templateUrl: './disegno-tecnico.component.html',
  styleUrls: ['./disegno-tecnico.component.scss']
})
export class DisegnoTecnicoComponent implements OnInit {

  @Input() form: any;
  @Input() brand:string;
  @Input() stepPagamento=0;
  @ViewChild(PaintableComponent)
  
  paintable: PaintableComponent;  
  active = false;
  useEraser = false;
  thickness = 2;
  color = '#000000';
  savedImage: any;
  drawing = true;
  manualUpload: any;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  isLoadingImage: boolean = true;
  width: number;
  height: number;
  disegnoTecnico:any;
  constructor(
	private formController:FormControllerService,
	private disegnoService : DisegnoTecnicoService,
) { }

  ngOnInit(): void {
	    this.height = 300;
    	this.width = document.getElementById('part').offsetWidth - 60;
		this.getDisegno();
  }


  getDisegno():void{
	    this.disegnoService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {		
	      if (res[this.stepPagamento] !== undefined) {
		        this.form.sections.disegno[this.stepPagamento] = res[this.stepPagamento];
		        if (this.form.sections.disegno[this.stepPagamento].disegno !== null && this.form.sections.disegno[this.stepPagamento].disegno!== undefined) {
		          // tslint:disable-next-line:max-line-length
		          this.cardImageBase64 = 'data:' + this.form.sections.disegno[this.stepPagamento].disegnoContentType + ';base64,' + this.form.sections.disegno[this.stepPagamento].disegno;
		          this.isLoadingImage = false;
		          this.active = false;
                  this.isImageSaved = true;
		        }else{
		          this.isLoadingImage = false;
		          //this.active = true;
		        }
		      } else{
		        this.isLoadingImage = false;
		        //this.active = true;
		      }
	    });
  }	 

  clear(): void {
    this.paintable?.clear();
    this.isImageSaved = false;
    delete this.cardImageBase64;
  }
  undo(): void {
    this.paintable?.undo();
  }

  redo(): void {
    this.paintable?.redo();
  }

  toggleEdit(): void {
    this.useEraser = false;
    this.active = !this.active;
    if (this.active) {
      $('body').addClass('noScr');
      document.body.requestFullscreen();
      $('html,body').animate({
        scrollTop: $('#part').offset().top - 80
      },'slow');
    } else {
      $('body').removeClass('noScr');
      document.exitFullscreen();
      $('html,body').animate({
        scrollTop: $('#part').offset().top - 80
      },'slow');
    }
  }

  toggleUseEraser(): void {
    this.useEraser = !this.useEraser;
  }

  get image(): string {
    return this.cardImageBase64 || undefined;
  }
  
  onLongPress(): void {
    console.log('longpress');
  }

  onSave(image: string): void {
    
    delete this.cardImageBase64;
    this.form.sections.disegno[this.stepPagamento].disegno = image;
    const arr = this.form.sections.disegno[this.stepPagamento].disegno.split(";");
    this.form.sections.disegno[this.stepPagamento].disegnoContentType = 'image/png';
    const byte = arr[1].split(",");
    this.form.sections.disegno[this.stepPagamento].disegno = byte[1];
    this.isImageSaved = true;
	this.form.sections.disegno[this.stepPagamento].ordineId = this.form.idOrder;
    if(this.form.sections.disegno[this.stepPagamento].id === undefined){
      this.disegnoService.save(JSON.stringify(this.form.sections.disegno[this.stepPagamento])).subscribe( res => {
        this.getDisegno();
      });
    } else {
      this.disegnoService.update(JSON.stringify(this.form.sections.disegno[this.stepPagamento])).subscribe(res => {
        this.getDisegno();
      });
    }
  }

  fileChangeEvent(fileInput: any): any {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 5242880;
      const allowed_types = [
        'image/jpg',
        'image/jpeg',
        'image/png',
      ];
      const max_height = 15200;
      const max_width = 25600;
  
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
            'Maximum size allowed is ' + max_size / 1000000 + 'Mb';
        return false;
      }
  
      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'Sono consentite solo immagini ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];


          if (img_height > max_height && img_width > max_width) {
            this.imageError =
                'Maximum dimentions allowed ' +
                max_height +
                '*' +
                max_width +
                'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.formController.compressImage(this.cardImageBase64, this.width, this.height).then(compressed => {
              this.cardImageBase64 = compressed;
              this.form.sections.disegno[this.stepPagamento].disegno = this.cardImageBase64;
              const arr = this.form.sections.disegno[this.stepPagamento].disegno.split(";");
              this.form.sections.disegno[this.stepPagamento].disegnoContentType = 'image/png';
              const byte = arr[1].split(",");
              this.form.sections.disegno[this.stepPagamento].disegno = byte[1];
            }, err => console.log('this.err => ', err));
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
      this.active = false;
    }
  }

  removeImage(): void {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.form.sections.disegno[this.stepPagamento].disegno = null;
    this.form.sections.disegno[this.stepPagamento].disegnoContentType = null;
  }


}
