<div class="row originalRow align-items-stretch" >
    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                STUFE A PELLET
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-4 text-center">MODELLO</div>
                    <div class="col-3 text-center">VOL. RISC.</div>
                    <div class="col-5 text-center">COLORE</div>
                </div>
                <div class="row originalRow mb-1" *ngFor="let pellet of stufePellet; let i = index" [ngClass]="{'onlyRedBorder': form.sections.stufe[i].checked && !form.sections.stufe[i].coloreId}">
                    <div class="col-5">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeColor($event, i)" [(ngModel)]="form.sections.stufe[i].checked">{{pellet.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-2 smalFt text-right">
                        {{returnVolDesc(pellet.id)}}
                    </div>
                    <div class="col-5">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnColDescr($event, i)" [(ngModel)]="form.sections.stufe[i].coloreId" [disabled]="!form.sections.stufe[i].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let color of returnColors(pellet.id); let f = index;" [value]="color.id">
                                {{color.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.stufe[i].checked && !form.sections.stufe[i].coloreId">
                            selezione obbligatoria
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-6 mb-4">

        <div class="anagraficaContainer mb-4">
            <div class="text-left text-success font-weight-bold mb-4">
                STUFE A BIOETANOLO
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-4">MODELLO</div>
                    <div class="col-3">VOL. RISC.</div>
                    <div class="col-5">COLORE</div>
                </div>
                <div class="row originalRow mb-1" [ngClass]="{'onlyRedBorder': form.sections.stufe[i + stufePellet.length].checked && !form.sections.stufe[i + stufePellet.length].coloreId}" *ngFor="let pellet of stufeBio; let i = index">
                    <div class="col-4">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeColor($event, (i + stufePellet.length))" [(ngModel)]="form.sections.stufe[i + stufePellet.length].checked">{{pellet.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-3">
                        {{returnVolDesc(pellet.id)}}
                    </div>
                    <div class="col-5">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnColDescr($event, i + stufePellet.length)" [(ngModel)]="form.sections.stufe[i + stufePellet.length].coloreId" [disabled]="!form.sections.stufe[i + stufePellet.length].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let color of returnColors(pellet.id); let f = index;" [value]="color.id">
                                {{color.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.stufe[i + stufePellet.length].checked && !form.sections.stufe[i + stufePellet.length].coloreId">
                            selezione obbligatoria
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="anagraficaContainer mb-4">
            <div class="text-left text-success font-weight-bold mb-4">
                TERMOSTUFE A PELLET
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-4">MODELLO</div>
                    <div class="col-3">VOL. RISC.</div>
                    <div class="col-5">COLORE</div>
                </div>
                <div class="row originalRow mb-1" [ngClass]="{'onlyRedBorder': form.sections.stufe[i + stufePellet.length + stufeBio.length].checked && !form.sections.stufe[i + stufePellet.length + stufeBio.length].coloreId}" *ngFor="let pellet of termoPellet; let i = index">
                    <div class="col-4">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeColor($event, (i + stufePellet.length + stufeBio.length))" [(ngModel)]="form.sections.stufe[i + stufePellet.length + stufeBio.length].checked">{{pellet.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-3">
                        {{returnVolDesc(pellet.id)}}
                    </div>
                    <div class="col-5">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnColDescr($event, i + stufePellet.length + stufeBio.length)" [(ngModel)]="form.sections.stufe[i + stufePellet.length + stufeBio.length].coloreId" [disabled]="!form.sections.stufe[i + stufePellet.length + stufeBio.length].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let color of returnColors(pellet.id); let f = index;" [value]="color.id">
                                {{color.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.stufe[i + stufePellet.length + stufeBio.length].checked && !form.sections.stufe[i + stufePellet.length + stufeBio.length].coloreId">
                            selezione obbligatoria
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="anagraficaContainer mb-4">
            <div class="text-left text-success font-weight-bold mb-4">
                CALDAIE A PELLET
            </div>
            <div *ngIf="trascodifiche">
                <div class="row originalRow mb-3 smalFt">
                    <div class="col-4">MODELLO</div>
                    <div class="col-3">VOL. RISC.</div>
                    <div class="col-5">COLORE</div>
                </div>
                <div class="row originalRow mb-1" [ngClass]="{'onlyRedBorder': form.sections.stufe[i + stufePellet.length + stufeBio.length + termoPellet.length].checked && !form.sections.stufe[i + stufePellet.length + stufeBio.length + termoPellet.length].coloreId}" *ngFor="let pellet of caldaiePellet; let i = index">
                    <div class="col-4">
                        <mat-checkbox color="primary" class="smalFt" (ngModelChange)="removeColor($event, (i + stufePellet.length + stufeBio.length + termoPellet.length))" [(ngModel)]="form.sections.stufe[i + stufePellet.length + stufeBio.length + termoPellet.length].checked">{{pellet.descrizione}}</mat-checkbox>
                    </div>
                    <div class="col-3">
                        {{returnVolDesc(pellet.id)}}
                    </div>
                    <div class="col-5">
                        <mat-radio-group class="smalFt" (ngModelChange)="returnColDescr($event, i + stufePellet.length + stufeBio.length + termoPellet.length)" [(ngModel)]="form.sections.stufe[i + stufePellet.length + stufeBio.length + termoPellet.length].coloreId" [disabled]="!form.sections.stufe[i + stufePellet.length + stufeBio.length + termoPellet.length].checked">
                            <mat-radio-button color="primary" class="mr-2" *ngFor="let color of returnColors(pellet.id); let f = index;" [value]="color.id">
                                {{color.descrizione}}
                            </mat-radio-button>
                        </mat-radio-group>
                        <div class="smallFont text-danger" *ngIf="form.sections.stufe[i + stufePellet.length + stufeBio.length + termoPellet.length].checked && !form.sections.stufe[i + stufePellet.length + stufeBio.length + termoPellet.length].coloreId">
                            selezione obbligatoria
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="anagraficaContainer">
            <div>
                <div class="text-danger mb-2">
                    ATTENZIONE LE TERMOSTUFE E LE CALDAIE PRODUCONO ACQUA SANITARIA SOLO CON L'INSTALLAZIONE DEL PUFFER
                </div>
                <div class="d-inline-block">MANTENERE GENERATORE DI CALORE ESISTENTE PER ACQUA CALDA SANITARIA</div>
                <mat-radio-group class="d-inline-block smalFt" [(ngModel)]="form.sections.dettaglioDto.manteneregen">
                    <mat-radio-button color="primary" class="mr-2" [value]="true">
                        SI
                    </mat-radio-button>
                    <mat-radio-button color="primary" class="mr-2" [value]="false">
                        NO
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="anagraficaContainer">
            <div *ngIf="loadedDettStufe">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Note</mat-label>
                    <textarea matInput placeholder="Note..." [(ngModel)]="form.sections.dettaglioDto.note"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
