import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {NuoviDatiTecniciService} from '../services/nuovi-dati-tecnici/nuovi-dati-tecnici.service';
import {NuoviDatiDTO , NuoviDati} from '../classes/dati-tecnici';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
@Component({
  selector: 'app-form-nuovi-dati',
  templateUrl: './form-nuovi-dati.component.html',
  styleUrls: ['./form-nuovi-dati.component.scss']
})
export class FormNuoviDatiComponent implements OnInit {
  @Input() form: any;
  @Input() prodotto: any;
  classBg: string = "blueBg";
  classContent: string = "blueCont";
  constructor(
  	private nuoviDatiTecService : NuoviDatiTecniciService,
	  private formController: FormControllerService,
  ) { }

  ngOnInit(): void {
    if(this.prodotto === 'bagno-pratico'){
      this.classBg = 'bagnoPraticoBg';
      this.classContent = 'bagnoPraticoCont'; 
    }
		this.nuoviDatiTecService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {	
			this.formController.mappingDatiTecniciToForm(res[0] , this.form.sections.nuoviDati);
		})
	
  }

}
