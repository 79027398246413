<div class="text-center {{brand === 'bagniHelp75' ? 'help75Bg' : 'blueBg'}}">
    OMAGGIO
</div>
<div class="{{brand === 'bagniHelp75' ? 'hepl75Cont' : 'blueCont'}}">
    <div class="row" *ngFor="let omaggio of form.sections.omaggio; let i = index;">
        <div class="col-6">
            <mat-form-field class="full-width">
                <mat-label>Omaggio</mat-label>
                <mat-select [(ngModel)]="omaggio.tipologiaId" (ngModelChange)="checkItem($event, i)">
                    <mat-option>-- Omaggio --</mat-option>
                    <mat-option [disabled]="alreadySelected(om.id)" *ngFor="let om of availableOmaggio" [value]="om.id">
                        {{om.descrizione}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-4" *ngIf="omaggio.tipologiaId">
            <app-input-number
                    (onNumChange)="omaggio.quantita = $event"
                    [step]="1"
                    [num]="omaggio.quantita"
                    [decimal]="false"
                    [payment]="false"
                    [small]="false"
                    [min] = "1"
                    label="Quantità"></app-input-number>
            <div class="smallFont text-danger" *ngIf="omaggio.tipologiaId && !omaggio.quantita">
                Campo obbligatorio
            </div>
        </div>
        <div class="col-2" *ngIf="form.sections.omaggio.length > 0">
            <button mat-icon-button color="warn" (click)="removeItem(i)">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
    <div>
        <button mat-flat-button class="buttonAdd" (click)="addOmaggio()">Aggiungi Omaggio</button>
    </div>
</div>
