<div class="pt-5 text-center mb-4"> </div>
<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0"  [disabled]="currentStep == 8 && openSign" (click)="setStep(currentStep-1)" mat-flat-button class="btnMainColor btnColor">< Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">        
        <button [disabled]="generatingPdf || (!validAccessori() && currentStep === 3) || (!validAnagrafica && currentStep === 0) || (!validAnagrafica && currentStep === 7) || (!validCaldaie() && currentStep === 1) || ((!validDatiAbitazione() || !validDisegnoCaldaia()) && currentStep === 2) || (!validCannaFumaria() && currentStep === 3) || (!validDettagliInstallazione() && currentStep === 3) || (currentStep === 6 && !validPrivacy()) || (currentStep === 5 && !this.formController.validPagamento(this.form)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))" mat-flat-button class="btnMainColor btnColor" (click)="saveModule(false)">Avanti ></button>
    	<div>
    		<div class="smallFont text-danger" *ngIf="(!validAccessori() && currentStep === 3) || (!validAnagrafica && currentStep === 0) || (!validAnagrafica && currentStep === 7) || (!validCaldaie() && currentStep === 1) ||((!validDatiAbitazione() || !validDisegnoCaldaia()) && currentStep === 2) || (!validCannaFumaria() && currentStep === 3) || (!validDettagliInstallazione() && currentStep === 3) || (currentStep === 5 && !this.formController.validPagamento(this.form)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))">
            	Compila tutti i campi obbligatori
        	</div>
			<div class="smallFont text-danger" *ngIf="(currentStep === 6 && !validPrivacy())">
           		 &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
    	</div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" class="" src="assets/img/loghi/ARIEL.jpg"/>
            </span>
            Modulo d'ordine caldaia Ariel
        </h4>
    </div>
    <hr/>
    <div class="moduleContainer mb-3">
        <div id="toPrint">
            <div class="" *ngIf="currentStep == 0">
                <app-anagrafica-sh [validAnagrafica]="validAnagrafica" [brand]="'ariel'" [form]="form"></app-anagrafica-sh>
            </div>

            <div class="" *ngIf="currentStep == 1">
                <div class="text-center mb-3">
                </div>
                <div>
                    <app-tipi-caldaie [trascodifiche]="trascodifiche" [form]="form"></app-tipi-caldaie>
                </div>
                <div>
                    <app-dettaglio-caldaia [form]="form"></app-dettaglio-caldaia>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 2">
                <div class="text-center mb-3">
                </div>
                <div>
                    <div class="row originalRow">
                        <div class="col-lg-6 mb-4">
                            <app-disegno-caldaia [trascodifiche]="trascodifiche" [form]="form"></app-disegno-caldaia>
                        </div>
						<div class="col-lg-6 mb-4">
                        	<app-dati-abitazione-cald [form]="form"  [trascodifiche]="trascodifiche"></app-dati-abitazione-cald>
						</div>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 3">
                <div class="text-center mb-3">
                </div>
                <div>
                    <app-accessori-cald [form]="form"  [trascodifiche]="trascodifiche"></app-accessori-cald>
                </div>
            </div>
            
            <div class="" *ngIf="currentStep == 4">
                <div class="text-center mb-3">
                </div>
                <div>
                    <app-omaggio-sh [form]="form"  [trascodifiche]="trascodifiche"></app-omaggio-sh>
                </div>            
            </div>

            <div class="" *ngIf="currentStep == 5">
                <div>
                    <!-- <app-pagamento [form]="form" [brand]="'ariel'" [trascodifiche] = "trascodifiche"></app-pagamento> -->
                    <app-pay-one [form]="form" [brand]="'ariel'" [trascodifiche] = "trascodifiche" [prodotto]="'caldaia'"></app-pay-one>
                </div>
            </div>

			<div class="lastStep" *ngIf="currentStep == 6">
				<app-privacy [form]="form" [brand]="'caldaia'"></app-privacy>
			</div>

            <div class="" *ngIf="currentStep == 7">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div>

            <div class="lastStep" *ngIf="currentStep == 8">
                <div class="text-center">
                    <div class="digitalSignatureButtons" *ngIf="currentStep == 8 && openSign" style="height: 124px">
                        <div class="signButton" (click)="edit()">
                            <span class="material-icons d-block">
                                edit
                            </span>
                            <span class="d-block mb-2">Modifica Contratto</span>
                        </div>
                    </div>
                  <div class="" *ngIf="form.idOrder">
                        <app-print-caldaia [trascodifiche] = "trascodifiche" [form]="form" (reload)="reload($event)"></app-print-caldaia>
                  </div>   
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row buttonRow">
    <div class="col-6">
        <button class="btnMainColor btnColor" *ngIf="currentStep>0"  [disabled]="currentStep == 8 && openSign"  (click)="setStep(currentStep-1)" mat-flat-button>< Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button class="btnMainColor btnColor" [disabled]="generatingPdf || (!validAccessori() && currentStep === 3) || (!validAnagrafica && currentStep === 0) || (!validAnagrafica && currentStep === 7) || (!validCaldaie() && currentStep === 1) || ((!validDatiAbitazione() || !validDisegnoCaldaia()) && currentStep === 2) || (!validCannaFumaria() && currentStep === 3) || (!validDettagliInstallazione() && currentStep === 3) || (currentStep === 6 && !validPrivacy()) || (currentStep === 5 && !this.formController.validPagamento(this.form)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))" mat-flat-button (click)="saveModule(false)">Avanti ></button>
         <div>
    		<div class="smallFont text-danger" *ngIf="(!validAccessori() && currentStep === 3) || (!validAnagrafica && currentStep === 0) || (!validAnagrafica && currentStep === 7) || (!validCaldaie() && currentStep === 1) || ((!validDatiAbitazione() || !validDisegnoCaldaia()) && currentStep === 2) || (!validCannaFumaria() && currentStep === 3) || (!validDettagliInstallazione() && currentStep === 3) || (currentStep === 5 && !this.formController.validPagamento(this.form)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))">
            	Compila tutti i campi obbligatori
        	</div>
			<div class="smallFont text-danger" *ngIf="(currentStep === 6 && !validPrivacy())">
           		 &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>         	
    	</div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
</div>

<div class="stepper">
    <div class="row">
        <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
            <div class="stepNumber" [ngClass]="
            {
            'doneStep': currentStep > step,
            'currentStep': currentStep == step,
            'errorStep': (!validAccessori() && currentStep === step) || (!validAnagrafica && currentStep === step) || (!validCaldaie() && currentStep === step) || ((!validDatiAbitazione() || !validDisegnoCaldaia()) && currentStep === step) || (!validCannaFumaria() && currentStep === step) || (!validDettagliInstallazione() && currentStep === step) || (currentStep === step && !validPrivacy()) || (currentStep === step && !this.formController.validPagamento(this.form))
            }">
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="
            {
            'currentStep': currentStep == totalSteps.length
            }">
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="printModal" tabindex="-1">
    <div class="modal-dialog modal-xxl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Anteprima contratto {{returnIdOrdine()}}</h3>
                <button type="button" class="close" (click)="togglePrintPreview(false)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mt-5" *ngIf="trascodifiche && showModalPrint">
                    <app-print-caldaia [trascodifiche] = "trascodifiche" [form]="form" [modale]="true" (reload)="reload($event)"></app-print-caldaia>
                </div>
            </div>
            <div class="modal-footer">
                <button mat-button (click)="togglePrintPreview(false)">Chiudi anteprima</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="errorModal" tabindex="-1">
    <app-error-modal [error]="error"></app-error-modal>
</div>


<div class="modal fade" id="confirmModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Avviso selezione caldaia</h3>
                <button type="button" class="close" (click)="openConfirmCaldaia(false)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Sei certo di selezionare più di una caldaia?
                </div>
            </div>
            <div class="modal-footer">
                <button mat-button (click)="openConfirmCaldaia(false)">Annulla</button>
                <button mat-button (click)="openConfirmCaldaia(true)">Avanti ></button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="timeoutModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Tempo limite generazione contratto</h3>
                <button type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
					Richiesta presa in carico, il nostro Customer Care provveder&agrave; ad inviare al cliente il contratto per la firma.           
                </div>
            </div>
        </div>
    </div>
</div>
<div class="mt-5 fidexPdf" *ngIf="trascodifiche && generatingPdf" > 
    <app-print-caldaia [trascodifiche] = "trascodifiche" [form]="form"></app-print-caldaia>
</div>