import { Injectable } from '@angular/core';
import { HttpService } from '../../../../../services/http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Trascodifica } from '../../../../classes/trascodifica';
import { Observable } from 'rxjs';
import {
  catchError,
  retry,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PromoService extends HttpService{
  constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }

  getCondizionatori(id: any, key: any): Observable<any> {
    return this.http
               .get<any>(this.apiUrl + 'condizionatores?' + key + id, this.setHttpOptions()).pipe(
            retry(0),
            catchError(err => this.handleError(err))
        );
  }

  saveCondizionatori(condizionatore: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + 'condizionatores', condizionatore, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  updateCondizionatori(condizionatore: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + 'condizionatores', condizionatore, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }

  delete(id: any): Observable<any> {
    return this.http.delete<any>(this.apiUrl + 'condizionatores/' + id, this.setHttpOptions()).pipe(
        retry(0),
        catchError(err => this.handleError(err))
    );
  }
}
