import { Component, Input, OnInit } from '@angular/core';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';

@Component({
  selector: 'app-form-nuovo-piatto-doccia',
  templateUrl: './form-nuovo-piatto-doccia.component.html',
  styleUrls: ['./form-nuovo-piatto-doccia.component.scss']
})
export class FormNuovoPiattoDocciaComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  isVisible  = false;
	options: Trascodifica[];
	optionPiattoDoccia: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};
	listOptionsOrder: OptionsAvailable[];
  constructor(private formController:FormControllerService, private optionsService: OptionsAvailableService) { }

  ngOnInit(): void {

    //Gestione SI / NO Piatto doccia
    this.options = this.formController.getTrascodificaByCodice('TYPE_OPTIONS', this.trascodifiche).filter(op => op.descrizione === 'NUOVO PIATTO DOCCIA');

    this.getOption(this.options[0].id).then(res => {
      if (res !== undefined && res.length > 0) {
        this.isVisible = res[0].selected;
        this.form.sections.optionsAvailable.push(res[0])

        this.form.sections.optionsAvailable = this.form.sections.optionsAvailable.filter((res: any) => res.id != undefined && res.id != null)
        this.optionPiattoDoccia = res[0];
      } else {
        this.isVisible = false;
        this.optionPiattoDoccia = new OptionsAvailable();
        this.optionPiattoDoccia.ordineId = this.form.idOrder;
        this.optionPiattoDoccia.typeId = this.options[0].id;
        this.optionPiattoDoccia.selected = undefined;
        this.form.sections.optionsAvailable.push(this.optionPiattoDoccia);
      }
    })
  }

  showDivPiattoDoccia(value) {
    if (value) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }

    this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0].id)[0].selected = value;

  }

  getOption(idOption:any):Promise<any>{

    return new Promise((resolve, reject) => {
      this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
        if(res !== undefined){
          this.listOptionsOrder = res;
          let optionBoxDoccia = res.filter(op => op.typeId === idOption)  
          resolve(optionBoxDoccia);				          
          }
      });
      });
    }
}
