export const constants = {
  BRAND_CRISTALBOX: 'cristalbox',
  TYPE_TIPOPRODOTTOSISTEMA_AURUM: 'TIPOPRODOTTOSISTEMA_AURUM',
  TYPE_TIPOPRODOTTOSISTEMA_IBRID: 'TIPOPRODOTTOSISTEMA_IBRID',
  TYPE_TIPOPRODOTTOSISTEMA_SLIM_AURUM: 'TIPOPRODOTTOSISTEMA_SLIM_AURUM',
  TYPE_TIPOPRODOTTOSISTEMA_P_FULL: 'TIPOPRODOTTOSISTEMA_P_FULL',
  TYPE_TIPOPRODOTTOSISTEMA_POWER: 'TIPOPRODOTTOSISTEMA_POWER',
  TYPE_TIPOLOGIADESCRIZIONE_ARIEL_HYBRID_ARUM: 'ARIEL HYBRID AURUM',
  TYPE_TIPOLOGIADESCRIZIONE_ARIEL_HYBRID_SLIM_AURUM: 'ARIEL HYBRID SLIM AURUM',
  TYPE_TIPOLOGIADESCRIZIONE_ARIEL_POWER_FULL: 'ARIEL POWER FULL',
  TYPE_TIPOLOGIADESCRIZIONE_ARIEL_HYBRID_KIT_IBRIDIZZATORE: 'ARIEL HYBRID KIT IBRIDIZZATORE',
  TYPE_TIPOLOGIADESCRIZIONE_ARIEL_ARIEL_POWER: 'ARIEL POWER',
  TYPE_NUOVO_BOX_DOCCIA: 'NUOVO BOX DOCCIA',
  TYPE_NUOVO_PIATTO_DOCCIA: "NUOVO PIATTO DOCCIA",
  TYPE_NUOVA_RUBINETTERIA: "NUOVA RUBINETTERIA",
  TYPE_NUOVA_CASSETTA: 'NUOVA CASSETTA WC',
  TYPE_NUOVI_SANITARI: "NUOVI SANITARI",
  TYPE_VASCA: 'VASCA',
  TYPE_MODSISTPOMCALORE: 'MODSISTPOMCALORE',
  TYPE_POTSISTPOMCALORE: 'POTSISTPOMCALORE',
  COMMESSA_ARIEL_IBRID: 'SFA-T00179',
  TIPODETRAZIONE_CESS_50: 'Cessione credito con sconto in fattura 50%',
  TIPODETRAZIONE_CESS_65: 'Cessione credito con sconto in fattura 65%',
  TIPODETRAZIONE_CESS_75: 'Cessione credito con sconto in fattura 75%',
  TYPE_TIPODETRAZIONE: 'TIPODETRAZIONE',
  TYPE_TIPODETRAZIONE0: 'TIPODETRAZIONE0',
  COMMESSA_BAGNO_PRATICO: 'SFA-T00178',
  COMMESSA_ARIEL_FOTOVOLTAICO_PREVENTIVO: "SFA-T00177-UT",
  COMMESSA_ARIEL_FOTOVOLTAICO: 'SFA-T00177',
  COMMESSA_BAGNI_ITALIANI: "SFA-T00055",
  COMMESSA_ARIEL_STUFA_PELLET: "SFA-T00067",
  COMMESSA_ARIEL_CALDAIE: 'SFA-T00050',
  COMMESSA_ARIEL_CONDIZIONATORI: 'SFA-T00065',
  COMMESSA_REMAIL_VASCA_IN_DOCCIA: 'SFA-T00052',
  COMMESSA_REMAIL_BAGNI_ITALIANI_HELP_75: 'SFA-T00169',
  COMMESSA_ZUCCHETTI_MONTASCALE: 'SFA-T00181',
  COMMESSA_ARIEL_DEPURATORE: 'SFA-T00182',
  OPTIONAL_HOME_GLIDE: 'OPTIONAL_HOME_GLIDE',
  OPTIONAL_FLOW_X_TIPO_ARRIVO: 'FLOW_X_TIPO_ARRIVO',
  OPTIONAL_FLOW_X_TIPO_PARTENZA: 'FLOW_X_TIPO_PARTENZA',
  OPTIONAL_FLOW_X_TIPO_GUIDA: 'FLOW_X_TIPO_GUIDA',
  OPTIONAL_FLOW_X_POS_JOY: 'FLOW_X_POS_JOY',
  OPTIONAL_FLOW_X_OPT_SEDILE: 'FLOW_X_OPT_SEDILE',
  OPTIONAL_FLOW_X_VARIE: 'FLOW_X_VARIE',
  MONTASCALE_DRITTO_SUL_PIANEROTTOLO: 'DRITTO SUL PIANEROTTOLO',
  MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ: 'CON CURVA DI PARCHEGGIO A      ° ORRIZZONTALE',
  MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_VERTIC: 'CON CURVA DI PARCHEGGIO A      ° VERTICALE',
  MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_90_VERTICALE: 'CON CURVA DI PARCHEGGIO A 90° VERTICALE',
  MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_180_VERTICALE: 'CON CURVA DI PARCHEGGIO A 180° VERTICALE',
  MONTASCALE_RADIOCOMANDO_CHIAMATA_E_PARCHEGGIO: 'RADIOCOMANDO CHIAMATA E PARCHEGGIO',
  MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_180: 'Con curva di parcheggio a 180°',
  MONTASCALE_TIPO_ARRIVO_BASSO_GUIDA: 'TIPO_ARRIVO_BASSO_GUIDA',
  MONTASCALE_TIPO_ARRIVO_ALTO_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ: 'Con curva di parcheggio a       ° orizzontale',
  MONTASCALE_TIPO_ARRIVO_ALTO_GUIDA: 'TIPO_ARRIVO_ALTO_GUIDA',
  MONTASCALE_STANDAR_SUL_PIANEROTTOLO_MINIMO_200MM: 'Standard sul pianerottolo (minimo 2000 mm) L      mm',
  DEPURATORE_ACQUA_BUONA_ARIEL: 'ACQUA BUONA ARIEL',
  COD_MODELLO_DEPURATORE: 'MODELLO_DEPURATORE',
  COD_DEPURATORE_ACQUA_BUONA_ARIEL_ABB: 'MODELLO_DEPURATORE_V_ABB',
  ACCESSORI_DEPURATORE_TRASC: 'ACCESSORI_DEPURATORE',
  TYPE_DOC_CERTIFICATO_FIRMA: 'Certificato di firma',
  TYPE_ROLE_TECH: 'ROLE_TECH',
  DIM_PIATTI_PIETR: 'DIM_PIATTI_PIETR',
  COLORE_PIATTI_EFF_PIE: 'COLORE_PIATTI_EFF_PIE',
  DIM_PIATTO_LARGO: 'DIM_PIATTO_LARGO',
  DIM_PIATTO_STRETTO: 'DIM_PIATTO_STRETTO',
  COLORE_PIATTO_LARGO: 'COLORE_PIATTO_LARGO',
  TYPE_OPTIONS: 'TYPE_OPTIONS',
  DESC_NUOVO_PIATTO_DOCCIA: 'NUOVO PIATTO DOCCIA',
  COD_PROMO: "PROMO",

  COD_BOX_DOCCIA_INGRESSO: "BOX_DOCCIA_INGRESSO",
  COD_BOX_DOCCIA_PROFILO: "BOX_DOCCIA_PROFILO",
  COD_BOX_DOCCIA_CRISTALLI: "BOX_DOCCIA_CRISTALLI",
  COD_BOX_DOCCIA_MODELLO: "BOX_DOCCIA_MODELLO",
  COD_ACCESSORI_TIPOLOGIA :"ACCESSORI_TIPOLOGIA",
  COMMESSA_CB_VASCA_IN_DOCCIA: 'SFA-T00186',

  MODELLO_ARUBA: "ARUBA",
  MODELLO_AMERIKA: "AMERIKA",
  MODELLO_AMERIKA_IMP: "AMERIKA IMP"

}