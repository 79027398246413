import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { DtoStufe } from '../../../../../classes/stufe';
import { BrandService } from '../../../../../../services/brand/brand.service';
import { StufeService } from '../services/stufe.service';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
@Component({
  selector: 'app-stufe-termo-caldaie',
  templateUrl: './stufe-termo-caldaie.component.html',
  styleUrls: ['./stufe-termo-caldaie.component.scss']
})
export class StufeTermoCaldaieComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  // trascodifiche: Trascodifica[];
  stufePellet: Trascodifica[];
  termoPellet: Trascodifica[];
  caldaiePellet: Trascodifica[];
  stufeBio: Trascodifica[];
  singleStufa: {} = new DtoStufe();
  stufe: [];
  dtoStufe: {};
  combinazioni: any;
  stufeResponse: any;
  dettaglioStufe: any;
  loadedDettStufe = false;

  constructor(
      private brandService: BrandService,
      private stufeService: StufeService,
      private formController: FormControllerService,
  ) {
  }

  ngOnInit(): void {
    this.form.sections.stufe = [];    
    this.getStufe();
    this.getDettaglio();
  }

  getStufe(): void {
    this.stufeService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.stufeResponse = res;
      this.getTranscodifiche();
    });
  }
  getDettaglio(): void {
    this.loadedDettStufe = false;
    this.stufeService.getDettaglio(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      if(res[0]) this.form.sections.dettaglioDto = res[0];
      
      //this.dettaglioStufe.ordineId = this.form.idOrder;
      // this.form.sections.dettaglioDto = this.dettaglioStufe;
      //if (!this.form.sections.dettaglioDto.note) {this.form.sections.dettaglioDto.note = ''; }
      this.loadedDettStufe = true;
    });
  }

  getTranscodifiche(): void {
    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.getStufPellet();
    });
  }

  getStufPellet(): void {
    this.formController.getTrascodificaByCodicPromise('MODSTUFEPELLET', this.trascodifiche).then(data => {
      this.stufePellet = data;
      this.managestufe(this.stufePellet, 'MODSTUFEPELLET');
      this.getStufeBio(); 
    });
  }

  getStufeBio(): void {
    this.formController.getTrascodificaByCodicPromise('MODSTUFEBIO', this.trascodifiche).then(data => {
      this.stufeBio = data;
      this.managestufe(this.stufeBio, 'MODSTUFEBIO');
      this.getTermoPellet();    
    });
  }

  getTermoPellet(): void {
    this.formController.getTrascodificaByCodicPromise('MODTERMOPELLET', this.trascodifiche).then(data => {
      this.termoPellet = data;
      this.managestufe(this.termoPellet, 'MODTERMOPELLET');
      this.getCaldPellet(); 
         
    });
  }

  getCaldPellet(): void {
    this.formController.getTrascodificaByCodicPromise('MODCALDPELLET', this.trascodifiche).then(data => {
      this.caldaiePellet = data;
      this.managestufe(this.caldaiePellet, 'MODCALDPELLET');  
      this.checkResponse();  
    });
  }

  checkResponse(): void {
    if (this.stufeResponse?.length > 0) {
      this.stufeResponse.forEach(st => {
        if (st.modstufepelletId) {
          const ind = this.form.sections.stufe.findIndex(stOr => stOr.modstufepelletId === st.modstufepelletId);
          if (ind !== -1) {
            this.form.sections.stufe[ind].id = st.id;
            this.form.sections.stufe[ind].checked = true;
            this.form.sections.stufe[ind].coloreId = st.coloreId;
          }
        }
        if (st.modcaldpelletId) {
          const ind = this.form.sections.stufe.findIndex(stOr => stOr.modcaldpelletId === st.modcaldpelletId);
          if (ind !== -1) {
            this.form.sections.stufe[ind].id = st.id;
            this.form.sections.stufe[ind].checked = true;
            this.form.sections.stufe[ind].coloreId = st.coloreId;
          }
        }
        if (st.modtermopelletId) {
          const ind = this.form.sections.stufe.findIndex(stOr => stOr.modtermopelletId === st.modtermopelletId);
          if (ind !== -1) {
            this.form.sections.stufe[ind].id = st.id;
            this.form.sections.stufe[ind].checked = true;
            this.form.sections.stufe[ind].coloreId = st.coloreId;
          }
        }
        if (st.modstufebioId) {
          const ind = this.form.sections.stufe.findIndex(stOr => stOr.modstufebioId === st.modstufebioId);
          if (ind !== -1) {
            this.form.sections.stufe[ind].id = st.id;
            this.form.sections.stufe[ind].checked = true;
            this.form.sections.stufe[ind].coloreId = st.coloreId;
          }
        }
      });
    }
  }

  managestufe(element, mod): void{
    element.forEach(s => {
      s.checked = false;
      this.form.sections.stufe.push(this.mapStufaToForm(s, mod, new DtoStufe()));
    });
  }

  mapStufaToForm(dto: DtoStufe, mod , form: DtoStufe): DtoStufe{
  if (dto !== undefined) {
    form.checked = dto.checked || false;
    form.ordineId = this.form.idOrder;
    form.coloreId = dto.coloreId || undefined;
    // form.coloreDescrizione = this.returnColDescr(dto.coloreId, null) || undefined;
    form.id = undefined;
    if (mod === 'MODCALDPELLET') {
      form.modcaldpelletId = dto.id;
      // form.modcaldpelletDescrizione = this.returnModDescr(dto.id);
    } else {
      form.modcaldpelletId = undefined;
      // form.modcaldpelletDescrizione = undefined;
    }
    if (mod === 'MODSTUFEPELLET') {
      form.modstufepelletId = dto.id;
      // form.modstufepelletDescrizione = this.returnModDescr(dto.id);
    } else {
      form.modstufepelletId = undefined;
      // form.modstufepelletDescrizione = undefined;
    }
    if (mod === 'MODTERMOPELLET') {
      form.modtermopelletId = dto.id;
      // form.modtermopelletDescrizione = this.returnModDescr(dto.id);
    } else {
      form.modtermopelletId = undefined;
      // form.modtermopelletDescrizione = undefined;
    }
    if(mod === 'MODSTUFEBIO') {
      form.modstufebioId = dto.id;
      // form.modtermopelletDescrizione = this.returnModDescr(dto.id);
    } else {
      form.modstufebioId = undefined;
      // form.modtermopelletDescrizione = undefined;
    }
    // form.volriscDescrizione = this.returnVolDesc(dto.id);
    form.volriscId = this.returnVolId(dto.id) || undefined;
  }
  return form;
  }

  returnVolDesc(id): string {	
	return this.formController.returnVolDesc(id,this.trascodifiche);
  }

  returnModId(id): number {
    if (id) {
      const index = this.trascodifiche.findIndex(r => !r.parentId && r.id === id );
      if (index !== -1) {
        return this.trascodifiche[index].id;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  returnModDescr(id): string {
    if (id) {
      const index = this.trascodifiche.findIndex(r => !r.parentId && r.id === id );
      if (index !== -1) {
        return this.trascodifiche[index].descrizione;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  returnVolId(id): number {
    if (id) {
      const index = this.trascodifiche.findIndex(r => r.codice === 'VOLRISC' && r.parentId === id );
      if (index !== -1) {
        return this.trascodifiche[index].id;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  returnColDescr(event, ind): string {
    const index = this.trascodifiche.findIndex(r => r.codice === 'COLORE_STUFE' && r.id === event );
    if (index !== -1) {
      if (ind !== undefined) {
        this.form.sections.stufe[ind].coloreDescrizione = this.trascodifiche[index].descrizione;
      }
      return this.trascodifiche[index].descrizione;
    } else {
      return undefined;
    }
  }

  returnColors(id): any {
	
	return this.formController.returnColors(id,this.trascodifiche);

  }

  removeColor(event, i): void{
    if (!event) {
      // delete this.form.sections.stufe[i].id;
      delete this.form.sections.stufe[i].coloreId;
      delete this.form.sections.stufe[i].coloreDescrizione;
    }
  }

}
