
<div class="row mb-3">
    <div class="col-6">
        <div class="row smallFont">
            <div class="col-6">
                <img class="logo" src="assets/img/loghi/logo-bagni-italiani.jpg"/>
            </div>
            <div class="col-6">
                <div><b>G.D.L. S.p.A.</b></div>
                <div>
                    Via Orbetello, 54/D<br/>
                    10148 TORINO<br/>
                    tel. 011 22.48.515 / fax 011 22.95.425<br/>
                    info@gdlspa.it<br/>
                    IBAN: IT 30 J 02008 01119 000100689984
                </div>
            </div>
        </div>
    </div>
    <div class="col-3">
        <mat-form-field class="mb-2 full-width" appearance="standard">
            <mat-label>Contratto N.</mat-label>
            <input matInput disabled placeholder="N° contratto">
        </mat-form-field>

        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Data</mat-label>
            <input (click)="picker.open()" [(ngModel)]="form.anagrafica.infoContratto.data" matInput [matDatepicker]="picker" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-3">
        <mat-form-field class="mb-2 full-width" appearance="standard">
            <mat-label>Consulente</mat-label>
            <input matInput [(ngModel)]="form.anagrafica.infoContratto.consulente" placeholder="consulente" required disabled="disabled">
            <mat-hint class="text-danger" *ngIf="!form.anagrafica.infoContratto.consulente">Nome consulente richiesto</mat-hint>
        </mat-form-field>
        
    </div>
</div>

<div class="text-center mb-3">
    CAPITOLATO CONTRATTO D'APPALTO
</div>
<div class="anagraficaContainer mb-4">
    <div class="row">
        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Nome</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.nome" placeholder="Nome" required>
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.nome">Nome richiesto</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Cognome</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.cognome" placeholder="Cognome" required>
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.cognome">Cognome richiesto</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Email</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.contatti.email" placeholder="Email" required>
                <mat-hint class="text-danger" *ngIf="!formController.checkMail(form.anagrafica.anagrafica.contatti.email)">Email valida richiesta</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Telefono 1</mat-label>
                <span matPrefix>+39 &nbsp;</span>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.contatti.tel1" placeholder="Telefono 1" required>
                <mat-hint class="text-danger" *ngIf="!formController.checkPhone(form.anagrafica.anagrafica.contatti.tel1)">Telefono valido richiesto</mat-hint>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Telefono 2</mat-label>
                <span matPrefix>+39 &nbsp;</span>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.contatti.tel2" placeholder="Telefono 2">
            </mat-form-field>
        </div>

        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Comune</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.indirizzo.comune" placeholder="Comune" required>
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.indirizzo.comune">Comune richiesto</mat-hint>
            </mat-form-field>

            <div class="row rowMargin">
                <div class="col-8">
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Via</mat-label>
                        <input matInput [(ngModel)]="form.anagrafica.anagrafica.indirizzo.via" placeholder="Via" required>
                        <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.indirizzo.via">Via richiesta</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Civico</mat-label>
                        <input matInput [(ngModel)]="form.anagrafica.anagrafica.indirizzo.civico" placeholder="Civico" required>
                        <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.indirizzo.civico">Civico richiesta</mat-hint>
                    </mat-form-field>
                </div>
            </div>

            <div class="row rowMargin">
                <div class="col-4">
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Cap</mat-label>
                        <input matInput [(ngModel)]="form.anagrafica.anagrafica.indirizzo.cap" placeholder="Cap" required>
                        <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.indirizzo.cap">Cap richiesto</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-4">
                    <!--
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Provincia</mat-label>
                        <input matInput [(ngModel)]="form.anagrafica.anagrafica.indirizzo.provincia" placeholder="Provincia" required>
                        <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.indirizzo.provincia">Provincia richiesta</mat-hint>
                    </mat-form-field> -->
                    <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Provincia</mat-label>
                        <input type="text"
                               placeholder="Provincia"
                               aria-label="Number"
                               matInput
                               [(ngModel)]="form.anagrafica.anagrafica.indirizzo.provincia"
                               [matAutocomplete]="auto"
                                (ngModelChange)="filter(form.anagrafica.anagrafica.indirizzo.provincia)">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let provincia of filtered" [value]="provincia.sigla">
                                {{provincia.nome}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.indirizzo.provincia">Provincia richiesta</mat-hint>
                    </mat-form-field>
                </div>

                <div class="col-4">
                    <br/>
                    <mat-checkbox class="mt-2" color="primary"
                                  [(ngModel)]="form.anagrafica.anagrafica.indirizzo.ztl"
                                  [checked]="form.anagrafica.anagrafica.indirizzo.ztl">
                        ZTL.
                    </mat-checkbox>
                </div>
            </div>

            <mat-form-field class="full-width" appearance="standard">
                <mat-label>C.F. / P.IVA</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.cf_piva" placeholder="C.F. / P.IVA" required>
                <mat-hint class="text-danger" *ngIf="!form.anagrafica.anagrafica.cf_piva">C.F. / P.IVA richiesta</mat-hint>
            </mat-form-field>

            <div>
                <mat-checkbox class="mt-2" color="primary"
                              [(ngModel)]="form.anagrafica.anagrafica.indirizzo.altroIndirizzo"
                              (change)="resetNewAddress($event.checked)"
                              [checked]="form.anagrafica.anagrafica.indirizzo.altroIndirizzo">
                    Diverso indirizzo installazione
                </mat-checkbox>
            </div>

        </div>

    </div>

    <div class="row" *ngIf="form.anagrafica.anagrafica.indirizzo.altroIndirizzo">
        <div class="col-12">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Indirizzo installazione</mat-label>
                <input matInput [(ngModel)]="form.anagrafica.anagrafica.indirizzo.indirizzoInstallazione" [required]="form.anagrafica.anagrafica.indirizzo.altroIndirizzo" placeholder="Indirizzo installazione">
            </mat-form-field>
        </div>

    </div>
</div>

<div class="text-center">
    G.D.L. S.p.A. (con sede in Via Orbetello, 54/D, 10148 Torino, CF: 10062800015, tel 011.22.48.038, www.gdlspa.it,
    iscritta alla Camera di Commercio di Torino, n° registro imprese TO 1102436) qui rappresentata dall'agente
    <mat-form-field class="mr-2 ml-2" appearance="standard">
        <input matInput [(ngModel)]="form.anagrafica.agente" required placeholder="Agente" disabled="disabled">
    </mat-form-field>
    all'uopo autorizzato e munito di specifici poteri rappresentativi
</div>
