<div class="pt-5 text-center mb-4">
</div>

<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 10 && openSign" (click)="setStep(currentStep-1)"
            class="btnMainColor btnColor" mat-flat-button>Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button [disabled]="generatingPdf || (stepControllerButton())" class="btnMainColor btnColor" mat-flat-button
            (click)="saveModule()">Avanti ></button>
        <div>
            <div class="smallFont text-danger" *ngIf="(stepController())">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 8 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" class="" src="assets/img/loghi/logo-bagni-italiani.jpg" />
            </span>
            Modulo d'ordine Bagni Italiani Help75
        </h4>
    </div>
    <hr />
    <div class="moduleContainer mb-3" *ngIf="trascodifiche">
        <div id="toPrint">
            <div class="" *ngIf="currentStep == 0">
                <app-anagrafica-sh [validAnagrafica]="validAnagrafica" [brand]="'remail'" [form]="form"></app-anagrafica-sh>
            </div>

            <div class="" *ngIf="currentStep === 1">
                <app-datitecnicivasca [trascodifiche]="trascodifiche" [brand]="'bagniHelp75'" [form]="form"></app-datitecnicivasca>
            </div>

            <div class="" *ngIf="currentStep == 2">
                <div class="row originalRow">
                    <div class="col-lg-6">
                        <app-form-dati-tecnici-att [form]="form" [trascodifiche]="trascodifiche" [brand]="'bagniHelp75'"></app-form-dati-tecnici-att>
                    </div>
                    <div class="col-lg-6">
                        <app-disegno-tecnico [form]="form" [brand]="'bagniHelp75'" [stepPagamento]=0></app-disegno-tecnico>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 3">
                <div class="text-center mb-3">

                </div>
                <div>
                    <div class="row originalRow">
                        <div class="col-lg-6">
                            <app-nuovidatitecnici [form]="form" [trascodifiche]="trascodifiche" [brand]="'bagniHelp75'"></app-nuovidatitecnici>
                            <app-gres [form]="form" [trascodifiche]="trascodifiche" [brand]="'bagniHelp75'"></app-gres>
                            <br>
                        </div>
                        <div class="col-lg-6">
                            <!-- piatto doccia effetto pietra e piatto doccia stone -->
                            <app-opereidrauliche-help75 [form]="form" [trascodifiche]="trascodifiche"></app-opereidrauliche-help75>
                            <br>
                            <app-omaggio [form]="form" [trascodifiche]="trascodifiche" [brand]="'bagniHelp75'"></app-omaggio>
                        </div>
                    </div>

                    <div class="row originalRow">
                        <div class="col-12">
                            <div class="blue-border mb-2">
                                <app-note-ordine [form]="form"></app-note-ordine>
                            </div>
                        </div>
                    </div>
                    <div class="row originalRow">
                        <div class="col-12">
                            <div class="text-danger smallFont">
                                INTERVENTI DI ABBATTIMENTO BARRIERE ARCHITETTONICHE ESEGUITI SECONDO I CRITERI DI
                                ADATTABILITÀ/VISITABILITÀ DEL LOCALE AI SENSI ART.
                                1 C.42 L. 30/12/2021, N. 234, ART. 119-TER DL N. 34/2020, DECRETO MINISTERO DEI LAVORI
                                PUBBLICI N. 236 DEL 14/06/1989, INTERVENTI ESEGUITI IN
                                EDILIZIA LIBERA
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep === 4">
                <app-pay-one [form]="form" [brand]="'bagniHelp75'" [trascodifiche]="trascodifiche"
                    [stepPagamento]=0></app-pay-one>
            </div>

            <div class="" *ngIf="currentStep == 5">
                <div class="help75Bg">ARREDO BAGNO</div>

                <div class="hepl75Cont">
                    CAPITOLATO CONTRATTO D'APPALTO DI FORNITURA E INSTALLAZIONE SENZA APPLICAZIONE DI SCONTO IN FATTURA:
                </div>


                <app-form-nuovi-sanitari (update)="toggleChange($event)" [mobileAttivo]="mobileAttivo" [form]="form"
                    [trascodifiche]="trascodifiche" [brand]="'bagniHelp75'"></app-form-nuovi-sanitari>
                <br>
                    <app-form-nuova-cassetta [form]="form" [trascodifiche]="trascodifiche" [brand]="'bagniHelp75'"> </app-form-nuova-cassetta>
                <br>
                <app-form-nuova-rubinetteria [form]="form" [trascodifiche]="trascodifiche" [brand]="'bagniHelp75'"></app-form-nuova-rubinetteria>
                <br>
                <app-boxdoccia [form]="form" [trascodifiche]="trascodifiche" [brand]="'bagniHelp75'"></app-boxdoccia>
            </div>

            <div class="" *ngIf="currentStep == 6">
                <div class="text-center mb-3">

                </div>
                <div class="row">
                    <div class="col-12">
                        <app-disegno-tecnico [form]="form" [brand]="'bagniHelp75'" [stepPagamento]=1></app-disegno-tecnico>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep === 7">
                <app-pay-one [form]="form" [brand]="'bagniHelp75'" [trascodifiche]="trascodifiche" [stepPagamento]=1></app-pay-one>
            </div>

            <div class="lastStep" *ngIf="currentStep == 8">
                <app-privacy [form]="form" [brand]="'bagni'"></app-privacy>
            </div>

            <div *ngIf="currentStep == 9">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div>

            <div class="lastStep" *ngIf="currentStep == 10">
                <div class="text-center">
                    <div class="digitalSignatureButtons" *ngIf="currentStep == 10 && openSign" style="height: 124px">
                        <div class="signButton" (click)="edit()">
                            <span class="material-icons d-block">
                                edit
                            </span>
                            <span class="d-block mb-2">Modifica Contratto</span>
                        </div>
                    </div>
                    <div class="" *ngIf="form.idOrder">
                        <app-help75-print [idOrder]="form.idOrder" [form]="form" (reload)="reload($event)"></app-help75-print>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row buttonRow">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 10 && openSign" class="btnMainColor btnColor"
            mat-flat-button (click)="setStep(currentStep-1)" mat-button>
            < Indietro</button>

    </div>

    <div class="col-6 text-right">
        <button [disabled]="generatingPdf || (stepControllerButton())" *ngIf="currentStep < totalSteps.length"
            class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">Avanti ></button>

        <div class="smallFont text-danger" *ngIf="(stepController())">
            Compila tutti i campi obbligatori
        </div>
        <div class="smallFont text-danger" *ngIf="currentStep === 8 && !validPrivacy()">
            &egrave; necessario accettare tutte le informative per procedere alla firma
        </div>
    </div>

</div>

<div class="stepper">
    <div class="row">
        <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
            <div class="stepNumber" [ngClass]="
            {
            'doneStep': currentStep > step,
            'currentStep': currentStep == step,
            'errorStep': (
            (!validAnagrafica && currentStep == step))
            }">
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="
            {
            'currentStep': currentStep == totalSteps.length
            }">
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>

        </div>
    </div>
</div>


<div class="modal fade" id="errorModal" tabindex="-1">
    <app-error-modal [error]="error"></app-error-modal>
</div>

<div class="modal fade" id="timeoutModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Tempo limite generazione contratto</h3>
                <button type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Richiesta presa in carico, il nostro Customer Care provveder&agrave; ad inviare al cliente il
                    contratto per la firma.
                </div>
            </div>
        </div>
    </div>
</div>