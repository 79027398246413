import { AfterViewInit, Component, DoCheck, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AnagraficaDTO } from '../../../bagni-italiani/main-bagni/classes/anagrafica';
import { AnagraficaForm } from '../../../../classes/anagrafica';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from 'src/app/services/brand/brand.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { Ordine } from 'src/app/modules/classes/ordine';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { DepuratoreDto } from 'src/app/modules/classes/depuratore-dto';
import { DepuratoreService } from 'src/app/services/depuratore/depuratore.service';
import { AccessoriDepuratoreService } from 'src/app/services/accessori-depuratore/accessori-depuratore.service';
import { constants } from '../../../../classes/constants';
import * as $ from 'jquery';
declare var jQuery: any;
@Component({
  selector: 'app-depuratore-acqua',
  templateUrl: './depuratore-acqua.component.html',
  styleUrls: ['./depuratore-acqua.component.scss']
})
export class DepuratoreAcquaComponent implements OnInit, DoCheck, AfterViewInit  {

  currentStep:number = 0;
  totalSteps: number[];

  showModalPrint: boolean = false;
  validAnagrafica: boolean = false;
  openSign:boolean=false;
  isLoaded: boolean = false;

  anagrafica: AnagraficaDTO;
  ordine: Ordine;
  depuratore: DepuratoreDto;
	trascodifiche: Trascodifica[];
  trascodificheAccessori: Trascodifica[];

  originalForm: any;
  obj1: any;
	obj2: any;
  form: any;
  
  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');

  constructor(
    public formController: FormControllerService,
    private route: ActivatedRoute,
    private anagraficaService: AnagraficaService,
		private brandService: BrandService,
    private orderService: OrdersService,
    private snackbar: MatSnackBar,
    private depuratoreService: DepuratoreService,
    private accessoriDepuratoreService: AccessoriDepuratoreService,
    private paymentService: PaymentService,
  ) {
    this.showModalPrint = false;
		this.validAnagrafica = false;
		this.form = {
			idOrder: Number(this.route.snapshot.paramMap.get('id')),
			anagrafica: new AnagraficaForm(),
      sections: {
        depuratore: new DepuratoreDto(),
        accessoriDepuratore: [], 
        pagamento: [new PagamentoDTO()],
      },
      ordine: null
    }
    this.totalSteps = Array(6).fill(1).map((x, i) => i);
   }

   ngOnInit(): void {
    this.originalForm = JSON.parse(JSON.stringify(this.form));
		
		if(localStorage.getItem('id_client') !== null ){
			this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));	
		}
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}

		this.getOrder(this.form.idOrder);
  }
   	
	 getOrder(idOrder): void {
		this.orderService.getSingleOrder(idOrder).subscribe(res => {
			this.ordine = res;
			this.form.ordine = res;
			localStorage.setItem('id_product', res.prodottoId);

			if(res.clienteId !== undefined  && res.clienteId !== null){
				localStorage.setItem('id_client', res.clienteId);	
				this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
			}
			
			localStorage.setItem('id_order', res.idordine);
		});

		this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
			this.trascodifiche = res;
      this.trascodificheAccessori =  this.trascodifiche.filter(item => item.codice === constants.ACCESSORI_DEPURATORE_TRASC) 
		});

    this.isLoaded = true;
	}

  saveModule(value): void {
    $('body').removeClass('noScr');
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
    if (this.currentStep === 0) {
			// Step salvataggio anagrafica
			this.anagrafica = this.form.anagrafica.anagrafica;
			if(this.anagrafica.id){
				this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
					// this.openSnackBar('Modulo salvato correttamente');
						this.ordine.clienteId = res.id;
						this.ordine.cognome = res.cognome;
						this.ordine.nome = res.nome;
						this.orderService.update(JSON.stringify(this.ordine)).subscribe();
					}, error => {
						console.error('Error => ', error);
						this.openSnackBar('Errore in fase di salvataggio del modulo');
					});				
			}else{
				this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
					// this.openSnackBar('Modulo salvato correttamente');
						this.ordine.clienteId = res.id;
						this.ordine.cognome = res.cognome;
						this.ordine.nome = res.nome;
						this.form.anagrafica.anagrafica.id = res.id;
						this.orderService.update(JSON.stringify(this.ordine)).subscribe();
					}, error => {
						console.error('Error => ', error);
						this.openSnackBar('Errore in fase di salvataggio del modulo');
					});	
			}				
		}else if(this.currentStep === 1){
      this.depuratore = this.form.sections.depuratore;
      if (this.hasValues(this.depuratore)) {
        this.form.sections.depuratore.ordineId = this.form.idOrder;
        if(this.depuratore.id === null || this.depuratore.id === undefined){
          this.depuratoreService.save(this.depuratore).subscribe(res => {
            this.depuratore = res;
           // this.openSnackBar('Modulo salvato correttamente');
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
          });
        }else {
          this.depuratoreService.update(this.depuratore).subscribe(res => {
            this.depuratore = res;
            // this.openSnackBar('Modulo aggiornato correttamente');
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar('Errore in fase di aggiornamento del modulo');
          }
       );
      }
     }
    }else if(this.currentStep === 2){
      // Faccio il save dell'ordine per salvare le note 
      this.orderService.update(JSON.stringify( this.form.ordine)).subscribe();

      if(this.form.sections.accessoriDepuratore && this.form.sections.accessoriDepuratore.length){
        this.form.sections.accessoriDepuratore.forEach(element => {

            if(element && (element.id === null || element.id === undefined)){
              this.accessoriDepuratoreService.save(element).subscribe(res => {
                if(res){
                  this.form.sections.accessoriDepuratore = res;
                }
              })
            }else{
              this.accessoriDepuratoreService.update(element).subscribe(res => {
                this.form.sections.accessoriDepuratore = res;
              }, error => {
                console.error('Error => ', error);
                this.formController.openSnackBar('Errore in fase di aggiornamento del modulo');
              })
            }
        });
      }
    }
    else if(this.currentStep === 3){
      // Step salvataggio pagamento
      if(this.hasValues(this.form.sections.pagamento[0])){
        this.form.sections.pagamento[0].ordineId = this.form.idOrder;
        const payment = this.form.sections.pagamento[0];
        if (payment.id === undefined || payment.id === null) {
          this.paymentService.save(JSON.stringify(payment)).subscribe();
        } else {
          this.paymentService.update(JSON.stringify(payment)).subscribe();
      }
     }
    }else if (this.currentStep === 4){
      //Step salvataggio privacy
      this.orderService.update(JSON.stringify( this.form.ordine)).subscribe();
    } 
    else if(this.currentStep === 5) {
      this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
        this.anagrafica = res;
        // this.openSnackBar('Modulo salvato correttamente');
        this.ordine.clienteId = res.id;
        this.ordine.cognome = res.cognome;
        this.ordine.nome = res.nome;
      }, error => {
        console.error('Error => ', error);
        this.openSnackBar('Errore in fase di salvataggio del modulo');
      });			
    }

    this.setStep(this.currentStep + 1);
  }

  ngDoCheck(): void {
		this.obj1 = JSON.stringify(this.originalForm);
		this.obj2 = JSON.stringify(this.form);
		if (this.obj1 !== this.obj2) {
			this.checkAnagrafica();
			this.originalForm = JSON.parse(JSON.stringify(this.form));
		}
	}

  
  validPrivacy(): boolean {
		if(!this.form.ordine?.accettprivacy || !this.form.ordine?.accettprivacynd || !this.form.ordine?.accettprivacyrd){
			return false;
		}else
			return true;
	}

  ngAfterViewInit(): void {
		console.log('origina form', this.originalForm);
	}

  setStep(num): void {
		this.currentStep = num;
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}
		this.scrollTop();
	}

  stepController():boolean {
    let disabled = false;

    if(!this.validAnagrafica && this.currentStep === 0){
      disabled = true;
    }
    if(!this.formController.validPagamento(this.form, true) && this.currentStep === 3){
      disabled = true;
    }
    if(this.currentStep === 4 && !this.validPrivacy()){
      disabled = true;
    }

    return disabled;
  }

  scrollTop(): void {
		$('html, body').animate({ scrollTop: 0 }, 300);
	}

  openSnackBar(text): void {
		this.snackbar.open(text, 'Ok', {
			duration: 4000
		});
	}

  checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

  togglePrintPreview(show:boolean): void {
		this.showModalPrint = show;
			jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
			jQuery('#printModal').modal('toggle');
  	}	
	
	returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

  reload(value: boolean) {
		this.openSign = value;
	}
}
