import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MontascaleComponent } from './montascale/montascale.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaintableModule } from 'paintablejs/angular';
import { MaterialModule } from '../../material/material.module';
import { SharedModule } from '../../shared/shared.module';
import { FLOWXCurvilineoComponent } from './montascale/flow-x-curvilineo/flow-x-curvilineo.component';
import { ArrivoInAltoDellaGuidaComponent } from './arrivi/arrivo-in-alto-della-guida/arrivo-in-alto-della-guida.component';
import { ArrivoInBassoDellaGuidaComponent } from './arrivi/arrivo-in-basso-della-guida/arrivo-in-basso-della-guida.component';
import { ArrivoItemComponent } from './arrivi/arrivo-item/arrivo-item.component';
import { DatiTecniciAttualiComponent } from './montascale/dati-tecnici-attuali/dati-tecnici-attuali.component';
import { RilievoMisureSchienaESedileComponent } from './montascale/rilievo-misure-schiena-e-sedile/rilievo-misure-schiena-e-sedile.component';
import { PrintMontascaleComponent } from './print-montascale/print-montascale.component';
@NgModule({
  declarations: [
    MontascaleComponent,
    FLOWXCurvilineoComponent,
    ArrivoInBassoDellaGuidaComponent,
    ArrivoInAltoDellaGuidaComponent,
    ArrivoItemComponent,
    DatiTecniciAttualiComponent,
    RilievoMisureSchienaESedileComponent,
    PrintMontascaleComponent
  ],
  imports: [
    CommonModule,
		MatFormFieldModule,
		MaterialModule,
		SharedModule,
		PaintableModule,
  ]
})
export class ZucchettiModule { }
