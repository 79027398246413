import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnagraficaDTO, AnagraficaForm } from 'src/app/modules/classes/anagrafica';
import { Ordine } from 'src/app/modules/classes/ordine';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { BrandService } from 'src/app/services/brand/brand.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { CaldaiaService } from '../caldaia/services/caldaia.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { environment } from 'src/environments/environment';
import { DettagliocaldaiaDTO } from 'src/app/modules/classes/caldaie/dettagliocaldaia-dto';
import { AbitazioneDTO } from '../classes/abitazione-dto';
import { AbitazioneService } from '../services/abitazione.service';
import { DisegnocaldaiaDTO } from 'src/app/modules/classes/caldaie/disegnocaldaia-dto';
import { AccessoricaldaiaDTO } from 'src/app/modules/classes/caldaie/accessoricaldaia-dto';
import { constants } from 'src/app/modules/classes/constants';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import * as $ from 'jquery';
declare var jQuery: any;

@Component({
  selector: 'app-pompacalore',
  templateUrl: './pompacalore.component.html',
  styleUrls: ['./pompacalore.component.scss']
})
export class PompacaloreComponent implements OnInit {

  showModalPrint: boolean = false;
  validAnagrafica: boolean = false;
  sendingPdf: boolean = false;
  generatingPdf:boolean = false;
  openSign: boolean = false;
  valTipo: boolean = true;
  isFinanziamentoPayment: boolean = false;

  urlFrame:string = "";

  currentStep:number = 0;
  currentPrinting:number = 0;

  ordine: Ordine;
  anagrafica: AnagraficaDTO;

  form: any;
  obj1: any;
  obj2: any;
  pdf: any;
  error: any;
  originalForm: any;

  totalSteps: number[];
  trascodifiche: Trascodifica[];
  listFinanziarie: Trascodifica[];
  attivitaLavList: Trascodifica[] = [];

  finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/ARIEL.jpg', product: 'sistemi ibridi e pompe di calore', brand: 'Ariel'}

  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");
  
  integratedSignature = environment.integratedSignature;

  constructor(
      public formController: FormControllerService, 
      private anagraficaService: AnagraficaService, 
      private route: ActivatedRoute,
      private brandService: BrandService, 
      private orderService: OrdersService, 
      private caldaiaService: CaldaiaService, 
      private paymentService: PaymentService,
      private abitazioneService: AbitazioneService,
      private allegatoService: AllegatoService
  ) {
      this.showModalPrint = false;
      this.validAnagrafica = false;
      this.form = {
        idOrder: Number(this.route.snapshot.paramMap.get('id')),
        anagrafica: new AnagraficaForm(),
        sections: {
          caldaie: [],
          caldaieToDelete: [],
          dettaglioCaldaia: new DettagliocaldaiaDTO(),
          abitazione: new AbitazioneDTO(),
          disegnoCaldaia: new DisegnocaldaiaDTO(),
				  accessori: new AccessoricaldaiaDTO(),
          pagamento: [new PagamentoDTO()],
          finanziamentoSections: {
            finanziamento: new Finanziamento(),
            finAnagrafica: new FinAnagrafica(),
            finDatiOccupazione: new FinDatiOccupazione(),
            finAnagraficaGarante: new FinAnagrafica(),
            finDatiOccupazioneGarante: new FinDatiOccupazione(),
            isAllegatoSaved: false
          },
          ordine: null,
        }
    };
    this.totalSteps = Array(8).fill(1).map((x, i) => i); // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
  }

  ngOnInit(): void {
    this.originalForm = JSON.parse(JSON.stringify(this.form));

    if (localStorage.getItem('id_client') !== null) {
      this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
    }
    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }
    this.getOrder(this.form.idOrder);
  }

  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
    if (this.obj1 !== this.obj2) {
      this.checkAnagrafica();
      this.originalForm = JSON.parse(JSON.stringify(this.form));
    }
  }

  ngAfterViewInit(): void {
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
      localStorage.setItem('id_product', res.prodottoId);
      if (res.clienteId !== undefined && res.clienteId !== null) {
        localStorage.setItem('id_client', res.clienteId);
        this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
      }
      localStorage.setItem('id_order', res.idordine);
    });
    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
    });
    this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
  }

  stepController(): boolean {
    switch (this.currentStep) {
        case 0:
            return this.generatingPdf || !this.validAnagrafica;
        case 1:
            return this.generatingPdf || !this.valTipo;
        case 2:
            return this.generatingPdf || !this.formController.validDatiAbitazione(this.form) || !this.formController.validDisegnoCaldaia(this.form);
        case 3:
            return this.generatingPdf || !this.validAccessori() || !this.formController.validCannaFumaria(this.form) || !this.formController.validDettagliInstallazione(this.form);
        case 4:
            return this.generatingPdf || !this.formController.validPagamento(this.form);
        case 5:
            return this.generatingPdf || !this.formController.validPrivacy(this.form);
        case 6:
            return this.generatingPdf || !this.validAnagrafica;
        case 7:
            return this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie );
        default:
            return this.generatingPdf;
        }
  }

  setStep(num): void {
    this.currentStep = num;
    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    if(this.form.sections.pagamento[0]!.tipopagsaldoId){
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if( tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(8).fill(1).map((x, i) => i);
			}else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(7).fill(1).map((x, i) => i);
			}
		}

    this.scrollTop();
  }

  scrollTop(): void {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  saveModule(): void {
    if (this.currentStep === 0) {
      // Step salvataggio anagrafica
      this.anagrafica = this.form.anagrafica.anagrafica;
      if (this.anagrafica.id) {
        this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
        }, error => {
          console.error('Error => ', error);
          this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
        });
      } else {
        this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.form.anagrafica.anagrafica.id = res.id;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
        }, error => {
          console.error('Error => ', error);
          this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
        });
      }
    } else if (this.currentStep === 1) {
      // Step salvataggio caldaie
      const savingCaldaie = [];
      this.form.sections.caldaie.forEach(r => {
        if (r.checked) {
          savingCaldaie.push(r);
        }
        if (!r.checked && r.id !== undefined) {
          this.removeCaldaia(r.id);
        }
      });
      savingCaldaie.forEach(s => {
        delete s.checked;
        if (s.id !== undefined) {
          this.updateCaldaia(s);
        } else {
          this.saveCaldaia(s);
        }
      });
      this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
    } else if (this.currentStep === 2) {
      // Step salvataggio disegno caldaia
      if (this.hasValues(this.form.sections.disegnoCaldaia)) {
        this.form.sections.disegnoCaldaia.ordineId = this.form.idOrder;
        if (this.form.sections.disegnoCaldaia.id) {
          this.updateDisegno(this.form.sections.disegnoCaldaia);
        } else {
          this.saveDisegno(this.form.sections.disegnoCaldaia);
        }
      }

      // Step salvataggio dati abitazione
      if (this.hasValues(this.form.sections.abitazione)) {
        this.form.sections.abitazione.ordineId = this.form.idOrder;
        if (this.form.sections.abitazione.id) {
          this.abitazioneService.update(JSON.stringify(this.form.sections.abitazione)).subscribe();
        } else {
          this.abitazioneService.save(JSON.stringify(this.form.sections.abitazione)).subscribe();
        }
      }
    } else if (this.currentStep === 3) {
      // Step salvataggio accessori caldaia
      if (this.hasValues(this.form.sections.accessori)) {
        this.form.sections.accessori.ordineId = this.form.idOrder;
        if (this.form.sections.accessori.id) {
          this.updateAccessori(this.form.sections.accessori);
        } else {
          this.saveAccessori(this.form.sections.accessori);
        }
      }
      
    } else if (this.currentStep === 4) {
      // Step salvataggio pagamento
      if (this.hasValues(this.form.sections.pagamento[0])) {
        this.form.sections.pagamento[0].ordineId = this.form.idOrder;
        const payment = this.form.sections.pagamento[0];
        if (payment.id === undefined || payment.id === null) {
          this.paymentService.save(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
					});
        } else {
          this.paymentService.update(JSON.stringify(payment)).subscribe((res: any) => {
						this.form.sections.pagamento[0] = res;
            // se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
            if(res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
              this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
                allegati.forEach((allegato:any) => {
                  if(allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)){
                    this.allegatoService.delete(allegato.id).subscribe();
                  }
                })
              })
            }
					});
        }
      }
    } else if (this.currentStep === 5) {
      //Step salvataggio privacy
      this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
    } else if (this.currentStep === 6) {
      this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
        this.ordine.clienteId = res.id;
        this.ordine.cognome = res.cognome;
        this.ordine.nome = res.nome;
      }, error => {
        console.error('Error => ', error);
        this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
      });
    } 

    this.setStep(this.currentStep + 1);
  }

  removeCaldaia(id): void {
    this.caldaiaService.delete(JSON.stringify(id)).subscribe(res => {
      this.getCaldaie();
    }, error => {
      console.error('Error => ', error);
    }
    );
  }

  saveCaldaia(caldaia): void {
    this.caldaiaService.save(JSON.stringify(caldaia)).subscribe(res => {
      this.getCaldaie();
    }, error => {
      console.error('Error => ', error);
    });
  }

  updateCaldaia(caldaia): void {
    this.caldaiaService.update(JSON.stringify(caldaia)).subscribe(res => {
      this.getCaldaie();
    }, error => {
      console.error('Error => ', error);
    }
    );
  }

  validCaldaie(): boolean {
    let valid = true;
    if (this.form.sections.caldaie.length > 0) {
      for (let i = 0; i < this.form.sections.caldaie.length; i++) {
        if (valid) {
          if (this.form.sections.caldaie[i].checked && !this.form.sections.caldaie[i].tipologiaId) {
            valid = false;
          } else if (this.form.sections.caldaie[i].checked && this.form.sections.caldaie[i].tipologiaId && this.form.sections.caldaie[i].modcaldcondecowdId && !this.form.sections.caldaie[i].coloreId) {
            valid = false;
          } else {
            valid = true;
          }
        }
      }
    }
    return valid;
  }

  togglePrintPreview(show: boolean): void {
    this.showModalPrint = show;
    jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#printModal').modal('toggle');
  }

  returnIdOrdine(): string {
    return localStorage.getItem('id_order');
  }

  closeSignPdf() {
    jQuery('#signModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#signModal').modal('toggle');
  }

  getCaldaie(): void {
    this.caldaiaService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      this.form.sections.caldaie = res;
    });
  }

  validTipologia(event) {
    if (event) {
      return this.valTipo = true;
    } else {
      return this.valTipo = false;
    }
  }

  saveAccessori(accessori): void {
		this.caldaiaService.saveAccessori(JSON.stringify(accessori)).subscribe(res => {
		}, error => {
			console.error('Error => ', error);
		});
	}
	updateAccessori(accessori): void {
		this.caldaiaService.updateAccessori(JSON.stringify(accessori)).subscribe(res => {
		}, error => {
			console.error('Error => ', error);
		}
		);
	}

  saveDisegno(disegno): void {
		this.caldaiaService.saveDisegno(JSON.stringify(disegno)).subscribe(res => {
			// this.openSnackBar('Modulo salvato correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di salvataggio del modulo');
		});
	}
	updateDisegno(disegno): void {
		this.caldaiaService.updateDisegno(JSON.stringify(disegno)).subscribe(res => {
			// this.openSnackBar('Modulo aggiornato correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di aggiornamento del modulo');
		}
		);
	}

  validAccessori(): boolean {
		if (this.form.sections.accessori.cronowifi && !this.form.sections.accessori.numcronowifi){
			return false;
		}
		if (this.form.sections.accessori.cronostand && !this.form.sections.accessori.numcronostand){
			return false;
		}
		return true;
	}

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

  reload(value: boolean) {
		this.openSign = value;
	}
}
