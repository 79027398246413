import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayOneComponent } from './payment/pay-one/pay-one.component';
import { MaterialModule } from '../material/material.module';
import { AnagraficaShComponent } from './anagrafica-sh/anagrafica-sh.component';
import { DisegnoTecnicoComponent } from './disegno-tecnico/disegno-tecnico.component';
import { PaintableModule } from 'paintablejs/angular';
import { InputNumberComponent } from './input-number/input-number.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { OmaggioShComponent } from './omaggio-sh/omaggio-sh.component';
import { ContactsCheckComponent } from './contacts-check/contacts-check.component';
import { PaymentInputNumberComponent } from './payment-input-number/payment-input-number.component';
import { DndDirective } from './upload-document/dnd.directive';
import { UploadAreaComponent } from './upload-document/upload-area/upload-area.component';
import { ProgressComponent } from './upload-document/upload-area/progress/progress.component';
import { SafePipe } from 'src/app/safe.pipe';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { CreateEstimateComponent } from './create-estimate/create-estimate.component';
import { PrintComponent } from "./pdf/print/print.component";
import { PreviewComponent } from './pdf/preview/preview.component';
import { PdfViewerModule } from "ng2-pdf-viewer";
import { PromoBagniComponent } from './promo-bagni/promo-bagni.component';
import { FinanziamentoComponent } from './payment/finanziamento/finanziamento.component';
import { FormFinanziamentoComponent } from './payment/finanziamento/form-finanziamento/form-finanziamento.component';
import { FinanziamentoPrintComponent } from './pdf/finanziamento-print/finanziamento-print.component';

@NgModule({
  declarations: [
    PayOneComponent,
    AnagraficaShComponent,
    DisegnoTecnicoComponent,
    InputNumberComponent,
    PrivacyComponent,
    UploadDocumentComponent,
    OmaggioShComponent,
    ContactsCheckComponent,
    PaymentInputNumberComponent,
    DndDirective,
    UploadAreaComponent,
    ProgressComponent,
    SafePipe,
    ErrorModalComponent,
    CreateEstimateComponent,
    PrintComponent,
    PromoBagniComponent,
    PreviewComponent,
    FinanziamentoComponent,
    FormFinanziamentoComponent,
    FinanziamentoPrintComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
	  PaintableModule,
    PdfViewerModule,
  ],
  exports: [
    PayOneComponent,
    AnagraficaShComponent,
	  DisegnoTecnicoComponent,
    InputNumberComponent,
	  PrivacyComponent,
	  OmaggioShComponent,
    ContactsCheckComponent,
    SafePipe,
    ErrorModalComponent,
    PrintComponent,
    PromoBagniComponent,
    PreviewComponent,
    FinanziamentoComponent,
    FormFinanziamentoComponent,
    FinanziamentoPrintComponent
  ]
})
export class SharedModule { }
