export class AccessoristufaDto {
	installazionestand: false;
	intubamentocannapiani: number;
	accessotettobotola: false;
	instalstandesterna: number;
	instalstandinterna: number;
	forosolettaqta: number;
	forocementoqta: number;
	forocementocm: number;
	foromuroqta: number;
	note: string;
	ponteggio: false;
	cestaidr: false;
	scambiatore: false;
	aspiracenere: false;
	modulowifi: false;
	pelletomaggio: false;
	ordineId: number;
	pufferId: number;
	pufferDescrizione:string;
	suoloPubblico:boolean;
}

