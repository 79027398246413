<p>allarmi works!</p>

<div class="pt-5 text-center mb-4"></div>
<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 6 && openSign" (click)="setStep(currentStep-1)"
            class="btnMainColor btnColor" mat-flat-button>
            < Indietro </button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button [disabled]="generatingPdf && isLoaded || stepController()" class="btnMainColor btnColor" mat-flat-button
            (click)="saveModule()">Avanti ></button>
        <div>
            <div class="smallFont text-danger" *ngIf="stepController()">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 4 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
    <div class="col-6 text-right">
    </div>
</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" class="" src="assets/img/loghi/mondialAllarmi.png" />
            </span>
            Modulo d'ordine Mondial allarmi
        </h4>
    </div>
    <hr />
    <div class="moduleContainer mb-3">
        <div id="toPrint">
            <div class="" *ngIf="currentStep == 0">
                <app-anagrafica-sh [brand]="'mondialAllarmi'" [validAnagrafica]="validAnagrafica"
                    [form]="form"></app-anagrafica-sh>
            </div>
            <div class="" *ngIf="currentStep == 1">
                <app-modello [form]="form" [trascodifiche]="trascodifiche"
                    [trascodificheModello]="trascodificheModello"></app-modello>
            </div>
            <div class="" *ngIf="currentStep == 2">
                <app-accessori-allarme [form]="form"
                    [trascodificheAcc]="trascodificheAccessori"></app-accessori-allarme>
            </div>
            <div class="" *ngIf="currentStep == 3">
                <app-pay-one [form]="form" [brand]="'mondial'" [prodotto]="'allarme'"
                    [trascodifiche]="trascodifiche"></app-pay-one>
            </div>
            <div class="" *ngIf="currentStep == 4">
                <app-privacy [form]="form" [brand]="'mondial'"></app-privacy>
            </div>

            <div class="" *ngIf="currentStep == 5">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div>

            <div class="" *ngIf="currentStep == 6 && isFinanziamentoPayment">
                <app-finanziamento [form]="form" [listFinanziarie]="listFinanziarie" [productColor]="'#0a2e5e'"
                    [pdfDetails]="finanziamentoPdfHeader"></app-finanziamento>
            </div>

            <ng-container *ngIf="currentStep == 6 && !isFinanziamentoPayment; else nextStepWithFinance">
                <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
            </ng-container>

            <ng-template #nextStepWithFinance>
                <ng-container *ngIf="currentStep == 7">
                    <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
                </ng-container>
            </ng-template>

            <ng-template #signatureAndPrint>
                <div class="lastStep">
                    <div class="text-center">
                        <div class="digitalSignatureButtons" *ngIf="openSign" style="height: 124px">
                            <div class="signButton" (click)="edit()">
                                <span class="material-icons d-block">edit</span>
                                <span class="d-block mb-2">Modifica Contratto</span>
                            </div>
                        </div>
                        <div class="" *ngIf="form.idOrder">
                            <app-print-allarme [anagrafica]="anagrafica" [idOrder]="form.idOrder"
                                [trascodifiche]="trascodifiche" [form]="form"
                                (reload)="reload($event)"></app-print-allarme>
                        </div>
                    </div>
                </div>
            </ng-template>



            <!--<div class="lastStep" *ngIf="currentStep == 6">
                <div class="text-center">
                    <div class="digitalSignatureButtons" *ngIf="currentStep == 6 && openSign" style="height: 124px">
                        <div class="signButton" (click)="edit()">
                            <span class="material-icons d-block">
                                edit
                            </span>
                            <span class="d-block mb-2">Modifica Contratto</span>
                        </div>
                    </div>
                    <app-print-allarme [anagrafica]="anagrafica" [idOrder]="form.idOrder"
                        [trascodifiche]="trascodifiche" [form]="form" (reload)="reload($event)"></app-print-allarme>
                </div>
            </div>-->
        </div>
    </div>
</div>

<div class="row buttonRow">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 6 && openSign" (click)="setStep(currentStep-1)"
            class="btnMainColor btnColor" mat-flat-button>
            < Indietro </button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button [disabled]="generatingPdf && isLoaded || stepController()" class="btnMainColor btnColor" mat-flat-button
            (click)="saveModule()">Avanti ></button>
        <div>

            <div class="smallFont text-danger" *ngIf="stepController()">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 4 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
</div>

<div class="stepper">
    <div class="row">
        <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
            <div class="stepNumber" [ngClass]="
            {
            'doneStep': currentStep > step,
            'currentStep': currentStep == step            
            }">
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="            
            {
            'currentStep': currentStep == totalSteps.length
            }">
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="printModal" tabindex="-1">
    <div class="modal-dialog modal-xxl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Anteprima contratto {{returnIdOrdine()}}</h3>
                <button type="button" class="close" (click)="togglePrintPreview(false)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mt-5" *ngIf="trascodifiche && showModalPrint">
                    <app-print-allarme [trascodifiche]="trascodifiche" [form]="form" [modale]="true"
                        (reload)="reload($event)"></app-print-allarme>
                </div>
            </div>
            <div class="modal-footer">
                <button mat-button (click)="togglePrintPreview(false)">Chiudi anteprima</button>
            </div>
        </div>
    </div>
</div>