import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

import {RivestimentiService} from '../services/rivestimenti/rivestimenti.service';
import {RivestimentiDTO, Rivestimenti} from '../classes/dati-tecnici';

import modelli from 'src/assets/modelli.json';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
@Component({
  selector: 'app-rivestimenti',
  templateUrl: './rivestimenti.component.html',
  styleUrls: ['./rivestimenti.component.scss']
})
export class RivestimentiComponent implements OnInit {
  @Input() form: any;
  
  constructor(private rivestimentiService : RivestimentiService, private formController : FormControllerService ) {

  }

  ngOnInit(): void {
	
	//recupero i dati rivestimenti se presenti ordineId.equals=
	this.rivestimentiService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
			this.form.sections.rivestimenti = this.formController.mappingRivestimentiDTOtoForm(res[0],this.form.sections.rivestimenti, this.form.idOrder);
	})	
	
  }


  


}
