<div class="pt-5 text-center mb-4">
</div>
<!--
<div class="rowbutton">
    <button mat-button (click)="toggleJson()">toggle json</button>
</div>

<div class="formJson">
    <pre>
        <code>
            {{form|json}}
        </code>
    </pre>
</div>
-->

<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 8 && openSign" (click)="setStep(currentStep-1)"
            class="btnMainColor btnColor" mat-flat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button
            [disabled]="generatingPdf || (!validAnagrafica && currentStep === 0) || (!validAnagrafica && currentStep === 7) || (!validPromo() && currentStep === 1) || (!validPiani() && currentStep === 2) || (!form.sections.abitazione.disegnotecnico && currentStep === 2) || (!validInstAccessori() && currentStep === 3) || (currentStep === 6 && !validPrivacy()) || (currentStep === 5 && !this.formController.validPagamento(this.form)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))"
            class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">
            Avanti >
        </button>
        <div>
            <!-- <button mat-button [disabled]="!validAnagrafica || !validTecnic() || !validNuovaCassetta() || !validNuoviSanitari() || !validNuovaRubinetteria()" (click)="sendModule()">Invia modulo</button> -->
            <div class="smallFont text-danger"
                *ngIf="(!validAnagrafica && currentStep === 0) || (!validAnagrafica && currentStep === 7) || (!validPromo() && currentStep === 1) || (!validPiani() && currentStep === 2) || (!form.sections.abitazione.disegnotecnico && currentStep === 2) || (!validInstAccessori() && currentStep === 3) || (currentStep === 5 && !this.formController.validPagamento(this.form)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 6 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>

    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
    <div class="col-6 text-right">
        <!-- <button mat-button [disabled]="!validAnagrafica">Invia modulo</button>
        <div class="smallFont text-danger" *ngIf="!validAnagrafica">
            Compila correttamente il punto 1 del modulo d'ordine
        </div>-->
    </div>
</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" class="" src="assets/img/loghi/ARIEL.jpg" />
            </span>
            Modulo d'ordine condizionatore Ariel
        </h4>
    </div>
    <hr />
    <div class="moduleContainer mb-3">
        <div id="toPrint">
            <div class="" *ngIf="currentStep == 0">
                <app-anagrafica-sh [brand]="'ariel'" [validAnagrafica]="validAnagrafica"
                    [form]="form"></app-anagrafica-sh>
            </div>

            <div class="" *ngIf="currentStep == 1">
                <div class="text-center mb-3">

                </div>
                <div>
                    <app-promo [form]="form" [trascodifiche]="trascodifiche"></app-promo>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 2">
                <div class="text-center mb-3">

                </div>
                <div>
                    <app-abitazione [form]="form" [trascodifiche]="trascodifiche"></app-abitazione>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 3">
                <div class="text-center mb-3">

                </div>
                <div>
                    <app-installazione-accessori [form]="form" [trascodifiche]="trascodifiche">
                    </app-installazione-accessori>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 4">
                <div class="text-center mb-3">

                </div>
                <div>
                    <app-omaggio-sh [form]="form" [trascodifiche]="trascodifiche"></app-omaggio-sh>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 5">
                <div class="text-center mb-3">

                </div>
                <div>
                    <app-pay-one [form]="form" [brand]="'ariel'" [prodotto]="'condizionatori'"
                        [trascodifiche]="trascodifiche"></app-pay-one>
                </div>
            </div>

            <div class="lastStep" *ngIf="currentStep == 6">
                <app-privacy [form]="form" [brand]="'condizionatori'"></app-privacy>
            </div>

            <div *ngIf="currentStep == 7">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div>

            <div class="lastStep" *ngIf="currentStep == 8">
                <div class="text-center">

                    <div class="digitalSignatureButtons" *ngIf="currentStep == 8 && openSign" style="height: 124px">
                        <div class="signButton" (click)="edit()">
                            <span class="material-icons d-block">
                                edit
                            </span>
                            <span class="d-block mb-2">Modifica Contratto</span>
                        </div>
                    </div>

                    <div class="" *ngIf="form.idOrder">
                        <app-print-cond [trascodifiche]="trascodifiche" [form]="form"
                            (reload)="reload($event)"></app-print-cond>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row buttonRow">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 8 && openSign" (click)="setStep(currentStep-1)"
            class="btnMainColor btnColor" mat-flat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button
            [disabled]="generatingPdf ||  (!validAnagrafica && currentStep === 0) || (!validAnagrafica && currentStep === 7) || (!validPromo() && currentStep === 1) || (!validPiani() && currentStep === 2) || (!form.sections.abitazione.disegnotecnico && currentStep === 2) || (!validInstAccessori() && currentStep === 3) || (currentStep === 6 && !validPrivacy()) || (currentStep === 5 && !this.formController.validPagamento(this.form)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))"
            class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">Avanti ></button>
        <div>
            <!-- <button mat-button [disabled]="!validAnagrafica || !validTecnic() || !validNuovaCassetta() || !validNuoviSanitari() || !validNuovaRubinetteria()" (click)="sendModule()">Invia modulo</button> -->
            <div class="smallFont text-danger"
                *ngIf="(!validAnagrafica && currentStep === 0) || (!validAnagrafica && currentStep === 7) || (!validPromo() && currentStep === 1) || (!validPiani() && currentStep === 2) || (!form.sections.abitazione.disegnotecnico && currentStep === 2) || (!validInstAccessori() && currentStep === 3) || (currentStep === 5 && !this.formController.validPagamento(this.form)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 6 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>

</div>

<div class="stepper">
    <div class="row">
        <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
            <div class="stepNumber" [ngClass]="
            {
            'doneStep': currentStep > step,
            'currentStep': currentStep == step,
            'errorStep': (!validAnagrafica && currentStep === step) || (!validPromo() && currentStep === step) || (!validPiani() && currentStep === step) || (!form.sections.abitazione.disegnotecnico && currentStep === step) || (!validInstAccessori() && currentStep === step) ||(currentStep === step && !validPrivacy()) || (currentStep === step && !this.formController.validPagamento(this.form))
            }"> <!-- (click)="setStep(step)" -->
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="
            {
            'currentStep': currentStep == totalSteps.length
            }"> <!-- (click)="setStep(totalSteps.length)" -->
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="errorModal" tabindex="-1">
    <app-error-modal [error]="error"></app-error-modal>
</div>



<div class="modal fade" id="printModal" tabindex="-1">
    <div class="modal-dialog modal-xxl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Anteprima contratto {{returnIdOrdine()}}</h3>
                <button type="button" class="close" (click)="togglePrintPreview(false)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="mt-5" *ngIf="trascodifiche && showModalPrint">
                    <app-print-cond [trascodifiche]="trascodifiche" [form]="form" [modale]="true"></app-print-cond>
                </div>
            </div>
            <div class="modal-footer">
                <button mat-button (click)="togglePrintPreview(false)">Chiudi anteprima</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="timeoutModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Tempo limite generazione contratto</h3>
                <button type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Richiesta presa in carico, il nostro Customer Care provveder&agrave; ad inviare al cliente il
                    contratto per la firma.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mt-5 fidexPdf" *ngIf="trascodifiche && generatingPdf">
    <app-print-cond [trascodifiche]="trascodifiche" [form]="form"></app-print-cond>
</div>