<div>
    <div id="headingOne">
        <h5 class="mb-0">
            <div class="text-center {{classBg}} row">
                <div class="col-6 text-right"> {{'NUOVO BOX DOCCIA'}}
                    <mat-hint class="text-danger">*</mat-hint>
                </div>
                <div class="col-6 text-left">
                    <mat-radio-group labelPosition="after" class="inline" color="accent"
                        [(ngModel)]="optionBoxDoccia.selected" (ngModelChange)="showDiv($event)">
                        <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
                        <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
                    </mat-radio-group>

                </div>
            </div>
        </h5>
    </div>
	
    <div id="collapseSix" *ngIf="isVisible" aria-labelledby="headingOne" data-parent="#accordionSixteen" [ngClass]="{'onlyRedBorder': !this.formController.validNuoviSan(this.form)}" >
        <div>
            <div class="{{classContent}}">
                <div *ngFor="let element of form.sections.boxdoccia; let i = index;let l = last;">
                    <div class="row">
                        <div class="col-4">
                            <mat-form-field class="full-width">
                                <mat-label>Modello</mat-label>
                                <mat-select [(ngModel)]="element.modelloId"
                                    (ngModelChange)="checkModel($event,i); checkProf($event,i); checkIngr($event,i); checkLarg($event,i);">
                                    <mat-option>-- Modello --</mat-option>
                                    <mat-option *ngFor="let model of boxes" [value]="model.id">{{model.descrizione}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                           <mat-form-field class="full-width"  *ngIf="element.modelloId">
                                <mat-label>Cristalli</mat-label>
                                <mat-select [(ngModel)]="element.cristalliId">
                                    <mat-option>-- Cristalli --</mat-option>
                                    <mat-option *ngFor="let model of element.listCristalli" [value]="model.id">
                                        {{model.descrizione}}</mat-option>
                                </mat-select>
                                 <mat-hint class="text-danger" *ngIf="element.modelloId && !element.cristalliId">
                                  Campo obbligatorio
                              </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-4">
                            <mat-form-field class="full-width" *ngIf="element.modelloId && element.cristalliId">
                                <mat-label>Profilo</mat-label>
                                <mat-select [(ngModel)]="element.profiloId">
                                    <mat-option>-- Profilo --</mat-option>
                                    <mat-option *ngFor="let model of element.listProfili" [value]="model.id">
                                        {{model.descrizione}}</mat-option>
                                </mat-select>
                                <mat-hint class="text-danger" *ngIf="element.modelloId && !element.profiloId">
                                  Campo obbligatorio
                              </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4" *ngIf="element.modelloId && element.profiloId">
                            <mat-form-field class="full-width">
                                <mat-label>Larghezza</mat-label>
                                <mat-select [(ngModel)]="element.larghezzaId">
                                    <mat-option>-- Larghezza --</mat-option>
                                    <mat-option *ngFor="let larghezza of element.listLarghezze" [value]="larghezza.id">
                                        {{larghezza.descrizione}}</mat-option>
                                </mat-select>
                                  <mat-hint class="text-danger" *ngIf="element.modelloId && !element.larghezzaId">
                                  Campo obbligatorio
                              </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="col-4" *ngIf="element.modelloId && element.larghezzaId">
                            <div class="mb-2">Ingresso</div>
                            <mat-radio-group aria-labelledby="example-radio-group-label"
                                [(ngModel)]="element.ingressoId" color="primary">
                                <mat-radio-button class="d-inline-block mr-2" *ngFor="let model of element.listIngressi"
                                    [value]="model.id">
                                    {{model.descrizione}}
                                </mat-radio-button>
                                <mat-hint class="text-danger" *ngIf="element.modelloId && !element.ingressoId">
                                  Campo obbligatorio
                              </mat-hint>
                            </mat-radio-group>

                        </div>
                        <div class="col-4 align-self-center" *ngIf="form.sections.boxdoccia.length >= 1">
                            <button mat-icon-button color="warn" (click)="removeModel(i)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </div>
                        <div class="col-12" *ngIf="!l">
                            <hr />
                        </div>
                    </div>
                </div>
                <div>
                    <button mat-flat-button class="{{classButton}}" (click)="addBox()">Aggiungi box</button>
                </div>
            </div>
        </div>


    </div>



</div>