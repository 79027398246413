import { Component, OnInit, Input } from '@angular/core';
import { OmaggioService } from '../../seller/bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { Trascodifica } from '../../classes/trascodifica';
import { FormControllerService } from '../../../services/controller/form-controller.service';
import { OmaggioDto } from '../../classes/omaggio-dto';
import { constants } from '../../classes/constants';
@Component({
  selector: 'app-omaggio-sh',
  templateUrl: './omaggio-sh.component.html',
  styleUrls: ['./omaggio-sh.component.scss']
})
export class OmaggioShComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() brand: string = 'default';
  @Input() prodotto: string = 'default';

  availableOmaggio: Trascodifica[];

  public constants = constants;

  constructor(
    private formController: FormControllerService, 
    private omaggioService: OmaggioService
  ) { }

  ngOnInit(): void {
    this.form.sections.omaggio = [];
    this.omaggioService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      for (var i = 0; i < res.length; i++) {
        const omaggio = res[i];
        this.form.sections.omaggio.push(omaggio);
      }
      if (this.brand !== 'default' && this.prodotto !== 'default') {
        this.availableOmaggio = this.formController.getTrascodificaByCodice(constants.OMAGGIO_ALLARME, this.trascodifiche);
      } else {
        this.availableOmaggio = this.formController.getTrascodificaByCodice( constants.COD_OMAGGIO_TIPOLOGIA, this.trascodifiche);
      }
    })
  }

  alreadySelected(value): boolean {
    const found = this.form.sections.omaggio.findIndex(r => r.id === value);
    return found !== - 1;
  }

  addOmaggio(): void {
    this.form.sections.omaggio.push(new OmaggioDto());
  }

  removeItem(i): void {
    if (this.form.sections.omaggio[i].id !== undefined) {
      this.omaggioService.delete(this.form.sections.omaggio[i].id).subscribe();
    }
    this.form.sections.omaggio.splice(i, 1);
  }

  checkItem(event, i): void {
    if (event == null) {
      this.form.sections.omaggio[i] = new OmaggioDto();
    }
  }

  getMessageOmaggioInstallazione(): boolean {    
    if(this.form.sections.omaggio?.length > 0 && this.prodotto == 'caldaia'){
      let result = this.form.sections.omaggio.filter((el: any ) => this.formController.getTrascodificaById(el.tipologiaId, this.availableOmaggio)?.descrizione?.includes('installazione'))
      return !!result.length;
    }else{
      return false;
    }
  }
}
