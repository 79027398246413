import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { RegistrationComponent } from './registration/registration.component';
import { ForgotComponent } from './forgot/forgot.component';
import { AuthGuard } from './guards/auth.guard';
import { FinishforgotComponent } from './finishforgot/finishforgot.component';
import { UseractivationComponent } from './useractivation/useractivation.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { NewOrderComponent } from './modules/seller/new-order/new-order.component';
import { ArielMainComponent } from './modules/seller/ariel/ariel-main/ariel-main.component';
import { MainBagniComponent } from './modules/seller/bagni-italiani/main-bagni/main-bagni.component';
import { BagnoComponent } from './modules/seller/bagni-italiani/main-bagni/bagno/bagno.component';
import { OrdersListComponent } from './modules/orders/orders-list/orders-list.component';
import { CreateOrderComponent } from './modules/shared/create-order/create-order.component';
import { StufeComponent } from './modules/seller/ariel/ariel-main/stufe/stufe.component';
import { CaldaiaComponent } from './modules/seller/ariel/ariel-main/caldaia/caldaia.component';
import { CondizionatoreComponent } from './modules/seller/ariel/ariel-main/condizionatore/condizionatore.component';
import { VascaInDocciaComponent } from './modules/seller/remail/remail-main/vasca-in-doccia/vasca-in-doccia.component';
import { UploadDocumentComponent } from './modules/shared/upload-document/upload-document.component';
import { SearchOrdersComponent } from './modules/orders/search-orders/search-orders.component';
import { Help75Component } from './modules/seller/bagni-italiani/help75/help75.component';
import { OrdersFrozenComponent } from './modules/orders/orders-frozen/orders-frozen.component';
import { FotovoltaicoComponent } from './modules/seller/ariel/ariel-main/fotovoltaico/fotovoltaico.component';
import { PompacaloreComponent } from './modules/seller/ariel/ariel-main/pompacalore/pompacalore.component';
import { BagnoPraticoComponent } from './modules/seller/bagni-italiani/bagno-pratico/bagno-pratico.component';
import { TechDashboardComponent } from './main/tech-dashboard/tech-dashboard.component';
import { DashboardGuard } from './guards/dashboard/dashboard.guard';
import { TechDashboardGuard } from './guards/dashboard/tech-dashboard/tech-dashboard.guard';
import { NewEstimateComponent } from './modules/estimate/new-estimate/new-estimate.component';
import { CreateEstimateComponent } from './modules/shared/create-estimate/create-estimate.component';
import { FotovoltaicoUTComponent } from './modules/seller/ariel/ariel-main/fotovoltaico-ut/fotovoltaico-ut.component';
import { EstimateListComponent } from './modules/estimate/estimate-list/estimate-list.component';
import { MontascaleComponent } from './modules/seller/zucchetti/montascale/montascale.component';
import { DepuratoreAcquaComponent } from './modules/seller/ariel/ariel-main/depuratore-acqua/depuratore-acqua.component';
import { CristalBoxVascaInDocciaComponent } from './modules/seller/cristalbox/cristalbox-main/vasca-in-doccia/vasca-in-doccia.component';
import { AllarmiComponent } from './modules/seller/mondial/allarmi/allarmi.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'LOGIN' },
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    data: { title: 'REGISTRATION' },
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    data: { title: 'FORGOT' },
  },
  {
    path: 'login/finish',
    component: FinishforgotComponent,
    data: { title: 'RESET_PASSWORD' },
  },
  {
    path: 'login/activate',
    component: UseractivationComponent,
    data: { title: 'USER_ACTIVATION' },
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'DASHBOARD' },
        canActivate: [AuthGuard, DashboardGuard],
      },
      {
        path: 'tech_dashboard',
        component: TechDashboardComponent,
        data: { title: 'TECH_DASHBOARD' },
        canActivate: [AuthGuard, TechDashboardGuard]
      },
      {
        path: 'new-order',
        component: NewOrderComponent,
        data: { title: 'NEW_ORDER' },
        canActivate: [AuthGuard, DashboardGuard],
      },
      {
        path: 'new-estimate',
        component: NewEstimateComponent,
        data: { title: 'NEW_QUOTE' },
        canActivate: [AuthGuard, TechDashboardGuard],
      },
      {
        path: 'create-estimate',
        component: CreateEstimateComponent,
        data: { title: 'CREATE_ESTIMATE' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-estimate/ARIEL/fotovoltaico/:id',
        component: FotovoltaicoUTComponent,
        data: { title: 'FOTOVOLTAICO' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/ARIEL',
        component: ArielMainComponent,
        data: { title: 'ARIEL' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/ARIEL/stufe/:id',
        component: StufeComponent,
        data: { title: 'STUFE' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/ARIEL/caldaia/:id',
        component: CaldaiaComponent,
        data: { title: 'CALDAIA' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/ARIEL/condizionatore/:id',
        component: CondizionatoreComponent,
        data: { title: 'CONDIZIONATORE' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/ARIEL/fotovoltaico/:id',
        component: FotovoltaicoComponent,
        data: { title: 'FOTOVOLTAICO' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/ARIEL/pompacalore/:id',
        component: PompacaloreComponent,
        data: { title: 'POMPA DI CALORE' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/ARIEL/depuratore/:id',
        component: DepuratoreAcquaComponent,
        data: { title: 'ARIEL' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/BAGNI_ITALIANI',
        component: MainBagniComponent,
        data: { title: 'BAGNI_ITALIANI' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/BAGNI_ITALIANI/bagno/:id',
        component: BagnoComponent,
        data: { title: 'BAGNI_ITALIANI' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/BAGNO_PRATICO/bagno/:id',
        component: BagnoPraticoComponent,
        data: { title: 'BAGNO PRATICO' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/ZUCCHETTI/montascale/:id',
        component: MontascaleComponent,
        data: { title: 'ZUCCHETTI' },
        canActivate: [AuthGuard],
      },
      {
        path: 'order-list',
        component: OrdersListComponent,
        data: { title: 'LISTA_ORDINI' },
        canActivate: [AuthGuard],
      },
      {
        path: 'estimate-list',
        component: EstimateListComponent,
        data: { title: 'LISTA _PREVENTIVI' },
        canActivate: [AuthGuard]
      },
      {
        path: 'order-frozen-list',
        component: OrdersFrozenComponent,
        data: { title: 'LISTA_ORDINI_CONGELATI' },
        canActivate: [AuthGuard],
      },
      {
        path: 'create-order/:id',
        component: CreateOrderComponent,
        data: { title: 'CREATE_ORDER' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/REMAIL/vascaindoccia/:id',
        component: VascaInDocciaComponent,
        data: { title: 'VASCA_IN_DOCCIA' },
        canActivate: [AuthGuard],
      },
      {
        path: 'upload-document/:id/:gdlid',
        component: UploadDocumentComponent,
        data: { title: 'UPLOAD_DOCUMENT' },
        canActivate: [AuthGuard],
      },
      {
        path: 'search-order',
        component: SearchOrdersComponent,
        data: { title: 'RICERCA_ORDINI' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/BAGNI_ITALIANI/help75/:id',
        component: Help75Component,
        data: { title: 'BAGNI_ITALIANI HELP75' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/CRISTALBOX/vascaindoccia/:id',
        component: CristalBoxVascaInDocciaComponent,
        data: { title: 'VASCA_IN_DOCCIA' },
        canActivate: [AuthGuard],
      },
      {
        path: 'new-order/MONDIAL/allarme/:id',
        component: AllarmiComponent,
        data: { title: 'ALLARME' },
        canActivate: [AuthGuard],
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
