<div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                DATI ABITAZIONE
            </div>
            <div *ngIf="trascodifiche && tipiAbitazione">
                <mat-radio-group class="smalFt" [(ngModel)]="form.sections.abitazione.tipoabitazioneId">
                    <mat-radio-button color="primary" class="mr-2" *ngFor="let type of tipiAbitazione; let t = index;" [value]="type.id">
                        {{type.descrizione}}
                    </mat-radio-button>
                   	<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.abitazione.tipoabitazioneId" (click)="form.sections.abitazione.tipoabitazioneId = null">
                          backspace
                    </span>                    
                </mat-radio-group>
                <hr/>

                <app-input-number
                        (onNumChange)="form.sections.abitazione.murospess = $event"
                        [step]="0.1"
                        [num]="form.sections.abitazione.murospess"
                        [decimal]="true"
                        [label]="'Spessore muro cm'"></app-input-number>
                <!--
                <mat-form-field class="full-width">
                    <mat-label>Spessore muro cm.</mat-label>
                    <input matInput type="number" min="0" step=".01" [(ngModel)]="form.sections.abitazione.murospess">
                    <button *ngIf="form.sections.abitazione.murospess" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.murospess=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                -->

                <app-input-number
                        (onNumChange)="form.sections.abitazione.pianointerv = $event"
                        [step]="1"
                        [num]="form.sections.abitazione.pianointerv"
                        [decimal]="false"
                        [label]="'Piano caldaia'"></app-input-number>
                <div *ngIf="!form.sections.abitazione.pianointerv && form.sections.abitazione.pianointerv!=0" class="mb-3 smallFont text-danger">Campo obbligatorio</div>
                    <!--
                <mat-form-field class="full-width">
                    <mat-label>Piano caldaia</mat-label>
                    <input matInput type="number" min="0" [(ngModel)]="form.sections.abitazione.pianointerv">
                    <button *ngIf="form.sections.abitazione.pianointerv" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.pianointerv=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                -->

                <mat-form-field class="full-width">
                    <mat-label>Locale caldaia</mat-label>
                    <input matInput type="text" [(ngModel)]="form.sections.abitazione.locale">
                    <button *ngIf="form.sections.abitazione.locale" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.locale=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>

                <app-input-number
                        (onNumChange)="form.sections.abitazione.pianistabile = $event"
                        [step]="1"
                        [num]="form.sections.abitazione.pianistabile"
                        [decimal]="false"
                        [label]="'Numero piani stabile'"></app-input-number>
                <div *ngIf="!form.sections.abitazione.pianistabile && form.sections.abitazione.pianistabile!=0" class="mb-3 smallFont text-danger">Campo obbligatorio</div>
                <!--
                <mat-form-field class="full-width">
                    <mat-label>Numero piani stabile</mat-label>
                    <input matInput type="number" min="0" [(ngModel)]="form.sections.abitazione.pianistabile">
                    <button *ngIf="form.sections.abitazione.pianistabile" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.pianistabile=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                -->
                <app-input-number
                        (onNumChange)="form.sections.abitazione.mq = $event"
                        [step]="1"
                        [num]="form.sections.abitazione.mq"
                        [decimal]="false"
                        [label]="'MQ'"></app-input-number>
                <div *ngIf="(!form.sections.abitazione.mq && form.sections.abitazione.mq!=0) || form.sections.abitazione.mq<1" class="mb-3 smallFont text-danger">Campo obbligatorio</div>

                <!--
               	<mat-form-field class="full-width">
                    <mat-label>MQ</mat-label>
                    <input matInput type="number" min="0" [(ngModel)]="form.sections.abitazione.mq">
                    <button *ngIf="form.sections.abitazione.mq" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.mq=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                -->

                 <hr/>
                <div class="mb-2">Canna fumaria:</div>
                <mat-radio-group class="d-inline-block smalFt" [(ngModel)]="form.sections.abitazione.cannafumariaId">
                    <mat-radio-button color="primary" class="mr-2" *ngFor="let type of tipiCannaFumaria; let t = index;" [value]="type.id">
                        {{type.descrizione}}
                    </mat-radio-button>
                   	<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.abitazione.cannafumariaId" (click)="form.sections.abitazione.cannafumariaId = null">
                          backspace
                    </span>                     
                </mat-radio-group>
                <div class="smallFont text-danger mb-3" *ngIf="!form.sections.abitazione.cannafumariaId">Campo obbligatorio</div>
                
            </div>

        </div>
