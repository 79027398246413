import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { RemailService } from '../../../services/remail.service';

@Component({
  selector: 'app-datitecnicivasca',
  templateUrl: './datitecnicivasca.component.html',
  styleUrls: ['./datitecnicivasca.component.scss']
})
export class DatitecnicivascaComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() brand:string;
  murettiToggle: boolean = false;
  constructor(
    private formController: FormControllerService,
    private remailServ: RemailService,
  ) { }

  ngOnInit(): void {
    this.getVasca();
  }

  getVasca(): void {
    this.remailServ.getDatiTecVasca(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
        if (res[0]){
            this.form.sections.datitecnicivasca = res[0];
        }
        if (this.form.sections.datitecnicivasca.lungmurosx ||
            this.form.sections.datitecnicivasca.largmurosx ||
            this.form.sections.datitecnicivasca.altmurosx ||
            this.form.sections.datitecnicivasca.eliminamurosx ||
            this.form.sections.datitecnicivasca.zoppo ||
            this.form.sections.datitecnicivasca.teneremurosxdentro ||
            this.form.sections.datitecnicivasca.teneremurosxfuori
        ) {
          this.murettiToggle = true;
        }
        if (this.form.sections.datitecnicivasca.lungmurodx ||
          this.form.sections.datitecnicivasca.largmurodx ||
          this.form.sections.datitecnicivasca.altmurodx ||
          this.form.sections.datitecnicivasca.eliminamurodx ||
          this.form.sections.datitecnicivasca.zoppomurodx ||
          this.form.sections.datitecnicivasca.teneremurodxdentro ||
          this.form.sections.datitecnicivasca.teneremurodxfuori
        ) {
          this.murettiToggle = true;
        }
        if (this.form.sections.datitecnicivasca.lungmurodietro ||
            this.form.sections.datitecnicivasca.largmurodietro ||
            this.form.sections.datitecnicivasca.altmurodietro ||
            this.form.sections.datitecnicivasca.eliminamurodietro ||
            this.form.sections.datitecnicivasca.zoppomurodietro ||
            this.form.sections.datitecnicivasca.lungmuroavanti ||
            this.form.sections.datitecnicivasca.largmuroavanti ||
            this.form.sections.datitecnicivasca.altmuroavanti ||
            this.form.sections.datitecnicivasca.zoppomuroavanti
        ) {
          this.murettiToggle = true;
        }
    });
  }

  toggleMur(event): void {
    if (!event){
      delete this.form.sections.datitecnicivasca.lungmurosx;
      delete  this.form.sections.datitecnicivasca.largmurosx;
      delete this.form.sections.datitecnicivasca.altmurosx;
      delete this.form.sections.datitecnicivasca.eliminamurosx;
      delete this.form.sections.datitecnicivasca.zoppo;
      delete this.form.sections.datitecnicivasca.teneremurosxdentro;
      delete this.form.sections.datitecnicivasca.teneremurosxfuori;
      delete this.form.sections.datitecnicivasca.lungmurodx;
      delete this.form.sections.datitecnicivasca.largmurodx;
      delete this.form.sections.datitecnicivasca.altmurodx;
      delete this.form.sections.datitecnicivasca.eliminamurodx;
      delete this.form.sections.datitecnicivasca.zoppomurodx;
      delete this.form.sections.datitecnicivasca.teneremurodxdentro;
      delete this.form.sections.datitecnicivasca.teneremurodxfuori;
      delete this.form.sections.datitecnicivasca.lungmurodietro;
      delete this.form.sections.datitecnicivasca.largmurodietro;
      delete this.form.sections.datitecnicivasca.altmurodietro;
      delete this.form.sections.datitecnicivasca.eliminamurodietro;
      delete this.form.sections.datitecnicivasca.zoppomurodietro;
      delete this.form.sections.datitecnicivasca.lungmuroavanti;
      delete this.form.sections.datitecnicivasca.largmuroavanti;
      delete this.form.sections.datitecnicivasca.altmuroavanti;
      delete this.form.sections.datitecnicivasca.zoppomuroavanti;
    }
  }

  setMuroSx(event): void {
    console.log('event ', event);
    this.form.sections.datitecnicivasca.murosx = event.checked;
    /*if (this.form.sections.datitecnicivasca.murosx) {
      this.form.sections.datitecnicivasca.murodx = false;
    }*/
  }
  setMuroDx(event): void {
    this.form.sections.datitecnicivasca.murodx = event.checked;
    /*if (this.form.sections.datitecnicivasca.murodx) {
      this.form.sections.datitecnicivasca.murosx = false;
    }*/
  }

  setScaricoSx(event): void {
    console.log('event ', event);
    this.form.sections.datitecnicivasca.scaricosx = event.checked;
    if (this.form.sections.datitecnicivasca.scaricosx) {
      this.form.sections.datitecnicivasca.scaricodx = false;
    }
  }
  setScaricoDx(event): void {
    this.form.sections.datitecnicivasca.scaricodx = event.checked;
    if (this.form.sections.datitecnicivasca.scaricodx) {
      this.form.sections.datitecnicivasca.scaricosx = false;
    }
  }

  murettoDentro(event): void {
    this.form.sections.datitecnicivasca.teneremurosxdentro = event.checked;
    if (this.form.sections.datitecnicivasca.teneremurosxdentro) {
      this.form.sections.datitecnicivasca.teneremurosxfuori = false;
    }
  }
  murettoFuori(event): void {
    this.form.sections.datitecnicivasca.teneremurosxfuori = event.checked;
    if (this.form.sections.datitecnicivasca.teneremurosxfuori) {
      this.form.sections.datitecnicivasca.teneremurosxdentro = false;
    }
  }
  murettoDxDentro(event): void {
    this.form.sections.datitecnicivasca.teneremurodxdentro = event.checked;
    if (this.form.sections.datitecnicivasca.teneremurodxdentro) {
      this.form.sections.datitecnicivasca.teneremurodxfuori = false;
    }
  }
  murettoDxFuori(event): void {
    this.form.sections.datitecnicivasca.teneremurodxfuori = event.checked;
    if (this.form.sections.datitecnicivasca.teneremurodxfuori) {
      this.form.sections.datitecnicivasca.teneremurodxdentro = false;
    }
  }

  setMuroDietro(event): void {
    console.log('event ', event);
    this.form.sections.datitecnicivasca.murodietro = event.checked;
    /*if (this.form.sections.datitecnicivasca.murosx) {
      this.form.sections.datitecnicivasca.murodx = false;
    }*/
  }
  
  murettoDietroDentro(event): void {
    this.form.sections.datitecnicivasca.teneremurodietrodentro = event.checked;
    if (this.form.sections.datitecnicivasca.teneremurodietrodentro) {
      this.form.sections.datitecnicivasca.teneremurodietrofuori = false;
    }
  }
  
  murettoDietroFuori(event): void {
    this.form.sections.datitecnicivasca.teneremurodietrofuori = event.checked;
    if (this.form.sections.datitecnicivasca.teneremurodietrofuori) {
      this.form.sections.datitecnicivasca.teneremurodietrodentro = false;
    }
  }
  
  murettoAvantiDentro(event): void {
    this.form.sections.datitecnicivasca.teneremuroavantidentro = event.checked;
    if (this.form.sections.datitecnicivasca.teneremuroavantidentro) {
      this.form.sections.datitecnicivasca.teneremuroavantifuori = false;
    }
  }
  murettoAvantiFuori(event): void {
    this.form.sections.datitecnicivasca.teneremuroavantifuori = event.checked;
    if (this.form.sections.datitecnicivasca.teneremuroavantifuori) {
      this.form.sections.datitecnicivasca.teneremuroavantidentro = false;
    }
  }
}
