import { Component, OnInit } from '@angular/core';
import { Signature } from '../../../../classes/signature';
import { AnagraficaDTO } from '../../../bagni-italiani/main-bagni/classes/anagrafica';
import { Trascodifica } from '../../../../classes/trascodifica';
import { Ordine } from '../../../../classes/ordine';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { UserService } from '../../../../../services/user/user.service';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { BrandService } from '../../../../../services/brand/brand.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnagraficaForm } from '../../../../classes/anagrafica';
import { AbitazioneDTO } from '../../ariel-main/classes/abitazione-dto';
import { PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { InstallazioneAccessoriDto } from '../../ariel-main/classes/installazione-accessori-dto';
import { AbitazioneService} from '../../ariel-main/services/abitazione.service';
import { PaymentService } from '../../../../../services/payment/payment.service';
import { InstallazioneAccessoriService } from '../../ariel-main/services/installazione-accessori.service';
import { PromoService } from '../services/promo.service';
import { OrdersService } from '../../../../../services/orders/orders.service';
import { DettaglioCondizionatore } from '../../../../../modules/classes/dettaglio-condizionatore';
import { DettaglioService } from '../../ariel-main/services/dettaglio.service';
import { OmaggioService } from '../../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { OmaggioDto } from '../../../../classes/omaggio-dto';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';

declare var jQuery: any;
@Component({
  selector: 'app-condizionatore',
  templateUrl: './condizionatore.component.html',
  styleUrls: ['./condizionatore.component.scss']
})
export class CondizionatoreComponent implements OnInit {
  
  openSign:boolean=false;
  generatingPdf:boolean = false;
  validAnagrafica: boolean = false;
  sendingPdf : boolean = false;
  showModalPrint: boolean;
  
  currentStep:number = 0;
  idCliente: number;
  valueProgBar: number = 0;
  currentPrinting: number = 0;
  
  urlFrame:string = "";

  formDiffer: any;
  originalForm: any;
  obj1: any;
  obj2: any;
  pdf: any;
  member:any;
  error: any;
  form: any;

  ordine:Ordine;
  signature: Signature;
  
  anagrafica: AnagraficaDTO;
  trascodifiche: Trascodifica[];
  totalSteps: number[];
 
  integratedSignature = environment.integratedSignature;
  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");
	
  constructor(
      public formController: FormControllerService,
      public userService: UserService,
      private anagraficaService: AnagraficaService,
      private brandService: BrandService,
      private route: ActivatedRoute,
      private snackbar: MatSnackBar,
	  private abitazioneService:AbitazioneService,
	  private paymentService:PaymentService,
	  private installazioneAccService:InstallazioneAccessoriService,
      private promoService: PromoService,
      private orderService:OrdersService,
      private dettaglioService: DettaglioService,
      private router: Router,
	  private omaggioService: OmaggioService,
  ) {
	  this.showModalPrint = false;
    this.validAnagrafica = false;
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get('id')),
      anagrafica: new AnagraficaForm(),
      sections: {
        condizionatori: [],
        abitazione: new AbitazioneDTO(),
		installazioneAcc:new InstallazioneAccessoriDto(),
        pagamento: [new PagamentoDTO()],
        dettaglioDto: new DettaglioCondizionatore(),
		ordine : null,
		omaggio : [new OmaggioDto()],
      }
    };
    this.totalSteps = Array(8).fill(1).map((x, i) => i ); // [0,1,2,3,4]
    // richiamo il serivizio di trascodifica che mi restituisce i dati per popolare i form
  }
  
  ngOnInit(): void {
    this.originalForm = JSON.parse(JSON.stringify(this.form));
	if(localStorage.getItem('id_client') !== null){
	    this.idCliente = Number(localStorage.getItem('id_client'));
    	this.form.anagrafica.anagrafica.id = this.idCliente;		
	}
	
    if (this.currentStep > 0){
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }
    // this.getAnagrafica();
    this.getOrder(this.form.idOrder);
  }
  
  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
		this.ordine = res;
		this.form.ordine = res;
  		localStorage.setItem('id_product', res.prodottoId);
      
		if(res.clienteId !== undefined  && res.clienteId !== null){
				localStorage.setItem('id_client', res.clienteId);	
				this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
			}
			
	      localStorage.setItem('id_order', res.idordine);
	    });

    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
    });
  }
  
  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
    if (this.obj1 !== this.obj2) {
      this.checkAnagrafica();
      this.originalForm = JSON.parse(JSON.stringify(this.form));
    }
  }

  
  ngAfterViewInit(): void{
    console.log('origina form', this.originalForm);
  }
  
  setStep(num): void {
    this.currentStep = num;
    if (this.currentStep > 0){
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }
    this.scrollTop();
  }
  
  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }
  
  scrollTop(): void {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }
  
  saveModule(): void{
    $('body').removeClass('noScr');
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    
    if(this.currentStep === 0){
      // Step salvataggio anagrafica
      this.anagrafica = this.form.anagrafica.anagrafica;
		if(this.anagrafica.id){
			this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
				// this.openSnackBar('Modulo salvato correttamente');
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe();
				}, error => {
					console.error('Error => ', error);
					this.openSnackBar('Errore in fase di salvataggio del modulo');
				});				
		}else{
			this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
				// this.openSnackBar('Modulo salvato correttamente');
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.form.anagrafica.anagrafica.id = res.id;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe();
				}, error => {
					console.error('Error => ', error);
					this.openSnackBar('Errore in fase di salvataggio del modulo');
				});	
		}
		
    } else if (this.currentStep === 1) {
      const savingCondizionatori = [];
      this.form.sections.condizionatori.forEach(r => {
        if (!r.toDelete) {
          savingCondizionatori.push(r);
        }
        if (r.toDelete) {
          this.promoService.delete(JSON.stringify(r.id)).subscribe(res => {
              }, error => {
                console.error('Error => ', error);
              }
          );
        }
      });
      savingCondizionatori.forEach(c => {
        delete c.toDelete;
        if (c.id !== undefined ){
          this.promoService.updateCondizionatori(JSON.stringify(c)).subscribe(res => {
              }, error => {
                console.error('Error => ', error);
              }
          );
        } else {
          this.promoService.saveCondizionatori(JSON.stringify(c)).subscribe(res => {
              }, error => {
                console.error('Error => ', error);
              }
          );
        }
      });

		if(this.hasValues(this.form.sections.dettaglioDto)){
			this.form.sections.dettaglioDto.ordineId = this.form.idOrder;
			if(this.form.sections.dettaglioDto.id === undefined || this.form.sections.dettaglioDto.id === null){
				this.dettaglioService.save(JSON.stringify(this.form.sections.dettaglioDto)).subscribe();
			}else{
				this.dettaglioService.update(JSON.stringify(this.form.sections.dettaglioDto)).subscribe();
			}			
		}
	    
    } else if (this.currentStep === 2){
		//dati abitazione
		if(this.hasValues(this.form.sections.abitazione)){
			this.form.sections.abitazione.ordineId = this.form.idOrder;
			if(this.form.sections.abitazione.id === undefined ||  this.form.sections.abitazione.id === null) {
				this.abitazioneService.save(JSON.stringify(this.form.sections.abitazione)).subscribe();
			}else{
				this.abitazioneService.update(JSON.stringify(this.form.sections.abitazione)).subscribe();
			}
		}
	}else if(this.currentStep === 3){
		//dettagli installazione e accessori
		if(this.hasValues(this.form.sections.installazioneAcc)){
			this.form.sections.installazioneAcc.ordineId = this.form.idOrder;
			if(this.form.sections.installazioneAcc.id === undefined ||  this.form.sections.installazioneAcc.id === null) {
				this.installazioneAccService.save(JSON.stringify(this.form.sections.installazioneAcc)).subscribe();
			}else{
				this.installazioneAccService.update(JSON.stringify(this.form.sections.installazioneAcc)).subscribe();
			}
		}
	}else if (this.currentStep === 4){
		//Step salvataggio omaggioDTO
		for(var i= 0; i < this.form.sections.omaggio.length; i++){
			var omaggio:OmaggioDto= this.form.sections.omaggio[i];
			omaggio.ordineId = this.form.idOrder;
			if( omaggio.id === undefined ||  omaggio.id === null){
				this.omaggioService.save(JSON.stringify( omaggio)).subscribe();
			}else{
				this.omaggioService.update(JSON.stringify( omaggio)).subscribe();
			}
		}
	}else if (this.currentStep === 5){
      // Step salvataggio pagamento
	  if(this.hasValues(this.form.sections.pagamento[0])){
		this.form.sections.pagamento[0].ordineId = this.form.idOrder;
  		const payment = this.form.sections.pagamento[0];
	      if (payment.id === undefined || payment.id === null) {
	        this.paymentService.save(JSON.stringify( payment)).subscribe();
	      }else{
	        this.paymentService.update(JSON.stringify( payment)).subscribe();
	      }
		}
    }else if (this.currentStep === 6){
		//Step salvataggio privacy
		this.orderService.update(JSON.stringify( this.form.ordine)).subscribe();
	} else if(this.currentStep === 7) {
	    this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
		    // this.openSnackBar('Modulo salvato correttamente');
		    this.ordine.clienteId = res.id;
		    this.ordine.cognome = res.cognome;
		    this.ordine.nome = res.nome;
	    }, error => {
		    console.error('Error => ', error);
		    this.openSnackBar('Errore in fase di salvataggio del modulo');
	    });
    }
    this.setStep(this.currentStep + 1);
  }
  
  	openSnackBar(text): void {
    	this.snackbar.open(text, 'Ok', {
      		duration: 4000
    	});
  	}
    	
  	togglePrintPreview(show:boolean): void {
		this.showModalPrint = show;
			jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
			jQuery('#printModal').modal('toggle');
	}
	
	closeSignPdf(){
		jQuery('#signModal').modal({backdrop: 'static', keyboard: false});
		jQuery('#signModal').modal('toggle');
	}

	returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}
	
	validPiani(): boolean {
  	if ((!this.form.sections.abitazione.pianistabile && this.form.sections.abitazione.pianistabile!=0) ||
	    (!this.form.sections.abitazione.pianistabile && this.form.sections.abitazione.pianistabile!=0)
	    ) {
		    return false;
	    } else {
		    return true;
	    }
	}

	validInstAccessori(): boolean {
		return this.formController.validInstAccessori(this.form);
	}
	
	validPrivacy(): boolean {
		if(!this.form.ordine.accettprivacy || !this.form.ordine.accettprivacynd || !this.form.ordine.accettprivacyrd){
			return false;
		}else
			return true;
	}
	
	validPromo():boolean {
		let select = false;
		if(this.form.sections.condizionatori.length > 0){
  			this.form.sections.condizionatori.forEach(r => {
        	if (!r.toDelete) {
				select = true;	
	        }
     	 });
		}
		
		return select;
	}
	

	  edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}
	
	  reload(value: boolean) {
		this.openSign = value;
	  }
}
