<div class="text-center bagnoPraticoBg">
    RIVESTIMENTI
</div>
<div class="bagnoPraticoCont" *ngIf="form.sections.rivestimenti">
    <div class="">
        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Pavimento (esclusa Linea Lux)</mat-label>
            <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pavimento">
        </mat-form-field>
    </div>
    <div class="">
        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Pareti</mat-label>
            <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareti">
        </mat-form-field>
    </div>
    <div class="row">
        <div class="col-3">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>A</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.paretea">
            </mat-form-field>
        </div>
        <div class="col-3">
            <app-input-number (onNumChange)="form.sections.rivestimenti.pareteah = $event" [step]="0.1"
                [num]="form.sections.rivestimenti.pareteah" [decimal]="true" [payment]="false" [small]="false"
                label="h"></app-input-number>
        </div>
        <div class="col-3">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>B</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareteb">
            </mat-form-field>
        </div>
        <div class="col-3">
            <app-input-number (onNumChange)="form.sections.rivestimenti.paretebh = $event" [step]="0.1"
                [num]="form.sections.rivestimenti.paretebh" [decimal]="true" [payment]="false" [small]="false"
                label="h"></app-input-number>
        </div>
    </div>

    <div class="row">
        <div class="col-3">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>C</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.paretec">
            </mat-form-field>

        </div>
        <div class="col-3">
            <app-input-number (onNumChange)="form.sections.rivestimenti.paretech = $event" [step]="0.1"
                [num]="form.sections.rivestimenti.paretech" [decimal]="true" [payment]="false" [small]="false"
                label="h"></app-input-number>
        </div>
        <div class="col-3">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>D</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.pareted">
            </mat-form-field>
        </div>
        <div class="col-3">
            <app-input-number (onNumChange)="form.sections.rivestimenti.paretedh = $event" [step]="0.1"
                [num]="form.sections.rivestimenti.paretedh" [decimal]="true" [payment]="false" [small]="false"
                label="h"></app-input-number>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <mat-form-field class="full-width" appearance="standard">
                <mat-label>Interno doccia</mat-label>
                <input matInput type="text" [(ngModel)]="form.sections.rivestimenti.internodoccia">
            </mat-form-field>
        </div>
        <div class="col-6">
            <app-input-number (onNumChange)="form.sections.rivestimenti.internodocciah = $event" [step]="0.1"
                [num]="form.sections.rivestimenti.internodocciah" [decimal]="true" [payment]="false" [small]="false"
                label="h"></app-input-number>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="full-width" appearance="fill">
                <mat-label>Note</mat-label>
                <textarea matInput [(ngModel)]="form.sections.rivestimenti.note"></textarea>
            </mat-form-field>
        </div>
    </div>

    <hr>
    <!--MOBILE BAGNO-->
    <div class="mb-3">
        <mat-slide-toggle class="example-margin" [(ngModel)]="mobileAttivo" [checked]="mobileAttivo"
            (ngModelChange)="toggleAttivo($event)">
            Mobile del cliente
        </mat-slide-toggle>
    </div>

    <div class="" *ngIf="!mobileAttivo">
        <div class="row mb-2">

            <div class="col-12">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label>Mobile bagno</mat-label>
                    <input matInput type="text"
                        [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.descrizione">
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-3">

            <div class="col-md-4 col-12 align-self-center">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label>Colore 1</mat-label>
                    <input matInput type="text"
                        [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colore">
                </mat-form-field>
            </div>

            <div class="col-md-4 col-12 align-self-center">
                <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo"
                    [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche">
                    <mat-radio-button class="mb-2 mr-2" [value]="vasche.id"
                        *ngFor="let vasche of tipiVasche">{{vasche.descrizione}}</mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione"
                        *ngIf="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche"
                        (click)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche = null">
                        backspace
                    </span>
                </mat-radio-group>
                <div class="mb-4 smallFont text-danger"
                    *ngIf="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche">
                    Campo obbligatorio
                </div>
            </div>

            <div class="col-md-3 col-12 align-self-center">
                <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo"
                    [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.vascaMob">
                    <mat-radio-button class="mb-2 mr-2" [value]="tipo.id"
                        *ngFor="let tipo of vascaMob">{{tipo.descrizione}}</mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione"
                        *ngIf="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.vascaMob"
                        (click)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.vascaMob = null">
                        backspace
                    </span>
                </mat-radio-group>
                <div class="mb-4 smallFont text-danger"
                    *ngIf="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.vascaMob">
                    Campo obbligatorio
                </div>
            </div>

        </div>
        <div class="row mb-3">
            <div class="col-md-4 col-12 align-self-center">
                <mat-form-field class="full-width" appearance="standard">
                    <mat-label>Colore 2</mat-label>
                    <input matInput type="text"
                        [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colore2">
                </mat-form-field>
            </div>
        </div>
        <hr />
        <div class="row mb-2">
            <div class="col-md-4 col-12 align-self-center">
                <mat-radio-group labelPosition="after" class="" color="primary" aria-label="Tipo"
                    [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile">
                    <mat-radio-button class="mb-2 mr-2" [value]="colonna.id"
                        *ngFor="let colonna of tipiCol?.slice()?.reverse()">{{colonna.descrizione}}</mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione"
                        *ngIf="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile"
                        (click)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile = null">
                        backspace
                    </span>
                </mat-radio-group>
            </div>

            <div class="col-md-4 col-12 align-self-center"
                *ngIf="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile && noCol && form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile != noCol[0]?.id">
                <app-input-number
                    (onNumChange)="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili = $event"
                    [step]="1" [num]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili"
                    [decimal]="false" [payment]="false" [small]="false" label="Quantità pensili"></app-input-number>
                <div class="smallFont text-danger"
                    *ngIf="!form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili || !form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili != 0">
                    Campo obbligatorio
                </div>
            </div>
        </div>
        <hr />
        <div class="row">

            <div class="col-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Note composizione</mat-label>
                    <textarea matInput
                        [(ngModel)]="form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>