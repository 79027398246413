export class Ordine{
	brandDescrizione:string;
	brandId:number;
	clienteCf:string
	clienteId:number | undefined;
	dataordine:Date;
	id:number;
	idordine:number;	
	prodottoCommessa:string
	prodottoId:number;
	statopraticaDescrizione:string;
	statopraticaId:number;
	statopraticaCodice:string;
	venditoreId:number;
	venditoreLogin:string;
	idfile:string;
	cognome:string;
	nome:string;
	gdlid:number;
	accettprivacy:boolean;
	accettprivacynd:boolean;
	accettprivacyrd:boolean;
	note:string;
	ordinejson:string;
	idProcedure:string;
	idMember:string;
	promoId?: number;
}