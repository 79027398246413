import { Component, OnInit,Input } from '@angular/core';

import {Trascodifica} from '../../../../classes/trascodifica';


import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import {OpereIdraulicheService} from '../../../bagni-italiani/main-bagni/services/opereIdrauliche/opereIdrauliche.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { OpereIdraulicheDTO } from '../../main-bagni/classes/dati-tecnici';

@Component({
  selector: 'app-opereidrauliche-help75',
  templateUrl: './opereidrauliche.component.html',
  styleUrls: ['./opereidrauliche.component.scss']
})
export class OpereidraulicheComponent implements OnInit {
  collapse:boolean=false;
  @Input() form:any;
  @Input() trascodifiche: Trascodifica[];  
  listColore:Trascodifica[] = []; 
  listMisure:Trascodifica[] = [];
  listColoreStone:Trascodifica[] = []; 
  listMisureStone:Trascodifica[] = [];

  isVisible  = false;
  options: Trascodifica[];
  optionPiattoDoccia: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};
  listOptionsOrder: OptionsAvailable[];
  constructor(
		public formController: FormControllerService,
		private opIdraulicheService: OpereIdraulicheService,
    private optionsService: OptionsAvailableService
	) { }

  ngOnInit(): void {
    this.options = this.formController.getTrascodificaByCodice('TYPE_OPTIONS',this.trascodifiche).filter(op => op.descrizione === 'NUOVO PIATTO DOCCIA');
    this.getOption();
    this.form.sections.piattoDoccia=new OpereIdraulicheDTO()
			this.opIdraulicheService.findByKey(this.form.idOrder , 'ordineId.equals=','opereidrauliches').subscribe(res => {
				this.listColore = this.formController.getTrascodificaByCodice('COLORE_PIATTI_EFF_PIE',this.trascodifiche);		
				this.listColoreStone = this.formController.getTrascodificaByCodice('COLORE_PIATTI_EFF_PIE_STONE_3D',this.trascodifiche);			
				if(res !== undefined && res[0] !== undefined){
					this.form.sections.piattoDoccia = res[0];
					this.changeModel(this.form.sections.piattoDoccia.colorepiateffpietraId);
					this.changeModelStone(this.form.sections.piattoDoccia.colorepiatstoneId);
				}
		})	
	
  }

  
  changeModel(event): void {
	  this.listMisure =  this.trascodifiche.filter(tr => (tr.codice === 'DIM_PIATTI_PIETR') && (tr.parentId === event));
	  this.listMisure = this.listMisure.sort((a, b) => a.descrizione > b.descrizione ? 1 : -1);
  }
  
  
  changeModelStone(event): void {
	  this.listMisureStone =  this.trascodifiche.filter(tr => (tr.codice === 'DIM_PIATTI_PIETR_STONE_3D') && (tr.parentId === event));
	  this.listMisureStone =  this.listMisureStone.sort((a, b) => a.descrizione > b.descrizione ? 1 : -1);
  }

  showDiv(value){    
    if(value ){
      this.isVisible = true;
    }else{
      this.isVisible = false;
    }
    this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0].id)[0].selected = value;
   
  }

  getOption(){
    this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
        if(res !== undefined){
          this.listOptionsOrder = res;
         // this.form.sections.optionsAvailable=this.form.sections.optionsAvailable.filter((res:any)=> res.id!=undefined || res.id!=null)
          let optionPiattoDoccia = res.filter(op => op.typeId === this.options[0].id)  
          if(optionPiattoDoccia !== undefined && optionPiattoDoccia.length > 0){
            this.isVisible = optionPiattoDoccia[0].selected;
           /*if( this.form.sections.optionsAvailable.filter((res:any)=> res.ordineId==optionPiattoDoccia.ordineId && res.typeId==optionPiattoDoccia.typeId).length>0)
           {
            this.form.sections.optionsAvailable.filter((res:any)=> res.ordineId==optionPiattoDoccia.ordineId && res.typeId==optionPiattoDoccia.typeId).map((res:any)=> {res.id=optionPiattoDoccia.id; return res} )
            } else {*/
              this.form.sections.optionsAvailable.push(optionPiattoDoccia[0])
              this.form.sections.optionsAvailable=this.form.sections.optionsAvailable.filter((res:any)=> res.id!=undefined && res.id!=null)
            //}
            this.optionPiattoDoccia = optionPiattoDoccia[0];

          }else{
            this.isVisible = false;
            this.optionPiattoDoccia = new OptionsAvailable();
            this.optionPiattoDoccia.ordineId = this.form.idOrder;
            this.optionPiattoDoccia.typeId = this.options[0].id;
            this.optionPiattoDoccia.selected = undefined;
            this.form.sections.optionsAvailable.push(this.optionPiattoDoccia);
          }
        }
    });

  }

}
