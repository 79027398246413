import { Component, OnInit, Input } from '@angular/core';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
@Component({
  selector: 'app-form-anagrafica-bagno',
  templateUrl: './form-anagrafica.component.html',
  styleUrls: ['./form-anagrafica.component.scss']
})
export class FormBagniAnagraficaComponent implements OnInit {
  @Input() form: any;
  province: any[];
  filtered: any[];
  constructor(
      public formController: FormControllerService,
  ) {
    this.province = formController.returnProvince();
  }

  ngOnInit(): void {
    console.log(this.form);
    this.filtered = JSON.parse(JSON.stringify(this.province));
  }

  resetNewAddress(event): void{
    this.form.anagrafica.anagrafica.indirizzo.indirizzoInstallazione = '';
  }

  filter(value): any{
    const str = value.toLowerCase();
    this.filtered = [];
    this.province.forEach( r => {
      if (r.nome.toLowerCase().includes(str)){
        this.filtered.push(r);
      }
    });
  }
}
