import { OptionsAvailable } from "src/app/modules/classes/options-available";

export class Sections{
	datiTecnici : DatiTecnici = new DatiTecnici();
	rivestimenti : Rivestimenti = new Rivestimenti();
	nuoviProdotti : NuoviProdotti = new NuoviProdotti();
	opereIdrauliche : OpereIdrauliche = new OpereIdrauliche();
	opereIdraulicheSost : OpereIdrauliche[] = [new OpereIdrauliche()];
	opereIdraulicheSostToDelete: OpereIdrauliche[] = [new OpereIdrauliche()];
	termosifoniArredi : TermosifoniArredi[] = [new TermosifoniArredi()];
	termosifoniArrediToDelete:TermosifoniArredi[] = [new TermosifoniArredi()];
	piattoEffettoPietra : PiattoEffettoPietra = new PiattoEffettoPietra();
	piattoStone : PiattoStone = new PiattoStone();
	soffittoPareti : SoffittoPareti = new SoffittoPareti();
	piattoDoccia : PiattoDoccia = new PiattoDoccia();
	noteLibere : SingleValue = new SingleValue();
	vascaVenezia : Vasca = new Vasca();
	vascaRoma : Vasca = new Vasca();
	vascaEcube : Vasca = new Vasca();
	vascaVerona : Vasca = new Vasca();
	vascaFirenze : Vasca = new Vasca();
	nuoviDati: NuoviDati = new NuoviDati();
	nuoviBox: SingleBox[] = [new SingleBox()];
	accessoriBox: AccessorioBox[] = [new AccessorioBox()];
	nuoviAccessori: Accessori[] = [new Accessori()];
	nuoviAccessoriToDelete: Accessori[] = [new Accessori()];
	omaggio: Omaggio[] = [new Omaggio()];
	pagamento: PagamentoDTO[] = [new PagamentoDTO()];
	grohe:Grohe[] = [new Grohe()];
	groheToDelete:Grohe[] = [new Grohe()];
	nuoviSanitariDto: NuoviSanDTO[] = [new NuoviSanDTO()];
	optionsAvailable:OptionsAvailable[] = [];
}

export class AccessorioBox{
	id:number;
	codice: string;
	quantita: number;
	tipologiaId:number;
	descrizione:string;
}
export class Omaggio{
	id: number
	codice: string;
	quantita: number;
	tipologiaId:number;
	descrizione:string;
}

export class Accessori{
	id:number;
	tipologiaId:number;
//	'KIT.ACC.IMPERO_2' = false;
//	'KIT.ACC.QUA.ARD_2' = false;
//	'KIT.ACC.TON.SAB_2' = false;
//	'KIT.BAGNO.IMPERO_2' = false;
//	'KIT.BAGNO.WIND_2' = false;
//	'KIT.BAGNO.SEVEN_2' = false;
//	'CEST.DOCCIA.RETT.M' = false;
//	'CEST.DOCCIA.ANG.M' = false;
//	'SCAR_CAV_LAV' = false;
//	'KIT.SICUREZZA' = false;
//	'CONTATORE.INC' = false;
}

export class Grohe{
	id:number;
	tipologiaId:number;
}


export class Pagamento{
	id:number;
	totaleOrdine: number;
	iva: number;
	cessioneCredito: boolean;
	totaleDovuto: number;
	caparraConfirmatoria: number;
	tipoPagamento: number;
	saldoInizioLavori: number;
	tipoPagamentoSaldo: number;
	percivaDescrizione:string;
	contotermico:boolean;
}

export class SingleBox{
	id:number;
	modello: CodeDescr = new CodeDescr();
	cristalli: CodeDescr = new CodeDescr();
	profilo: CodeDescr = new CodeDescr();
	larghezza: CodeDescr = new CodeDescr();
	ingresso: CodeDescr = new CodeDescr();
	listModelli:any;
	listCristalli:any;
	listProfili:any;
	listLunghezze:any;
	listIngressi:any;
}

export class CodeDescr{
	id:number;
	code: string;
	descr: string;
}

export class NuoviDati{
	id:number;
	orderId:number;
	larghezza: number;
	prof: number;
	muro: string;
	box: Box = new Box();
	piantinaAttuale:string;
	piantinaAttualeType:string;
	nuovaPiantina:string;
	nuovaPiantinaType:string;
}

export class Box{
	lunghezza: number;
	larghezza: number;
	altezza: number;
}

export class Vasca{
	id:number;
	model : string;
	code : string;
	rivestimento? : string;
	dimensions : number;
	pannello? : number;
	seduta? : string;
	scarico? : string;
	sportello? : number;
	hydro? : boolean;
	orderId:number;
	veronaseduta?: boolean;
	firenzepannellocentro?: boolean;
	firenzepannellodx?: boolean;
	firenzepannellosx?: boolean;
	firenzepannelloattr?: boolean;
	misurapassaggioporta?: number;
	ecubemisuraId?: number;
	ecubesedutadx?: boolean;
	ecubesedutasx?: boolean;
}
export class PiattoStone{
	colorepiatstoneId: number;
	dimpiattstoneId: number;
}
export class NuoviProdotti{
	id:number;
	nuoviSanitari : NuoviSanitari = new NuoviSanitari();
	nuovaCassettaWc : NuovaCassettaWc = new NuovaCassettaWc();
	nuovaRubinetteria : Nuovarubinetteria = new Nuovarubinetteria();
	grohe : SingleValue = new SingleValue();	
}

export class PiattoDoccia{
	largo : PiattoEffettoPietra = new PiattoEffettoPietra();
	stretto : SingleValue = new SingleValue();
}

export class SoffittoPareti{
	inb : boolean;
	scr : boolean;
	//inb : SingleValue = new SingleValue();
	//scr : SingleValue = new SingleValue();
}

export class TermosifoniArredi{
	id:number;
	tipologiaId:number;
	// termoarredo : SingleValue = new SingleValue();
}

export class PiattoEffettoPietra{
	coloreId : number;
	dimensioneId:number;
	codice : string;
}

export class NuoviSanitari{
	modelloSanitari : Modello = new Modello();
	mobileBagno : MobileBagno = new MobileBagno();
	notelibere : string;
	isCliente:boolean;
}

export class OpereIdrauliche{
	id:number;
	tipologiaId:number;
}

export class Nuovarubinetteria{
	model : string;
	code : string;
	id: number;
	subLavaboId:number;
	subBidetId : number;
	docciaType : DocciaType = new DocciaType();
	astaComby : SingleValue = new SingleValue();
	colonne : SingleValue = new SingleValue();
	miscelatoreEst : MiscelatoreEst = new MiscelatoreEst();
	rubbicolId:number;
	astasingola:boolean;
	astadoppia:boolean;
	lavabo: boolean;
	bidet: boolean;
	doccia: boolean;
	vasca: boolean;
}

export class MiscelatoreEst{
	typeId : number;
	model : SingleValue = new SingleValue();
}

export class SingleValue{
	id:number;
	value : string;
	descrizione:string;
}

export class DocciaType{
	modelId : number;
	miscelatoreId : number;
}

export class NuovaCassettaWc{
	modelId : number;
	placcaTypeId : number;
	placcaColorId : number;
	cassettamonolitebidet: boolean;
	cassettamonolitewc: boolean;
}

export class DatiTecnici {
	id:number;
	tipo: string;
	tipoAbitazioneId:number;
	piano: number;
	diProprieta: boolean;
	ascensore: boolean;
	accessibilita_cortile: boolean;
	nRampe:number;
	nGradini:number;
	alzata:number;
	pedata:number;
	largScala:number;
	misuraPianerottolol:number;
	misuraPianerottoloh:number;
	dimensioniVano: Dimensioni = new Dimensioni();
	dimensioniPorta: DimensioniPorta = new DimensioniPorta();
	docciaVasca: DocciaVasca = new DocciaVasca();
	misuraTermosifone: MisuraTermosifone = new MisuraTermosifone();
	sanitariTipoId: number;
	lavatricePresente: boolean;
	lavatriceDaMantenere: boolean;
	bidetPresente: boolean;
	attacchiLavatriceDaCreare: boolean;
	attacchiLavatriceNote: string;
	wcPresente: boolean;
	boilerPresente: boolean;
	boilerDaMantenere: boolean;
	scaricoWcId: number;
	cassettaScaricoId: number;
	comandoSciaquone: boolean;
	adesionePiastrelleId: number;
	muriPerimetraliId: number;
	puntoLuce: boolean;
	tipoRiscaldamentoId: number;
	noteTecniche: string;
	ordineId:number;
	tipoimpiantoId: number;
	tipoimpiantoDescrizione:string;
	cassettamonolitebidet: boolean;
	cassettamonolitewc: boolean;
	imptermpres: boolean = true;
	termospres: boolean = true;
}

export class MisuraTermosifone{
	lung : number;
	prof : number;
	alt : number;
	incassato : boolean;
	interasse : number;
}

export class DocciaVasca{
	tipoBagnoId:number;
	value : boolean;
	dimensioni : Dimensioni = new Dimensioni();
}

export class Dimensioni{
	lung : number;
	larg : number;
	alt : number;
}

export class DimensioniPorta{
	lung : number;
	alt : number;
	altPiastrelle : number;
}

export class Rivestimenti{
	id:number;
	pavimento : string;
	pareti : string;
	A : string;
	Ah : number;
	B : string;
	Bh : number;
	A1 : string;
	Ah1 : string;
	B1 : string;
	Bh1 : string;
	internoDoccia : string;
	internoDH : string;
	note : string;
	ordineId:number;
	pareteC:string;
	pareteCh:number;
	pareteD:string;
	pareteDh: number;
}

export class Modello{
	modello : SingleValue = new SingleValue();
	scarico : SingleValue = new SingleValue();
	sanitari  : SingleValue = new SingleValue();
	prodotto  : SingleValue = new SingleValue();
	codiceGDL : string;
}


export class MobileBagno{
	descrizione : string;
	colore : string;
	colore2 : string;
	numeroVasche : number;
	numvascmobDescrizione:string;	
	colonnaPensile : number;
	tipocolmobDescrizione:string;
	quantitaPensili : number;
	note : string;
	vascaMob: number;
	vascamobDescrizione:string;
}

export class DatiTecniciDTO {
	id: number;
    numeropianistabile: number;
    pianointervento: number;
	nRampe: number;
	nGradini: number;
    proprieta: boolean;
    ascensore: boolean;
    accesscortile: boolean ; 
    lungvano: number;
    largvano: number ;
    altvano: number;
    lungporta: number;
    largporta: number;
    altporta: number;
    lungtipobagno: number;
    largtipobagno: number;
    alttipobagno: number;
    alttermo: number;
    interasstermo: number;
	alzata: number;
	pedata: number;
	largScala: number;
	misuraPianerottolol: number;
	misuraPianerottoloh: number;
    lavatrpres: boolean;
    lavatrmanten: boolean;
    bidet: boolean;
    attlavcreare:boolean;
    noteattlavcr: string;
    wcpresente: boolean;
    boilerpresente:boolean;
    boilermanten:boolean;
    comandosciacq: boolean;
    puntolucelavabo: boolean;
    notetecniche: string;
    tipoabitazioneId: number;
    tipoabitazioneDescrizione: string;
    ordineId :number
    ordineIdordine : string;
    tipobagnoId :number;
    tipobagnoDescrizione :string;
    tiposanitariId : number;
    tiposanitariDescrizione : string;
    tiposcaricowcId  : number
    tiposcaricowcDescrizione : string;
    tipocasscwcId :number;
    tipocasscwcDescrizione :string;
    adesionepiasId : number;
    adesionepiasDescrizione : string;
    muriperiId : number;
    muriperiDescrizione :string;
    tiporiscaId : number;
    tiporiscaDescrizion : string;
    altpiastrelle:number;
    lungtermos:number;
	proftermos:number;
	termosifoneIncassato:boolean;
	tipoimpiantoId:number;
	tipoimpiantoDescrizione:string;
	cassettamonolitebidet: boolean;
	cassettamonolitewc: boolean;
	imptermpres: boolean = true;
	termospres: boolean = true;
}


export class RivestimentiDTO{
	id:number;
	pavimentodesc : string;
	paretedesc : string;
	paretea : string;
	pareteah : number;
	pareteb : string;
	paretebh : number;	
	internodoccia : string;
	internodocciah : string;
	note : string;
	ordineId:number;
	paretec:string;
	paretech:number;
	pareted:string;
	paretedh: number;
}

export class VascaDTO {
	id:number;
	ordineId:number;
	firenzeidro:boolean;
	firenzenoidro:boolean;
	firenzemisuraId:number;
	firenzepannelloId:number;
	firenzesportelloId:number;
	romamisuraId:number;
	romasedutadx:boolean;
	romasedutasx:boolean;
	veneziaidro:boolean;
	venezianoidro:boolean;
	veneziamisuraId:number;
	veneziasedutadx:boolean;
	veneziasedutasx:boolean;
	veronamisuraId:number;
	veronascaricodx:boolean;
	veronascaricosx:boolean;
	veronasportelloId:number;
	veronaseduta: boolean;
	veronasedutadx:boolean;
	veronasedutasx:boolean;
	opensedileint: boolean;
	emotiondimId: number;
	emotiondimDescrizione:string;
	emotionaperturaDescrizione:	string;
	emotionaperturaId: number;
	emotionlarghporta: number;
	expresserogazioneId: number;
	expresserogazioneDescrizione:string;
	expresslargh: number;
	expresslung: number;
	expressalt: number;
	docciasun: boolean;
	docciasunmisureId: number;
	docciasunmisureDescrizione:string;
	docciasuningressoId: number;
	docciasuningressoDescrizione:string;
	docciasunprofiloId: number;
	docciasunprofiloDescrizione:string;
	openposportestrId:number;
	openposportestrDescrizione:string;
	firenzepannellocentro: boolean;
	firenzepannellodx: boolean;
	firenzepannellosx: boolean;
	firenzepannelloattr: boolean;
	misurapassaggioporta: number;
	ecubemisuraId: number;
	ecubesedutadx: boolean;
	ecubesedutasx: boolean;
}

export class NuoviDatiDTO{
	boxl: number;
	boxp:number;
	boxh:number;
	id:number;
	murodx:boolean;
	murosx:boolean;
	ordineId:number;
	piattol:number;
	piattop:number;
	nuovapiantina:string;
    nuovapiantinaContentType:string;
    piantina:string;
	piantinaContentType:string;
}

export class NuoviSanDTO {
	id: number;
	modsanitariDescrizione: string;
	modsanitariId: number;
	ordineId: number;
	ordineIdordine: string;
	tipoprodottoDescrizione: string;
	tipoprodottoId: number;
	tiposanitariDescrizione: string;
	tiposanitariId: number;
	tiposcaricoDescrizione: string;
	tiposcaricoId: number;
}

export class SanitariDTO{
	id: number;		
    idordineId :number;
    mobilebagno : string;
    mobilecolore: string;
	mobilecolore2: string;
	numvascmobId: number;   
	numvascmobDescrizione:string; 
	qtapensmob: number;
	notecompmob: string;
	tipocasswcId: number;
	tipoplaccawcId: number;
	coloreplaccawcId:number;
	rubkitId: number;
	rubtipoastaId: number;
    rubmiscestId: number;
	tipocolmobId: number;
	tipocolmobDescrizione:string;
	rubserieId: number;
    rubtipocolId: number;
    rubprodId: number;
    rubmiscserieId:number;
    rubmiscId:number;
    rubbidetId:number;
    modsanitariId:number;
    tiposcaricoId:number;
	tiposanitariId:number;
	tipoprodottoId:number;
	codicegdl:string;
	modsanitariDescrizione:string;
	tiposcaricoDescrizione:string;
	tiposanitariDescrizione:string;
	tipoprodottoDescrizione:string;
	notelibere: string;
	vascamobId: number;
	vascamobDescrizione:string
	cassettamonolitebidet: boolean;
	cassettamonolitewc: boolean;
	rubbicolId:number;
	astasingola:boolean;
	astadoppia:boolean;
	lavabo:boolean;
	bidet:boolean;
	doccia:boolean;
	vasca:boolean;
}

export class OpereIdraulicheDTO{
	id:number;
	ordineId:number;
	// tiposostopereidId:number;
	tipointtermoId:number;
	colorepiateffpietraId:number;
	dimpiatteffpietraId:number;
	necscrostatura:boolean;
	imbsoffitto:boolean;
	colorepiattolargId:number;
	colorepiattolargDescrizione:string;
	misurapiattolargId:number;
	misurapiattolargDescrizione:string;
	misurapiattostrId:number;
	misurapiattostrDescrizione:string;
	notelibere:string;
	colorepiatstoneId: number;
	dimpiattstoneId: number;
}

export class OpereIdraulicheSostDTO{
	id:number;
	ordineId:number;
	tipologiaId:number;
}

export class BoxdocciaDTO{
	id:number;
	cristalliId:number;
	larghezzaId:number;
	modelloId:number;
	ordineId:number;
	profiloId:number;
	modelloDescrizione:string;
	cristalliDescrizione:string;
	profiloDescrizione:string;
	larghezzaDescrizione:string;
	ingressoId:number;
	ingressoDescrizione:string;
}

export class AccessoriDTO{
	id:number;
	ordineId:number;
	quantita:number;
	tipologiaId:number;
	tipologiaDescrizione:string;
}

export class OmaggioDTO{
	id:number;
	ordineId:number;
	quantita:number;
	tipologiaId:number;
	tipologiaDescrizione:string;
}

export class NuoviAccessoriDTO{
	id:number;
	ordineId:number;	
	tipologiaId:number;
}

export class PagamentoDTO{
	accettazrich:boolean;
	caparraconf:number;
    id:number;
	metodopagId:number;
	metodopagDescrizione:string;
	note:string;
	ordineId:number;
	percivaId:number;
	cessionecredito:boolean;
	saldoinizlav:number;
	speserott:number;
	spesetraspmont:number;
	tipoincentivoId:number;
	tipopagsaldoId:number;
	tipopagsaldoDescrizione:string;
	totaledovuto:number;
	totaleordine:number;
	percivaDescrizione:string;
	tipodetrazioneId:number;
	tipodetrazioneDescrizione:string;
}

export class GroheDTO{
	id:number;
	note:string;
	ordineId:number;
	tipologiaId:number;

}

export class TermoarrediDTO{
	id:number;	
	ordineId:number;
	tipologiaId:number;
}
