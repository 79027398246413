<div class="zucchettiMontascaleBg"> ACCESSORI </div>
  <div class="zucchettiMontascaleCont">

    <div class="row">

        <div *ngFor="let acc of form.sections.accessoriDepuratore ; let i = index">
      
            <div class="row">
                <div class="col-sm-6">
                    <a>{{getDescByTrascId(acc.tipologiaId)}}</a>
                </div>
                <div class="col-sm-6">
                    <span class="mr-2"> Quantità </span> 
                    <app-input-number
                        (onNumChange)="updateQuantita(acc, $event)"
                        [step]="1"
                        [num]="acc.quantita"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="">
                    </app-input-number>
                </div>
                <br>
            </div>
        </div>
    </div> 
  </div>
  <div class="row">
    <mat-form-field class="full-width" appearance="fill">
        <mat-label>Note</mat-label>
        <textarea matInput [(ngModel)]="form.ordine.note"></textarea>
    </mat-form-field>
  </div>   