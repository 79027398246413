import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { DtoStufe } from '../../../../../classes/stufe';
import { BrandService } from '../../../../../../services/brand/brand.service';
import { StufeService } from '../services/stufe.service';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { constants } from 'src/app/modules/classes/constants';
import { trigger, state, style, transition, animate } from '@angular/animations';
@Component({
  selector: 'app-stufe-termo-caldaie',
  templateUrl: './stufe-termo-caldaie.component.html',
  styleUrls: ['./stufe-termo-caldaie.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*', minHeight: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  
})
export class StufeTermoCaldaieComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];

  stufePellet: Trascodifica[];
  termoPellet: Trascodifica[];
  caldaiePellet: Trascodifica[];
  stufeBio: Trascodifica[];
  stufeLegna: Trascodifica[];
  singleStufa: {} = new DtoStufe();
  stufe: [];
  dtoStufe: {};
  combinazioni: any;
  stufeResponse: any;
  dettaglioStufe: any;
  loadedDettStufe: boolean = false;
  expandedElement: string;

  public constants = constants;

  constructor(
      private brandService: BrandService,
      private stufeService: StufeService,
      private formController: FormControllerService,
  ) {
  }

  ngOnInit(): void {
    this.form.sections.stufe = [];    
    this.getStufe();
    this.getDettaglio();
  }

  getStufe(): void {
    this.stufeService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.stufeResponse = res;
      this.getTranscodifiche();
    });
  }

  getDettaglio(): void {
    this.loadedDettStufe = false;
    this.stufeService.getDettaglio(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      if(res[0]) this.form.sections.dettaglioDto = res[0];
        this.loadedDettStufe = true;
    });
  }

  getTranscodifiche(): void {
    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.getStufPellet();
    });
  }

  getStufPellet(): void {
    this.formController.getTrascodificaByCodicPromise(constants.MOD_MODSTUFEPELLET, this.trascodifiche).then(data => {
      this.stufePellet = data;
      this.managestufe(this.stufePellet, constants.MOD_MODSTUFEPELLET);
      this.getStufeBio(); 
    });
  }

  getStufeBio(): void {
    this.formController.getTrascodificaByCodicPromise( constants.COD_MODSTUFEBIO, this.trascodifiche).then(data => {
      this.stufeBio = data;
      this.managestufe(this.stufeBio, constants.COD_MODSTUFEBIO);
      this.getStufeALegna();    
    });
  }

  getStufeALegna(): void {
    this.formController.getTrascodificaByCodicPromise(constants.COD_MODSTUFELEGNA, this.trascodifiche).then(data => {
      this.stufeLegna = data;
      this.managestufe(this.stufeLegna, constants.COD_MODSTUFELEGNA);  
      this.getTermoPellet(); 
    }); 
  }

  getTermoPellet(): void {
    this.formController.getTrascodificaByCodicPromise( constants.COD_MODTERMOPELLET, this.trascodifiche).then(data => {
      this.termoPellet = data;
      this.managestufe(this.termoPellet, constants.COD_MODTERMOPELLET);
      this.getCaldPellet();  
    });
  }

  getCaldPellet(): void {
    this.formController.getTrascodificaByCodicPromise(constants.COD_MODCALDPELLET, this.trascodifiche).then(data => {
      this.caldaiePellet = data;
      this.managestufe(this.caldaiePellet, constants.COD_MODCALDPELLET);  
      this.checkResponse();
    });
  }
  
  checkResponse(): void {
    if (this.stufeResponse?.length > 0) {
      this.stufeResponse.forEach(st => {
        if (st.modstufepelletId) {
          const ind = this.form.sections.stufe.findIndex(stOr => stOr.modstufepelletId === st.modstufepelletId);
          if (ind !== -1) {
            this.form.sections.stufe[ind].id = st.id;
            this.form.sections.stufe[ind].checked = true;
            this.form.sections.stufe[ind].coloreId = st.coloreId;
            this.form.sections.stufe[ind].quantita = st.quantita;
          }
        }
        if (st.modcaldpelletId) {
          const ind = this.form.sections.stufe.findIndex(stOr => stOr.modcaldpelletId === st.modcaldpelletId);
          if (ind !== -1) {
            this.form.sections.stufe[ind].id = st.id;
            this.form.sections.stufe[ind].checked = true;
            this.form.sections.stufe[ind].coloreId = st.coloreId;
            this.form.sections.stufe[ind].quantita = st.quantita;
          }
        }
        if (st.modtermopelletId) {
          const ind = this.form.sections.stufe.findIndex(stOr => stOr.modtermopelletId === st.modtermopelletId);
          if (ind !== -1) {
            this.form.sections.stufe[ind].id = st.id;
            this.form.sections.stufe[ind].checked = true;
            this.form.sections.stufe[ind].coloreId = st.coloreId;
            this.form.sections.stufe[ind].quantita = st.quantita;
          }
        }
        if (st.modstufebioId) {
          const ind = this.form.sections.stufe.findIndex(stOr => stOr.modstufebioId === st.modstufebioId);
          if (ind !== -1) {
            this.form.sections.stufe[ind].id = st.id;
            this.form.sections.stufe[ind].checked = true;
            this.form.sections.stufe[ind].coloreId = st.coloreId;
            this.form.sections.stufe[ind].quantita = st.quantita;
            this.form.sections.stufe[ind].bollinoSconto = st.bollinoSconto;
          }
        }
        if (st.modstufelegnaId) {
          const ind = this.form.sections.stufe.findIndex(stOr => stOr.modstufelegnaId === st.modstufelegnaId);
          if (ind !== -1) {
            this.form.sections.stufe[ind].id = st.id;
            this.form.sections.stufe[ind].checked = true;
            this.form.sections.stufe[ind].coloreId = st.coloreId;
            this.form.sections.stufe[ind].quantita = st.quantita;
          }
        }
      });
    }
  }

  managestufe(element, mod): void{
    element.forEach(s => {
      s.checked = false;
      this.form.sections.stufe.push(this.mapStufaToForm(s, mod, new DtoStufe()));
    });
  }


  mapStufaToForm(dto: DtoStufe, mod , form: DtoStufe): DtoStufe{

    if (dto !== undefined) {
      form.checked = dto.checked || false;
      form.ordineId = this.form.idOrder;
      form.coloreId = dto.coloreId || undefined;
      form.id = undefined;

      if (mod === 'MODCALDPELLET') {
        form.modcaldpelletId = dto.id;
      } else {
        form.modcaldpelletId = undefined;
      }
      if (mod === 'MODSTUFEPELLET') {
        form.modstufepelletId = dto.id;
      } else {
        form.modstufepelletId = undefined;
      }
      if (mod === 'MODTERMOPELLET') {
        form.modtermopelletId = dto.id;
      } else {
        form.modtermopelletId = undefined;
      }
      if(mod === 'MODSTUFEBIO') {
        form.modstufebioId = dto.id;
      } else {
        form.modstufebioId = undefined;
      }
      
      if(mod === constants.COD_MODSTUFELEGNA) {
        form.modstufelegnaId = dto.id;
      } else {
        form.modstufelegnaId = undefined;
      }

      form.volriscId = this.returnVolId(dto.id) || undefined;
    }
    return form;
    }

  returnVolDesc(id): string {	
	  return this.formController.returnVolDesc(id,this.trascodifiche);
  }

  returnModId(id): number {
    if (id) {
      const index = this.trascodifiche.findIndex(r => !r.parentId && r.id === id );
      if (index !== -1) {
        return this.trascodifiche[index].id;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  returnModDescr(id): string {
    if (id) {
      const index = this.trascodifiche.findIndex(r => !r.parentId && r.id === id );
      if (index !== -1) {
        return this.trascodifiche[index].descrizione;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  returnVolId(id): number {
    if (id) {
      const index = this.trascodifiche.findIndex(r => r.codice === constants.COD_VOLRISC && r.parentId === id );
      if (index !== -1) {
        return this.trascodifiche[index].id;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  returnColDescr(event, ind): string {
    const index = this.trascodifiche.findIndex(r => r.codice === constants.COD_COLORE_STUFE && r.id === event );
    if (index !== -1) {
      if (ind !== undefined) {
        this.form.sections.stufe[ind].coloreDescrizione = this.trascodifiche[index].descrizione;
      }
      return this.trascodifiche[index].descrizione;
    } else {
      return undefined;
    }
  }

  returnColors(id): any {
	  return this.formController.returnColors(id,this.trascodifiche);
  }

  removeColor(event, i): void{
    if (!event) {
      delete this.form.sections.stufe[i].coloreId;
      delete this.form.sections.stufe[i].coloreDescrizione;
    }
  }

  removeQuantita(event, i): void{
    if (!event) {
      delete this.form.sections.stufe[i].quantita;
    }
  }

  removeBollinoSconto(event, i): void{
    if (!event) {
      delete this.form.sections.stufe[i].bollinoSconto;
    }
  }

  toggle(element: string): void {
    this.expandedElement = this.expandedElement === element ? null : element;
  }
}
