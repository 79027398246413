import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Trascodifica } from '../../modules/classes/trascodifica';
import { Signature, Subscribers } from '../../modules/classes/signature';
import {
  NuoviProdotti,
  SanitariDTO,
  NuoviDatiDTO,
  NuoviDati,
  AccessorioBox,
  AccessoriDTO,
  OmaggioDTO,
  Omaggio,
  BoxdocciaDTO,
  SingleBox,
  PagamentoDTO,
  Pagamento,
  DatiTecnici,
  DatiTecniciDTO,
  Rivestimenti,
  RivestimentiDTO,
  Grohe,
  GroheDTO,
  TermoarrediDTO,
  TermosifoniArredi,
  OpereIdraulicheDTO,
  Sections,
  OpereIdraulicheSostDTO,
  OpereIdrauliche,
  PiattoDoccia,
  Accessori,
  NuoviAccessoriDTO,
} from '../../modules/seller/bagni-italiani/main-bagni/classes/dati-tecnici';
import { Procedure, Member, File, Config, Email, Started, Header, Webhook, MemberFinished } from '../../modules/classes/signature';
import { SignatureService } from '../signature/signature.service';
import { Ordine } from '../../modules/classes/ordine';
import { OrdineLog } from '../../modules/classes/ordineLog';
import { OrdersService } from '../orders/orders.service';
import { HttpService } from '../http.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { OrderlogService } from '../orderLog/orderlog.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { constants } from 'src/app/modules/classes/constants';
import { SignatureRequestDto } from 'src/app/modules/classes/signature-request-dto';
import { DocumentDTO, InfoDTO, SignerDTO } from 'src/app/modules/classes/signer-dto';
@Injectable({
  providedIn: 'root'
})
export class FormControllerService {
  province = [
    {
      nome: 'Agrigento',
      sigla: 'AG',
      regione: 'Sicilia',
    },
    {
      nome: 'Alessandria',
      sigla: 'AL',
      regione: 'Piemonte',
    },
    {
      nome: 'Ancona',
      sigla: 'AN',
      regione: 'Marche',
    },
    {
      nome: 'Arezzo',
      sigla: 'AR',
      regione: 'Toscana',
    },
    {
      nome: 'Ascoli Piceno',
      sigla: 'AP',
      regione: 'Marche',
    },
    {
      nome: 'Asti',
      sigla: 'AT',
      regione: 'Piemonte',
    },
    {
      nome: 'Avellino',
      sigla: 'AV',
      regione: 'Campania',
    },
    {
      nome: 'Bari',
      sigla: 'BA',
      regione: 'Puglia',
    },
    {
      nome: 'Barletta-Andria-Trani',
      sigla: 'BT',
      regione: 'Puglia',
    },
    {
      nome: 'Belluno',
      sigla: 'BL',
      regione: 'Veneto',
    },
    {
      nome: 'Benevento',
      sigla: 'BN',
      regione: 'Campania',
    },
    {
      nome: 'Bergamo',
      sigla: 'BG',
      regione: 'Lombardia',
    },
    {
      nome: 'Biella',
      sigla: 'BI',
      regione: 'Piemonte',
    },
    {
      nome: 'Bologna',
      sigla: 'BO',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Bolzano/Bozen',
      sigla: 'BZ',
      regione: 'Trentino-Alto Adige/Südtirol',
    },
    {
      nome: 'Brescia',
      sigla: 'BS',
      regione: 'Lombardia',
    },
    {
      nome: 'Brindisi',
      sigla: 'BR',
      regione: 'Puglia',
    },
    {
      nome: 'Cagliari',
      sigla: 'CA',
      regione: 'Sardegna',
    },
    {
      nome: 'Caltanissetta',
      sigla: 'CL',
      regione: 'Sicilia',
    },
    {
      nome: 'Campobasso',
      sigla: 'CB',
      regione: 'Molise',
    },
    {
      nome: 'Carbonia-Iglesias',
      sigla: 'CI',
      regione: 'Sardegna',
    },
    {
      nome: 'Caserta',
      sigla: 'CE',
      regione: 'Campania',
    },
    {
      nome: 'Catania',
      sigla: 'CT',
      regione: 'Sicilia',
    },
    {
      nome: 'Catanzaro',
      sigla: 'CZ',
      regione: 'Calabria',
    },
    {
      nome: 'Chieti',
      sigla: 'CH',
      regione: 'Abruzzo',
    },
    {
      nome: 'Como',
      sigla: 'CO',
      regione: 'Lombardia',
    },
    {
      nome: 'Cosenza',
      sigla: 'CS',
      regione: 'Calabria',
    },
    {
      nome: 'Cremona',
      sigla: 'CR',
      regione: 'Lombardia',
    },
    {
      nome: 'Crotone',
      sigla: 'KR',
      regione: 'Calabria',
    },
    {
      nome: 'Cuneo',
      sigla: 'CN',
      regione: 'Piemonte',
    },
    {
      nome: 'Enna',
      sigla: 'EN',
      regione: 'Sicilia',
    },
    {
      nome: 'Fermo',
      sigla: 'FM',
      regione: 'Marche',
    },
    {
      nome: 'Ferrara',
      sigla: 'FE',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Firenze',
      sigla: 'FI',
      regione: 'Toscana',
    },
    {
      nome: 'Foggia',
      sigla: 'FG',
      regione: 'Puglia',
    },
    {
      nome: 'Forlì-Cesena',
      sigla: 'FC',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Frosinone',
      sigla: 'FR',
      regione: 'Lazio',
    },
    {
      nome: 'Genova',
      sigla: 'GE',
      regione: 'Liguria',
    },
    {
      nome: 'Gorizia',
      sigla: 'GO',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Grosseto',
      sigla: 'GR',
      regione: 'Toscana',
    },
    {
      nome: 'Imperia',
      sigla: 'IM',
      regione: 'Liguria',
    },
    {
      nome: 'Isernia',
      sigla: 'IS',
      regione: 'Molise',
    },
    {
      nome: 'L\'Aquila',
      sigla: 'AQ',
      regione: 'Abruzzo',
    },
    {
      nome: 'La Spezia',
      sigla: 'SP',
      regione: 'Liguria',
    },
    {
      nome: 'Latina',
      sigla: 'LT',
      regione: 'Lazio',
    },
    {
      nome: 'Lecce',
      sigla: 'LE',
      regione: 'Puglia',
    },
    {
      nome: 'Lecco',
      sigla: 'LC',
      regione: 'Lombardia',
    },
    {
      nome: 'Livorno',
      sigla: 'LI',
      regione: 'Toscana',
    },
    {
      nome: 'Lodi',
      sigla: 'LO',
      regione: 'Lombardia',
    },
    {
      nome: 'Lucca',
      sigla: 'LU',
      regione: 'Toscana',
    },
    {
      nome: 'Macerata',
      sigla: 'MC',
      regione: 'Marche',
    },
    {
      nome: 'Mantova',
      sigla: 'MN',
      regione: 'Lombardia',
    },
    {
      nome: 'Massa-Carrara',
      sigla: 'MS',
      regione: 'Toscana',
    },
    {
      nome: 'Matera',
      sigla: 'MT',
      regione: 'Basilicata',
    },
    {
      nome: 'Medio Campidano',
      sigla: 'VS',
      regione: 'Sardegna',
    },
    {
      nome: 'Messina',
      sigla: 'ME',
      regione: 'Sicilia',
    },
    {
      nome: 'Milano',
      sigla: 'MI',
      regione: 'Lombardia',
    },
    {
      nome: 'Modena',
      sigla: 'MO',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Monza e della Brianza',
      sigla: 'MB',
      regione: 'Lombardia',
    },
    {
      nome: 'Napoli',
      sigla: 'NA',
      regione: 'Campania',
    },
    {
      nome: 'Novara',
      sigla: 'NO',
      regione: 'Piemonte',
    },
    {
      nome: 'Nuoro',
      sigla: 'NU',
      regione: 'Sardegna',
    },
    {
      nome: 'Ogliastra',
      sigla: 'OG',
      regione: 'Sardegna',
    },
    {
      nome: 'Olbia-Tempio',
      sigla: 'OT',
      regione: 'Sardegna',
    },
    {
      nome: 'Oristano',
      sigla: 'OR',
      regione: 'Sardegna',
    },
    {
      nome: 'Padova',
      sigla: 'PD',
      regione: 'Veneto',
    },
    {
      nome: 'Palermo',
      sigla: 'PA',
      regione: 'Sicilia',
    },
    {
      nome: 'Parma',
      sigla: 'PR',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Pavia',
      sigla: 'PV',
      regione: 'Lombardia',
    },
    {
      nome: 'Perugia',
      sigla: 'PG',
      regione: 'Umbria',
    },
    {
      nome: 'Pesaro e Urbino',
      sigla: 'PU',
      regione: 'Marche',
    },
    {
      nome: 'Pescara',
      sigla: 'PE',
      regione: 'Abruzzo',
    },
    {
      nome: 'Piacenza',
      sigla: 'PC',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Pisa',
      sigla: 'PI',
      regione: 'Toscana',
    },
    {
      nome: 'Pistoia',
      sigla: 'PT',
      regione: 'Toscana',
    },
    {
      nome: 'Pordenone',
      sigla: 'PN',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Potenza',
      sigla: 'PZ',
      regione: 'Basilicata',
    },
    {
      nome: 'Prato',
      sigla: 'PO',
      regione: 'Toscana',
    },
    {
      nome: 'Ragusa',
      sigla: 'RG',
      regione: 'Sicilia',
    },
    {
      nome: 'Ravenna',
      sigla: 'RA',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Reggio di Calabria',
      sigla: 'RC',
      regione: 'Calabria',
    },
    {
      nome: 'Reggio nell\'Emilia',
      sigla: 'RE',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Rieti',
      sigla: 'RI',
      regione: 'Lazio',
    },
    {
      nome: 'Rimini',
      sigla: 'RN',
      regione: 'Emilia-Romagna',
    },
    {
      nome: 'Roma',
      sigla: 'RM',
      regione: 'Lazio',
    },
    {
      nome: 'Rovigo',
      sigla: 'RO',
      regione: 'Veneto',
    },
    {
      nome: 'Salerno',
      sigla: 'SA',
      regione: 'Campania',
    },
    {
      nome: 'Sassari',
      sigla: 'SS',
      regione: 'Sardegna',
    },
    {
      nome: 'Savona',
      sigla: 'SV',
      regione: 'Liguria',
    },
    {
      nome: 'Siena',
      sigla: 'SI',
      regione: 'Toscana',
    },
    {
      nome: 'Siracusa',
      sigla: 'SR',
      regione: 'Sicilia',
    },
    {
      nome: 'Sondrio',
      sigla: 'SO',
      regione: 'Lombardia',
    },
    {
      nome: 'Taranto',
      sigla: 'TA',
      regione: 'Puglia',
    },
    {
      nome: 'Teramo',
      sigla: 'TE',
      regione: 'Abruzzo',
    },
    {
      nome: 'Terni',
      sigla: 'TR',
      regione: 'Umbria',
    },
    {
      nome: 'Torino',
      sigla: 'TO',
      regione: 'Piemonte',
    },
    {
      nome: 'Trapani',
      sigla: 'TP',
      regione: 'Sicilia',
    },
    {
      nome: 'Trento',
      sigla: 'TN',
      regione: 'Trentino-Alto Adige/Südtirol',
    },
    {
      nome: 'Treviso',
      sigla: 'TV',
      regione: 'Veneto',
    },
    {
      nome: 'Trieste',
      sigla: 'TS',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Udine',
      sigla: 'UD',
      regione: 'Friuli-Venezia Giulia',
    },
    {
      nome: 'Valle d\'Aosta/Vallée d\'Aoste',
      sigla: 'AO',
      regione: 'Valle d\'Aosta/Vallée d\'Aoste',
    },
    {
      nome: 'Varese',
      sigla: 'VA',
      regione: 'Lombardia',
    },
    {
      nome: 'Venezia',
      sigla: 'VE',
      regione: 'Veneto',
    },
    {
      nome: 'Verbano-Cusio-Ossola',
      sigla: 'VB',
      regione: 'Piemonte',
    },
    {
      nome: 'Vercelli',
      sigla: 'VC',
      regione: 'Piemonte',
    },
    {
      nome: 'Verona',
      sigla: 'VR',
      regione: 'Veneto',
    },
    {
      nome: 'Vibo Valentia',
      sigla: 'VV',
      regione: 'Calabria',
    },
    {
      nome: 'Vicenza',
      sigla: 'VI',
      regione: 'Veneto',
    },
    {
      nome: 'Viterbo',
      sigla: 'VT',
      regione: 'Lazio',
    },
  ];
  integratedSignature = environment.integratedSignature;
  isApiV3 = environment.isV3;
  constructor(
    private signatureService: SignatureService,
    private orderService: OrdersService,
    private httpService: HttpService,
    private orderLogService: OrderlogService,
    private router: Router,
    private snackbar: MatSnackBar,
  ) {

  }

  checkAnagrafica(form): any {
    return this.checkMail(form.anagrafica.anagrafica.email) && this.checkAnagraficaFields(form.anagrafica) && this.checkPhone(form.anagrafica.anagrafica.tel1) && this.checkCf(form) && this.checkDifferentAddress(form);
  }

  checkCf(form): boolean {
    // Controllo che il CF sia alfanumerico e max 16 caratteri
    return /[a-zA-Z0-9]/.test(form.anagrafica.anagrafica.cf) && form.anagrafica.anagrafica.cf.length === 16 || (/[a-zA-Z0-9]/.test(form.anagrafica.anagrafica.cf) && form.anagrafica.anagrafica.cf.length === 11 && form.anagrafica.anagrafica.ragionesociale && form.anagrafica.anagrafica.ragionesociale?.trim() !== '');
  }

  checkMail(email): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkAnagraficaFields(obj): boolean {
    if (obj.anagrafica.nome && obj.anagrafica.cognome && obj.anagrafica.cf && obj.infoContratto.consulente) {
      return this.checkAddress(obj.anagrafica) && obj.anagrafica.nome && obj.anagrafica.cognome && obj.anagrafica.cf && obj.infoContratto.consulente;
    } else {
      return false;
    }
  }

  checkAddress(obj): boolean {
    return obj.citta && obj.indirizzo && obj.numero && obj.cap && obj.provincia;
  }

  checkPhone(phone): boolean {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/im;
    return re.test(phone);
  }

  checkDifferentAddress(form) {
    if (form.anagrafica.anagrafica.diversoind && form.anagrafica.anagrafica.indirizzoinst.trim() !== '') {
      return true;
    } else if (!form.anagrafica.anagrafica.diversoind) {
      return true;
    }
    return false;
  }

  checkColor(obj, tipoCassettaWc: Trascodifica[]): boolean {
    let tipo = tipoCassettaWc.filter(t => t.descrizione === "Cassetta wc ad incasso GEBERIT");
    if (tipo[0] !== undefined && obj.modelId == tipo[0].id) {
      if (obj.placcaColorId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkType(obj, tipoCassettaWc: Trascodifica[]): boolean {
    let tipo = tipoCassettaWc.filter(t => t.descrizione === "Cassetta wc ad incasso GEBERIT");
    if (tipo[0] !== undefined && obj.modelId == tipo[0].id) {
      if (obj.placcaTypeId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  // controlli finanziamento

  checkFinAnagrafica(form): any {
    if(form.sections.finanziamentoSections.finAnagrafica){
      return this.checkFinAnagraficaFields(form.sections.finanziamentoSections.finAnagrafica) && this.checkFinCf(form);
    }
    return false;
  }

  checkFinAnagraficaGarante(form): any {
    if(form.sections.finanziamentoSections.finAnagraficaGarante){
      return this.checkFinAnagraficaGaranteFields(form.sections.finanziamentoSections.finAnagraficaGarante) && this.checkFinGaranteCf(form);
    }
    return false;
  }

  checkFinAnagraficaFields(obj): boolean {
    if (obj?.nome && obj?.cognome && obj?.codicefiscale && obj?.sesso && obj?.datanascita && obj?.comunenascita && obj?.statocivileId) {
      return obj.nome && obj.cognome && obj.codicefiscale && obj.sesso && obj.datanascita && obj.comunenascita && obj.statocivileId;
    } else {
      return false;
    }
  }

  checkFinAnagraficaGaranteFields(obj): boolean {
    if (obj?.nome && obj?.cognome && obj?.codicefiscale && obj?.sesso && obj?.datanascita && obj?.comunenascita && obj?.statocivileId) {
      return obj.nome && obj.cognome && obj.codicefiscale && obj.sesso && obj.datanascita && obj.comunenascita && obj.statocivileId;
    } else {
      return false;
    }
  }

  checkFinDatiResidenza(obj): any{
    if (obj?.via && obj?.civico && obj?.comune && obj?.cap && obj?.provincia && obj?.tipoabitazioneId) {
      return obj.via && obj.civico && obj.comune && obj.cap && obj.provincia && obj.tipoabitazioneId ;
    }else{
      return false;
    }
  }
  
  checkFinDatiResidenzaGarante(obj): any{
    if (obj?.via && obj?.civico && obj?.comune && obj?.cap && obj?.provincia && obj?.tipoabitazioneId) {
      return obj.via && obj.civico && obj.comune && obj.cap && obj.provincia && obj.tipoabitazioneId ;
    }else{
      return false;
    }
  }

  checkFinRecapiti(obj): any{
    if (obj?.tel && obj?.email) {
      return obj.tel && obj.email;
    }else{
      return false;
    }
  }

  checkFinRecapitiGarante(obj): any{
    if (obj?.tel && obj?.email) {
      return obj.tel && obj.email;
    }else{
      return false;
    }
  }

  checkFinCf(form): boolean {
    // Controllo che il CF sia alfanumerico e max 16 caratteri
    return /[a-zA-Z0-9]/.test(form.sections.finanziamentoSections.finAnagrafica?.codicefiscale) && form.sections.finanziamentoSections.finAnagrafica?.codicefiscale?.length === 16 || (/[a-zA-Z0-9]/.test(form.sections.finanziamentoSections.finAnagrafica?.codicefiscale) && form.sections.finanziamentoSections.finAnagrafica?.codicefiscale?.length === 11 && form.anagrafica.anagrafica.ragionesociale && form.anagrafica.anagrafica.ragionesociale?.trim() !== '');
  }

  checkFinGaranteCf(form): boolean {
    return /[a-zA-Z0-9]/.test(form.sections.finanziamentoSections.finAnagraficaGarante?.codicefiscale) && form.sections.finanziamentoSections.finAnagraficaGarante?.codicefiscale?.length === 16;
  }

  checkFinDatiOccupazione(obj, trascodifiche: Trascodifica[]): any{
    if (obj?.attivitalavId) {
      if(this.checkAttivitaLavorativa(obj.attivitalavId, trascodifiche)){
        if(obj.datainizioocc && obj.tipoAssunzioneId){
          return obj.datainizioocc && obj.tipoAssunzioneId;
        }
      }else{
        return obj.attivitalavId;
      }
    }else{
      return false;
    }
  }

  checkFinDatiOccupazioneGarante(obj, trascodifiche: Trascodifica[]): any{
    if (obj?.attivitalavId) {
      if(this.checkAttivitaLavorativa(obj.attivitalavId, trascodifiche)){
        if(obj.datainizioocc && obj.tipoAssunzioneId){
          return obj.datainizioocc && obj.tipoAssunzioneId;
        }
      }else{
        return obj.attivitalavId;
      }
    }else{
      return false;
    }
  }
  
  checkAttivitaLavorativa(attivitaId: number, trascodifiche: Trascodifica[]): boolean {
    if(attivitaId){
      let obj = this.getTrascodificaById(attivitaId, trascodifiche);
      if((obj && obj.descrizione.includes(constants.DESC_ATTIVITA_LAV_DIPENDENTE)) || (obj && obj.descrizione.includes(constants.DESC_ATTIVITA_LAV_OCCUP_SALT)) ){
        return true;
      }
    }
    return false;
  }

  checkVisibilitySectionDatoreDiLavoro(form: any, attivitaLavList: any[]): boolean {
    if (form.sections.finanziamentoSections.finDatiOccupazione && form.sections.finanziamentoSections.finDatiOccupazione.attivitalavId && attivitaLavList?.length) {
      let trascodifica = this.getTrascodificaById(form.sections.finanziamentoSections.finDatiOccupazione.attivitalavId, attivitaLavList);
      return trascodifica && ( trascodifica.descrizione.includes(constants.DESC_ATTIVITA_LAV_DIPENDENTE) || trascodifica.descrizione.includes(constants.DESC_ATTIVITA_LAV_OCCUP_SALT) || trascodifica.descrizione.includes(constants.DESC_ATTIVITA_LAV_AUTO))
    }
    return false;
  }

  checkVisibilitySectionDatoreDiLavoroGarante(form: any, attivitaLavList: any[]): boolean {
    if (form.sections.finanziamentoSections.finDatiOccupazioneGarante && form.sections.finanziamentoSections.finDatiOccupazioneGarante.attivitalavId && attivitaLavList?.length) {
      let trascodifica = this.getTrascodificaById(form.sections.finanziamentoSections.finDatiOccupazioneGarante.attivitalavId, attivitaLavList);
      return trascodifica && ( trascodifica.descrizione.includes(constants.DESC_ATTIVITA_LAV_DIPENDENTE) || trascodifica.descrizione.includes(constants.DESC_ATTIVITA_LAV_OCCUP_SALT) || trascodifica.descrizione.includes(constants.DESC_ATTIVITA_LAV_AUTO))
    }
    return false;
  }

  checkFinDatiDatore(obj): any{
    if (obj?.ragionesocialedatore && obj?.via && obj?.civico && obj?.comune && obj?.cap && obj?.provincia) {
      return obj.ragionesocialedatore && obj.via && obj.civico && obj.comune && obj.cap && obj.provincia;
    }else{
      return false;
    }
  }

  checkFinDatiDatoreGarante(obj): any{
    if (obj?.ragionesocialedatore && obj?.via && obj?.civico && obj?.comune && obj?.cap && obj?.provincia) {
      return obj.ragionesocialedatore && obj.via && obj.civico && obj.comune && obj.cap && obj.provincia;
    }else{
      return false;
    }
  }

  checkDatiReddito(obj): any{
    if (obj?.nummensilita && obj?.totalereddito ) {
      return obj.nummensilita && obj.totalereddito;
    }else{
      return false;
    }
  }

  checkDatiRedditoGarante(obj): any{
    if (obj?.nummensilita && obj?.totalereddito ) {
      return obj.nummensilita && obj.totalereddito;
    }else{
      return false;
    }
  }

  checkDatiPagamento(form: any, finanziariaTrascId: number, listFinanziarie: Trascodifica[], listattivtaLav: Trascodifica[]): any{
      return  !(
        this.checkPagamentoBollettino(form, finanziariaTrascId, listFinanziarie, listattivtaLav ) 
          ? form.sections.finanziamentoSections.finanziamento.bollettino == null 
          : form.sections.finanziamentoSections.finanziamento.iban?.trim() == '' || !this.isValidIban(form.sections.finanziamentoSections.finanziamento.iban?.trim())
        )
  }

  checkProspetto(obj): any{
    if ( obj?.prezzotot && obj?.acconto !== null &&  obj?.residuo && obj?.numrate && obj?.tan && obj?.taeg && obj?.importorata ) {
      return obj.prezzotot && obj?.acconto !== null &&  obj.residuo && obj.numrate && obj?.tan && obj?.taeg && obj?.importorata
    }else{
      return false;
    }
  }

  checkPagamentoBollettino(form: any, finanziariaTrascId: number, listFinanziarie: Trascodifica[], listattivtaLav: Trascodifica[]): boolean {
    let descFinanziariaTrasc = this.getTrascodificaById(finanziariaTrascId, listFinanziarie)?.descrizione;
    let descOccupazioneTrasc = this.getTrascodificaById(form.sections.finanziamentoSections.finDatiOccupazione.attivitalavId, listattivtaLav)?.descrizione;
    if(descFinanziariaTrasc && descOccupazioneTrasc) {
      if (
         (descFinanziariaTrasc == constants.DESC_FINANZIARIA_COMPASS && 
         (descOccupazioneTrasc == constants.DESC_ATTIVITA_LAV_DIPENDENTE || descOccupazioneTrasc == constants.DESC_ATTIVITA_LAV_PENSIONATO)) ||
         (descFinanziariaTrasc == constants.DESC_FINANZIARIA_SANTANDER && 
         (descOccupazioneTrasc == constants.DESC_ATTIVITA_LAV_DIPENDENTE || descOccupazioneTrasc == constants.DESC_ATTIVITA_LAV_PENSIONATO))
      ) {
            return true;
      }
    }
    return false;
  }

  checkEta(form: any, finanziariaTrascId: number, listFinanziarie: Trascodifica[]): boolean {
    if(form.sections.finanziamentoSections.finanziamento.numrate){
      let descFinanziariaTrasc = this.getTrascodificaById(finanziariaTrascId, listFinanziarie)?.descrizione;
      let nRateYear: number = form.sections.finanziamentoSections.finanziamento.numrate / 12; 
      if(descFinanziariaTrasc) {
        if(descFinanziariaTrasc == constants.DESC_FINANZIARIA_COFIDIS){
          return this.calculateEtaFinaleFinanziamento(form, constants.MAX_ETA_FIN_COFIDIS, nRateYear);
        }else if(descFinanziariaTrasc == constants.DESC_FINANZIARIA_COMPASS || descFinanziariaTrasc == constants.DESC_FINANZIARIA_SANTANDER){
          return this.calculateEtaFinaleFinanziamento(form, constants.MAX_ETA_FIN_COMPASS, nRateYear);
        }
      }
    }
  }

  calculateEtaFinaleFinanziamento(form: any, maxEtaFinanziaria: number, nRateYear: number): boolean {
    const birthDate = new Date(form.sections.finanziamentoSections.finAnagrafica.datanascita);
    const age: number = this.getAgeFromBirthDate(birthDate); 
  
    /*console.log('----------------');
    console.log('Età: ' + age);
    console.log('Anni delle rate: ' + nRateYear);
    console.log('Somma: ' + (age + nRateYear)); 
    console.log('Età massima finanziamento: ' + maxEtaFinanziaria);*/
  
    return (age + nRateYear) > maxEtaFinanziaria && this.calculateEtaFinaleFinanziamentoGarante(form, maxEtaFinanziaria, nRateYear); 
  }
  

  calculateEtaFinaleFinanziamentoGarante(form: any, maxEtaFinanziaria: number, nRateYear: number): boolean {
    if (!form.sections.finanziamentoSections.finAnagraficaGarante.datanascita) { 
      console.log('Ritorna true, nessun garante');
      return true; 
    } else {
      const birthDate = new Date(form.sections.finanziamentoSections.finAnagraficaGarante.datanascita); 
      const age: number = this.getAgeFromBirthDate(birthDate); // Calcola l'età del garante
      
      /*console.log('Età garante: ' + age);
      console.log('Anni delle rate del garante: ' + nRateYear);
      console.log('Somma garante: ' + (age + nRateYear)); 
      console.log('Età massima finanziamento per il garante: ' + maxEtaFinanziaria);*/
  
      return (age + nRateYear) > maxEtaFinanziaria; 
    }
  }
  
  getAgeFromBirthDate(date: Date): number {
    const today = new Date();
    let age = 0;

    age = today.getFullYear() - date.getFullYear();
    const monthDiff = today.getMonth() - date.getMonth();

    // Se non ha ancora compiuto gli anni quest'anno, sottrae 1
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
        age--;
    }

    return age;
  }

  shouldShowPopupGaranzie(form: any, attivitaLavList: Trascodifica[], trascodifiche: Trascodifica[] ): boolean {
    const attivitaLavId = form.sections.finanziamentoSections.finDatiOccupazione.attivitalavId;
    
    if (attivitaLavId) {
      let obj = this.getTrascodificaById(attivitaLavId, attivitaLavList);
      const prezzoTotale = form.sections.finanziamentoSections.finanziamento.prezzotot;
      
      if (obj) {
        if ((obj.descrizione === constants.DESC_ATTIVITA_LAV_CASALINGA || obj.descrizione === constants.DESC_ATTIVITA_LAB_CONDIZ_NON_PROFESS)) {
          let finanziaria = this.getTrascodificaById(form.sections.finanziamentoSections.finanziamento.finanziariaId, trascodifiche)?.descrizione;
          if(finanziaria){
            if (finanziaria.includes(constants.DESC_FINANZIARIA_SANTANDER) && prezzoTotale > constants.GARANZIA_MAX_CASALINGA_SANTANDER) {
              return true;
            } else if (finanziaria.includes(constants.DESC_FINANZIARIA_COFIDIS) && prezzoTotale > constants.GARANZIA_MAX_CASALINGA_COFIDIS) {
              return true;
            } else if (finanziaria.includes(constants.DESC_FINANZIARIA_COMPASS) && prezzoTotale > constants.GARANZIA_MAX_CASALINGA_COMPASS) {
              return true;
            }
          }
        }
      }
    }
  
    return false;
  }

  // Il regex verifica che l'IBAN:
  // Inizi con due lettere maiuscole (il codice paese).
  // Sia seguito da due numeri (le cifre di controllo).
  // Contenga tra 11 e 30 caratteri alfanumerici (il resto del codice IBAN, che include il numero di conto, il codice filiale, ecc.).
  isValidIban(iban: string): boolean {
    const ibanRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{11,30}$/;
    if (!ibanRegex.test(iban)) {
        return false;
    }

    const rearrangedIban = iban.slice(4) + iban.slice(0, 4);

    const ibanNumeric = rearrangedIban.replace(/[A-Z]/g, (char) => {
        return (char.charCodeAt(0) - 55).toString();
    });

    return this.mod97(ibanNumeric) === 1;
  }

  // L'algoritmo funziona elaborando l'IBAN in blocchi di 7 cifre alla volta. Questo evita problemi con numeri troppo grandi per essere gestiti da JavaScript in un unico blocco.
  // Ad ogni ciclo:
  // Si prende il "blocco" di 7 cifre e si aggiunge il resto della divisione precedente.
  // Si calcola il resto della divisione per 97 di quel blocco.
  // Il resto viene accumulato e passato alla successiva iterazione.
  // Questo continua fino alla fine della stringa numerica dell'IBAN.
  // L'algoritmo Modulo 97 fa parte dello standard ISO 13616, che definisce la struttura e le regole di validazione degli IBAN. Questo standard specifica che per la verifica dell'IBAN deve essere utilizzata 
  // la divisione per 97, poiché offre un modo efficiente di controllare grandi numeri con una bassa probabilità di errore.
  mod97(ibanNumeric: string): number {
    let remainder = 0;

    for (let i = 0; i < ibanNumeric.length; i += 7) {
        const block = remainder + ibanNumeric.substr(i, 7);
        remainder = parseInt(block, 10) % 97;
    }

    return remainder;
  }

  validStepFinanziamento(form:any, attivitaLavList:Trascodifica[], listFinanziarie: Trascodifica[] ): boolean {
    const standardChecks = 
    !form.sections.finanziamentoSections.isAllegatoSaved ||
    !this.checkFinAnagrafica(form) ||
    !this.checkFinDatiResidenza(form.sections.finanziamentoSections.finAnagrafica) ||
    !this.checkFinRecapiti(form.sections.finanziamentoSections.finAnagrafica) ||
    !this.checkFinDatiOccupazione(form.sections.finanziamentoSections.finDatiOccupazione, attivitaLavList) ||
    (
    this.checkVisibilitySectionDatoreDiLavoro(form, attivitaLavList) 
      ? !this.checkFinDatiDatore(form.sections.finanziamentoSections.finDatiOccupazione)
      : false
    ) ||
    this.shouldShowPopupGaranzie(form, attivitaLavList, listFinanziarie ) ||
    !this.checkDatiPagamento(form, form.sections.finanziamentoSections.finanziamento.finanziariaId, listFinanziarie, attivitaLavList) ||
    !this.checkDatiReddito(form.sections.finanziamentoSections.finDatiOccupazione) ||
    !this.checkProspetto(form.sections.finanziamentoSections.finanziamento);
  
  // Controllo aggiuntivo se garante presente
  const garanteCheck = form.sections.finanziamentoSections.finAnagraficaGarante?.garante ? 
    !this.checkFinAnagraficaGarante(form) ||
    !this.checkFinDatiResidenzaGarante(form.sections.finanziamentoSections.finAnagraficaGarante) ||
    !this.checkFinRecapitiGarante(form.sections.finanziamentoSections.finAnagraficaGarante) ||
    !this.checkFinDatiOccupazioneGarante(form.sections.finanziamentoSections.finDatiOccupazioneGarante, attivitaLavList) ||
    (
      this.checkVisibilitySectionDatoreDiLavoroGarante(form, attivitaLavList) 
        ? !this.checkFinDatiDatoreGarante(form.sections.finanziamentoSections.finDatiOccupazioneGarante)
        : false
    ) ||
    !this.checkDatiRedditoGarante(form.sections.finanziamentoSections.finDatiOccupazioneGarante)
  : false;


  let validMaxYears;
  if(form.sections.finanziamentoSections && form.sections.finanziamentoSections.finanziamento &&  form.sections.finanziamentoSections.finanziamento.finanziariaId
    && listFinanziarie.length){
    validMaxYears = this.checkEta(form, form.sections.finanziamentoSections.finanziamento.finanziariaId,  listFinanziarie);
  }
  
  return  validMaxYears || standardChecks || garanteCheck;
  }

  // fine controlli finanziamento

  returnProvince(): any {
    return this.province;
  }

  /*
  START MAPPING
   */

  /*
  Mapping dati tecnici
   */
  public mappingFormToDatiTecniciDTO(form: DatiTecnici, dto: DatiTecniciDTO): DatiTecniciDTO {
    dto.id = form.id;
    dto.tipoabitazioneId = form.tipoAbitazioneId;
    dto.tipobagnoId = form.docciaVasca.tipoBagnoId;
    dto.tiposanitariId = form.sanitariTipoId;
    dto.tiposcaricowcId = form.scaricoWcId;
    dto.tipocasscwcId = form.cassettaScaricoId;
    dto.cassettamonolitebidet = form.cassettamonolitebidet;
    dto.cassettamonolitewc = form.cassettamonolitewc;
    dto.adesionepiasId = form.adesionePiastrelleId;
    dto.muriperiId = form.muriPerimetraliId;
    dto.tiporiscaId = form.tipoRiscaldamentoId;
    dto.ordineId = form.ordineId;
    dto.proprieta = form.diProprieta;
    dto.ascensore = form.ascensore;
    dto.accesscortile = form.accessibilita_cortile;
    dto.pianointervento = form.piano;
    dto.lungvano = form.dimensioniVano.lung;
    dto.altvano = form.dimensioniVano.alt;
    dto.largvano = form.dimensioniVano.larg;
    dto.altporta = form.dimensioniPorta.alt;
    dto.lungporta = form.dimensioniPorta.lung;
    dto.altpiastrelle = form.dimensioniPorta.altPiastrelle;
    dto.lungtipobagno = form.docciaVasca.dimensioni.lung;
    dto.largtipobagno = form.docciaVasca.dimensioni.larg;
    dto.alttipobagno = form.docciaVasca.dimensioni.alt;
    dto.alttermo = form.misuraTermosifone.alt;
    dto.lungtermos = form.misuraTermosifone.lung;
    dto.proftermos = form.misuraTermosifone.prof;
    dto.termosifoneIncassato = form.misuraTermosifone.incassato;
    dto.interasstermo = form.misuraTermosifone.interasse;
    dto.lavatrpres = form.lavatricePresente;
    dto.lavatrmanten = form.lavatriceDaMantenere;
    dto.bidet = form.bidetPresente;
    dto.attlavcreare = form.attacchiLavatriceDaCreare;
    dto.noteattlavcr = form.attacchiLavatriceNote;
    dto.wcpresente = form.wcPresente;
    dto.boilerpresente = form.boilerPresente;
    dto.boilermanten = form.boilerDaMantenere;
    dto.comandosciacq = form.comandoSciaquone;
    dto.puntolucelavabo = form.puntoLuce;
    dto.notetecniche = form.noteTecniche;
    dto.tipoimpiantoId = form.tipoimpiantoId;
    dto.tipoimpiantoDescrizione = form.tipoimpiantoDescrizione;
    dto.imptermpres = form.imptermpres;
    dto.termospres = form.termospres;
    dto.nRampe = form.nRampe;
    dto.nGradini = form.nGradini;
    dto.alzata = form.alzata;
    dto.pedata = form.pedata;
    dto.largScala = form.largScala;
    dto.misuraPianerottoloh = form.misuraPianerottoloh;
    dto.misuraPianerottolol = form.misuraPianerottolol;
    return dto;
  }

  /*
   Mapping dati rivestimenti
   */

  public mappingFormToRivestimentiDto(form: Rivestimenti, dto: RivestimentiDTO): RivestimentiDTO {
    dto.id = form.id;
    dto.pavimentodesc = form.pavimento;
    dto.paretedesc = form.pareti;
    dto.paretea = form.A;
    dto.pareteah = form.Ah;
    dto.pareteb = form.B;
    dto.paretebh = form.Bh;
    dto.internodoccia = form.internoDoccia;
    dto.internodocciah = form.internoDH;
    dto.note = form.note;
    //dto.ordineId = form.ordineId;
    dto.paretec = form.pareteC;
    dto.paretech = form.pareteCh;
    dto.pareted = form.pareteD;
    dto.paretedh = form.pareteDh;
    return dto;
  }

  public mappingFormToSanitariDTO(form: NuoviProdotti, dto: SanitariDTO, idOrder): SanitariDTO {
    dto.id = form.id;
    //nuovi sanitari
    dto.modsanitariId = form.nuoviSanitari.modelloSanitari.modello.id;
    dto.tiposcaricoId = form.nuoviSanitari.modelloSanitari.scarico.id;
    dto.tiposanitariId = form.nuoviSanitari.modelloSanitari.sanitari.id;
    dto.tipoprodottoId = form.nuoviSanitari.modelloSanitari.prodotto.id;
    dto.notelibere = form.nuoviSanitari.notelibere;
    //TODO codice gdl da passare in seguito
    dto.mobilebagno = form.nuoviSanitari.mobileBagno.descrizione;
    dto.mobilecolore = form.nuoviSanitari.mobileBagno.colore;
    dto.mobilecolore2 = form.nuoviSanitari.mobileBagno.colore2;
    dto.numvascmobId = form.nuoviSanitari.mobileBagno.numeroVasche;
    dto.tipocolmobId = form.nuoviSanitari.mobileBagno.colonnaPensile;
    dto.qtapensmob = form.nuoviSanitari.mobileBagno.quantitaPensili;
    dto.vascamobId = form.nuoviSanitari.mobileBagno.vascaMob;
    dto.notecompmob = form.nuoviSanitari.mobileBagno.note;
    //nuova cassetta wc
    if (form.nuovaCassettaWc.modelId) {
      dto.tipocasswcId = form.nuovaCassettaWc.modelId;
      dto.tipoplaccawcId = form.nuovaCassettaWc.placcaTypeId;
      dto.coloreplaccawcId = form.nuovaCassettaWc.placcaColorId;
      dto.cassettamonolitebidet = form.nuovaCassettaWc.cassettamonolitebidet;
      dto.cassettamonolitewc = form.nuovaCassettaWc.cassettamonolitewc;
    }
    dto.murettoModelId = form.nuovaCassettaWc.murettoModelId;

    //nuova rubinetteria
    //Serie
    if (form.nuovaRubinetteria.id) {
      dto.rubserieId = form.nuovaRubinetteria.id;
      dto.rubbidetId = form.nuovaRubinetteria.subBidetId;
      dto.rubprodId = form.nuovaRubinetteria.subLavaboId;
    }

    //kit
    dto.rubkitId = form.nuovaRubinetteria.docciaType.modelId;
    //miscelatore kit
    dto.rubmiscId = form.nuovaRubinetteria.docciaType.miscelatoreId;
    //asta
    dto.rubtipoastaId = form.nuovaRubinetteria.astaComby.id;
    //asta singola
    dto.astasingola = form.nuovaRubinetteria.astasingola;
    //asta doppia
    dto.astadoppia = form.nuovaRubinetteria.astadoppia;
    //colonna
    dto.rubtipocolId = form.nuovaRubinetteria.colonne.id;
    //miscrelatore esterno
    dto.rubmiscestId = form.nuovaRubinetteria.miscelatoreEst.typeId;
    //dati grohe
    dto.lavabo = form.nuovaRubinetteria.lavabo;
    dto.bidet = form.nuovaRubinetteria.bidet;
    dto.doccia = form.nuovaRubinetteria.doccia;
    dto.vasca = form.nuovaRubinetteria.vasca;
    //serie miscelatore esterno
    if (form.nuovaRubinetteria.miscelatoreEst.model && form.nuovaRubinetteria.miscelatoreEst.model.id) {
      dto.rubmiscserieId = form.nuovaRubinetteria.miscelatoreEst.model.id;
    }

    //colore nuova rubinetteria
    dto.rubbicolId = form.nuovaRubinetteria.rubbicolId;

    //dto.idordineId = idOrder;

    return dto;
  }

  public mappingFormToGroheDto(form: Grohe, dto: GroheDTO, idOrder): GroheDTO {
    dto.id = form.id;
    dto.tipologiaId = form.tipologiaId;
    dto.ordineId = idOrder;
    return dto;
  }

  public getTrascodificaByCodice(codice: string, trascodifiche: Trascodifica[]): Trascodifica[] {

    return trascodifiche.filter(
      tr => tr.codice === codice);
  }

  public getTrascodificaById(id: number, trascodifiche: Trascodifica[]): Trascodifica {

    return trascodifiche.find(
      tr => tr.id === id);
  }

  getTrascodificaByCodicPromise(text, array): any {
    return new Promise((resolve, reject) => {
      const data = array.filter(tr => tr.codice === text);
      resolve(data);
    });
  }

  mappingSanitariDTOtoForm(dto: SanitariDTO, form: NuoviProdotti): NuoviProdotti {
    if (dto !== undefined) {
      form.id = dto.id;
      //nuovi sanitari
      form.nuoviSanitari.modelloSanitari.modello.id = dto.modsanitariId;
      form.nuoviSanitari.modelloSanitari.modello.descrizione = dto.modsanitariDescrizione;
      form.nuoviSanitari.modelloSanitari.scarico.id = dto.tiposcaricoId;
      form.nuoviSanitari.modelloSanitari.scarico.descrizione = dto.tiposcaricoDescrizione;
      form.nuoviSanitari.modelloSanitari.sanitari.id = dto.tiposanitariId;
      form.nuoviSanitari.modelloSanitari.sanitari.descrizione = dto.tiposanitariDescrizione;
      form.nuoviSanitari.modelloSanitari.prodotto.id = dto.tipoprodottoId;
      form.nuoviSanitari.modelloSanitari.prodotto.descrizione = dto.tipoprodottoDescrizione;
      form.nuoviSanitari.notelibere = dto.notelibere;
      //TODO per adesso non si deve passare
      //form.nuoviSanitari.modelloSanitari.codiceGDL = dto.codicegdl;				
      form.nuoviSanitari.mobileBagno.descrizione = dto.mobilebagno;
      form.nuoviSanitari.mobileBagno.colore = dto.mobilecolore;
      form.nuoviSanitari.mobileBagno.colore2 = dto.mobilecolore2;
      form.nuoviSanitari.mobileBagno.numeroVasche = dto.numvascmobId;
      form.nuoviSanitari.mobileBagno.numvascmobDescrizione = dto.numvascmobDescrizione;
      form.nuoviSanitari.mobileBagno.colonnaPensile = dto.tipocolmobId;
      form.nuoviSanitari.mobileBagno.tipocolmobDescrizione = dto.tipocolmobDescrizione;
      form.nuoviSanitari.mobileBagno.quantitaPensili = dto.qtapensmob;
      form.nuoviSanitari.mobileBagno.vascaMob = dto.vascamobId;
      form.nuoviSanitari.mobileBagno.vascamobDescrizione = dto.vascamobDescrizione;
      form.nuoviSanitari.mobileBagno.note = dto.notecompmob;
      //nuova cassetta wc
      form.nuovaCassettaWc.modelId = dto.tipocasswcId;
      form.nuovaCassettaWc.placcaColorId = dto.coloreplaccawcId;
      form.nuovaCassettaWc.placcaTypeId = dto.tipoplaccawcId;
      form.nuovaCassettaWc.cassettamonolitewc = dto.cassettamonolitewc;
      form.nuovaCassettaWc.cassettamonolitebidet = dto.cassettamonolitebidet;
      form.nuovaCassettaWc.murettoModelId = dto.murettoModelId;
      //nuova rubinetteria
      //Serie
      form.nuovaRubinetteria.id = dto.rubserieId;
      form.nuovaRubinetteria.subBidetId = dto.rubbidetId;
      form.nuovaRubinetteria.subLavaboId = dto.rubprodId;
      //kit 
      form.nuovaRubinetteria.docciaType.modelId = dto.rubkitId;
      //miscelatore kit
      form.nuovaRubinetteria.docciaType.miscelatoreId = dto.rubmiscId;
      //asta			
      form.nuovaRubinetteria.astaComby.id = dto.rubtipoastaId;
      //asta singola
      form.nuovaRubinetteria.astasingola = dto.astasingola;
      //asta doppia
      form.nuovaRubinetteria.astadoppia = dto.astadoppia;
      //colonna
      form.nuovaRubinetteria.colonne.id = dto.rubtipocolId;
      //miscrelatore esterno			
      form.nuovaRubinetteria.miscelatoreEst.typeId = dto.rubmiscestId;
      //dati grohe
      form.nuovaRubinetteria.lavabo = dto.lavabo;
      form.nuovaRubinetteria.bidet = dto.bidet;
      form.nuovaRubinetteria.doccia = dto.doccia;
      form.nuovaRubinetteria.vasca = dto.vasca;

      //serie miscelatore esterno
      if (dto.rubmiscserieId) {
        form.nuovaRubinetteria.miscelatoreEst.model.id = dto.rubmiscserieId;
      }
      //colore rubinetteria
      form.nuovaRubinetteria.rubbicolId = dto.rubbicolId;
    }



    //this.form.ordinerId= this.form.idOrder;
    return form;
  }

  mappingDatiTecniciToForm(dto: NuoviDatiDTO, form: NuoviDati): NuoviDati {
    if (dto !== undefined) {
      form.id = dto.id;
      form.box.larghezza = dto.boxl;
      form.box.lunghezza = dto.boxp;
      form.box.altezza = dto.boxh;
      form.muro = dto.murosx === true ? 'MUROSX' : 'MURODX';
      form.larghezza = dto.piattol;
      form.prof = dto.piattop;
      form.piantinaAttuale = dto.piantina;
      form.nuovaPiantina = dto.nuovapiantina;
    }

    return form;
  }

  public mappingFormToNuoviDatiTecDTO(form: NuoviDati, dto: NuoviDatiDTO, idOrder: number): NuoviDatiDTO {
    dto.id = form.id;
    dto.boxl = form.box.larghezza;
    dto.boxp = form.box.lunghezza;
    dto.boxh = form.box.altezza;
    if (form.muro !== undefined) {
      dto.murosx = form.muro === 'MUROSX' ? true : false;
      dto.murodx = form.muro === 'MURODX' ? true : false;
    }

    dto.piattol = form.larghezza;
    dto.piattop = form.prof;
    dto.ordineId = idOrder;
    dto.piantina = form.piantinaAttuale;
    dto.piantinaContentType = form.piantinaAttualeType;
    dto.nuovapiantina = form.nuovaPiantina;
    dto.nuovapiantinaContentType = form.nuovaPiantinaType;
    return dto;
  }

  public compressImage(src, newX, newY): any {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;

      img.onload = () => {
        const elem = document.createElement('canvas');
        const image = {
          width: img.width,
          height: img.height
        };
        // Get the page dimensions:
        const page = {
          width: newX,
          height: newY,
        };

        const imageRatio = image.width / image.height;

        // Get the page ratio, this is what we use to decide which dimension to fix:
        const pageRatio = page.width / page.height;

        elem.height = page.width / imageRatio;
        elem.width = page.width;
        if (imageRatio < 2) {
          elem.height = 300;
          elem.width = elem.height * imageRatio;
        }
        // elem.width = newX;
        // elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, elem.width, elem.height);
        const data = ctx.canvas.toDataURL();
        res(data);
      };
      img.onerror = error => rej(error);
    });
  }

  checkDimensionsVerona(event, trascodifiche): boolean {
    // var result = false;
    var result = true;
    if (event !== undefined) {
      var tras = this.getTrascodificaByCodice('VERONA_MISURA', trascodifiche).filter(tr => tr.id === event);
      if (tras.length > 0) {
        if (tras[0].descrizione === '160*65' || tras[0].descrizione === '170*70') {
          result = true;
        }
      }
    }
    return result;
  }

  mappingAccessoriDtoToForm(dto: AccessoriDTO, form: AccessorioBox): AccessorioBox {
    if (dto !== undefined) {
      form.id = dto.id;
      form.quantita = dto.quantita;
      form.tipologiaId = dto.tipologiaId;
    }

    return form;
  }

  mappingOmaggioDTOtoForm(dto: OmaggioDTO, form: Omaggio): Omaggio {

    if (dto !== undefined) {
      form.id = dto.id;
      form.quantita = dto.quantita;
      form.tipologiaId = dto.tipologiaId;
    }

    return form;
  }

  mappingBoxdocciaDtoToForm(dto: BoxdocciaDTO, form: SingleBox): SingleBox {
    if (dto !== undefined) {
      form.id = dto.id;
      form.modello.id = dto.modelloId;
      form.modello.descr = dto.modelloDescrizione;
      form.cristalli.id = dto.cristalliId;
      form.cristalli.descr = dto.cristalliDescrizione;
      form.profilo.id = dto.profiloId;
      form.profilo.descr = dto.profiloDescrizione;
      form.larghezza.id = dto.larghezzaId;
      form.larghezza.descr = dto.larghezzaDescrizione;
      form.ingresso.id = dto.ingressoId;
      form.ingresso.descr = dto.ingressoDescrizione;
    }
    return form;
  }

  mappingNuoviAccessoriDTOtoForm(dto: any, form: any): any {
    if (dto !== undefined) {
      form.id = dto.id;
      form.tipologiaId = dto.tipologiaId;
    }
    return form;
  }

  mappingPaymentDTOtoFotm(dto: PagamentoDTO, form: Pagamento): Pagamento {
    if (dto !== undefined) {
      form.id = dto.id;
      form.caparraConfirmatoria = dto.caparraconf;
      form.cessioneCredito = dto.cessionecredito;
      form.iva = dto.percivaId;
      form.totaleOrdine = dto.totaleordine;
      form.totaleDovuto = dto.totaledovuto;
      form.tipoPagamento = dto.metodopagId;
      form.saldoInizioLavori = dto.saldoinizlav;
      form.tipoPagamentoSaldo = dto.tipopagsaldoId;
      form.percivaDescrizione = dto.percivaDescrizione;
    }

    return form;
  }

  public mappingDatiTecniciDTOtoForm(dto: DatiTecniciDTO, form: DatiTecnici, idOrder): DatiTecnici {
    if (dto !== undefined) {
      form.id = dto.id;
      form.tipoAbitazioneId = dto.tipoabitazioneId;
      form.docciaVasca.tipoBagnoId = dto.tipobagnoId;
      form.sanitariTipoId = dto.tiposanitariId;
      form.scaricoWcId = dto.tiposcaricowcId;
      form.cassettaScaricoId = dto.tipocasscwcId;
      form.cassettamonolitebidet = dto.cassettamonolitebidet;
      form.cassettamonolitewc = dto.cassettamonolitewc;
      form.adesionePiastrelleId = dto.adesionepiasId;
      form.muriPerimetraliId = dto.muriperiId;
      form.tipoRiscaldamentoId = dto.tiporiscaId;
      form.diProprieta = dto.proprieta;
      form.ascensore = dto.ascensore;
      form.accessibilita_cortile = dto.accesscortile;
      form.piano = dto.pianointervento;
      form.dimensioniVano.lung = dto.lungvano;
      form.dimensioniVano.alt = dto.altvano;
      form.dimensioniVano.larg = dto.largvano;
      form.dimensioniPorta.alt = dto.altporta;
      form.dimensioniPorta.lung = dto.lungporta;
      form.dimensioniPorta.altPiastrelle = dto.altpiastrelle;
      form.docciaVasca.dimensioni.lung = dto.lungtipobagno;
      form.docciaVasca.dimensioni.larg = dto.largtipobagno;
      form.docciaVasca.dimensioni.alt = dto.alttipobagno;
      form.misuraTermosifone.alt = dto.alttermo;
      form.misuraTermosifone.lung = dto.lungtermos;
      form.misuraTermosifone.prof = dto.proftermos;
      form.misuraTermosifone.incassato = dto.termosifoneIncassato;
      form.misuraTermosifone.interasse = dto.interasstermo;
      form.lavatricePresente = dto.lavatrpres;
      form.lavatriceDaMantenere = dto.lavatrmanten;
      form.bidetPresente = dto.bidet;
      form.attacchiLavatriceDaCreare = dto.attlavcreare;
      form.attacchiLavatriceNote = dto.noteattlavcr;
      form.wcPresente = dto.wcpresente;
      form.boilerPresente = dto.boilerpresente;
      form.boilerDaMantenere = dto.boilermanten;
      form.comandoSciaquone = dto.comandosciacq;
      form.puntoLuce = dto.puntolucelavabo;
      form.noteTecniche = dto.notetecniche;
      form.tipoimpiantoId = dto.tipoimpiantoId;
      form.tipoimpiantoDescrizione = dto.tipoimpiantoDescrizione;
      form.imptermpres = dto.imptermpres;
      form.termospres = dto.termospres;
      form.nRampe = dto.nRampe;
      form.nGradini = dto.nGradini;
      form.alzata = dto.alzata;
      form.pedata = dto.pedata;
      form.largScala = dto.largScala;
      form.misuraPianerottolol = dto.misuraPianerottolol;
      form.misuraPianerottoloh = dto.misuraPianerottoloh;
    }

    //form.ordineId = idOrder;
    return form;
  }

  mappingTermoarrediDTOtoForm(dto: TermoarrediDTO, form: TermosifoniArredi): TermosifoniArredi {
    if (dto !== undefined) {
      form.id = dto.id;
      form.tipologiaId = dto.tipologiaId;
    }

    return form;
  }

  mappingOpDTOtoForm(dto: OpereIdraulicheDTO, form: Sections): void {
    if (dto !== undefined) {
      form.opereIdrauliche.id = dto.id;
      // form.termosifoniArredi.termoarredo.id = dto.tipointtermoId;
      if (form.piattoEffettoPietra) {
        form.piattoEffettoPietra.coloreId = dto.colorepiateffpietraId;
        form.piattoEffettoPietra.dimensioneId = dto.dimpiatteffpietraId;
      }

      if (form.piattoDoccia) {
        if (form.piattoDoccia.largo) {
          form.piattoDoccia.largo.coloreId = dto.colorepiattolargId;
          form.piattoDoccia.largo.dimensioneId = dto.misurapiattolargId;
        }

        if (form.piattoDoccia.stretto) {
          form.piattoDoccia.stretto.id = dto.misurapiattostrId;
        }
      }

      if (form.soffittoPareti) {
        form.soffittoPareti.inb = dto.imbsoffitto;
        form.soffittoPareti.scr = dto.necscrostatura;
      }

      if (form.noteLibere)
        form.noteLibere.value = dto.notelibere;
    }

  }

  public mappingFormToOpereIdraulicheDto(form: Sections, dto: OpereIdraulicheDTO, savePiatto: boolean): OpereIdraulicheDTO {
    dto.id = form.opereIdrauliche.id;

    //dto.ordineId = this.form.idOrder;
    // dto.tipointtermoId = form.termosifoniArredi.termoarredo.id;
    if (savePiatto) {
      dto.colorepiateffpietraId = form.piattoEffettoPietra.coloreId;
      dto.dimpiatteffpietraId = form.piattoEffettoPietra.dimensioneId;
      if (form.piattoStone) {
        dto.colorepiatstoneId = form.piattoStone.colorepiatstoneId;
        dto.dimpiattstoneId = form.piattoStone.dimpiattstoneId;
      }
      if (form.piattoDoccia) {
        if (form.piattoDoccia.largo) {
          dto.colorepiattolargId = form.piattoDoccia.largo.coloreId;
          dto.misurapiattolargId = form.piattoDoccia.largo.dimensioneId;
        }
        if (form.piattoDoccia.stretto) {
          dto.misurapiattostrId = form.piattoDoccia.stretto.id;
        }
      }


    } else {
      dto.colorepiateffpietraId = null;
      dto.dimpiatteffpietraId = null;
      dto.colorepiatstoneId = null;
      dto.dimpiattstoneId = null;
      dto.colorepiattolargId = null;
      dto.misurapiattolargId = null;
      dto.misurapiattostrId = null;
    }



    dto.notelibere = form.noteLibere ? form.noteLibere.value : '';
    dto.imbsoffitto = form.soffittoPareti.inb;
    dto.necscrostatura = form.soffittoPareti.scr;
    return dto;
  }

  mappingOpereIdrSostDTOtoForm(dto: OpereIdraulicheSostDTO, form: OpereIdrauliche): OpereIdrauliche {
    if (dto !== undefined) {
      form.id = dto.id;
      form.tipologiaId = dto.tipologiaId;
    }

    return form;
  }

  mappingPiattoLargoDTOtoForm(dto: OpereIdraulicheDTO, form: PiattoDoccia): PiattoDoccia {
    if (dto !== undefined) {
      form.largo.coloreId = dto.colorepiattolargId;
      form.largo.dimensioneId = dto.misurapiattolargId;
      form.stretto.id = dto.misurapiattostrId;
    }
    return form;
  }

  public mappingRivestimentiDTOtoForm(dto: RivestimentiDTO, form: Rivestimenti, idOrder): Rivestimenti {
    if (dto !== undefined) {
      form.id = dto.id;
      form.pavimento = dto.pavimentodesc;
      form.pareti = dto.paretedesc;
      form.A = dto.paretea;
      form.Ah = dto.pareteah;
      form.B = dto.pareteb;
      form.Bh = dto.paretebh;
      form.internoDoccia = dto.internodoccia;
      form.internoDH = dto.internodocciah;
      form.note = dto.note;
      form.pareteC = dto.paretec;
      form.pareteCh = dto.paretech;
      form.pareteD = dto.pareted;
      form.pareteDh = dto.paretedh;
    }

    form.ordineId = idOrder;
    return form;
  }


  createProcedure(response: any, form: any, numPage: number, position: string, url: string, newTypeSignautre: boolean): Procedure {
    var pr = new Procedure();
    var mem = new Member();
    var file = new File();
    var prStart = new Started();
    var prFinish = new Started();
    var prFinishSign = new Started();
    var prRefeused = new Started();
    var email = new Email();
    var config = new Config();
    var header = new Header();
    var webhook = new Webhook();
    var mFinished = new MemberFinished();
    var mRefused = new MemberFinished();
    var sub = new Subscribers();
    pr.name = "Ordine numero: " + String(form.idGdlOrder);
    pr.description = "Procedura di firma digitale";
    pr.start = true;
    if (newTypeSignautre) {
      prStart.subject = "Contratto firmato",
        prStart.message = "Salve <tag data-tag-type=\"string\" data-tag-name=\"recipient.firstname\"></tag> <tag data-tag-type=\"string\" data-tag-name=\"recipient.lastname\"></tag>, <br><br>Hai firmato con successo un documento, se lo desidera può scaricare il documento al seguente link: <tag data-tag-type=\"button\" data-tag-name=\"url\" data-tag-title=\"Scarica documento\">Scarica il documento</tag>",
        prStart.to = ["@members.auto"],
        prStart.fromName = "GDL s.p.a";

      prFinish.subject = "La procedura di firma è completata",
        prFinish.message = "Salve il documento con numero ordine " + String(form.idGdlOrder) + " è stato firmato, se lo desidera può scaricare il documento al seguente link: <tag data-tag-type=\"button\" data-tag-name=\"url\" data-tag-title=\"Scarica documento\">Scarica il documento</tag>",
        prFinish.to = ["@subscribers"],
        prFinish.fromName = "GDL s.p.a";

      prRefeused.subject = "Contratto numero " + String(form.idGdlOrder) + " rifiutato",
        prRefeused.message = "Il cliente " + form.anagrafica.anagrafica.nome.trim() + " " + form.anagrafica.anagrafica.cognome.trim() + ", <br><br>ha rifiutato di firmare il documento",
        prRefeused.to = ["@members.auto", "@subscribers"],
        prRefeused.fromName = "GDL s.p.a";

      email["procedure.finished"] = [];
      email["procedure.finished"].push(prStart);
      email["procedure.finished"].push(prFinish);

      email["procedure.refused"] = [];
      email["procedure.refused"].push(prRefeused);
    } else {
      prStart.subject = "Richiesta firma contratto",
        prStart.message = "Buongiorno <tag data-tag-type=\"string\" data-tag-name=\"recipient.firstname\"></tag> <tag data-tag-type=\"string\" data-tag-name=\"recipient.lastname\"></tag>, <br><br>Sei stato invitato a firmare il documento, per favore clicca sul pulsante per leggerlo e firmarlo: <tag data-tag-type=\"button\" data-tag-name=\"url\" data-tag-title=\"Firma il documento\">Firma il documento</tag>",
        prStart.to = ["@members.auto"],
        prStart.fromName = "GDL s.p.a";

      prFinish.subject = "La procedura di firma è completata",
        prFinish.message = "Salve il documento con numero ordine " + String(form.idGdlOrder) + " è stato firmato, se lo desidera può scaricare il documento al seguente link: <tag data-tag-type=\"button\" data-tag-name=\"url\" data-tag-title=\"Scarica documento\">Scarica il documento</tag>",
        prFinish.to = ["@subscribers"],
        prFinish.fromName = "GDL s.p.a";

      prFinishSign.subject = "Contratto firmato",
        prFinishSign.message = "Salve <tag data-tag-type=\"string\" data-tag-name=\"recipient.firstname\"></tag> <tag data-tag-type=\"string\" data-tag-name=\"recipient.lastname\"></tag>, <br><br>Hai firmato con successo un documento, se lo desidera può scaricare il documento al seguente link: <tag data-tag-type=\"button\" data-tag-name=\"url\" data-tag-title=\"Scarica documento\">Scarica il documento</tag>",
        prFinishSign.to = ["@members.auto"],
        prFinishSign.fromName = "GDL s.p.a";

      prRefeused.subject = "Contratto " + String(form.idGdlOrder) + " rifiutato",
        prRefeused.message = "Il cliente " + form.anagrafica.anagrafica.nome.trim() + " " + form.anagrafica.anagrafica.cognome.trim() + ", <br><br>ha rifiutato di firmare il documento",
        prRefeused.to = ["@members.auto", "@subscribers"],
        prRefeused.fromName = "GDL s.p.a";

      email["procedure.started"] = [];
      email["procedure.started"].push(prStart);

      email["procedure.finished"] = [];
      email["procedure.finished"].push(prFinish);
      email["procedure.finished"].push(prFinishSign);

      email["procedure.refused"] = [];
      email["procedure.refused"].push(prRefeused);
    }


    mem.firstname = form.anagrafica.anagrafica.nome.trim();
    mem.lastname = form.anagrafica.anagrafica.cognome.trim();
    mem.email = form.anagrafica.anagrafica.email.trim();
    mem.phone = "+39" + form.anagrafica.anagrafica.tel1.trim();

    file.file = response.id;
    file.page = numPage;

    file.position = position;//"96,720,200,734";
    //file.mention = "Letto ed approvato";
    //file.mention2 = "Firmato da "+mem.firstname +" "+mem.lastname;

    let arrayfile = response.id.split("/");
    let size = arrayfile.length;

    header["X-Custom-Header"] = "Yousign Webhook - Test value";
    mFinished.headers = header;
    mFinished.url = url + "webhook/" + form.idGdlOrder + "/" + true + "/" + arrayfile[size - 1];
    mFinished.method = "GET";

    mRefused.headers = header;
    mRefused.url = url + "webhook/" + form.idGdlOrder + "/" + false + "/" + arrayfile[size - 1];
    mRefused.method = "GET";

    webhook["member.finished"] = [];
    webhook["member.finished"].push(mFinished);

    webhook["procedure.refused"] = [];
    webhook["procedure.refused"].push(mRefused);

    config.webhook = webhook;
    config.email = email;

    mem.fileObjects = [];
    mem.fileObjects.push(file);

    sub.email = form.venditoreEmail.trim();

    pr.subscribers = [];
    pr.subscribers.push(sub);
    pr.config = config;
    pr.members = [];
    pr.members.push(mem);

    return pr;
  }

  returnVolDesc(id, trascodifiche: Trascodifica[]): string {
    if (id) {
      const index = trascodifiche.findIndex(r => r.codice === constants.COD_VOLRISC && r.parentId === id);
      if (index !== -1) {
        return trascodifiche[index].descrizione;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  returnColors(id, trascodifiche: Trascodifica[]): any {
    const colors = [];
    trascodifiche.forEach(r => {
      if (r.codice === constants.COD_COLORE_STUFE && r.parentId === id) {
        colors.push(r);
      }
    });
    return colors;
  }

  returnTypeCald(id, trascodifiche: Trascodifica[]): any {
    const colors = [];
    trascodifiche.forEach(r => {
      if (r.codice === constants.COD_ALIMENTAZIONE_CALDAIA && r.parentId === id) {
        colors.push(r);
      }
    });
    return colors;
  }

  returnColorsCald(id, trascodifiche: Trascodifica[]): any {
    const colors = [];
    trascodifiche.forEach(r => {
      if (r.codice === constants.COD_COLORE_CALDAIA && r.parentId === id) {
        colors.push(r);
      }
    });
    return colors;
  }

  createSignature(newPdf: string, name: string, description: string): any {
    return new Promise((resolve, reject) => {
      var signature = new Signature();
      signature.name = name;
      signature.type = 'signable';
      signature.description = description;
      signature.content = newPdf;
      resolve(signature);
    });

  }

  capitalize(value): string {
    let str;
    str = value.charAt(0).toUpperCase() + value.slice(1);
    return str;
  }


  createSignatureRequest(name: string, type: string, idOrder: number, custom_experience_id?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      var signature = new SignatureRequestDto(name, type);
      if (custom_experience_id) {
        signature.custom_experience_id = custom_experience_id;
      }

      signature.external_id = environment.signWebhookId;

      this.signatureService.signature_requests(JSON.stringify(signature)).subscribe(res => {
        resolve(res);
      }, async error => {
        //save log error
        await this.saveLogError(idOrder, signature, error);
        reject(error);
      });

    });

  }

  uploadDocument(file: any, fileName: string, idOrder: number, idSignatureRequest: string): Promise<any> {
    const form = new FormData();
    form.append('file', file, fileName + '.pdf');
    form.append('nature', 'signable_document');
    return new Promise((resolve, reject) => {
      this.signatureService.uploadDocument(form, idSignatureRequest).subscribe(res => {
        resolve(res);
      }, async error => {
        //save log error
        await this.saveLogError(idOrder, form, error);
        reject(error);
      });
    });
  }

  createSigner(form: any, idOrder: number, documentId: string, page: number, coordinates: string, idSignatureRequest: string): Promise<any> {
    return new Promise((resolve, reject) => {
      var info = new InfoDTO(form.anagrafica.anagrafica.nome.trim(), form.anagrafica.anagrafica.cognome.trim(), form.anagrafica.anagrafica.email.trim(),
        "+39" + form.anagrafica.anagrafica.tel1.trim(), 'it');
      var document = [new DocumentDTO(documentId, 'signature', page, Number(coordinates['x']), Number(coordinates['y']), Number(coordinates['width']), Number(coordinates['height']))];

      var signer = new SignerDTO(info, 'electronic_signature', 'otp_sms', document, 'email');

      this.signatureService.addSigner(signer, idSignatureRequest).subscribe(res => {
        resolve(res);
      }, async error => {
        //save log error
        await this.saveLogError(idOrder, signer, error);
        reject(error);
      });

    });

  }

  createFollower(email: string, idOrder: number, idSignatureRequest: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let body = [{ 'email': email, 'locale': 'it' }];

      this.signatureService.addFollower(body, idSignatureRequest).subscribe(res => {
        resolve(res);
      }, async error => {
        //save log error
        await this.saveLogError(idOrder, body, error);
        reject(error);
      });

    });
  }

  activateSignatureRequest(idOrder: number, idSignatureRequest: string, idDocument: string, idSigner: string, mailFollower: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.signatureService.activateSignature(idSignatureRequest).subscribe(res => {
        //update stato pratica in attesa di firma
        this.orderService.getSingleOrder(idOrder).subscribe(resOrder => {
          resOrder.statopraticaCodice = "W";
          resOrder.statopraticaId = 5;
          resOrder.idProcedure = idSignatureRequest;
          resOrder.idMember = idSigner;
          resOrder.idfile = idDocument;
          resOrder.flgV3 = this.isApiV3;
          this.orderService.update(JSON.stringify(resOrder)).subscribe(_ => { resolve(res); });
        });
        this.createFollower(mailFollower, idOrder, idSignatureRequest);
      }, async error => {
        //save log error
        await this.saveLogError(idOrder, idSignatureRequest, error);
        reject(error);
      });
    });
  }


  private async saveLogError(idOrder: number, request: any, error: any) {
    var log = new OrdineLog();
    log.ordineId = idOrder;
    log.dataInvioYS = this.createDateAsUTC(new Date());
    log.json = JSON.stringify(request);
    log.secondoJson = JSON.stringify(error);
    this.orderLogService.save(JSON.stringify(log)).subscribe();
  }

  async manageSignature(newPdf, idOrder, form, page, position, title, customExperence?: string) {
    var idSignatureRequest = '';
    //Step 1: Initiate a Signature Request
    await this.createSignatureRequest('Documento contratto ' + form.ordine.idordine, 'email', idOrder, customExperence).then(res => { idSignatureRequest = res.id });
    var idDocument;
    //Step 2: Upload a Document
    await this.uploadDocument(newPdf, title, idOrder, idSignatureRequest).then(res => { idDocument = res.id });
    //Step 3: Add a signer
    var idSigner;
    await this.createSigner(form, idOrder, idDocument, page, position, idSignatureRequest).then(resSign => { idSigner = resSign.id });
    //Step 4: Activate the Signature Request and create follower
    return await this.activateSignatureRequest(idOrder, idSignatureRequest, idDocument, idSigner, form.ordine.venditoreEmail);
  }


  sendSignature(newPdf, idOrder, form, page, position, title, newTypeSignautre: boolean): any {

    return new Promise((resolve, reject) => {
      this.createSignature(newPdf, title, 'Invio documento per firma').then(res => {
        var signature = res;
        this.orderService.getSingleOrder(idOrder).subscribe(res => {
          form.idGdlOrder = res.idordine;
          form.venditoreEmail = res.venditoreEmail;
          var order: Ordine = res;
          var log = new OrdineLog();
          log.ordineId = order.id;
          log.dataInvioYS = this.createDateAsUTC(new Date());
          this.orderLogService.save(JSON.stringify(log)).subscribe(res => {
            log.id = res.id
            this.signatureService.sendFile(JSON.stringify(signature)).subscribe(resSign => {
              if (resSign != undefined) {
                log.dataRispostaYS = this.createDateAsUTC(new Date());
                order.idfile = resSign.id;
                order.flgV3 = this.isApiV3;
                log.json = JSON.stringify(resSign);
                this.orderLogService.update(JSON.stringify(log)).subscribe();
                var proc = this.createProcedure(resSign, form, page, position, this.httpService.setApiurl(), newTypeSignautre);
                log.dataSecondoInvioYS = this.createDateAsUTC(new Date());
                this.orderLogService.update(JSON.stringify(log)).subscribe();
                this.signatureService.createProcedue(proc).subscribe(resProc => {
                  //aggiorno lo stato sull'ordine
                  order.statopraticaId = 5;
                  log.dataSecondaRispostaYS = this.createDateAsUTC(new Date());
                  log.secondoJson = JSON.stringify(resProc);
                  order.ordinejson = resProc.config.webhook["member.finished"][0].url;
                  order.idProcedure = resProc.id;
                  order.idMember = resProc.members[0].id;
                  this.orderLogService.update(JSON.stringify(log)).subscribe();
                  this.orderService.update(JSON.stringify(order)).subscribe(ordRes => {
                    resolve(resProc);
                  });
                }, error => {
                  console.log(error);
                  log.json = JSON.stringify(error);
                  this.orderLogService.update(JSON.stringify(log)).subscribe();
                  //Verifico di che errore sei tratta.
                  let messageError = "Attenzione, si è verificato un problema nell'invio del contratto.Riprovare tra qualche minuto.";
                  if (error.error.violations[0]) {
                    let mgErr: string = error.error.violations[0].propertyPath;
                    if (mgErr === "members[0].lastname") {
                      messageError = "Attenzione, si è verificato un problema nell'invio del contratto. Controllare il campo Cognome del contratto."
                    } else if (mgErr === "members[0].firstname") {
                      messageError = "Attenzione, si è verificato un problema nell'invio del contratto. Controllare il campo Nome del contratto."
                    }
                  }
                  reject(messageError);
                });
              }
            }, error => {
              console.log(error);
              log.json = JSON.stringify(error);
              this.orderLogService.update(JSON.stringify(log)).subscribe();
              reject(error);
            });
          });
        });
      });
    });
  }

  validAccessori(form: any): boolean {
    let valid = true;
    if (form.sections.accessorio.length > 0) {
      for (let i = 0; i < form.sections.accessorio.length; i++) {
        if (valid) {
          if (form.sections.accessorio[i].tipologiaId &&
            form.sections.accessorio[i].quantita
          ) {
            valid = true;
          } else if (!form.sections.accessorio[i].tipologiaId && !form.sections.accessorio[i].quantita) {
            valid = true;
          } else {
            valid = false;
          }
        }
      }
    }
    return valid;
  }

  validInstAccessori(form: any): boolean {
    if (form.sections.installazioneAcc.installazioneconpred && !form.sections.installazioneAcc.qtainstconpred) {
      return false;
    }

    if (form.sections.installazioneAcc.installazionesenzapred && !form.sections.installazioneAcc.qtainstsenzapred) {
      return false;
    }
    if (form.sections.installazioneAcc.staffeue && (!form.sections.installazioneAcc.posizioneueId || !form.sections.installazioneAcc.qtaue)) {
      return false;
    }
    if (form.sections.installazioneAcc.pompacaricocondensa && !form.sections.installazioneAcc.numpompecondensa) {
      return false;
    }

    return true;
  }

  returnIva(id, codice, trascodifiche): string {
    if (id) {
      const index = trascodifiche.findIndex(r => r.codice === codice && r.id === id);
      if (index !== -1) {
        return trascodifiche[index].descrizione;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  validPagamento(form, skipDetrValidation?: boolean): boolean {
    if (!form.sections.pagamento[0].percivaId)
      return false;

    if (!skipDetrValidation) {
      if (!form.sections.pagamento[0]?.tipodetrazioneId)
        return false;
    }

    if (form.sections.pagamento[0].totaleordine < 0 || form.sections.pagamento[0].totaledovuto < 0 || form.sections.pagamento[0].caparraconf < 0 || form.sections.pagamento[0].saldoinizlav < 0) {
      return false;
    }

    if (form.sections.pagamento[0].caparraconf == undefined || form.sections.pagamento[0].caparraconf > 0) {
      return !(!form.sections.pagamento[0].totaleordine || !form.sections.pagamento[0].totaledovuto || form.sections.pagamento[0].caparraconf == undefined || !form.sections.pagamento[0].metodopagId || form.sections.pagamento[0].saldoinizlav == undefined || !form.sections.pagamento[0].tipopagsaldoId);
    } else if (form.sections.pagamento[0].caparraconf == 0) {
      return !(!form.sections.pagamento[0].totaleordine || !form.sections.pagamento[0].totaledovuto || form.sections.pagamento[0].saldoinizlav == undefined || !form.sections.pagamento[0].tipopagsaldoId);
    }

  }

  validOmaggio(form): boolean {
    return form.sections.omaggio.every((res: any) => res.tipologiaId && res.quantita);
  }

  returnProdName(opt): string {
    switch (opt) {
      case constants.COMMESSA_ARIEL_CALDAIE: return 'Caldaie';
      case constants.COMMESSA_BAGNI_ITALIANI: return 'Bagni';
      case constants.COMMESSA_ARIEL_CONDIZIONATORI: return 'Condizionatori';
      case constants.COMMESSA_ARIEL_STUFA_PELLET: return 'Stufe';
      case constants.COMMESSA_REMAIL_VASCA_IN_DOCCIA: return 'Vasca in doccia';
      case constants.COMMESSA_REMAIL_BAGNI_ITALIANI_HELP_75: return 'Bagni Help';
      case constants.COMMESSA_ARIEL_FOTOVOLTAICO: return 'Fotovoltaico';
      case constants.COMMESSA_ARIEL_FOTOVOLTAICO_PREVENTIVO: return 'Fotovoltaico';
      case constants.COMMESSA_ZUCCHETTI_MONTASCALE: return 'Montascale';
      case constants.COMMESSA_ARIEL_IBRID: return 'Sistemi ibridi';
      case constants.COMMESSA_BAGNO_PRATICO: return 'Bagno Pratico';
      case constants.COMMESSA_ARIEL_DEPURATORE: return 'Depuratore';
      case constants.COMMESSA_CB_VASCA_IN_DOCCIA: return 'Vasca in doccia';
      case constants.COMMESSA_MONDIAL_ALLARME: return 'Allarme';
    }
  }

  validMurettoSx(form: any): boolean {
    if ((form.sections.datitecnicivasca.lungmurosx ||
      form.sections.datitecnicivasca.largmurosx ||
      form.sections.datitecnicivasca.altmurosx) &&
      (form.sections.datitecnicivasca.eliminamurosx === null ||
        form.sections.datitecnicivasca.eliminamurosx === undefined ||
        form.sections.datitecnicivasca.zoppo === null ||
        form.sections.datitecnicivasca.zoppo === undefined)
    ) {
      return false;
    } else {
      return true;
    }
  }

  validMurettoDx(form: any): boolean {
    if ((form.sections.datitecnicivasca.lungmurodx ||
      form.sections.datitecnicivasca.largmurodx ||
      form.sections.datitecnicivasca.altmurodx) &&
      (form.sections.datitecnicivasca.eliminamurodx === null ||
        form.sections.datitecnicivasca.eliminamurodx === undefined ||
        form.sections.datitecnicivasca.zoppomurodx === null ||
        form.sections.datitecnicivasca.zoppomurodx === undefined)
    ) {
      return false;
    } else {
      return true;
    }
  }

  validMurettoDietro(form: any): boolean {
    if ((form.sections.datitecnicivasca.lungmurodietro ||
      form.sections.datitecnicivasca.largmurodietro ||
      form.sections.datitecnicivasca.altmurodietro) &&
      (form.sections.datitecnicivasca.zoppomurodietro === null ||
        form.sections.datitecnicivasca.zoppomurodietro === undefined)
    ) {
      return false;
    } else {
      return true;
    }
  }

  validMurettoDavanti(form: any): boolean {
    if ((form.sections.datitecnicivasca.lungmuroavanti ||
      form.sections.datitecnicivasca.largmuroavanti ||
      form.sections.datitecnicivasca.altmuroavanti) &&
      (form.sections.datitecnicivasca.zoppomuroavanti === null ||
        form.sections.datitecnicivasca.zoppomuroavanti === undefined)
    ) {
      return false;
    } else {
      return true;
    }
  }


  createDateAsUTC(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  goToDashboard() {
    this.router.navigate(['dashboard']);
  }

  goToDashboardUfficioTecnico() {
    this.router.navigate(['tech_dashboard']);
  }

  public validOption(optionsAv: OptionsAvailable[], typesOptions: string, trascodifiche: Trascodifica[]): boolean {
    let options = this.getTrascodificaByCodice('TYPE_OPTIONS', trascodifiche).filter(op => (op.descrizione === typesOptions));
    let valid = false;
    if (optionsAv === undefined || optionsAv === null || optionsAv.length === 0) {
      valid = false;
    } else {
      let op = optionsAv.filter(op => (op.typeId === options[0].id));
      if (op.length > 0) {
        if (op[0].selected === undefined) {
          valid = false;
        } else {
          valid = true;
        }

      } else
        valid = false;
    }

    return valid;
  }

  public checkOmaggio(omaggio: Omaggio[]): boolean {
    let check = true;
    omaggio.forEach(om => {
      if (om.tipologiaId) {
        if (!om.quantita || om.quantita <= 0) {
          check = false;
        }
      }
    })
    return check;
  }

  openPdf(flgV3, idFile, idSignatureRequest): void {
    if (flgV3) {
      this.signatureService.downloadPdf(idSignatureRequest, idFile).subscribe(res => {
        if (res !== undefined) {
          const blob = new Blob([res], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          var anchor = document.createElement('a');
          anchor.href = url;

          anchor.target = '_blank';

          anchor.click();
        }
      });
    }
    else {
      this.signatureService.downoloadFile(idFile).subscribe(res => {
        if (res !== undefined) {

          let content = window.atob(res);
          const byteNumbers = new Array(content.length);
          for (let i = 0; i < content.length; i++) {
            byteNumbers[i] = content.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          var anchor = document.createElement('a');
          anchor.href = url;

          anchor.target = '_blank';

          anchor.click();
        }
      })
    }
  }


  downloadPdf(flgV3, idordine, idFile, idSignatureRequest): void {
    if (flgV3) {
      this.signatureService.downloadPdf(idSignatureRequest, idFile).subscribe(res => {
        const blob = new Blob([res], { type: "application/pdf" });
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = data;
        link.download = idordine + ".pdf";
        link.click();
      });
    } else {
      this.signatureService.downoloadFile(idFile).subscribe(res => {
        if (res !== undefined) {
          const link = document.createElement("a");
          link.href = 'data:application/octet-stream;base64,' + res;
          link.download = idordine + ".pdf";
          link.click();
        }
      })
    }
  }

  downloadCertificatePdf(flgV3, idordine, idMember, idSignatureRequest): void {
    if (flgV3) {
      this.signatureService.downloadAuditPDF(idSignatureRequest, idMember).subscribe(res => {
        const blob = new Blob([res], { type: "application/pdf" });
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = data;
        link.download = idordine + "_certificatoFirma.pdf";
        link.click();
      });
    } else {
      this.signatureService.downoloadCertificateFile(idMember).subscribe(res => {
        if (res !== undefined) {
          //window.URL.createObjectURL(res);
          const link = document.createElement("a");
          link.href = 'data:application/octet-stream,' + res; // window.URL.createObjectURL(blob);
          link.download = idordine + "_certificatoFirma.pdf";
          link.click();
        }
      })
    }

  }

  openSnackBar(text): void {
    this.snackbar.open(text, 'Ok', {
      duration: 4000
    });
  }

  validPrivacy(form: any): boolean {
    if (!form.ordine?.accettprivacy || !form.ordine?.accettprivacynd || !form.ordine?.accettprivacyrd) {
      return false;
    } else
      return true;
  }

  validTecnic(datiTecnici: any): boolean {
    if (
      datiTecnici.lavatriceDaMantenere !== undefined &&
      datiTecnici.lavatriceDaMantenere !== null &&
      datiTecnici.docciaVasca.tipoBagnoId &&
      datiTecnici.tipoAbitazioneId &&
      datiTecnici.dimensioniVano &&
      datiTecnici.dimensioniVano.lung &&
      datiTecnici.dimensioniVano.larg &&
      datiTecnici.dimensioniVano.alt &&
      datiTecnici.dimensioniPorta.lung &&
      datiTecnici.dimensioniPorta.alt &&
      datiTecnici.dimensioniPorta.altPiastrelle &&
      datiTecnici.scaricoWcId &&
      datiTecnici.muriPerimetraliId &&
      this.validDatiTermo(datiTecnici) &&
      this.validTipoRiscaldamento(datiTecnici)
    ) {
      return true;
    } else {
      return false;
    }
  }

  validTipoRiscaldamento(datiTecnici: any): boolean {
    if (datiTecnici.imptermpres) {
      if (datiTecnici.tipoRiscaldamentoId &&
        datiTecnici.tipoimpiantoId) {
        return true;
      } else
        return false;
    } else if (datiTecnici.imptermpres == null || datiTecnici.imptermpres == undefined) {
      return false;
    } else
      return true;
  }

  validDatiTermo(datiTecnici): boolean {
    if (datiTecnici.termospres) {
      if (datiTecnici.misuraTermosifone.lung &&
        datiTecnici.misuraTermosifone.alt &&
        datiTecnici.misuraTermosifone.prof &&
        datiTecnici.misuraTermosifone.incassato !== undefined &&
        datiTecnici.misuraTermosifone.interasse) {
        return true;
      } else {
        return false;
      }
    } else if (datiTecnici.termospres == null || datiTecnici.termospres == undefined) {
      return false;
    } else
      return true;
  }

  validNuoviBox(form: any): boolean {
    let valid = true;
    if (form.sections.nuoviBox.length > 0) {
      for (let i = 0; i < form.sections.nuoviBox.length; i++) {
        if (valid) {
          if (form.sections.nuoviBox[i].modello.id &&
            form.sections.nuoviBox[i].cristalli.id &&
            form.sections.nuoviBox[i].profilo.id &&
            form.sections.nuoviBox[i].larghezza.id
          ) {
            valid = true;
          } else {
            valid = false;
          }
        }

      }
    }
    return valid;
  }

  checkBoxDoccia(form: any): boolean {
    let result = true;
    let cristalliTmp = "";
    let profiliTmp = "";
    if (form.sections.nuoviBox.length > 1) {
      form.sections.nuoviBox.forEach(r => {
        if (cristalliTmp !== "" && cristalliTmp !== r.cristalli.descr) {
          result = false;

        }

        if (profiliTmp !== "" && profiliTmp !== r.profilo.descr) {
          result = false;
        }

        cristalliTmp = r.cristalli.descr;
        profiliTmp = r.profilo.descr;
      });
    }

    return result;
  }

  public mappingFormToBoxdocciaDTO(form: SingleBox, dto: BoxdocciaDTO, idOrder: any): BoxdocciaDTO {
    dto.id = form.id
    dto.modelloId = form.modello.id;
    dto.cristalliId = form.cristalli.id;
    dto.profiloId = form.profilo.id;
    dto.larghezzaId = form.larghezza.id;
    dto.ordineId = idOrder;

    return dto;
  }

  public mappingFormToNuoviAccessoriDTO(form: Accessori, dto: NuoviAccessoriDTO, idOrder: any): NuoviAccessoriDTO {
    dto.id = form.id;
    dto.ordineId = idOrder;
    dto.tipologiaId = form.tipologiaId;

    return dto;
  }

  validNuoviSan(form: any): boolean {
    let valid = true;
    if (form.sections.nuoviSanitariDto.length > 0) {
      for (let i = 0; i < form.sections.nuoviSanitariDto.length; i++) {
        if (valid) {
          if (form.sections.nuoviSanitariDto[i].modsanitariId &&
            form.sections.nuoviSanitariDto[i].tiposcaricoId &&
            form.sections.nuoviSanitariDto[i].tiposanitariId &&
            form.sections.nuoviSanitariDto[i].tipoprodottoId
          ) {
            valid = true;
          } else {
            valid = false;
          }
        }

      }
    }
    return valid;
  }


  validNuovoBoxDoccia(form: any): boolean {
    let valid = true;
    if (form.sections.boxdoccia.length > 0) {
      for (let i = 0; i < form.sections.boxdoccia.length; i++) {

        if (form.sections.boxdoccia[i].modelloId &&
          form.sections.boxdoccia[i].cristalliId &&
          form.sections.boxdoccia[i].profiloId &&
          form.sections.boxdoccia[i].larghezzaId &&
          form.sections.boxdoccia[i].ingressoId
        ) {
          valid = true;
        } else {
          valid = false;
        }


      }
    }
    return valid;
  }

  validDatiAbitazione(form: any): boolean {
    if (
      form.sections.abitazione.pianointerv === undefined ||
      form.sections.abitazione.pianointerv == null ||
      form.sections.abitazione.pianistabile === undefined ||
      form.sections.abitazione.pianistabile == null ||
      form.sections.abitazione.mq === undefined ||
      form.sections.abitazione.mq == null ||
      form.sections.abitazione.mq < 1 ||
      !form.sections.abitazione.cannafumariaId
    ) {
      return false;
    } else {
      return true;
    }
  }

  validDisegnoCaldaia(form: any): boolean {
    if (form.sections.disegnoCaldaia.tipoId) {
      if (form.sections.disegnoCaldaia.tipoDescrizione === 'NESSUNA') {
        delete form.sections.disegnoCaldaia.altezza;
        delete form.sections.disegnoCaldaia.larghezza;
        delete form.sections.disegnoCaldaia.profondita;
        return true;
      } else {
        if (form.sections.disegnoCaldaia.altezza === undefined ||
          form.sections.disegnoCaldaia.altezza == null ||
          form.sections.disegnoCaldaia.altezza < 0.1 ||
          form.sections.disegnoCaldaia.larghezza === undefined ||
          form.sections.disegnoCaldaia.larghezza == null ||
          form.sections.disegnoCaldaia.larghezza < 0.1 ||
          form.sections.disegnoCaldaia.profondita === undefined ||
          form.sections.disegnoCaldaia.profondita == null ||
          form.sections.disegnoCaldaia.profondita < 0.1
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  validCannaFumaria(form: any): boolean {
    if (form.sections.accessori.accessotetto == null) {
      return false;
    }
    if (form.sections.accessori.accessotetto == false && !form.sections.accessori.cestaidr && !form.sections.accessori.ponteggio) {
      return false;
    }

    if (form.sections.accessori.valvolatermo == null) {
      return false;
    }
    return true;
  }


  validDettagliInstallazione(form: any): boolean {
    if (!form.sections.accessori.installazioneId ||
      !form.sections.accessori.uscitafumiId ||
      !form.sections.accessori.prelievoariaId ||
      !form.sections.accessori.scaricocondId) {
      return false;
    } else {
      return true;
    }
  }

  validRilievoMisureSchinaEsedile(form: any): boolean {
    const misure = form.sections?.montascaleMisure;

    if (misure) {
      const distanzaSchienaGinocchio = misure.distanzaschienaginocchio;
      const distanzaSedileTesta = misure.distanzasediletesta;
      const distanzaSchienaFronte = misure.distanzaschienafronte;

      const condizione1 = !distanzaSchienaGinocchio || (distanzaSchienaGinocchio >= constants.MIN_SCHIENA_GINOCCHIO_MISURA_MONTASCALE && distanzaSchienaGinocchio <= constants.MAX_SCHIENA_GINOCCHIO_MISURA_MONTASCALE);
      const condizione2 = !distanzaSedileTesta || (distanzaSedileTesta >= constants.MIN_SCHIENA_TESTA_MISURA_MONTASCALE && distanzaSedileTesta <= constants.MAX_SCHIENA_TESTA_MISURA_MONTASCALE);
      const condizione3 = !distanzaSchienaFronte || (distanzaSchienaFronte >= constants.MIN_SCHIENA_FRONTE_MISURA_MONTASCALE && distanzaSchienaFronte <= constants.MAX_SCHIENA_FRONTE_MISURA_MONTASCALE);

      return condizione1 && condizione2 && condizione3;
    }

    // Se non ci sono misure, il rilievo valido in quanto i dati non sono obbligatori
    return true;
  }

  validAccessoriDepuratore(form: any): boolean {
    const accDepuratore = form.sections?.accessoriDepuratore1;
    const accDepuratore1 = form.sections?.accessoriDepuratore2;
    const manutenzioneDepuratore = form.sections?.manutenzioneDepuratore;

    if (accDepuratore && accDepuratore1 && manutenzioneDepuratore) {
      if (accDepuratore.quantita && accDepuratore1.quantita && manutenzioneDepuratore.quantita) {
        return true;
      }
    }

    return false;
  }

  isCaparraConfIsValid(totale: number, caparra: number): boolean {
    return caparra < totale * 0.3 ? true : false;
  }

  generateSortFn(prop, reverse) {
    return function (a, b) {
      if (parseInt(a[prop].slice(0, -1)) < parseInt(b[prop].slice(0, -1))) return reverse ? 1 : -1;
      if (parseInt(a[prop].slice(0, -1)) > parseInt(b[prop].slice(0, -1))) return reverse ? -1 : 1;
      return 0;
    };
  }

  checkCAP(form: any) {
    // Controllo che il CF sia alfanumerico e max 16 caratteri
    if (form.anagrafica.anagrafica.cap && form.anagrafica.anagrafica.cap.toString().length !== 5) {
      let cicle = 5 - form.anagrafica.anagrafica.cap.toString().length;
      for (let i = 0; i < cicle; i++) {
        form.anagrafica.anagrafica.cap = '0' + form.anagrafica.anagrafica.cap;
      }
    }
  }

  public removeFrame(): void {
    const displayDiv = document.getElementById("signFrame");
    // Check if there is at least one child to remove
    if (displayDiv.childElementCount === 0) {
      return;
    }

    // Remove the last child (iframe element) of div
    displayDiv.removeChild(displayDiv.lastChild);
  }

  /*orderSelectListResult(trascodifiche: Trascodifica[]): Trascodifica[] {
    return trascodifiche.sort((a, b) => {
      if (a.ordinamento === null || a.ordinamento === undefined) {
        return 1;
      }

      if (b.ordinamento === null || b.ordinamento === undefined) {
        return -1;
      }

      return a.ordinamento - b.ordinamento;
    });
  }*/
}
