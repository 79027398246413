import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemailMainComponent } from './remail-main/remail-main.component';
import { VascaInDocciaComponent } from './remail-main/vasca-in-doccia/vasca-in-doccia.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from '../../material/material.module';
import { PaintableModule } from 'paintablejs/angular';
import { SharedModule } from '../../shared/shared.module';
import { DatitecnicivascaComponent } from './remail-main/vasca-in-doccia/datitecnicivasca/datitecnicivasca.component';
import { OmaggioComponent } from './remail-main/vasca-in-doccia/omaggio/omaggio.component';
import { AccessoriComponent } from './remail-main/vasca-in-doccia/accessori/accessori.component';
import { NuovidatitecniciComponent } from './remail-main/vasca-in-doccia/nuovidatitecnici/nuovidatitecnici.component';
import { BoxdocciaComponent } from './remail-main/vasca-in-doccia/boxdoccia/boxdoccia.component';
import { NuovopiattodocciaComponent } from './remail-main/vasca-in-doccia/nuovopiattodoccia/nuovopiattodoccia.component';
import { GresComponent } from './remail-main/vasca-in-doccia/gres/gres.component';
import { MiscelatoriComponent } from './remail-main/vasca-in-doccia/miscelatori/miscelatori.component';
import { VascaComponent } from './remail-main/vasca-in-doccia/vasca/vasca.component';
import { VascaInDocciaPrintComponent } from './remail-main/vasca-in-doccia/print/vasca-in-doccia-print.component';
@NgModule({
  declarations: [RemailMainComponent, VascaInDocciaComponent, OmaggioComponent, DatitecnicivascaComponent, AccessoriComponent, NuovidatitecniciComponent, BoxdocciaComponent, NuovopiattodocciaComponent, GresComponent, MiscelatoriComponent, VascaComponent, VascaInDocciaPrintComponent],
  imports: [
    CommonModule,
	  MatFormFieldModule,
	  MaterialModule,
	  PaintableModule,
	  SharedModule,
  ],
  exports:[DatitecnicivascaComponent,NuovidatitecniciComponent,BoxdocciaComponent,GresComponent,OmaggioComponent,AccessoriComponent]
})
export class RemailModule { }
