import { OptionsAvailableService } from './../../../../../services/optionsAvailable/options-available.service';
import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';

import {SanitariService} from '../services/sanitari/sanitari.service';
import {SanitariDTO, NuoviProdotti, NuoviSanDTO,
} from '../classes/dati-tecnici';
import {Trascodifica} from '../../../../classes/trascodifica';
import {FormControllerService} from '../../../../../services/controller/form-controller.service';

import modelli from 'src/assets/modelli.json';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { _MatTabGroupBase } from '@angular/material/tabs';
@Component({
  selector: 'app-form-nuovi-sanitari',
  templateUrl: './form-nuovi-sanitari.component.html',
  styleUrls: ['./form-nuovi-sanitari.component.scss']
})
export class FormNuoviSanitariComponent implements OnInit {
	collapse:boolean=false;
	panelOpenState=false;
@Input() form: any;
@Input() trascodifiche: Trascodifica[];
@Input() mobileAttivo: boolean;
@Output('update') change: EventEmitter<any> = new EventEmitter<any>();
@Input() brand:any;
models = modelli;
availableModels: any[];
avaModel: any;
toFilterModel: any[];
toFilterScarico: any[];
avaScarico: any;
toFilterSanitari: any[];
avaSanitari: any;
toFilterTipo: any[];
avaTipo: any;
toFilterCode: any[];
avaCode: any[];
tipiVasche: Trascodifica[];
tipiCol: Trascodifica[];
vascaMob: Trascodifica[];

isVisible  = false;
options: Trascodifica[];
  optionNuoviSanitariOmaggio: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};
  listOptionsOrder: OptionsAvailable[];
// mobileAttivo = false;
constructor(
	public sanitariService: SanitariService,
	public formController: FormControllerService,
	private optionsService:OptionsAvailableService
) {
	this.availableModels = [];
	this.toFilterModel = [];
	this.avaModel = [];
	this.avaScarico = [];
	this.toFilterScarico = [];
	this.toFilterSanitari = [];
	this.toFilterTipo = [];
	this.avaTipo = [];
	this.toFilterCode = [];
	this.avaCode = [];
	this.avaSanitari = [];
}

  ngOnInit(): void {
	
	this.options = this.formController.getTrascodificaByCodice('TYPE_OPTIONS',this.trascodifiche).filter(op => op.descrizione === 'NUOVI SANITARI');
    this.getOption();

    this.form.sections.nuoviSanitariDto = [];
	this.sanitariService.findByKey(this.form.idOrder , 'idordineId.equals=').subscribe(res => {
		this.formController.mappingSanitariDTOtoForm(res[0], this.form.sections.nuoviProdotti);
	    this.tipiVasche = this.formController.getTrascodificaByCodice('NUM_VASCHE_MOB' , this.trascodifiche);
	    this.vascaMob = this.formController.getTrascodificaByCodice('VASCAMOB' , this.trascodifiche);
		this.tipiCol = this.formController.getTrascodificaByCodice('TIPO_COLONNA_MOB' , this.trascodifiche);
		if (this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.descrizione ||
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colore ||
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche ||
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile) {
			this.toggleAttivo(false);
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = false;
		}else{
			this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = true;
		}
	  });
	
	this.sanitariService.getNuoviSan(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
		if (res[0]) {
		    this.form.sections.nuoviSanitariDto = res;
		    for(let i = 0; i < this.form.sections.nuoviSanitariDto.length; i++) {
			    this.avaModel.push(this.formController.getTrascodificaByCodice('MODELLO_SANITARI' , this.trascodifiche));
			    this.avaScarico.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_SCARICO') && (tr.parentId === this.form.sections.nuoviSanitariDto[i].modsanitariId)));
			    this.avaSanitari.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_NUOVI_SANITARI') && (tr.parentId === this.form.sections.nuoviSanitariDto[i].tiposcaricoId)));
			    this.avaTipo.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_PRODOTTO') && (tr.parentId === this.form.sections.nuoviSanitariDto[i].tiposanitariId)));
		    }
		}
	});
	
  }

  changeModel(event, i): void { 
	this.avaScarico[i] =  this.trascodifiche.filter( tr => (tr.codice === 'TIPO_SCARICO') && (tr.parentId === event));
	  delete this.form.sections.nuoviSanitariDto[i].tiposcaricoId;
	  delete this.form.sections.nuoviSanitariDto[i].tiposanitariId;
	  delete this.form.sections.nuoviSanitariDto[i].tipoprodottoId;
  }

  changeScarico(event, i): void {
	this.avaSanitari[i] = this.trascodifiche.filter(tr => (tr.codice === 'TIPO_NUOVI_SANITARI') && (tr.parentId === event));
	  delete this.form.sections.nuoviSanitariDto[i].tiposanitariId;
	  delete this.form.sections.nuoviSanitariDto[i].tipoprodottoId;
  }

	changeSanitari(event, i): void {
		delete this.form.sections.nuoviSanitariDto[i].tipoprodottoId;
		this.avaTipo[i] = this.trascodifiche.filter(tr => (tr.codice === 'TIPO_PRODOTTO') && (tr.parentId === event));
	}

  changeProd(event, i): void {
    // this.avaTipo[i] = this.toFilterTipo.filter(option => option.toLowerCase().includes(event));
    // this.setCode(event, i);
  }

	addSan(): void{
  	    this.form.sections.nuoviSanitariDto.push(new NuoviSanDTO());
		this.avaModel.push(this.formController.getTrascodificaByCodice('MODELLO_SANITARI' , this.trascodifiche));
		this.avaScarico.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_SCARICO')));
		this.avaSanitari.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_NUOVI_SANITARI')));
		this.avaTipo.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_PRODOTTO')));
	}
	
	removeSan(i): void {
		if (this.form.sections.nuoviSanitariDto[i].id) {
			this.sanitariService.deleteNuoviSan(this.form.sections.nuoviSanitariDto[i].id).subscribe();
		}
		this.form.sections.nuoviSanitariDto.splice(i, 1);
		this.avaModel.splice(i, 1);
		this.avaScarico.splice(i, 1);
		this.avaSanitari.splice(i, 1);
		this.avaTipo.splice(i, 1);
	}
	
	validNuoviSan(): boolean {
		let valid = true;
		if (this.form.sections.nuoviSanitariDto.length > 0) {
			for (let i = 0; i < this.form.sections.nuoviSanitariDto.length; i++) {
				if (valid) {
					if (this.form.sections.nuoviSanitariDto[i].modsanitariId &&
						this.form.sections.nuoviSanitariDto[i].tiposcaricoId &&
						this.form.sections.nuoviSanitariDto[i].tiposanitariId &&
						this.form.sections.nuoviSanitariDto[i].tipoprodottoId
					) {
						valid = true;
					} else {
						valid = false;
					}
				}
				
			}
		}
		return valid;
	}
	
	toggleAttivo(event): void {
		/*if (event) {
			delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.descrizione;
			delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colore;
			delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche;
			delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.vascaMob;
			delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile;
			delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili;
			delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note;
		}*/
		this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente = event;
		this.change.emit(event);
	}

	showDiv(value){    
		if(value ){
		  this.isVisible = true;
		}else{
		  this.isVisible = false;
		}
		this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0]?.id)[0].selected = value;
	   
	  }
	
	  getOption(){
		this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
			if(res !== undefined){
			  this.listOptionsOrder = res;
			  let optionNuoviSanitariOmaggio = res.filter(op => op.typeId === this.options[0].id)  
			  if(optionNuoviSanitariOmaggio !== undefined && optionNuoviSanitariOmaggio.length > 0){
				this.isVisible = optionNuoviSanitariOmaggio[0].selected;
				this.form.sections.optionsAvailable=res;				
				this.optionNuoviSanitariOmaggio = optionNuoviSanitariOmaggio[0];
	
			  }else{
				this.isVisible = false;
				this.optionNuoviSanitariOmaggio = new OptionsAvailable();
				this.optionNuoviSanitariOmaggio.ordineId = this.form.idOrder;
				this.optionNuoviSanitariOmaggio.typeId = this.options[0].id;
				this.optionNuoviSanitariOmaggio.selected = undefined;
				this.form.sections.optionsAvailable.push(this.optionNuoviSanitariOmaggio);
			   
			  }
			}
		});
	
	  }

}
