<div class="finanziamentoBg" [ngStyle]="{'background': productColor}"> MODULO RACCOLTA DATI FINANZIAMENTO </div>
<div class="finanziamentoCont" [ngStyle]="{'border-color': productColor}">
    <div class="container">
      <div class="row justify-content-center align-items-center mb-5">
            <div class="col-sm-4 col-12" *ngFor="let finanziaria of listFinanziarie" style="margin-bottom: 20px;">
                <div class="customStyle" [ngClass]="{'selected': isSelected(finanziaria)}" (click)="selectFinanziaria(finanziaria)">
                    <div class="customBlock">
                        <img [src]="returnImgFinanziaria(finanziaria.descrizione)" alt="Santander">
                        <div [innerHTML]="finanziaria.note"></div>
                    </div>
                    <div class="footerBlock">
                        <button class="btn btn-primary" [ngStyle]="{'background': productColor}" [disabled]="isSelected(finanziaria)">Seleziona</button>
                    </div>
                </div>
            </div>
        </div>        
    </div>
    <div class="container" *ngIf="form.sections.finanziamentoSections.finanziamento && form.sections.finanziamentoSections.finanziamento.finanziariaId && finanziariaSelected && finanziariaSelected?.id"> 
        <form-finanziamento [form]="form" [finanziamento]="finanziariaSelected" [listFinanziarie]="listFinanziarie" [changeFinanziamento]="deleteFinanziamenento"
            [productColor]="productColor" [pdfDetails]="pdfDetails" [idOrder]="idOrder">
        </form-finanziamento>
    </div>
</div>

<div class="modal fade" id="changeFinanziaria" tabindex="-1">
   <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Avviso cambio finanziaria </h3>
        </div>
        <div class="modal-body">
          <div>
            <a>Attenzione stai cambiando finanziaria da <strong>{{oldFinanziaria?.descrizione}}</strong>  a <strong>{{newFinanziaria?.descrizione}}</strong> se confermi perderai tutti i dati inseriti nelle sezioni sottostanti.</a><br>
            <a>Vuoi procedere ugualmente ?</a> 
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" (click)="cancelChange()">Annulla</button>
          <button class="btn" (click)="confirmChange()" [ngStyle]="{'background': productColor}" style="color: white;">Avanti</button>
        </div>
      </div>
   </div>
</div>