import { Component, Input, OnInit } from '@angular/core';
import { kitSicurezza } from 'src/app/modules/classes/kitSicurezza';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { SicurezzaService } from 'src/app/services/sicurezza/sicurezza.service';

@Component({
  selector: 'app-form-sicurezza',
  templateUrl: './form-sicurezza.component.html',
  styleUrls: ['./form-sicurezza.component.scss']
})
export class FormSicurezzaComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche: any;
  listKit: Trascodifica[];
  constructor(private formController:FormControllerService, private kitSicurezzaSerive:SicurezzaService) { }

  ngOnInit(): void {
    this.form.sections.kitSicurezza = [];
    this.form.sections.kitSicurezzaToDelete = [];
    this.kitSicurezzaSerive.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      if(res){
        this.form.sections.kitSicurezza = res;
      }
      this.listKit = this.formController.getTrascodificaByCodice('KIT_SICUREZZA', this.trascodifiche);

    })
  }


  update(event, id): void {
    if (-1 === this.form.sections.kitSicurezza.findIndex(r => r.tipologiaId === id)) {
      if (event.checked) {
        var newVal = new kitSicurezza();
        newVal.tipologiaId = id;
        this.form.sections.kitSicurezza.push(newVal);
      }
    } else {
      var accToDelete = this.form.sections.kitSicurezza.filter(tr => (tr.tipologiaId === id));
      this.form.sections.kitSicurezzaToDelete.push(accToDelete[0]);
      this.form.sections.kitSicurezza.splice(this.form.sections.kitSicurezza.findIndex(r => r.tipologiaId === id), 1);
    }
  }


  checked(id): string {
    if (-1 !== this.form.sections.kitSicurezza.findIndex(r => r.tipologiaId === id)) {
      return 'checked';
    } else {
      return '';
    }
  }

}
