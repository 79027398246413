import { Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {
  @Input() num: any;
  @Input() step: number;
  @Input() decimal: boolean;
  @Input() label: string;
  @Input() payment: boolean;
  @Input() small: boolean;
  @Input() disabled: boolean;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onNumChange = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  updateNumber(decimal, add): void {
    if (!this.num) {
      this.num = 0;
    }
    if (add) {
      this.num = parseFloat(this.num);
      if(decimal){
        this.num += 0.10;
      }else{
        this.num += 1;
      }
    } else if (this.num !== 0 || this.num !== undefined) {
      this.num = parseFloat(this.num);
      if (!this.num){
        this.num = 0;
      } else {
        if(decimal){
          this.num -= 0.10;
        }else{
          this.num -= 1;
        }
      }
    } else {
      this.num = 0;
    }
    if (this.payment || decimal) {
      this.num = this.num.toFixed(2);
    }
    this.emit();
  }

  emit(): void {
    if (this.num || this.num === 0) {
      let newNum = this.num.toString();
      if (newNum.includes(',')){
        newNum = newNum.replace(',', '.');
      }
      this.num = parseFloat(newNum);
    }
    // this.num = parseFloat(newNum);
    this.onNumChange.emit(this.num);
  }
}
