<div class="row originalRow">
    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer" id="part">
            <div class="text-left text-success font-weight-bold mb-4">
                DISEGNO TECNICO
            </div>
            <!--
            <div class="mb-3">
                <mat-radio-group [(ngModel)]="drawing" aria-label="Select an option"
                                 color="primary"> <mat-radio-button class="mr-3"
                                                                    [value]="false">Carica un file</mat-radio-button> <mat-radio-button
                        [value]="true">Disegna la piantina</mat-radio-button> </mat-radio-group>

            </div>
            -->

            <div *ngIf="!drawing">

                <form>
                    <div class="form-group">
                        <fieldset class="form-group">

                            <label for="file-up"> {{imageError}}
                                <div class="custom-file fileInputProfileWrap">
                                    <input id="file-up" type="file"
                                           (change)="fileChangeEvent($event)" class="fileInputProfile">
                                    <div class="img-space">

                                        <ng-container *ngIf="isImageSaved; else elseTemplate">
                                            <img [src]="cardImageBase64" /> </ng-container>
                                        <ng-template #elseTemplate> <img
                                                src="/assets/img/placeholder.jpeg" class="img-responsive">
                                        </ng-template>

                                    </div>

                                </div>
                            </label>
                        </fieldset>
                    </div>
                    <button mat-button color="warn" (click)="removeImage()"
                            *ngIf="isImageSaved">Rimuovi immagine</button>
                </form>
            </div>


            <div class="" *ngIf="drawing">
                <div class="paint">
                    <div class="mb-3">
                        <button class="mr-2" mat-icon-button (click)="clear()"
                                matTooltip="Cancella">
                            <mat-icon>clear</mat-icon>
                        </button>
                        <button class="mr-2" mat-icon-button (click)="undo()"
                                matTooltip="Indietro">
                            <mat-icon>undo</mat-icon>
                        </button>
                        <button class="mr-2" mat-icon-button (click)="redo()"
                                matTooltip="Avanti">
                            <mat-icon>redo</mat-icon>
                        </button>
                        <button class="mr-2" mat-button (click)="toggleEdit()">{{
                            active ? "Salva" : "Fai il disegno" }}</button>
                        <button class="mr-2" mat-icon-button (click)="toggleUseEraser()"
                                matTooltip="Gomma">
                            <mat-icon *ngIf="useEraser">edit</mat-icon>
                            <mat-icon *ngIf="!useEraser">auto_fix_off</mat-icon>
                        </button>

                        <input class="mr-2" type="color" [(ngModel)]="color" />
                        <!--
                            <input type="range" [(ngModel)]="thickness" min="{1}" max="{30}" step="{1}" />
                            -->
                    </div>

                    <div *ngIf="!isLoadingImage">
                        <paintable [hidden]="!active" #paintable [width]="width"
                                   [height]="height" [active]="active" [useEraser]="useEraser"
                                   [thickness]="thickness" [thicknessEraser]="15" [color]="color"
                                   [scaleFactor]="1" [image]="image" (onSave)="onSave($event)"
                                   (onLongPress)="onLongPress()">
                            <div class="canvas-inner"></div>
                            <div id="canvasOverlay" [ngClass]="{'cancella':useEraser}"></div>
                        </paintable>
                    </div>
                    <div *ngIf="!isLoadingImage">
                        <div [hidden]="active">
                            <img [src]="cardImageBase64">
                        </div>
                    </div>
                </div>
                <button mat-button color="warn" (click)="removeImage()"
                        *ngIf="cardImageBase64">Rimuovi immagine</button>
            </div>

        </div>
        <div class="text-danger smallFont" *ngIf="!cardImageBase64 || !form.sections.abitazione.disegnotecnico">
            campo obbligatorio
        </div>
    </div>
    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                DATI ABITAZIONE
            </div>
            <div *ngIf="trascodifiche && tipiAbitazione">
                <mat-radio-group class="smalFt" [(ngModel)]="form.sections.abitazione.tipoabitazioneId">
                    <mat-radio-button color="primary" class="mr-2" *ngFor="let type of tipiAbitazione; let t = index;" [value]="type.id">
                        {{type.descrizione}}
                    </mat-radio-button>
                   	<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.abitazione.tipoabitazioneId" (click)="form.sections.abitazione.tipoabitazioneId = null">
                          backspace
                    </span>                    
                </mat-radio-group>
                <hr/>

                <app-input-number
                        (onNumChange)="form.sections.abitazione.pianistabile = $event"
                        [step]="1"
                        [num]="form.sections.abitazione.pianistabile"
                        [decimal]="false"
                        [payment]="false"
                        label="Numero piani stabile"></app-input-number>
                <div class="text-danger smallFont mb-2" *ngIf="!form.sections.abitazione.pianistabile && form.sections.abitazione.pianistabile!=0">
                    Campo obbligatorio
                </div>
                <!--
                <mat-form-field class="full-width">
                    <mat-label>Numero piani stabile</mat-label>
                    <input matInput type="number" min="0" [(ngModel)]="form.sections.abitazione.pianistabile">
                    <button *ngIf="form.sections.abitazione.pianistabile" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.pianistabile=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                -->

                <app-input-number
                        (onNumChange)="form.sections.abitazione.pianointerv = $event"
                        [step]="1"
                        [num]="form.sections.abitazione.pianointerv"
                        [decimal]="false"
                        [payment]="false"
                        label="Piano intervento"></app-input-number>
                <div class="text-danger smallFont mb-2" *ngIf="!form.sections.abitazione.pianointerv && form.sections.abitazione.pianointerv!=0">
                    Campo obbligatorio
                </div>
                <!--
                <mat-form-field class="full-width">
                    <mat-label>Piano intervento</mat-label>
                    <input matInput type="number" min="0" [(ngModel)]="form.sections.abitazione.pianointerv">
                    <button *ngIf="form.sections.abitazione.pianointerv" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.pianointerv=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                -->

                <app-input-number
                        (onNumChange)="form.sections.abitazione.altezza = $event"
                        [step]="0.1"
                        [num]="form.sections.abitazione.altezza"
                        [decimal]="true"
                        [payment]="false"
                        label="Altezza locale abitazione mt."></app-input-number>
                <!--
                <mat-form-field class="full-width">
                    <mat-label>Altezza locale abitazione mt.</mat-label>
                    <input matInput type="number" min="0" step=".01" [(ngModel)]="form.sections.abitazione.altezza">
                    <button *ngIf="form.sections.abitazione.altezza" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.altezza=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                -->

                <app-input-number
                        (onNumChange)="form.sections.abitazione.murospess = $event"
                        [step]="0.1"
                        [num]="form.sections.abitazione.murospess"
                        [decimal]="true"
                        [payment]="false"
                        label="SPESSORE MURO PERIMETRALE cm"></app-input-number>
                <!--
                <mat-form-field class="full-width">
                    <mat-label>SPESSORE MURO PERIMETRALE cm.</mat-label>
                    <input matInput type="number" min="0" step=".01" [(ngModel)]="form.sections.abitazione.murospess">
                    <button *ngIf="form.sections.abitazione.murospess" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.murospess=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                -->

                <app-input-number
                        (onNumChange)="form.sections.abitazione.volumetot = $event"
                        [step]="0.1"
                        [num]="form.sections.abitazione.volumetot"
                        [decimal]="true"
                        [payment]="false"
                        label="VOLUME TOTALE ABITAZIONE M3"></app-input-number>
                <!--
                <mat-form-field class="full-width">
                    <mat-label>VOLUME TOTALE ABITAZIONE MTQ</mat-label>
                    <input matInput type="number" min="0" step=".01" [(ngModel)]="form.sections.abitazione.volumetot">
                    <button *ngIf="form.sections.abitazione.volumetot" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.volumetot=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                -->

                <app-input-number
                        (onNumChange)="form.sections.abitazione.volumeclima = $event"
                        [step]="0.1"
                        [num]="form.sections.abitazione.volumeclima"
                        [decimal]="true"
                        [payment]="false"
                        label="VOLUME CLIMATIZZABILE M3"></app-input-number>
                <!--
                <mat-form-field class="full-width">
                    <mat-label>VOLUME CLIMATIZZABILE MTQ</mat-label>
                    <input matInput type="number" min="0" step=".01" [(ngModel)]="form.sections.abitazione.volumeclima">
                    <button *ngIf="form.sections.abitazione.volumeclima" matSuffix mat-icon-button aria-label="Clear" (click)="form.sections.abitazione.volumeclima=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                -->

            </div>

        </div>
    </div>   
</div>
