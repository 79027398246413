import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { CaldaiaService } from '../services/caldaia.service';
import { BrandService } from '../../../../../../services/brand/brand.service';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
@Component({
  selector: 'app-accessori-cald',
  templateUrl: './accessori-cald.component.html',
  styleUrls: ['./accessori-cald.component.scss']
})
export class AccessoriCaldComponent implements OnInit {
  accessoriResponse: any;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  TIPO_INSTALLAZIONE: Trascodifica[];
  USCITA_FUMI: Trascodifica[];
  PRELIEVO_ARIA: Trascodifica[];
  SCARICO_CONDENSA: Trascodifica[];
  constructor(
      private caldaiaService: CaldaiaService,
      private brandService: BrandService,
      public formController: FormControllerService,
      //public mainCaldaia: CaldaiaComponent,
  ) { }

  ngOnInit(): void {
    this.getAccessori();
  }

  getAccessori(): void {
    this.caldaiaService.getAccessoriCaldaia(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.accessoriResponse = res[0];
      if (this.accessoriResponse !== undefined) {
        this.form.sections.accessori = this.accessoriResponse;
      }
      //this.form.sections.accessori.ordineId = this.form.idOrder;
      this.getTIPO_INSTALLAZIONE();
    });
  }

  getTIPO_INSTALLAZIONE(): void {
    this.TIPO_INSTALLAZIONE = undefined;
    this.formController.getTrascodificaByCodicPromise('TIPO_INSTALLAZIONE', this.trascodifiche).then(data => {
      this.TIPO_INSTALLAZIONE = data;
      this.getUSCITA_FUMI();
    });
  }
  getUSCITA_FUMI(): void {
    this.USCITA_FUMI = undefined;
    this.formController.getTrascodificaByCodicPromise('USCITA_FUMI', this.trascodifiche).then(data => {
      this.USCITA_FUMI = data;
      this.getPRELIEVO_ARIA();
    });
  }
  getPRELIEVO_ARIA(): void {
    this.PRELIEVO_ARIA = undefined;
    this.formController.getTrascodificaByCodicPromise('PRELIEVO_ARIA', this.trascodifiche).then(data => {
      this.PRELIEVO_ARIA = data;
      this.getSCARICO_CONDENSA();
    });
  }
  getSCARICO_CONDENSA(): void {
    this.SCARICO_CONDENSA = undefined;
    this.formController.getTrascodificaByCodicPromise('SCARICO_CONDENSA', this.trascodifiche).then(data => {
      this.SCARICO_CONDENSA = data;
    });
  }

  validCannaFumaria(): boolean {
    if (this.form.sections.accessori.uscitafumiId) {
      const index = this.trascodifiche.findIndex(r => r.codice === 'USCITA_FUMI' && r.id === this.form.sections.accessori.uscitafumiId );
      if (this.trascodifiche[index].descrizione.includes('FUMARIA') && !this.form.sections.accessori.intubcanna) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
    
  }
  
  reserCronoWifiQta(event): void {
    if(event === false){
      delete this.form.sections.accessori.numcronowifi;
    }
  }
  reserCronoStandQta(event): void {
    if(event === false){
      delete this.form.sections.accessori.numcronostand;
    }
  }
  
  cestaIdraulica(event): void {
		this.form.sections.accessori.cestaidr = event.checked;
		if (this.form.sections.accessori.cestaidr) {
			this.form.sections.accessori.ponteggio = false;
		}
	}

  ponteggio(event): void {
		this.form.sections.accessori.ponteggio = event.checked;
		if (this.form.sections.accessori.ponteggio) {
			this.form.sections.accessori.cestaidr = false;
		}
	}
	
	accessoTetto(event):void{
	 	if(event.value){
			this.form.sections.accessori.cestaidr = null;
			this.form.sections.accessori.ponteggio = null;
		}
 	}		
}
