<div class="pt-5 text-center mb-4">
</div>

<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 12 && openSign" class="btnMainColor btnColor" mat-flat-button (click)="setStep(currentStep-1)"
            mat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button               
            [disabled]="generatingPdf && isLoaded || stepController()"
            class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">Avanti ></button>
        <div>
            <div class="smallFont text-danger"
                *ngIf="(!validAnagrafica && currentStep === 0) || (currentStep === 8 && !this.formController.validPagamento(this.form, true))"> 
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger"
                *ngIf="(!this.formController.validRilievoMisureSchinaEsedile(this.form) && this.currentStep === 6)">
                Controllare le distanze inserite
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 9 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>

    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
    <div class="col-6 text-right">
    </div>
</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" class="" src="assets/img/loghi/zucchetti-logo_1.jpg"/>
            </span>
            Modulo d'ordine montascale Zucchetti
        </h4>
    </div>
    <hr />
    <div class="moduleContainer mb-3">
            <div class="" *ngIf="currentStep == 0">
                <app-anagrafica-sh [brand]="'ZUCCHETTI'" [validAnagrafica]="validAnagrafica"
                    [form]="form"></app-anagrafica-sh>
            </div>

            <div class="" *ngIf="currentStep == 1">
                    <div class="zucchettiMontascaleBg">HOME GLIDE (rettilineo) STANDARD PACK</div>
                    <div class="zucchettiMontascaleSubTitle">Inclusi di 5 m. guida poltroncina, rivestimento ariaio, iovstick su bracciolo e 2 stazioni di chiamata e invio infrarossi
                    </div>
                    <div class="zucchettiMontascaleCont">
                        <b>HOME GLIDE (rettilineo) STANDARD PACK - OPTIONAL:</b>
                        <br>
                        <div *ngFor="let homeGlideOptions of form.sections.homeGlideOptionsAvailable; let i = index">
                            <mat-checkbox class="mr-2" [checked]="homeGlideOptions.selected" (change)="onHomeGlideOptionsChange(i)" color="primary" value> 
                              {{ convertTrascodifica(homeGlideOptions.typeId) }}
                            </mat-checkbox>
                        </div>  
                    </div>
            </div>

            <div class="" *ngIf="currentStep == 2">
               <app-flow-x-curvilineo [form]="form" [trascodifiche]="trascodifiche"></app-flow-x-curvilineo>
            </div>
            
            <div class="" *ngIf="currentStep == 3">
                <app-arrivo-in-basso-della-guida [form]="form" [trascodifiche]="trascodifiche"></app-arrivo-in-basso-della-guida>
            </div>

            <div class="" *ngIf="currentStep == 4">
                <app-arrivo-in-alto-della-guida [form]="form" [trascodifiche]="trascodifiche"></app-arrivo-in-alto-della-guida>
            </div>

            <div class="" *ngIf="currentStep == 5">
                <app-dati-tecnici-attuali [form]="form" [trascodifiche]="trascodifiche"></app-dati-tecnici-attuali>
            </div>

            <div class="" *ngIf="currentStep == 6">
                <app-rilievo-misure-schiena-e-sedile [form]="form"></app-rilievo-misure-schiena-e-sedile>
            </div>  

            <div class="" *ngIf="currentStep == 7">
                <app-disegno-tecnico [form]="form" [brand]="'montascaleZucchetti'" [stepPagamento]=0></app-disegno-tecnico>
            </div>

            <div class="" *ngIf="currentStep == 8">
                <app-pay-one [form]="form" [brand]="'zucchetti'" [prodotto]="'montascale'"
                [trascodifiche]="trascodifiche"></app-pay-one>
            </div>

            <div class="" *ngIf="currentStep == 9">
                <div class="text-center mb-3">
                </div>
                <div>
                    <app-privacy [form]="form" [brand]="'zucchetti'"></app-privacy>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 10">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div>

            <div class="" *ngIf="currentStep == 11 && isFinanziamentoPayment">
                <app-finanziamento [form]="form" [listFinanziarie]="listFinanziarie" [pdfDetails]="finanziamentoPdfHeader" [productColor]="'#0a2e5e'"></app-finanziamento>
            </div>

            <ng-container *ngIf="currentStep == 11 && !isFinanziamentoPayment; else nextStepWithFinance">
                <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
            </ng-container>
            
            <ng-template #nextStepWithFinance>
                <ng-container *ngIf="currentStep == 12">
                    <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
                </ng-container>
            </ng-template>
            
            <ng-template #signatureAndPrint>
                <div class="lastStep">
                    <div class="text-center">
                        <div class="digitalSignatureButtons" *ngIf="openSign" style="height: 124px">
                            <div class="signButton" (click)="edit()">
                                <span class="material-icons d-block">edit</span>
                                <span class="d-block mb-2">Modifica Contratto</span>
                            </div>
                        </div>
                        <div class="" *ngIf="form.idOrder">
                            <app-print-montascale [anagrafica]="anagrafica" [idOrder]="form.idOrder"
                                [trascodifiche]="trascodifiche" [form]="form" (reload)="reload($event)">
                            </app-print-montascale>                        
                        </div>
                    </div>
                </div>
            </ng-template>
            
        </div>
    </div>

<div class="row buttonRow">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 12 && openSign" class="btnMainColor btnColor" mat-flat-button (click)="setStep(currentStep-1)"
            mat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">  
        <button               
            [disabled]="generatingPdf || stepController() "
            class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">Avanti ></button>
        <div>
            <div class="smallFont text-danger"
                *ngIf="(!validAnagrafica && currentStep === 0) || (currentStep === 8 && !this.formController.validPagamento(this.form, true))"> 
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger"
                *ngIf="(!this.formController.validRilievoMisureSchinaEsedile(this.form) && this.currentStep === 6)">
                Controllare le distanze inserite
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 9 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length">
    </div>
</div>

<div class="stepper">
    <div class="row">
        <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
            <div class="stepNumber" [ngClass]="
            {
            'doneStep': currentStep > step,
            'currentStep': currentStep == step,
            'errorStep': (!validAnagrafica && currentStep === step) ||(currentStep === step && !validPrivacy()) || (currentStep === step && !this.formController.validPagamento(this.form, true))
            }">
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="{'currentStep': currentStep == totalSteps.length}">
                
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="errorModal" tabindex="-1">
    <app-error-modal [error]="error"></app-error-modal>
</div>