import { environment } from 'src/environments/environment';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, DoCheck, OnInit } from '@angular/core';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { AnagraficaDTO, AnagraficaForm } from '../../../../classes/anagrafica';
import { UserService } from '../../../../../services/user/user.service';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { BrandService } from '../../../../../services/brand/brand.service';
import { Trascodifica } from '../../../../classes/trascodifica';
import { Ordine } from '../../../../classes/ordine';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../../../../services/orders/orders.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StufeService } from './services/stufe.service';
import { DettaglioStufe } from '../../../../classes/dettaglio-stufe';
import { AbitazioneDTO } from '../../../ariel/ariel-main/classes/abitazione-dto';
import { AccessoristufaDto } from '../../../../classes/accessoristufa-dto';
import { PaymentService } from '../../../../../services/payment/payment.service';
import { Signature } from '../../../../classes/signature';
import { AbitazioneService } from '../../ariel-main/services/abitazione.service';
import { OmaggioService } from '../../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { OmaggioDto } from '../../../../classes/omaggio-dto';
import * as $ from 'jquery';

declare var jQuery: any;
@Component({
  selector: 'app-stufe',
  templateUrl: './stufe.component.html',
  styleUrls: ['./stufe.component.scss']
})
export class StufeComponent implements OnInit, DoCheck, AfterViewInit,AfterContentChecked {

  validAnagrafica: boolean = false;
  showModalPrint: boolean = false;
  confirmStufe: boolean = false;
  generatingPdf: boolean = false;
  openSign:boolean=false;

  idCliente: number;
  currentStep: number = 0;
  valueProgBar: number = 0;

  obj1: any;
  obj2: any;
  form: any;
 
  signature: Signature;
  anagrafica: AnagraficaDTO;
  trascodifiche: Trascodifica[];
  stufePellet: Trascodifica[];
  termoPellet: Trascodifica[];
  caldaiePellet: Trascodifica[];
  totalSteps: number[];

  ordine: Ordine;
  
	sendingPdf : boolean = false;
	pdf:any;
	error:any;
	member:any;
	formDiffer: any;
	originalForm: any;
	showFrame = false;
	urlFrame = "";
	hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");
	integratedSignature = environment.integratedSignature;

  constructor(
      public formController: FormControllerService,
      public userService: UserService,
      private anagraficaService: AnagraficaService,
      private brandService: BrandService,
      private route: ActivatedRoute,
      private orderService: OrdersService,
      private snackbar: MatSnackBar,
      private stufeService: StufeService,
      private paymentService: PaymentService,
      private abitazioneService:AbitazioneService,
	  private router: Router,
	  private omaggioService: OmaggioService,
	  private changeDetector:ChangeDetectorRef
  ) {
  	this.showModalPrint = false;
    this.validAnagrafica = false;
    this.form = {
      	idOrder: Number(this.route.snapshot.paramMap.get('id')),
      	anagrafica: new AnagraficaForm(),
      	sections: {
        stufe: [],
        dettaglioDto: new DettaglioStufe(),
        casaStufa: new AbitazioneDTO(),
        accStufa: new AccessoristufaDto(),
        pagamento: [new PagamentoDTO()],
		ordine: null,
		omaggio : [new OmaggioDto()],
      }
	
    };
    this.totalSteps = Array(8).fill(1).map((x, i) => i ); // [0,1,2,3,4]
    // richiamo il serivizio di trascodifica che mi restituisce i dati per popolare i form
    // richiamo il serivizio di trascodifica che mi restituisce i dati per popolare i form
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
	
	getOrder(idOrder): void {
		this.orderService.getSingleOrder(idOrder).subscribe(res => {
			this.ordine = res;
			this.form.ordine = res;
			localStorage.setItem('id_product', res.prodottoId);
			if(res.clienteId !== undefined  && res.clienteId !== null){
				localStorage.setItem('id_client', res.clienteId);
				this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
			}
						
			localStorage.setItem('id_order', res.idordine);
		});
		this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
			this.trascodifiche = res;
		});
	}

  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
    if (this.obj1 !== this.obj2) {
      this.checkAnagrafica();
      this.originalForm = JSON.parse(JSON.stringify(this.form));
    }
  }

  ngOnInit(): void {
    this.originalForm = JSON.parse(JSON.stringify(this.form));
	if(localStorage.getItem('id_client') !== null ){
		this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
	}
    if (this.currentStep > 0){
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }
	  this.getOrder(this.form.idOrder);
    // this.getAnagrafica();
  }


  getOrdine(): void{
    this.orderService.getSingleOrder(this.form.idOrder).subscribe(res => {
      this.form.anagrafica.infoContratto.consulente = res.venditoreLogin;
      this.form.anagrafica.agente = res.venditoreLogin;
    }, error => {
      console.error('Error => ', error);
    });
  }

  ngAfterViewInit(): void{
    console.log('origina form', this.originalForm);
  }

  setStep(num): void {
    this.currentStep = num;
    if (this.currentStep > 0){
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }
    this.scrollTop();
    this.scrollTop();
  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  toggleJson(): void {
    $('.formJson').toggleClass('visibleJson');
  }

  scrollTop(): void {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }

  saveModule(value): void {
	  $('body').removeClass('noScr');
	  if (document.fullscreenElement) {
		  document.exitFullscreen();
	  }
    if (this.currentStep === 0){
      // Step salvataggio anagrafica
      this.anagrafica = this.form.anagrafica.anagrafica;
		if(this.anagrafica.id){
			this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
				// this.openSnackBar('Modulo salvato correttamente');
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe();
				}, error => {
					console.error('Error => ', error);
					this.openSnackBar('Errore in fase di salvataggio del modulo');
				});
		}else{
			this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
				// this.openSnackBar('Modulo salvato correttamente');
					this.ordine.clienteId = res.id;
					this.ordine.cognome = res.cognome;
					this.ordine.nome = res.nome;
					this.form.anagrafica.anagrafica.id = res.id;
					this.orderService.update(JSON.stringify(this.ordine)).subscribe();
				}, error => {
					console.error('Error => ', error);
					this.openSnackBar('Errore in fase di salvataggio del modulo');
				});
		}
    } else if (this.currentStep === 1){
    	this.confirmStufe = value;
	    if (!this.confirmStufe && this.moreThanOneStufa()){
		    this.openConfirmStufe(false);
	    } else {
		    this.confirmStufe = true;
	    }
	    if (this.confirmStufe) {
		    // Step salvataggio stufe
		    const savingStufe = [];
		    this.form.sections.stufe.forEach(r => {
			    if (r.checked) {
				    savingStufe.push(r);
			    }
			    if (!r.checked && r.id !== undefined) {
				    this.removeStufa(r.id);
			    }
		    });
		    savingStufe.forEach(s => {
			    delete s.checked;
			    delete s.coloreDescrizione;
			    if (s.id !== undefined ){
				    this.updateStufa(s);
			    } else {
				    this.saveStufa(s);
			    }
		    });
		
		    if(this.hasValues(this.form.sections.dettaglioDto)){
			    this.form.sections.dettaglioDto.ordineId = this.form.idOrder;
			    if (this.form.sections.dettaglioDto.id) {
				    this.updateNote();
			    } else {
				    this.saveNote();
			    }
		    }
	    }
    } else if (this.currentStep === 2){
      // Step salvataggio casaStufa
	  if(this.hasValues(this.form.sections.casaStufa)){
			this.form.sections.casaStufa.ordineId = this.form.idOrder;
  		if (this.form.sections.casaStufa.id) {
	        this.abitazioneService.update(JSON.stringify(this.form.sections.casaStufa)).subscribe(res => {
	              // this.openSnackBar('Modulo aggiornato correttamente');
	            }, error => {
	              console.error('Error => ', error);
	              // this.openSnackBar('Errore in fase di aggiornamento del modulo');
	            }
	        );
	      } else {
	        this.abitazioneService.save(JSON.stringify(this.form.sections.casaStufa)).subscribe(res => {
	              // this.openSnackBar('Modulo aggiornato correttamente');
	            }, error => {
	              console.error('Error => ', error);
	              // this.openSnackBar('Errore in fase di aggiornamento del modulo');
	            }
	        );
	      }
	   }
    } else if (this.currentStep === 3){
      // Step salvataggio accStufa
		const hasValuesAcc = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined" && v !== false);
		if(hasValuesAcc(this.form.sections.accStufa)){
				this.form.sections.accStufa.ordineId = this.form.idOrder;
		      if (this.form.sections.accStufa.id) {
		        this.stufeService.updateAccStufa(JSON.stringify(this.form.sections.accStufa)).subscribe(res => {
		              // this.openSnackBar('Modulo aggiornato correttamente');
		            }, error => {
		              console.error('Error => ', error);
		              // this.openSnackBar('Errore in fase di aggiornamento del modulo');
		            }
		        );
		      } else {
		        this.stufeService.saveAccStufa(JSON.stringify(this.form.sections.accStufa)).subscribe(res => {
		              // this.openSnackBar('Modulo aggiornato correttamente');
		            }, error => {
		              console.error('Error => ', error);
		              // this.openSnackBar('Errore in fase di aggiornamento del modulo');
		            }
		        );
		      }
		}
    }else if (this.currentStep === 4){
		//Step salvataggio omaggioDTO
		for(var i= 0; i < this.form.sections.omaggio.length; i++){
			var omaggio:OmaggioDto= this.form.sections.omaggio[i];
			omaggio.ordineId = this.form.idOrder;
			if( omaggio.id === undefined ||  omaggio.id === null){
				this.omaggioService.save(JSON.stringify( omaggio)).subscribe();
			}else{
				this.omaggioService.update(JSON.stringify( omaggio)).subscribe();
			}
		}
	}else if (this.currentStep === 5){
      // Step salvataggio pagamento
		if(this.hasValues(this.form.sections.pagamento[0])){
			this.form.sections.pagamento[0].ordineId = this.form.idOrder;
			const payment = this.form.sections.pagamento[0];
	        if (payment.id === undefined || payment.id === null) {
        		this.paymentService.save(JSON.stringify( payment)).subscribe();
		      }else{
		        this.paymentService.update(JSON.stringify( payment)).subscribe();
		      }
		}
    }else if (this.currentStep === 6){
			//Step salvataggio privacy
			this.orderService.update(JSON.stringify( this.form.ordine)).subscribe();
	} else if (this.currentStep === 7) {
	    this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
		    // this.openSnackBar('Modulo salvato correttamente');
		    this.ordine.clienteId = res.id;
		    this.ordine.cognome = res.cognome;
		    this.ordine.nome = res.nome;
	    }, error => {
		    console.error('Error => ', error);
		    this.openSnackBar('Errore in fase di salvataggio del modulo');
	    });    
    }
		
	  if (this.confirmStufe && this.currentStep === 1){
		  this.setStep(this.currentStep + 1);
	  } else if (!this.confirmStufe && this.currentStep === 1) {
		  console.log('stop');
	  } else {
		  this.setStep(this.currentStep + 1);
	  }
  }
	
	openConfirmStufe(value): void{
		this.confirmStufe = value;
		jQuery('#confirmModal').modal({backdrop: 'static', keyboard: false});
		jQuery('#confirmModal').modal('toggle');
		if (this.confirmStufe) {
			this.saveModule(true);
		}
	}
	
	moreThanOneStufa(): boolean {
		let count = 0;
		this.form.sections.stufe.forEach(r => {
			if (r.checked) {
				count += 1;
			}
		});
		if (count > 1) {
			return true;
		} else {
			return false;
		}
	}

  saveStufa(stufa): void {
    this.stufeService.save(JSON.stringify(stufa)).subscribe(res => {
      // this.openSnackBar('Modulo salvato correttamente');
    }, error => {
      console.error('Error => ', error);
      // this.openSnackBar('Errore in fase di salvataggio del modulo');
    });
  }
  updateStufa(stufa): void {
    this.stufeService.update(JSON.stringify(stufa)).subscribe(res => {
      // this.openSnackBar('Modulo aggiornato correttamente');
        }, error => {
          console.error('Error => ', error);
      // this.openSnackBar('Errore in fase di aggiornamento del modulo');
        }
    );
  }
  saveNote(): void {
    this.stufeService.saveDt(JSON.stringify(this.form.sections.dettaglioDto)).subscribe(res => {
      // this.openSnackBar('Modulo salvato correttamente');
    }, error => {
      console.error('Error => ', error);
      // this.openSnackBar('Errore in fase di salvataggio del modulo');
    });
  }
  updateNote(): void {
    this.stufeService.updateDt(JSON.stringify(this.form.sections.dettaglioDto)).subscribe(res => {
      // this.openSnackBar('Modulo salvato correttamente');
    }, error => {
      console.error('Error => ', error);
      // this.openSnackBar('Errore in fase di salvataggio del modulo');
    });
  }
  removeStufa(id): void {
    this.stufeService.delete(JSON.stringify(id)).subscribe(res => {
      // this.openSnackBar('Stufa rimossa correttamente');
        }, error => {
          console.error('Error => ', error);
          // this.openSnackBar('Errore in fase di aggiornamento del modulo');
        }
    );
  }

  openSnackBar(text): void {
    this.snackbar.open(text, 'Ok', {
      duration: 4000
    });
  }

  validStufe(): boolean {
    let valid = true;
    if (this.form.sections.stufe.length > 0) {
      for (let i = 0; i < this.form.sections.stufe.length; i++) {
        if (valid) {
          if (this.form.sections.stufe[i].checked && !this.form.sections.stufe[i].coloreId) {
            valid = false;
          } else {
            valid = true;
          }
        }
      }
    }
    return valid;
  }

  createPdf(pdf,intervalId): void {
    //pdf.save('contratto.pdf');
	  this.sendingPdf = true;
	  const title = localStorage.getItem('id_order') + '.pdf';
	  pdf.setProperties({
		  title,
	  });
    this.valueProgBar = 80;
	const newPdf = btoa(pdf.output());

	this.formController.sendSignature(newPdf,this.form.idOrder,this.form,10,"477,57,583,86", title,false).then(res => {
		this.valueProgBar = 100;
		this.sendingPdf = false;
		this.generatingPdf = false;
		clearInterval(intervalId);
		this.router.navigate(['order-list']);
	}, error => {
      	this.generatingPdf = false;
		this.sendingPdf = false;
      	this.ordine.statopraticaCodice = 'A';
      	clearInterval(intervalId);
		this.error = error;
	  	jQuery('#errorModal').modal({backdrop: 'static', keyboard: false});
	  	jQuery('#errorModal').modal('toggle');
	});
  }

	togglePrintPreview(show:boolean): void {
		this.showModalPrint = show;
		jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
		jQuery('#printModal').modal('toggle');
	}
  
  	returnIdOrdine(): string {
	  return localStorage.getItem('id_order');
  	}
  
  	validStabile(): boolean {
  		if (
  			this.form.sections.casaStufa.pianistabile === undefined ||
	    	this.form.sections.casaStufa.pianistabile == null ||
	    	this.form.sections.casaStufa.pianointerv === undefined ||
	    	this.form.sections.casaStufa.pianointerv === null ||
        	!this.form.sections.casaStufa.cannafumariaId) {
	      return false;
    	} else {
  		  return true;
    	}
  	}
	
	validInstallazione(): boolean {
		if (
			this.form.sections.accStufa.intubamentocannapiani === undefined ||
			this.form.sections.accStufa.intubamentocannapiani == null ||
			this.form.sections.accStufa.instalstandesterna === undefined ||
			this.form.sections.accStufa.instalstandinterna === null ||
			this.form.sections.accStufa.instalstandesterna === undefined ||
			this.form.sections.accStufa.instalstandinterna === null ||
			this.form.sections.accStufa.forosolettaqta === undefined ||
			this.form.sections.accStufa.forosolettaqta === null ||
			this.form.sections.accStufa.forosolettaqta < 0 ||
			this.form.sections.accStufa.forocementoqta === undefined ||
			this.form.sections.accStufa.forocementoqta === null ||
			this.form.sections.accStufa.forocementoqta < 0 ||
			this.form.sections.accStufa.forocementocm === undefined ||
			this.form.sections.accStufa.forocementocm === null ||
			this.form.sections.accStufa.forocementocm < 0 ||
			this.form.sections.accStufa.foromuroqta === undefined ||
			this.form.sections.accStufa.foromuroqta === null ||
			this.form.sections.accStufa.foromuroqta < 0) {
			return false;
		} else {
			return true;
		}
	}
	
	validPrivacy(): boolean {
		if(!this.form.ordine.accettprivacy || !this.form.ordine.accettprivacynd || !this.form.ordine.accettprivacyrd){
			return false;
		}else
			return true;
	}
		
	closeSignPdf(){
		jQuery('#signModal').modal({backdrop: 'static', keyboard: false});
		jQuery('#signModal').modal('toggle');
	}


	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

  	reload(value: boolean) {
    	this.openSign = value;
  	}
}
