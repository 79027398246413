import { Component, Input, OnInit,ViewChild } from '@angular/core';

import { PaintableComponent } from 'paintablejs/angular';
import * as _ from 'lodash';
import { AbitazioneService} from '../../services/abitazione.service';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import * as $ from 'jquery';
import { Trascodifica } from '../../../../../classes/trascodifica';
@Component({
  selector: 'app-abitazione',
  templateUrl: './abitazione.component.html',
  styleUrls: ['./abitazione.component.scss']
})
export class AbitazioneComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @ViewChild(PaintableComponent)
  paintable: PaintableComponent;
  active = false;
  useEraser = false;
  thickness = 2;
  color = '#000000';
  savedImage: any;
  drawing = true;
  manualUpload: any;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  isLoadingImage: boolean = true;
  width: number;
  height: number;
  abitazione:any;
  tipiAbitazione:Trascodifica[];
  constructor(
	private abitazioneService: AbitazioneService,
	private formController:FormControllerService,
  ) {
		
  }

  ngOnInit(): void {
    //this.form.sections.abitazione.ordineId = this.form.idOrder;
    this.getAbitazione();
    this.height = 300;
    this.width = document.getElementById('part').offsetWidth - 60;
	this.tipiAbitazione = this.formController.getTrascodificaByCodice('TIPO_ABITAZIONE', this.trascodifiche);
  }

  getAbitazione():void {
	
    this.abitazioneService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.abitazione = res[0];

      if (this.abitazione !== undefined) {
        this.form.sections.abitazione = this.abitazione;
        if (this.form.sections.abitazione.disegnotecnico !== null && this.form.sections.abitazione.disegnotecnico !== undefined) {
          // tslint:disable-next-line:max-line-length
          this.cardImageBase64 = 'data:' + this.form.sections.abitazione.disegnotecnicoContentType + ';base64,' + this.form.sections.abitazione.disegnotecnico;
          this.isLoadingImage = false;
          this.active = false;
          this.isImageSaved = true;
        }else{
          this.isLoadingImage = false;
          //this.active = true;
        }
      } else{
        this.isLoadingImage = false;
        //this.active = true;
      }
    });
	
  }	


  clear(): void {
    this.paintable?.clear();
    this.isImageSaved = false;
    delete this.cardImageBase64;
    delete this.form.sections.abitazione.disegnotecnico;
  }
  undo(): void {
    this.paintable?.undo();
  }

  redo(): void {
    this.paintable?.redo();
  }

  toggleEdit(): void {
    this.useEraser = false;
    this.active = !this.active;
    if (this.active) {
      $('body').addClass('noScr');
      document.body.requestFullscreen();
      $('html,body').animate({
        scrollTop: $('#part').offset().top - 80
      },'slow');
    } else {
      $('body').removeClass('noScr');
      document.exitFullscreen();
      $('html,body').animate({
        scrollTop: $('#part').offset().top - 80
      },'slow');
    }
  }

  toggleUseEraser(): void {
    this.useEraser = !this.useEraser;
  }

  get image(): string {
    return this.cardImageBase64 || undefined;
  }
  onLongPress(): void {
    console.log('longpress');
  }

  onSave(image: string): void {
    delete this.cardImageBase64;
    this.form.sections.abitazione.disegnotecnico = image;
    const arr = this.form.sections.abitazione.disegnotecnico.split(";");
    this.form.sections.abitazione.disegnotecnicoContentType = 'image/png';
    const byte = arr[1].split(",");
    this.form.sections.abitazione.disegnotecnico = byte[1];
    this.isImageSaved = true;
    if(this.form.sections.abitazione.id === undefined){
      this.form.sections.abitazione.ordineId = this.form.idOrder;
      this.abitazioneService.save(JSON.stringify(this.form.sections.abitazione)).subscribe( res => {
        this.getAbitazione();
      });
    } else {
      this.form.sections.abitazione.ordineId = this.form.idOrder;
      this.abitazioneService.update(JSON.stringify(this.form.sections.abitazione)).subscribe(res => {
        this.getAbitazione();
      });
    }
  }

  fileChangeEvent(fileInput: any): any {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 5242880;
      const allowed_types = [
        'image/jpg',
        'image/jpeg',
        'image/png',
      ];
      const max_height = 15200;
      const max_width = 25600;
  
      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
            'Maximum size allowed is ' + max_size / 1000000 + 'Mb';
        return false;
      }
  
      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'Sono consentite solo immagini ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];


          if (img_height > max_height && img_width > max_width) {
            this.imageError =
                'Maximum dimentions allowed ' +
                max_height +
                '*' +
                max_width +
                'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.formController.compressImage(this.cardImageBase64, this.width, this.height).then(compressed => {
              this.cardImageBase64 = compressed;
              this.form.sections.abitazione.disegnotecnico = this.cardImageBase64;
              const arr = this.form.sections.abitazione.disegnotecnico.split(";");
              this.form.sections.abitazione.disegnotecnicoContentType = 'image/png';
              const byte = arr[1].split(",");
              this.form.sections.abitazione.disegnotecnico = byte[1];
            }, err => console.log('this.err => ', err));
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
      this.active = false;
    }
  }

  removeImage(): void {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    delete this.form.sections.abitazione.disegnotecnico;
  }

}
