export class DtoStufe {
	checked: boolean;
	id: number;
	coloreDescrizione: string;
	coloreId: number;
	modcaldpelletDescrizione: string;
	modcaldpelletId: number;
	modstufepelletDescrizione: string;
	modstufepelletId: number;
	modtermopelletDescrizione: string;
	modtermopelletId: number;
	modstufebioId: number;
	modstufebioDescrizione: string;
	volriscDescrizione: string;
	volriscId: number;
	ordineId: number;
}
