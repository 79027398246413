import { Component, Input, OnInit } from '@angular/core';
import { CodeDescr, SingleBox } from '../classes/dati-tecnici';
import { Trascodifica } from '../../../../classes/trascodifica';
import { BoxdocciaService } from '../services/boxdoccia/boxdoccia.service';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
@Component({
  selector: 'app-form-nuovi-box',
  templateUrl: './form-nuovi-box.component.html',
  styleUrls: ['./form-nuovi-box.component.scss']
})
export class FormNuoviBoxComponent implements OnInit {

  collapse:boolean=false;
  @Input() form: any;
  @Input() trascodifiche:Trascodifica[];
  singleBox: {} = new SingleBox();
  combinazioni: any;
  boxes: Trascodifica[];
  cristalli: Trascodifica[] = [new Trascodifica()];
  profili: Trascodifica[];
  larghezze: Trascodifica[];
  isVisible  = false;
  options: Trascodifica[];
  optionNuovoBoxDoccia: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};

  constructor(private boxdocciaService : BoxdocciaService , private formController: FormControllerService, private optionsService: OptionsAvailableService){
  }

  ngOnInit(): void {

    this.form.sections.nuoviBox = [];
	  this.boxdocciaService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
		
    for (var i = 0; i < res.length; i++){
			const box = this.formController.mappingBoxdocciaDtoToForm(res[i] , new SingleBox());
			this.form.sections.nuoviBox.push(box);
			this.form.sections.nuoviBox[i].listCristalli = this.trascodifiche.filter(
 	 			tr => (tr.codice === 'BOX_DOCCIA_CRISTALLI') && (tr.parentId === box.modello.id));
			this.form.sections.nuoviBox[i].listProfili = this.trascodifiche.filter(
		      	tr => (tr.codice === 'BOX_DOCCIA_PROFILO') && (tr.parentId === box.modello.id));

 			this.form.sections.nuoviBox[i].listLarghezze = this.trascodifiche.filter(
      			tr => (tr.codice === 'BOX_DOCCIA_LARGHEZZA') && (tr.parentId === box.modello.id));

      this.form.sections.nuoviBox[i].listIngressi = this.formController.getTrascodificaByCodice('BOX_DOCCIA_INGRESSO', this.trascodifiche);
		}
		
		this.boxes = this.formController.getTrascodificaByCodice('BOX_DOCCIA_MODELLO',this.trascodifiche);

    this.options = this.formController.getTrascodificaByCodice('TYPE_OPTIONS', this.trascodifiche).filter(op => op.descrizione === 'NUOVO BOX DOCCIA');

    this.getOption(this.options[0].id).then(res => {
      if (res !== undefined && res.length > 0) {
        this.isVisible = res[0].selected;
        this.form.sections.optionsAvailable.push(res[0])

        this.form.sections.optionsAvailable = this.form.sections.optionsAvailable.filter((res: any) => res.id != undefined && res.id != null)
        this.optionNuovoBoxDoccia = res[0];
      } else {
        this.isVisible = false;
        this.optionNuovoBoxDoccia = new OptionsAvailable();
        this.optionNuovoBoxDoccia.ordineId = this.form.idOrder;
        this.optionNuovoBoxDoccia.typeId = this.options[0].id;
        this.optionNuovoBoxDoccia.selected = undefined;
        this.form.sections.optionsAvailable.push(this.optionNuovoBoxDoccia);
      }
    });
	 })  
  }

  addBox(): void {
    const newBox = new SingleBox();
    this.form.sections.nuoviBox.push(newBox);
  }

  removeModel(i): void {
	if(this.form.sections.nuoviBox[i].id !== undefined){
		this.boxdocciaService.delete(this.form.sections.nuoviBox[i].id).subscribe();
	}
    this.form.sections.nuoviBox.splice(i, 1);
  }

  checkModel(event, i): void {
    this.form.sections.nuoviBox[i].cristalli = new CodeDescr();
    this.form.sections.nuoviBox[i].profilo = new CodeDescr();
    this.form.sections.nuoviBox[i].larghezza = new CodeDescr();

	  this.form.sections.nuoviBox[i].listCristalli = this.trascodifiche.filter(
      tr => (tr.codice === 'BOX_DOCCIA_CRISTALLI') && (tr.parentId === event));

	  this.form.sections.nuoviBox[i].listProfili = this.trascodifiche.filter(
      tr => (tr.codice === 'BOX_DOCCIA_PROFILO') && (tr.parentId === event));

 	  this.form.sections.nuoviBox[i].listLarghezze = this.trascodifiche.filter(
      tr => (tr.codice === 'BOX_DOCCIA_LARGHEZZA') && (tr.parentId === event));
  
    this.form.sections.nuoviBox[i].listIngressi = this.trascodifiche.filter(
        tr => (tr.codice === 'BOX_DOCCIA_INGRESSO'));
  }
	
	validNuoviBox(): boolean {
		let valid = true;
		if (this.form.sections.nuoviBox.length > 0) {
			for (let i = 0; i < this.form.sections.nuoviBox.length; i++) {
				if (valid) {
					if (
            this.form.sections.nuoviBox[i].modello.id &&
						this.form.sections.nuoviBox[i].cristalli.id &&
						this.form.sections.nuoviBox[i].profilo.id &&
						this.form.sections.nuoviBox[i].larghezza.id &&
						this.form.sections.nuoviBox[i].ingresso.id
					) {
						valid = true;
					} else {
						valid = false;
					}
				}
			}
		}
		return valid;
	}

  showDiv(value) {
    if (value) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
    this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0].id)[0].selected = value;
  }

  getOption(idOption:any):Promise<any>{

    return new Promise((resolve, reject) => {
      this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
        if(res !== undefined){
            let option = res.filter(op => op.typeId === idOption)  
            resolve(option);				          
          }
       });
      });
    } 
}