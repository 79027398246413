
<div class="loginPage">

    <div class="loginContainer">
        <div class="text-center mb-4">
            <img class="mb-3" alt="logo" title="remail" src="/assets/img/gdl.png">
            <div class="intro-login">
                <h4>REGISTRA TUO PROFILO PERSONALE</h4>
            </div>
        </div>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Username</mat-label>
            <input  matInput [(ngModel)]="username" required>
            <button mat-button *ngIf="username" matSuffix mat-icon-button aria-label="Clear" (click)="username=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input type="email" matInput [(ngModel)]="email" required>
            <button mat-button *ngIf="email" matSuffix mat-icon-button aria-label="Clear" (click)="email=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <p class="mt-0 text-danger small" *ngIf="!validEmail(email)">
            Inserisci un indirizzo email valido
        </p>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="password" [type]="hide ? 'password' : 'text'" required>
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Conferma password</mat-label>
            <input matInput [(ngModel)]="passwordConfirm" type="password" required>
        </mat-form-field>
        <p class="mt-0 text-danger small" *ngIf="passwordConfirm !== password">
            Le password non coincidono
        </p>
        <div class="text-left mb-3" style="font-size:10px; position:relative" *ngIf="password">
            <div>
                <span style="font-size:11px;vertical-align: middle">La password deve contenere almeno 8 caratteri di cui: 2 lettere maiuscole, 2 lettere minuscole, 2 numeri ed un carattere speciale</span>
            </div>
            <!--
                <span style="vertical-align: middle;font-size:14px;color:red;" class="material-icons" *ngIf="barValue <= 66">
                close
            </span>
            <span style="vertical-align: middle;font-size:14px;color:green;" class="material-icons" *ngIf="barValue > 66">
                done
            </span>
             -->
            
            <span style="vertical-align: middle">Forza della password</span>
            <div class="row noMarginRow" >
                <div class="customClass blue" *ngIf="upperLetters(password)"></div>
                <div class="customClass blue" *ngIf="lowerLetters(password)"></div>
                <div class="customClass blue" *ngIf="numbers(password)"></div>
                <div class="customClass blue" *ngIf="symbols(password)"></div>
                <div class="customClass blue" *ngIf="length(password)"></div>
              </div>
              <mat-progress-bar id="bar"
                  mode="determinate"
                  [value]="0"
                  ></mat-progress-bar>
        </div>
        <hr/>
        <div class="text-center">
            <a *ngIf="!loading" mat-flat-button color="warn" class="mr-2" routerLink="/login">
                Torna al login
            </a>
            <button *ngIf="!loading" mat-flat-button color="primary" (click)="registrationAction()" [disabled]="!upperLetters(password) ||!lowerLetters(password) || !numbers(password) || !symbols(password) || !length(password) || (!username || !password  || !validEmail(email)) || passwordConfirm !== password">
                Registrati <span class="material-icons">login</span>
            </button>
            <mat-progress-bar *ngIf="loading" mode="indeterminate" color="warn"></mat-progress-bar>
        </div>

    </div>

</div>

