import { AfterViewInit, Component, DoCheck, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AnagraficaDTO } from '../../../bagni-italiani/main-bagni/classes/anagrafica';
import { AnagraficaForm } from '../../../../classes/anagrafica';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from 'src/app/services/brand/brand.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { Ordine } from 'src/app/modules/classes/ordine';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { DepuratoreDto } from 'src/app/modules/classes/depuratore-dto';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { DepuratoreService } from 'src/app/services/depuratore/depuratore.service';
import { AccessoriDepuratoreService } from 'src/app/services/accessori-depuratore/accessori-depuratore.service';
import { constants } from '../../../../classes/constants';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import * as $ from 'jquery';
declare var jQuery: any;
@Component({
  selector: 'app-depuratore-acqua',
  templateUrl: './depuratore-acqua.component.html',
  styleUrls: ['./depuratore-acqua.component.scss']
})
export class DepuratoreAcquaComponent implements OnInit, DoCheck, AfterViewInit  {

  currentStep:number = 0;
  totalSteps: number[];

  generatingPdf: boolean  = false;
  showModalPrint: boolean = false;
  validAnagrafica: boolean = false;
  openSign:boolean=false;
  isLoaded: boolean = false;
  isFinanziamentoPayment: boolean = false;

  anagrafica: AnagraficaDTO;
  ordine: Ordine;
  depuratore: DepuratoreDto;
  trascodifiche: Trascodifica[];
  listFinanziarie: Trascodifica[];
  trascodificheAccessori: Trascodifica[];
  attivitaLavList: Trascodifica[] = [];
  trascodificheAccGasatore: Trascodifica[]; 

  originalForm: any;
  obj1: any;
  obj2: any;
  form: any;
  
  finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
	logoUrl: 'assets/img/loghi/ARIEL.jpg', product: 'depuratore', brand: 'Ariel'}

  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');

  constructor(
    public formController: FormControllerService,
    private route: ActivatedRoute,
    private anagraficaService: AnagraficaService,
	private brandService: BrandService,
    private orderService: OrdersService,
    private snackbar: MatSnackBar,
    private depuratoreService: DepuratoreService,
    private accessoriDepuratoreService: AccessoriDepuratoreService,
    private paymentService: PaymentService,
	private allegatoService: AllegatoService
  ) {
    this.showModalPrint = false;
		this.validAnagrafica = false;
		this.form = {
			idOrder: Number(this.route.snapshot.paramMap.get('id')),
			anagrafica: new AnagraficaForm(),
      sections: {
        depuratore: new DepuratoreDto(),
        accessoriDepuratore: [], 
        isFrigoGasatore: false,
        accessoriGasatore: [], 
        pagamento: [new PagamentoDTO()],
		finanziamentoSections: {
			finanziamento: new Finanziamento(),
			finAnagrafica: new FinAnagrafica(),
			finDatiOccupazione: new FinDatiOccupazione(),
			finAnagraficaGarante: new FinAnagrafica(),
			finDatiOccupazioneGarante: new FinDatiOccupazione(),
			isAllegatoSaved: false
		},
      },
      ordine: null
    }
    this.totalSteps = Array(7).fill(1).map((x, i) => i); // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
   }

   ngOnInit(): void {
    this.originalForm = JSON.parse(JSON.stringify(this.form));
		
		if(localStorage.getItem('id_client') !== null ){
			this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));	
		}
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}

		this.getOrder(this.form.idOrder);
  }
   	
	 getOrder(idOrder): void {
		this.orderService.getSingleOrder(idOrder).subscribe(res => {
			this.ordine = res;
			this.form.ordine = res;
			localStorage.setItem('id_product', res.prodottoId);

			if(res.clienteId !== undefined  && res.clienteId !== null){
				localStorage.setItem('id_client', res.clienteId);	
				this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
			}
			
			localStorage.setItem('id_order', res.idordine);
		});

		this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
			this.trascodifiche = res;
      		this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
      		this.trascodificheAccessori =  this.trascodifiche.filter(item => item.codice === constants.ACCESSORI_DEPURATORE_TRASC) 
      this.trascodificheAccGasatore = this.trascodifiche.filter(item => constants.ACCESSORI_DEPURATORE_GASATORE);
		});

		this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);

    	this.isLoaded = true;
	}

  saveModule(value): void {
    $('body').removeClass('noScr');
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
    if (this.currentStep === 0) {
			// Step salvataggio anagrafica
			this.anagrafica = this.form.anagrafica.anagrafica;
			if(this.anagrafica.id){
				this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
					// this.openSnackBar('Modulo salvato correttamente');
						this.ordine.clienteId = res.id;
						this.ordine.cognome = res.cognome;
						this.ordine.nome = res.nome;
						this.orderService.update(JSON.stringify(this.ordine)).subscribe();
					}, error => {
						console.error('Error => ', error);
						this.openSnackBar('Errore in fase di salvataggio del modulo');
					});				
			}else{
				this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
						this.ordine.clienteId = res.id;
						this.ordine.cognome = res.cognome;
						this.ordine.nome = res.nome;
						this.form.anagrafica.anagrafica.id = res.id;
						this.orderService.update(JSON.stringify(this.ordine)).subscribe();
					}, error => {
						console.error('Error => ', error);
						this.openSnackBar('Errore in fase di salvataggio del modulo');
					});	
			}				
		}else if(this.currentStep === 1){
      this.depuratore = this.form.sections.depuratore;
      if (this.hasValues(this.depuratore)) {
        this.form.sections.depuratore.ordineId = this.form.idOrder;
        if(this.depuratore.id === null || this.depuratore.id === undefined){
          this.depuratoreService.save(this.depuratore).subscribe(res => {
            this.depuratore = res;
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
          });
        }else {
          this.depuratoreService.update(this.depuratore).subscribe(res => {
            this.depuratore = res;
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar('Errore in fase di aggiornamento del modulo');
          }
       );
      }
     }
    }else if(this.currentStep === 2){
      // Faccio il save dell'ordine per salvare le note 
      this.orderService.update(JSON.stringify( this.form.ordine)).subscribe();

      if(this.form.sections.accessoriDepuratore && this.form.sections.accessoriDepuratore.length){
        this.form.sections.accessoriDepuratore.forEach(element => {
          if (element.quantita !== null && element.quantita !== undefined) {
            if(element && (element.id === null || element.id === undefined)){
              this.accessoriDepuratoreService.save(element).subscribe(res => {
                if(res){
                  this.form.sections.accessoriDepuratore = res;
                }
              })
            }else{
              this.accessoriDepuratoreService.update(element).subscribe(res => {
                this.form.sections.accessoriDepuratore = res;
              }, error => {
                console.error('Error => ', error);
                this.formController.openSnackBar('Errore in fase di aggiornamento del modulo');
              })
            }
          }else{
            // nel caso voglia pulire un valore da valorizzato a vuoto.
            // posso cancellarlo, perchè poi il componente di occupa di ripristinarlo.
            if(element && element.id){
              this.accessoriDepuratoreService.delete(element.id).subscribe()
            }
          }
        });
      }

      if(this.form.sections.accessoriGasatore && this.form.sections.accessoriGasatore.length){
            this.form.sections.accessoriGasatore.forEach(element => {
              if (element.quantita !== null && element.quantita !== undefined) {
              if(element && (element.id === null || element.id === undefined)){
                this.accessoriDepuratoreService.save(element).subscribe(res => {
                  if(res){
                    this.form.sections.accessoriGasatore = res;
                  }
                })
              }else{
                this.accessoriDepuratoreService.update(element).subscribe(res => {
                  this.form.sections.accessoriGasatore = res;
                }, error => {
                  console.error('Error => ', error);
                  this.formController.openSnackBar('Errore in fase di aggiornamento del modulo');
                })
              }
            }else{
              // nel caso voglia pulire un valore da valorizzato a vuoto.
              // posso cancellarlo, perchè poi il componente di occupa di ripristinarlo.
              if(element && element.id){
                this.accessoriDepuratoreService.delete(element.id).subscribe()
              }  
            }
        });  
      }
    }
    else if(this.currentStep === 3){
      // Step salvataggio pagamento
      if(this.hasValues(this.form.sections.pagamento[0])){
        this.form.sections.pagamento[0].ordineId = this.form.idOrder;
        const payment = this.form.sections.pagamento[0];
        if (payment.id === undefined || payment.id === null) {
          this.paymentService.save(JSON.stringify(payment)).subscribe((res: any) => {
			this.form.sections.pagamento[0] = res;
		});
        } else {
          this.paymentService.update(JSON.stringify(payment)).subscribe((res: any) => {
			this.form.sections.pagamento[0] = res;
			// se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
			if(res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
				this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
					allegati.forEach((allegato:any) => {
						if(allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)){
							this.allegatoService.delete(allegato.id).subscribe();
						}
					})
				})
			}
		});
      }
     }
    }else if (this.currentStep === 4){
      //Step salvataggio privacy
      this.orderService.update(JSON.stringify( this.form.ordine)).subscribe();
    } 
    else if(this.currentStep === 5) {
      this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
        this.anagrafica = res;
        // this.openSnackBar('Modulo salvato correttamente');
        this.ordine.clienteId = res.id;
        this.ordine.cognome = res.cognome;
        this.ordine.nome = res.nome;
      }, error => {
        console.error('Error => ', error);
        this.openSnackBar('Errore in fase di salvataggio del modulo');
      });			
    }

    this.setStep(this.currentStep + 1);
  }

  ngDoCheck(): void {
		this.obj1 = JSON.stringify(this.originalForm);
		this.obj2 = JSON.stringify(this.form);
		if (this.obj1 !== this.obj2) {
			this.checkAnagrafica();
			this.originalForm = JSON.parse(JSON.stringify(this.form));
		}
	}
  
  validPrivacy(): boolean {
		if(!this.form.ordine?.accettprivacy || !this.form.ordine?.accettprivacynd || !this.form.ordine?.accettprivacyrd){
			return false;
		}else
			return true;
	}

  ngAfterViewInit(): void {
		console.log('origina form', this.originalForm);
	}

  setStep(num): void {
		this.currentStep = num;
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}

    if(this.form.sections.pagamento[0]!.tipopagsaldoId){
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if( tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(7).fill(1).map((x, i) => i);
			}else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(6).fill(1).map((x, i) => i);
			}
		}

		this.scrollTop();
	}

	stepController(): boolean {
		switch (this.currentStep) {
			case 0:
				return this.generatingPdf || !this.validAnagrafica;
			case 3:
				return this.generatingPdf || !this.formController.validPagamento(this.form, true);
			case 4:
				return this.generatingPdf || !this.validPrivacy();
			case 6:
				return this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie );
			default:
				return this.generatingPdf;
		}
	}
	

  scrollTop(): void {
		$('html, body').animate({ scrollTop: 0 }, 300);
	}

  openSnackBar(text): void {
		this.snackbar.open(text, 'Ok', {
			duration: 4000
		});
	}

  checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

  togglePrintPreview(show:boolean): void {
		this.showModalPrint = show;
			jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
			jQuery('#printModal').modal('toggle');
  	}	
	
	returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

  reload(value: boolean) {
		this.openSign = value;
	}
}
