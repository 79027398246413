<div class="anagraficaContainer mb-4">
	<div class="text-left text-success font-weight-bold mb-4">
		CONDIZIONATORE</div>
	<div class="row originalRow">
		<div class="col-4">
			<mat-form-field class="full-width"> <mat-label>Seleziona
			tipologia</mat-label> <mat-select (ngModelChange)="tipoSelected($event)"
				[(ngModel)]="selectedTipo" required> <mat-option
				[value]="undefined">Seleziona tipologia</mat-option> <mat-option
				*ngFor="let tipo of tipologie" [value]="tipo.id">{{tipo.descrizione}}</mat-option>
			</mat-select>
			<mat-hint class="text-danger" *ngIf="!selectedTipo">Tipologia campo richiesto</mat-hint> 
			</mat-form-field>
		</div>
		<div class="col-4">
			<mat-form-field class="full-width"
				*ngIf="selectedTipo && availablePromo"> <mat-label>Seleziona
			promozione</mat-label> <mat-select [(ngModel)]="selectedPromo"> <mat-option
				[value]="undefined">Seleziona promozione</mat-option> <mat-option
				*ngFor="let tipo of availablePromo" [value]="tipo.id">{{tipo.descrizione}}</mat-option>
			</mat-select> 
			<mat-hint class="text-danger" *ngIf="!selectedPromo">Promozione campo richiesto</mat-hint> 
			</mat-form-field>
		</div>
		<div class="col-4 align-self-center">
			<div *ngIf="selectedPromo">
				<button class="btnMedium greenBtn" mat-flat-button (click)="selectPromo()">Seleziona</button>
			</div>
		</div>
	</div>
	<div class=""
		*ngIf="form.sections.condizionatori && form.sections.condizionatori.length > 0 && modelliInverter">
		<hr />
		<div class="row originalRow">
			<div class="col-4">Modello</div>
			<div class="col-1">Q.tà</div>
			<div class="col-5">Promozione</div>
			<div class="col-2"></div>
		</div>
		<hr />
		<div class="littleFont"
			*ngFor="let condizionatore of form.sections.condizionatori; let i = index; let l = last">
			<div class="row originalRow" *ngIf="!condizionatore.toDelete">
				<div class="col-4" *ngIf="condizionatore.modelloId">{{returnInverterMod(condizionatore.modelloId)}}</div>
				<div class="col-1" *ngIf="condizionatore.modelloId">{{returnInverterQt(condizionatore.modelloId)}}</div>
				<div class="col-5">
					Tipologia: {{returnPromoTipo(condizionatore.modelloId)}} |
					Promozione: {{returnInverterPromo(condizionatore.modelloId)}}

				</div>
				<div class="col-2">
					<button class="btnMedium redBtn" mat-flat-button (click)="removeInverter(condizionatore.modelloId)">Rimuovi</button>
					<!--
					<span matTooltip="Rimuovi"
							style="cursor: pointer; vertical-align: top;"
							class="material-icons text-danger"
							(click)="removeInverter(condizionatore.modelloId)">cancel</span>
					-->
				</div>
			</div>
			<hr *ngIf="!l && !condizionatore.toDelete" />
		</div>
	</div>
</div>

<div class="anagraficaContainer">
	<mat-form-field class="full-width" appearance="fill">
	<mat-label>Note</mat-label> <textarea matInput placeholder="Note..."
		[(ngModel)]="form.sections.dettaglioDto.note"></textarea> </mat-form-field>
</div>
