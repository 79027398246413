import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {Trascodifica} from '../../../../classes/trascodifica'
import {FormControllerService} from '../../../../../services/controller/form-controller.service';
import {SanitariService} from '../services/sanitari/sanitari.service';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
@Component({
  selector: 'app-form-nuova-rubinetteria',
  templateUrl: './form-nuova-rubinetteria.component.html',
  styleUrls: ['./form-nuova-rubinetteria.component.scss']
})
export class FormNuovaRubinetteriaComponent implements OnInit {
	
  @Input() form: any;
  @Input() trascodifiche : Trascodifica[];	
  @Input() brand:string;
  tipiSerieRub : Trascodifica[];  
  rubTipiAsta : Trascodifica[];
  rubTipiCol : Trascodifica[];
  tipiMiscEst: Trascodifica[];
  serieTipiMiscEst : Trascodifica[];
  subLavaboLime : Trascodifica[];
  subBidetLime : Trascodifica[];
  viewSerieLime : boolean = false;
  subBidetTango : Trascodifica[];
  subLavaboTango : Trascodifica[];
  viewSerieTango : boolean = false;
  subLavaboStick : Trascodifica[];
  subBidetStick : Trascodifica[];
  viewSerieStick : boolean = false;
  modelListLime : Trascodifica[];
  modelListTango : Trascodifica[];
  modelListStick : Trascodifica[];
  modelListLight: Trascodifica[];
  miscListLime : Trascodifica[];
  miscListTango : Trascodifica[];
  miscListStick : Trascodifica[];
  subLavaboLight: Trascodifica[];
  subBidetLight : Trascodifica[];
  viewSerieLight : boolean = false;
  miscListLight: Trascodifica[];
  colListLight: Trascodifica[];
  viewSerieGrohe : boolean = false;
  subLavaboGrohe: Trascodifica[];
  subBidetGrohe : Trascodifica[];  

  isVisible:boolean  = false;
  collapse:boolean=false;
  options: Trascodifica[];
  optionNuovaRubinetteria: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};
  listOptionsOrder: OptionsAvailable[];
  classBg:string = "blueBg";
  classContent:string = "blueCont";
  
  constructor(
		private formController:FormControllerService,
		private sanitariService:SanitariService,
		private optionsService:OptionsAvailableService
	) { }

  ngOnInit(): void {
	if(this.brand === 'bagniHelp75'){
		this.classBg = "help75Bg";
		this.classContent = "hepl75Cont";
	}else if (this.brand === 'bagno-pratico'){
		this.classBg = "bagnoPraticoBg";
		this.classContent = "bagnoPraticoCont";	
	}
	
	this.options = this.formController.getTrascodificaByCodice('TYPE_OPTIONS',this.trascodifiche).filter(op => op.descrizione === 'NUOVA RUBINETTERIA');
	this.getOption();
	
	this.sanitariService.findByKey(this.form.idOrder , 'idordineId.equals=').subscribe(res => {
			this.formController.mappingSanitariDTOtoForm(res[0], this.form.sections.nuoviProdotti);				
			this.tipiSerieRub = this.formController.getTrascodificaByCodice('SERIE_RUBINETTERIA' , this.trascodifiche);
			this.rubTipiAsta = this.formController.getTrascodificaByCodice('TIPO_ASTA_COMBY' , this.trascodifiche);
			this.rubTipiCol =  this.formController.getTrascodificaByCodice('TIPO_COLONNE_RUB' , this.trascodifiche);
			this.tipiMiscEst = this.formController.getTrascodificaByCodice('TIPO_MISC_EST' , this.trascodifiche);
			this.serieTipiMiscEst = this.formController.getTrascodificaByCodice('SERIE_MISC_EST' , this.trascodifiche);
			this.modelListLime = this.formController.getTrascodificaByCodice('KIT_SERIE_LIME' , this.trascodifiche);
			this.modelListTango = this.formController.getTrascodificaByCodice('KIT_SERIE_TANGO' , this.trascodifiche);
			this.modelListStick = this.formController.getTrascodificaByCodice('KIT_SERIE_STICK' , this.trascodifiche);	
			this.modelListLight = this.formController.getTrascodificaByCodice('KIT_SERIE_LIGHT' , this.trascodifiche);			
			this.miscListLime = this.formController.getTrascodificaByCodice('MISC_SERIE_LIME' , this.trascodifiche);
			this.miscListTango = this.formController.getTrascodificaByCodice('MISC_SERIE_TANGO' , this.trascodifiche);
			this.miscListStick = this.formController.getTrascodificaByCodice('MISC_SERIE_STICK' , this.trascodifiche);
			this.miscListLight = this.formController.getTrascodificaByCodice('MISC_SERIE_LIGHT' , this.trascodifiche);
			this.colListLight = this.formController.getTrascodificaByCodice('COL_SERIE_LIGHT' , this.trascodifiche);
			if(this.form.sections.nuoviProdotti.nuovaRubinetteria.id !== null && this.form.sections.nuoviProdotti.nuovaRubinetteria.id !== undefined){
				this.changeSerie(this.form.sections.nuoviProdotti.nuovaRubinetteria.id);
			}	
	  });
  }

  resetdocciaType(event): void{
    if (this.form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatore) {
      delete this.form.sections.nuoviProdotti.nuovaRubinetteria.docciaType.miscelatore;
    }
  }

  changeSerie(event): void {
	this.subLavaboLime =  this.trascodifiche.filter(
      tr => (tr.codice === 'LIME.LAVABO') && (tr.parentId === event));
	this.subBidetLime = this.trascodifiche.filter(
      tr => (tr.codice === 'LIME.BIDET') && (tr.parentId === event));		
		if(this.subLavaboLime.length > 0 || this.subBidetLime.length > 0){
			this.viewSerieLime = true;
			this.viewSerieTango = false;
			this.viewSerieStick = false;
			this.viewSerieLight = false;
			this.viewSerieGrohe = false;
		}else{
			this.subLavaboTango = this.trascodifiche.filter(
      			tr => (tr.codice === 'TANGO.LAVABO') && (tr.parentId === event));
			this.subBidetTango = this.trascodifiche.filter(
      			tr => (tr.codice === 'TANGO.BIDET') && (tr.parentId === event)); 
			if(this.subLavaboTango.length > 0 || this.subBidetTango.length > 0){
				this.viewSerieTango = true;
				this.viewSerieLime = false;
				this.viewSerieStick = false;
				this.viewSerieLight = false;
				this.viewSerieGrohe = false;
			}else{
				this.subLavaboStick = this.trascodifiche.filter(
      			tr => (tr.codice === 'STICK.LAVABO') && (tr.parentId === event));

				this.subBidetStick = this.trascodifiche.filter(
      			tr => (tr.codice === 'STICK.BIDET') && (tr.parentId === event));

				if(this.subLavaboStick.length > 0 || this.subBidetStick.length > 0){
					this.viewSerieStick = true;
					this.viewSerieLime = false;
					this.viewSerieTango = false;
					this.viewSerieLight = false;
					this.viewSerieGrohe = false;
				}else{
					this.subLavaboLight = this.trascodifiche.filter(
      				tr => (tr.codice === 'LIGHT.LAVABO') && (tr.parentId === event));

					this.subBidetLight = this.trascodifiche.filter(
	      			tr => (tr.codice === 'LIGHT.BIDET') && (tr.parentId === event));
	
					if(this.subLavaboLight.length > 0 || this.subBidetLight.length > 0){
						this.viewSerieLight = true;
						this.viewSerieStick = false;
						this.viewSerieLime = false;
						this.viewSerieTango = false;
						this.viewSerieGrohe = false;
					}else {
						//check grohe
						this.subLavaboGrohe= this.trascodifiche.filter(
		      				tr => (tr.codice === 'GROHE.LAVABO') && (tr.parentId === event));
		
							this.subBidetGrohe = this.trascodifiche.filter(
			      			tr => (tr.codice === 'GROHE.BIDET') && (tr.parentId === event));

							this.viewSerieGrohe = true;
							this.viewSerieLight = false;
							this.viewSerieStick = false;
							this.viewSerieLime = false;
							this.viewSerieTango = false;
					}
				}
			}
		}
  }

  resetNuovaRub(event): void{
		this.viewSerieStick = false;
		this.viewSerieLime = false;
		this.viewSerieTango = false;
		this.viewSerieLight = false;
		this.viewSerieGrohe = false;
  }  

  setAstaSingola(event): void {
    console.log('event ', event);
    this.form.sections.nuoviProdotti.nuovaRubinetteria.astasingola = event.checked;
    /*if (this.form.sections.datitecnicivasca.murosx) {
      this.form.sections.datitecnicivasca.murodx = false;
    }*/
  }
  
  setAstaDoppia(event): void {
    console.log('event ', event);
    this.form.sections.nuoviProdotti.nuovaRubinetteria.astadoppia = event.checked;
    /*if (this.form.sections.datitecnicivasca.murosx) {
      this.form.sections.datitecnicivasca.murodx = false;
    }*/
  }

  showDiv(value){    
	if(value ){
	  this.isVisible = true;
	}else{
	  this.isVisible = false;
	}
	this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0]?.id)[0].selected = value;
   
  }

  getOption(){
	this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
		if(res !== undefined){
		  this.listOptionsOrder = res;
		  let optionNuovaRubinetteria = res.filter(op => op.typeId === this.options[0].id)  
		  	if(optionNuovaRubinetteria !== undefined && optionNuovaRubinetteria.length > 0){
				this.isVisible = optionNuovaRubinetteria[0].selected;
				this.form.sections.optionsAvailable=res;
				this.optionNuovaRubinetteria = optionNuovaRubinetteria[0];
		  	}else{
				this.isVisible = false;
				this.optionNuovaRubinetteria = new OptionsAvailable();
				this.optionNuovaRubinetteria.ordineId = this.form.idOrder;
				this.optionNuovaRubinetteria.typeId = this.options[0].id;
				this.optionNuovaRubinetteria.selected = undefined;
				this.form.sections.optionsAvailable.push(this.optionNuovaRubinetteria);
		  	}
		  }
	  });
   }
}
