<div class="row originalRow">
    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                Dettagli installazione
            </div>
            <div class="row" *ngIf="trascodifiche && TIPO_INSTALLAZIONE">
                <div class="col-3">
                    Installazione
                </div>
                <div class="col-9">
                    <mat-radio-group class="smalFt" [(ngModel)]="form.sections.accessori.installazioneId">
                        <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of TIPO_INSTALLAZIONE; let f = index;" [value]="tipo.id">
                            {{tipo.descrizione}}
                        </mat-radio-button>
		               	<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.accessori.installazioneId" (click)="form.sections.accessori.installazioneId = null">
		                      backspace
		                </span>                        
                    </mat-radio-group>
                    <div class="smallFont text-danger" *ngIf="!form.sections.accessori.installazioneId">
                        Campo obbligatorio
                    </div>
                </div>

            </div>
            <hr/>
            <div class="row" *ngIf="trascodifiche && USCITA_FUMI">
                <div class="col-3">
                    Uscita fumi
                </div>
                <div class="col-9">
                    <mat-radio-group class="smalFt" [(ngModel)]="form.sections.accessori.uscitafumiId">
                        <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of USCITA_FUMI; let f = index;" [value]="tipo.id">
                            {{tipo.descrizione}}
                        </mat-radio-button>
		               	<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.accessori.uscitafumiId" (click)="form.sections.accessori.uscitafumiId = null">
		                      backspace
		                </span>                        
                    </mat-radio-group>
                    <div class="smallFont text-danger" *ngIf="!form.sections.accessori.uscitafumiId">
                        Campo obbligatorio
                    </div>
                </div>

            </div>
            <hr/>
            <div class="row" *ngIf="trascodifiche && PRELIEVO_ARIA">
                <div class="col-3">
                    Prelievo d'aria
                </div>
                <div class="col-9">
                    <mat-radio-group class="smalFt" [(ngModel)]="form.sections.accessori.prelievoariaId">
                        <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of PRELIEVO_ARIA; let f = index;" [value]="tipo.id">
                            {{tipo.descrizione}}
                        </mat-radio-button>
		               	<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.accessori.prelievoariaId" (click)="form.sections.accessori.prelievoariaId = null">
		                      backspace
		                </span>                        
                    </mat-radio-group>
                    <div class="smallFont text-danger" *ngIf="!form.sections.accessori.prelievoariaId">
                        Campo obbligatorio
                    </div>
                </div>

            </div>
            <hr/>
            <div class="row" *ngIf="trascodifiche && SCARICO_CONDENSA">
                <div class="col-3">
                    Scarico condensa
                </div>
                <div class="col-9">
                    <mat-radio-group class="smalFt" [(ngModel)]="form.sections.accessori.scaricocondId">
                        <mat-radio-button color="primary" class="mr-2" *ngFor="let tipo of SCARICO_CONDENSA; let f = index;" [value]="tipo.id">
                            <span class="d-block">{{tipo.descrizione}}</span>
                        </mat-radio-button>
		               	<span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.accessori.scaricocondId" (click)="form.sections.accessori.scaricocondId = null">
		                      backspace
		                </span>                        
                    </mat-radio-group>
                    <div class="smallFont text-danger" *ngIf="!form.sections.accessori.scaricocondId">
                        Campo obbligatorio
                    </div>
                    <div class="smallFont pt-2">
                        è un tubo corrugato, l'annegamento del muro è a carico del cliente.
                    </div>
                </div>

            </div>
            <hr/>
            <div class="row" *ngIf="trascodifiche">
                <div class="col-12">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Note</mat-label>
                        <textarea matInput [(ngModel)]="form.sections.accessori.note"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer" [ngClass]="{'onlyRedBorder': !formController.validCannaFumaria(form)}">
            <div class="text-left text-success font-weight-bold mb-4">
                Accessori
            </div>
            <div class="">
                <mat-checkbox color="primary" class="smalFt" [(ngModel)]="form.sections.accessori.lavaggioimp">Lavaggio impianti</mat-checkbox>
            </div>
            <div class="">
                <div>Intubazione canna fumaria n° piani.</div>
                <app-input-number
                        (onNumChange)="form.sections.accessori.intubcanna = $event"
                        [step]="1"
                        [num]="form.sections.accessori.intubcanna"
                        [decimal]="false"
                        [label]="'Intubazione canna fumaria n° piani.'"></app-input-number>
                <div class="mb-3 smallFont" *ngIf="validCannaFumaria()">* obbligatoria quanto la canna fumaria è singola</div>
                <div class="mb-3 smallFont text-danger" *ngIf="!validCannaFumaria()">
                    * obbligatoria quanto la canna fumaria è singola
                </div>
            </div>
            <div class="mb-3">
                <div>Accesso al tetto</div>
                <mat-radio-group class="smalFt" [(ngModel)]="form.sections.accessori.accessotetto" (change)="accessoTetto($event)" >
                    <mat-radio-button color="primary" class="mr-2" [value]="true">
                        <span class="d-block">Si</span>
                    </mat-radio-button>
                    <mat-radio-button color="primary" class="mr-2" [value]="false">
                        <span class="d-block">No</span>
                    </mat-radio-button>
                    <div class="smallFont text-danger" *ngIf="form.sections.accessori.accessotetto == null">
                        selezione obbligatoria
                    </div>
                    <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.accessori.accessotetto != null" (click)="form.sections.accessori.accessotetto = null">
                          backspace
                    </span>
                </mat-radio-group>
            </div>
			<div class="mb-3" *ngIf="!form.sections.accessori.accessotetto">
                <mat-checkbox color="primary" class="smalFt mr-4" [(ngModel)]="form.sections.accessori.cestaidr" (change)="cestaIdraulica($event)">
                    Cesta idraulica                    
                    <div class="smallFont text-danger" *ngIf="form.sections.accessori && !form.sections.accessori.cestaidr && !form.sections.accessori.ponteggio && !form.sections.accessori.accessotetto">
                        selezione obbligatoria
                    </div>
                </mat-checkbox>
                <mat-checkbox color="primary" class="smalFt" [(ngModel)]="form.sections.accessori.ponteggio" (change)="ponteggio($event)">
                    Ponteggio
                    <div class="smallFont text-danger" *ngIf="form.sections.accessori && !form.sections.accessori.cestaidr && !form.sections.accessori.ponteggio && !form.sections.accessori.accessotetto">
                        selezione obbligatoria
                    </div>
                </mat-checkbox>
                <div class="smallFont">Nel caso non ci sia accesso al tetto per intubare la canna fumaria</div>
                <mat-checkbox color="primary" class="smalFt" [(ngModel)]="form.sections.accessori.suoloPubblico">
                    Suolo pubblico
                </mat-checkbox>
            </div>
            
            <div class="">
                <mat-checkbox (ngModelChange)="reserCronoWifiQta($event)" color="primary" class="smalFt" [(ngModel)]="form.sections.accessori.cronowifi">Cronotermostato WI-FI</mat-checkbox>
            </div>
            <div class="mb-3" *ngIf="form.sections.accessori.cronowifi">
                <app-input-number
                        (onNumChange)="form.sections.accessori.numcronowifi = $event"
                        [step]="1"
                        [num]="form.sections.accessori.numcronowifi"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="Qt.à"></app-input-number>
                <div class="smallFont text-danger" *ngIf="form.sections.accessori.cronowifi && !form.sections.accessori.numcronowifi">
                    Campo obbligatorio
                </div>

            </div>
            <div class="">
                <mat-checkbox (ngModelChange)="reserCronoStandQta($event)" color="primary" class="smalFt" [(ngModel)]="form.sections.accessori.cronostand">Cronotermostato standard</mat-checkbox>
            </div>
            <div class="mb-3" *ngIf="form.sections.accessori.cronostand">
                <app-input-number
                        (onNumChange)="form.sections.accessori.numcronostand = $event"
                        [step]="1"
                        [num]="form.sections.accessori.numcronostand"
                        [decimal]="false"
                        [payment]="false"
                        [small]="true"
                        label="Qt.à"></app-input-number>
                <div class="smallFont text-danger" *ngIf="form.sections.accessori.cronostand && !form.sections.accessori.numcronostand">
                    Campo obbligatorio
                </div>

            </div>
            
            <div class="">
                <mat-form-field class="full-width">
                    <mat-label>Valvola termostatica n°</mat-label>
                    <input matInput type="number" min="0" step="1" [(ngModel)]="form.sections.accessori.valvolatermo">
                </mat-form-field>
                <div class="smallFont text-danger" *ngIf="!form.sections.accessori.valvolatermo">
                    Campo obbligatorio
                </div>
                <div class="mb-3 smallFont">
                    Necessita foto radiatori più valvole<br/>
                    per avere la Detrazione Fiscale del 65% si ricorda che la valvola termostatica deve essere
                    applicata su ogni radiatore presente nell’abitazione e che serve un cronotermostato
                </div>
            </div>
            <div class="">
                <mat-checkbox color="primary" class="smalFt" [(ngModel)]="form.sections.accessori.pompacond">
                    Pompa per condensa
                    <div class="smallFont">utile quando lo scarico della condensa supera 3 mt o siamo in assenza di pendenza</div>
                </mat-checkbox>
            </div>
            <div class="">
                <mat-checkbox color="primary" class="smalFt" [(ngModel)]="form.sections.accessori.nokalzone">
                    Dispositivo NOKAL ZONE
                </mat-checkbox>
            </div>
            <div class="">
                <mat-checkbox color="primary" class="smalFt" [(ngModel)]="form.sections.accessori.abbattitore">
                    Abbattitore di acidità
                    <div class="smallFont">obbligatorio solo quando si scarica la condensa in pluviale</div>
                </mat-checkbox>
            </div>
        </div>
    </div>
</div>
