<div class="text-center bagnoPraticoBg row">
    <div class="col-6 text-right">NUOVO PIATTO DOCCIA
        <mat-hint class="text-danger">*</mat-hint>
    </div>
    <div class="col-6 text-left">
        <mat-radio-group labelPosition="after" class="inline" color="accent" [(ngModel)]="optionPiattoDoccia.selected"
            (ngModelChange)="showDivPiattoDoccia($event)">
            <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
            <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
        </mat-radio-group>
    </div>

</div>
<br>
<app-piatto-effetto-pietra-pratico [form]="form" [trascodifiche]="trascodifiche" *ngIf="isVisible">
</app-piatto-effetto-pietra-pratico>

