import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  catchError,
  retry,
} from 'rxjs/operators';
import { HttpService } from '../http.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProdottoService extends HttpService{

  constructor(
      public router: Router,
      public http: HttpClient
  ) {
    super(router, http);
  }

  getProdottos(data , key): Observable<any> {
	if(key === null || key === undefined){
	    return this.http
           .get<any>(this.apiUrl + 'prodottos', this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
           );
	}else{
	    return this.http
           .get<any>(this.apiUrl + 'prodottos?'+ key + data, this.setHttpOptions())
           .pipe(
               retry(0),
               catchError(this.handleError)
           );
	}

  }

}
