<div class="row originalRow">
    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
                DETTAGLI INSTALLAZIONE
            </div>

            <div *ngIf="trascodifiche">
                <div class="mb-2">
                   		<mat-checkbox  color="primary" [(ngModel)]="form.sections.installazioneAcc.installazioneconpred" [checked]="true" (ngModelChange)="controlloInstPred($event)">Installazione con predisposizione</mat-checkbox>
                        <app-input-number
                                (onNumChange)="form.sections.installazioneAcc.qtainstconpred = $event"
                                *ngIf="form.sections.installazioneAcc.installazioneconpred"
                                [step]="1"
                                [num]="form.sections.installazioneAcc.qtainstconpred"
                                [decimal]="false"
                                [payment]="false"
                                label="Qt.à"></app-input-number>
                        <div class="smallFont text-danger" *ngIf="form.sections.installazioneAcc.installazioneconpred && !form.sections.installazioneAcc.qtainstconpred">
                            Campo obbligatorio
                        </div>
                </div>
                <div class="mb-5">
                     <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.installazionesenzapred" [checked]="true" (ngModelChange)="controlloInstSenza($event)">Installazione senza predisposizione</mat-checkbox>
                     <app-input-number
                                (onNumChange)="form.sections.installazioneAcc.qtainstsenzapred = $event"
                                *ngIf="form.sections.installazioneAcc.installazionesenzapred"
                                [step]="1"
                                [num]="form.sections.installazioneAcc.qtainstsenzapred"
                                [decimal]="false"
                                [payment]="false"
                                label="Qt.à"></app-input-number>
                        <div class="smallFont text-danger" *ngIf="form.sections.installazioneAcc.installazionesenzapred && !form.sections.installazioneAcc.qtainstsenzapred">
                            Campo obbligatorio
                        </div>
                     
                </div>
                <div class="mb-2">
                     <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.staffeue" (ngModelChange)="controlloStaffe($event)" [checked]="true">Staffe U.E</mat-checkbox>
                </div>

                <div class="row originalRow mt-3" *ngIf="form.sections.installazioneAcc.staffeue">
                    <div class="col-8">
                        <mat-form-field class="full-width">
                            <mat-label>Posizione U.E</mat-label>
                            <mat-select [(ngModel)]="form.sections.installazioneAcc.posizioneueId">
                                <mat-option>-- Posizione U.E --</mat-option>
                                <mat-option *ngFor="let option of listaPosizioneUE" [value]="option.id">{{option.descrizione}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-4">
                        <app-input-number
                                (onNumChange)="form.sections.installazioneAcc.qtaue = $event"
                                [step]="1"
                                [num]="form.sections.installazioneAcc.qtaue"
                                [decimal]="false"
                                [payment]="false"
                                label="Qt.à"></app-input-number>
                        <div class="smallFont text-danger" *ngIf="form.sections.installazioneAcc.posizioneueId && !form.sections.installazioneAcc.qtaue">
                            Campo obbligatorio
                        </div>
                    </div>
                </div>

                <!--
                <mat-form-field class="col-4" *ngIf="form.sections.installazioneAcc.staffeue">
	                <mat-label>Qt.à</mat-label>
	                <input matInput type="number" min="1" placeholder="" [(ngModel)]="form.sections.installazioneAcc.qtaue">
	                <mat-hint class="text-danger" *ngIf="form.sections.installazioneAcc.posizioneueId && !form.sections.installazioneAcc.qtaue">
	                    Campo obbgligatorio
	                </mat-hint>
	            </mat-form-field>
	            -->
             
            </div>

        </div>
    </div>

    <div class="col-lg-6 mb-4">
        <div class="anagraficaContainer">
            <div class="text-left text-success font-weight-bold mb-4">
               ACCESSORI
            </div>
            <div *ngIf="trascodifiche">
                <div class="">
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.smontaggiovecchioimp" [checked]="true">Smontaggio vecchio impianto</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox color="primary" (ngModelChange)="controlloPompaSenza($event)" [(ngModel)]="form.sections.installazioneAcc.pompacaricocondensa" [checked]="true">Pompa scarico condensa</mat-checkbox>
                    </div>
                    <div class="mb-3" *ngIf="form.sections.installazioneAcc.pompacaricocondensa">
                        <app-input-number
                                (onNumChange)="form.sections.installazioneAcc.numpompecondensa = $event"
                                [step]="1"
                                [num]="form.sections.installazioneAcc.numpompecondensa"
                                [decimal]="false"
                                [payment]="false"
                                [small]="true"
                                label="Qt.à"></app-input-number>
                        <div class="smallFont text-danger" *ngIf="form.sections.installazioneAcc.pompacaricocondensa && !form.sections.installazioneAcc.numpompecondensa">
                            Campo obbligatorio
                        </div>
                    </div>
					<div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.ponteggio" [checked]="true">Ponteggio da Mt.3 a Mt.6</mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.cestaidraulica" [checked]="true">Cesta idraulica oltre Mt.6</mat-checkbox>
                        <div class="smallFont mb-3">
                            (verificare sempre che le aree di accesso all'abitazione presentino degli spazi adeguati al passaggio e al posizionamento del mezzo)
                        </div>
                    </div>
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.suoloPubblico" [checked]="true">Suolo pubblico</mat-checkbox>
                    </div>
                    
                    <div class="d-inline-block mr-2">Kit linea idraulica Mt.3, tubazione frigorifera + canalina pvc</div>
                    <app-input-number
                            (onNumChange)="form.sections.installazioneAcc.kitlineaidr = $event"
                            [step]="1"
                            [num]="form.sections.installazioneAcc.kitlineaidr"
                            [decimal]="false"
                            [payment]="false"
                            [small]="true"
                            label="Qt.à"></app-input-number>
                    <!--
	                <mat-form-field class="d-inline-block smallerfield">
	                	<mat-label>Qt.à</mat-label>
	                    <input matInput type="number" min="0" [(ngModel)]="form.sections.installazioneAcc.kitlineaidr">
	                </mat-form-field>
                    -->

                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.aggiuntagas" [checked]="true">Aggiunta gas</mat-checkbox>
                    </div>
                    <!--
                   	<div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.contotermico" [checked]="true">Conto termico 2.0</mat-checkbox>
                        <div class="smallFont mb-3">
                           (per accedere al Conto Termico servono la foto del prodotto sostituito comprese le foto delle relative targhette identificative)
                        </div>
                    </div>
                    -->
                    <!--
                    <div>
                        <mat-checkbox color="primary" [(ngModel)]="form.sections.installazioneAcc.asservazione" [checked]="true">Asservazione</mat-checkbox>
                        <div class="smallFont mb-3">
                           (necessaria per il Conto Termico in caso di superamento del 10% della potenza del prodotto sostituito)
                        </div>
                    </div>
                    -->
                    
                    <div class="d-inline-block mr-2">Fori muro</div>

                    <app-input-number class="mr-2"
                            (onNumChange)="form.sections.installazioneAcc.forimuroqta = $event"
                            [step]="1"
                            [num]="form.sections.installazioneAcc.forimuroqta"
                            [decimal]="false"
                            [payment]="false"
                            [small]="true"
                            label="Qt.à"></app-input-number>

                    <app-input-number
                            (onNumChange)="form.sections.installazioneAcc.forimurocm = $event"
                            [step]="1"
                            [num]="form.sections.installazioneAcc.forimurocm"
                            [decimal]="false"
                            [payment]="false"
                            [small]="true"
                            label="cm"></app-input-number>
                    <!--
	                <mat-form-field class=" d-inline-block smallerfield mr-4">
	                	<mat-label>Qt.à</mat-label>
	                    <input matInput type="number" min="0" [(ngModel)]="form.sections.installazioneAcc.forimuroqta">
	                </mat-form-field>
                     
                    <mat-form-field class=" d-inline-block smallerfield">
                    	<mat-label>cm</mat-label>
	                    <input matInput type="number" min="0" [(ngModel)]="form.sections.installazioneAcc.forimurocm">
	                </mat-form-field>
                    -->
                </div>
            </div>

        </div>
    </div>
</div>
<div class="row originalRow">
    <div class="col-12 mb-4">
        <div class="anagraficaContainer">
	         <div>
	             <mat-form-field class="full-width" appearance="fill">
	                 <mat-label>Note</mat-label>
	                 <textarea matInput placeholder="Note..." [(ngModel)]="form.sections.installazioneAcc.note"></textarea>
	             </mat-form-field>
	         </div>
        </div>
    </div>        
</div>
