import { Component, Input, OnInit } from '@angular/core';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { NuoviSanDTO } from '../../main-bagni/classes/dati-tecnici';
import { SanitariService } from '../../main-bagni/services/sanitari/sanitari.service';

@Component({
  selector: 'app-nuovi-sanitari-pratico',
  templateUrl: './nuovi-sanitari-pratico.component.html',
  styleUrls: ['./nuovi-sanitari-pratico.component.scss']
})
export class NuoviSanitariPraticoComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];

  isVisible = false;
  options: Trascodifica[];
  optionNuoviSanitariOmaggio: OptionsAvailable = { id: undefined, typeId: undefined, ordineId: undefined, selected: false };
  listOptionsOrder: OptionsAvailable[];

  avaModel = [];
  avaScarico = [];
  avaTipo = [];
  avaSanitari = [];
  constructor(public sanitariService: SanitariService,
    public formController: FormControllerService,
    private optionsService: OptionsAvailableService) { }

  ngOnInit(): void {
    this.options = this.formController.getTrascodificaByCodice('TYPE_OPTIONS', this.trascodifiche).filter(op => op.descrizione === 'NUOVI SANITARI');

    this.getOption(this.options[0].id).then(res => {
      if (res !== undefined && res.length > 0) {
        this.isVisible = res[0].selected;
        this.form.sections.optionsAvailable.push(res[0])

        this.form.sections.optionsAvailable = this.form.sections.optionsAvailable.filter((res: any) => res.id != undefined && res.id != null)
        this.optionNuoviSanitariOmaggio = res[0];
      } else {
        this.isVisible = false;
        this.optionNuoviSanitariOmaggio = new OptionsAvailable();
        this.optionNuoviSanitariOmaggio.ordineId = this.form.idOrder;
        this.optionNuoviSanitariOmaggio.typeId = this.options[0].id;
        this.optionNuoviSanitariOmaggio.selected = undefined;
        this.form.sections.optionsAvailable.push(this.optionNuoviSanitariOmaggio);
      }
    })

    this.form.sections.nuoviSanitariDto = [];

    this.sanitariService.getNuoviSan(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      if (res[0]) {
        this.form.sections.nuoviSanitariDto = res;
        for (let i = 0; i < this.form.sections.nuoviSanitariDto.length; i++) {
          this.avaModel.push(this.formController.getTrascodificaByCodice('MODELLO_SANITARI', this.trascodifiche));
          this.avaScarico.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_SCARICO') && (tr.parentId === this.form.sections.nuoviSanitariDto[i].modsanitariId)));
          this.avaSanitari.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_NUOVI_SANITARI') && (tr.parentId === this.form.sections.nuoviSanitariDto[i].tiposcaricoId)));
          this.avaTipo.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_PRODOTTO') && (tr.parentId === this.form.sections.nuoviSanitariDto[i].tiposanitariId)));
        }
      }
    });
  }

  /* getOption() {
     this.optionsService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
       if (res !== undefined) {
         this.listOptionsOrder = res;
         let optionNuoviSanitariOmaggio = res.filter(op => op.typeId === this.options[0].id)
         if (optionNuoviSanitariOmaggio !== undefined && optionNuoviSanitariOmaggio.length > 0) {
           this.isVisible = optionNuoviSanitariOmaggio[0].selected;
           this.form.sections.optionsAvailable = res;
           this.optionNuoviSanitariOmaggio = optionNuoviSanitariOmaggio[0];
 
         } else {
           this.isVisible = false;
           this.optionNuoviSanitariOmaggio = new OptionsAvailable();
           this.optionNuoviSanitariOmaggio.ordineId = this.form.idOrder;
           this.optionNuoviSanitariOmaggio.typeId = this.options[0].id;
           this.optionNuoviSanitariOmaggio.selected = undefined;
           this.form.sections.optionsAvailable.push(this.optionNuoviSanitariOmaggio);
 
         }
       }
     });
   }*/

  getOption(idOption: any): Promise<any> {

    return new Promise((resolve, reject) => {
      this.optionsService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
        if (res !== undefined) {
          this.listOptionsOrder = res;
          let optionBoxDoccia = res.filter(op => op.typeId === idOption)
          resolve(optionBoxDoccia);
        }
      });
    });
  }


  showDiv(value) {
    if (value) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
    let op: OptionsAvailable =   this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0].id)[0];
    if(op !== undefined){
      op.selected = value;
    }
  }

  addSan(): void {
    this.form.sections.nuoviSanitariDto.push(new NuoviSanDTO());
    this.avaModel.push(this.formController.getTrascodificaByCodice('MODELLO_SANITARI', this.trascodifiche));
    this.avaScarico.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_SCARICO')));
    this.avaSanitari.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_NUOVI_SANITARI')));
    this.avaTipo.push(this.trascodifiche.filter(tr => (tr.codice === 'TIPO_PRODOTTO')));
  }

  removeSan(i): void {
    if (this.form.sections.nuoviSanitariDto[i].id) {
      this.sanitariService.deleteNuoviSan(this.form.sections.nuoviSanitariDto[i].id).subscribe();
    }
    this.form.sections.nuoviSanitariDto.splice(i, 1);
    this.avaModel.splice(i, 1);
    this.avaScarico.splice(i, 1);
    this.avaSanitari.splice(i, 1);
    this.avaTipo.splice(i, 1);
  }

  changeModel(event, i): void {
    this.avaScarico[i] = this.trascodifiche.filter(tr => (tr.codice === 'TIPO_SCARICO') && (tr.parentId === event));
    delete this.form.sections.nuoviSanitariDto[i].tiposcaricoId;
    delete this.form.sections.nuoviSanitariDto[i].tiposanitariId;
    delete this.form.sections.nuoviSanitariDto[i].tipoprodottoId;
  }

  changeScarico(event, i): void {
    this.avaSanitari[i] = this.trascodifiche.filter(tr => (tr.codice === 'TIPO_NUOVI_SANITARI') && (tr.parentId === event));
    delete this.form.sections.nuoviSanitariDto[i].tiposanitariId;
    delete this.form.sections.nuoviSanitariDto[i].tipoprodottoId;
  }

  changeSanitari(event, i): void {
    delete this.form.sections.nuoviSanitariDto[i].tipoprodottoId;
    this.avaTipo[i] = this.trascodifiche.filter(tr => (tr.codice === 'TIPO_PRODOTTO') && (tr.parentId === event));
  }
}
