import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { MaterialModule } from './modules/material/material.module';
import { FormsModule } from '@angular/forms';
import { RegistrationComponent } from './registration/registration.component';
import { ForgotComponent } from './forgot/forgot.component';
import { HttpClientModule } from '@angular/common/http';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { FinishforgotComponent } from './finishforgot/finishforgot.component';
import { UseractivationComponent } from './useractivation/useractivation.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { SellerModule } from './modules/seller/seller.module';
import { OrdersModule } from './modules/orders/orders.module';
import { RemailModule } from './modules/seller/remail/remail.module';
import { TechDashboardComponent } from './main/tech-dashboard/tech-dashboard.component';
import { EstimateModule } from './modules/estimate/estimate.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    RegistrationComponent,
    ForgotComponent,
    FinishforgotComponent,
    UseractivationComponent,
    DashboardComponent,
    TechDashboardComponent,
  ],
  imports: [
    HttpClientModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    SellerModule,
    OrdersModule,
    RemailModule,
    EstimateModule
  ],
  providers: [
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000}},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
