import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { OpereIdraulicheService } from '../../main-bagni/services/opereIdrauliche/opereIdrauliche.service';

@Component({
  selector: 'app-piatto-effetto-pietra-pratico',
  templateUrl: './piatto-effetto-pietra-pratico.component.html',
  styleUrls: ['./piatto-effetto-pietra-pratico.component.scss']
})
export class PiattoEffettoPietraPraticoComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  listColore: Trascodifica[];
  listMisure: Trascodifica[];
  constructor(private opIdraulicheService: OpereIdraulicheService, private formController: FormControllerService) { }

  ngOnInit(): void {
    this.opIdraulicheService.findByKey(this.form.idOrder, 'ordineId.equals=', 'opereidrauliches').subscribe(res => {
      this.listColore = this.formController.getTrascodificaByCodice('COLORE_PIATTI_EFF_PIE', this.trascodifiche);
      this.form.sections.piattoEffettoPietra.coloreId = res[0] !== undefined ? res[0].colorepiateffpietraId : null;
      if (this.form.sections.piattoEffettoPietra.coloreId !== undefined) {
        this.changeModel(this.form.sections.piattoEffettoPietra.coloreId);
        this.form.sections.piattoEffettoPietra.dimensioneId = res[0] !== undefined ? res[0].dimpiatteffpietraId : null;
      }

    })

  }
  changeModel(event): void {
    if (this.form.sections.piattoEffettoPietra.codice) {
      delete this.form.sections.piattoEffettoPietra.codice;
    }
    this.listMisure = this.trascodifiche.filter(tr => (tr.codice === 'DIM_PIATTI_PIETR') && (tr.parentId === event));
  }

}
