<div class="col-12">
    <div class="text-center blueBg row">
        <div class="col-6 text-right">VASCA
            <mat-hint class="text-danger">*</mat-hint>
        </div>
        <div class="col-6 text-left">
            <mat-radio-group labelPosition="after" class="inline" color="primary" [(ngModel)]="optionVasca.selected"
                (ngModelChange)="showDiv($event)">
                <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
                <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>
<br>
<div class="row" *ngIf="isVisible">
    <div class="col-lg-4">

        <div id="headingOne">
            <h5 class="mb-0">
                <div class="text-center blueBg">
                    VASCA NOVA: RIVESTITA CON PANNELLI (Rubinett. Integrata)
                </div>

            </h5>
        </div>

        <div class="blueCont"
            [ngClass]="{'onlyRedBorder': form.sections.vascaVenezia.dimensions && (form.sections.vascaVenezia.hydro== null || form.sections.vascaVenezia.hydro==undefined)}">
            <div class="">
                <mat-radio-group (ngModelChange)="setCodeVenezia($event)" labelPosition="after" class="inline"
                    color="primary" aria-label="Tipo" [(ngModel)]="form.sections.vascaVenezia.dimensions">
                    <mat-radio-button class="mb-2 mr-2" [value]="dim.id" *ngFor="let dim of listDimVascaVenezia">
                        {{dim.descrizione}}</mat-radio-button>
                    <!-- <mat-radio-button class="mb-2 mr-2" value="NOV.170X80">170X70</mat-radio-button>
                                <mat-radio-button class="mr-2" value="NOV.180X80">180X80</mat-radio-button>
                                <mat-radio-button disabled class="mr-2" value="NOV.190X80">190x80</mat-radio-button> -->
                    <span class="undo material-icons" matTooltip="Cancella selezione"
                        *ngIf="form.sections.vascaVenezia.dimensions" (click)="removeVascaVenezia()">
                        backspace
                    </span>
                </mat-radio-group>
                <hr />
                <mat-radio-group [disabled]="!form.sections.vascaVenezia.dimensions"
                    (ngModelChange)="setCodeHVenezia($event)" labelPosition="after" class="inline" color="primary"
                    aria-label="Tipo" [(ngModel)]="form.sections.vascaVenezia.hydro">
                    <mat-radio-button class="mb-2 mr-2" [value]="true">Con idromassaggio
                    </mat-radio-button>
                    <mat-radio-button class="mr-2" [value]="false">Senza idromassaggio
                    </mat-radio-button>
                </mat-radio-group>
                <div class="smallFont text-danger"
                    *ngIf="form.sections.vascaVenezia.dimensions && (form.sections.vascaVenezia.hydro== null || form.sections.vascaVenezia.hydro==undefined)">
                    Selezione obbligatoria
                </div>
            </div>


        </div>

        <br>



        <div id="headingOne">
            <h5 class="mb-0">
                <div class="text-center blueBg">
                    VASCA ECUBE
                </div>

            </h5>
        </div>

        <div class="blueCont"
            [ngClass]="{'onlyRedBorder': form.sections.vascaEcube.dimensions && (form.sections.vascaEcube.seduta == null || form.sections.vascaEcube.seduta== undefined)}">
            <div class="">
                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                    [(ngModel)]="form.sections.vascaEcube.dimensions">
                    <mat-radio-button class="mb-2 mr-2" [value]="dim.id" *ngFor="let dim of listDimEcube">
                        {{dim.descrizione}}</mat-radio-button>
                    <!-- <mat-radio-button class="mb-2 mr-2" value="NOV.170X80">170X70</mat-radio-button>
                                    <mat-radio-button class="mr-2" value="NOV.180X80">180X80</mat-radio-button>
                                    <mat-radio-button disabled class="mr-2" value="NOV.190X80">190x80</mat-radio-button> -->
                    <span class="undo material-icons" matTooltip="Cancella selezione"
                        *ngIf="form.sections.vascaEcube.dimensions" (click)="removeVascaEcube()">
                        backspace
                    </span>
                </mat-radio-group>
                <hr />
                <mat-radio-group [disabled]="!form.sections.vascaEcube.dimensions" labelPosition="after" class="inline"
                    color="primary" aria-label="Tipo" [(ngModel)]="form.sections.vascaEcube.seduta">
                    <mat-radio-button class="mb-2 mr-2" value="SEDUTASX">Seduta Sx</mat-radio-button>
                    <mat-radio-button class="mr-2" value="SEDUTADX">Seduta Dx</mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione"
                        *ngIf="form.sections.vascaEcube.seduta" (click)="form.sections.vascaEcube.seduta = null">
                        backspace
                    </span>
                </mat-radio-group>
                <div class="smallFont text-danger"
                    *ngIf="form.sections.vascaEcube.dimensions && (form.sections.vascaEcube.seduta== null || form.sections.vascaEcube.seduta==undefined)">
                    Selezione obbligatoria
                </div>
            </div>
        </div>
        <br>


    </div>


    <div class="col-lg-4">
        <div id="headingOne">
            <h5 class="mb-0">
                <div class="text-center blueBg">
                    VASCA EXPRESS: RIVESTITA CON MURETTI PIASTRELLATI
                </div>
            </h5>
        </div>

        <div class="blueCont" [ngClass]="{'onlyRedBorder': !validVascaRoma()}">
            <div class="">
                <mat-radio-group (ngModelChange)="setCodeRoma($event)" labelPosition="after" class="d-block"
                    color="primary" aria-label="Tipo" [(ngModel)]="form.sections.vascaRoma.dimensions">
                    <mat-radio-button class="mb-2 mr-2" [value]="dim.id" *ngFor="let dim of listDimVascaRoma">
                        {{dim.descrizione}}
                        <sup>({{dim.id}})</sup>
                    </mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione"
                        *ngIf="form.sections.vascaRoma.dimensions" (click)="form.sections.vascaRoma = {}">
                        backspace
                    </span>
                </mat-radio-group>
                <hr />
                <mat-radio-group [disabled]="!form.sections.vascaRoma.dimensions"
                    (ngModelChange)="setCodeSVenezia($event)" labelPosition="after" class="inline" color="primary"
                    aria-label="Tipo" [(ngModel)]="form.sections.vascaRoma.seduta">
                    <mat-radio-button class="mb-2 mr-2" value="SEDUTASX">Seduta Sx</mat-radio-button>
                    <mat-radio-button class="mr-2" value="SEDUTADX">Seduta Dx</mat-radio-button>
                    <span class="undo material-icons" matTooltip="Cancella selezione"
                        *ngIf="form.sections.vascaRoma.seduta" (click)="form.sections.vascaRoma.seduta = null">
                        backspace
                    </span>
                </mat-radio-group>
                <div class="smallFont text-danger" *ngIf="form.sections.vascaRoma.dimensions &&
                                 !form.sections.vascaRoma.seduta &&
                                 (form.sections.vascaRoma.dimensions == 1337 ||
                                 form.sections.vascaRoma.dimensions == 1339 ||
                                 form.sections.vascaRoma.dimensions == 100 ||
                                 form.sections.vascaRoma.dimensions == 102)">
                    Selezione obbligatoria
                </div>
                <div class="smallFont text-danger" *ngIf="form.sections.vascaRoma.dimensions &&
                                 form.sections.vascaRoma.seduta && (
                                 form.sections.vascaRoma.dimensions === 1338 ||
                                 form.sections.vascaRoma.dimensions === 1340 ||
                                form.sections.vascaRoma.dimensions === 103 ||
                                form.sections.vascaRoma.dimensions === 104 ||
                                form.sections.vascaRoma.dimensions === 105 ||
                                form.sections.vascaRoma.dimensions === 106 ||
                                 form.sections.vascaRoma.dimensions === 108)">
                    Selezione NON consentita
                </div>
            </div>


        </div>

    </div>

    <div class="col-lg-4">



        <div id="headingOne">
            <h5 class="mb-0">
                <div class="text-center blueBg">
                    VASCA OPEN: VASCA CON SPORTELLO ESTRAIBILE
                </div>
            </h5>
        </div>

        <div class="blueCont"
            [ngClass]="{'onlyRedBorder': (!viewSedutaVerona && form.sections.vascaVerona.dimensions && (!form.sections.vascaVerona.scarico || !form.sections.vascaVerona.sportello)) || viewSedutaVerona && form.sections.vascaVerona.dimensions && (!form.sections.vascaVerona.scarico || !form.sections.vascaVerona.sportello || (form.sections.vascaVerona.veronaseduta && !form.sections.vascaVerona.seduta))}">
            <div class="">
                <mat-radio-group (ngModelChange)="setCodeVerona($event);" labelPosition="after" class="inline"
                    color="primary" aria-label="Tipo" [(ngModel)]="form.sections.vascaVerona.dimensions">
                    <mat-radio-button class="mb-2 mr-2" [value]="dim.id" *ngFor="let dim of listDimVascaVerona">
                        {{dim.descrizione}}
                        <sup>({{dim.id}})</sup>
                    </mat-radio-button>
                    <!-- <mat-radio-button class="mb-2 mr-2" value="VASCA.VERONA.120X70">120X70</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="VASCA.VERONA.140X65">140X65</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="VASCA.VERONA.140X70">140X70</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="VASCA.VERONA.160X70">160X70</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="VASCA.VERONA.160X65">160X65</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="VASCA.VERONA.170X70">170X70</mat-radio-button> -->

                    <span class="undo material-icons" matTooltip="Cancella selezione"
                        *ngIf="form.sections.vascaVerona.dimensions" (click)="form.sections.vascaVerona = {}">
                        backspace
                    </span>
                </mat-radio-group>
                <hr />
                <mat-radio-group [disabled]="!form.sections.vascaVerona.dimensions"
                    (ngModelChange)="setCodeSVerona($event)" labelPosition="after" class="inline" color="primary"
                    aria-label="Tipo" [(ngModel)]="form.sections.vascaVerona.scarico">
                    <mat-radio-button class="mb-2 mr-2" value="SCARICOSX">Scarico Sx</mat-radio-button>
                    <mat-radio-button class="mr-2" value="SCARICODX">Scarico Dx</mat-radio-button>
                </mat-radio-group>

                <hr />
                <mat-radio-group [disabled]="!form.sections.vascaVerona.scarico"
                    (ngModelChange)="setCodeSpVerona($event)" labelPosition="after" class="inline" color="primary"
                    aria-label="Tipo" [(ngModel)]="form.sections.vascaVerona.sportello">
                    <mat-radio-button class="mb-2 mr-2" [value]="sportello.id"
                        *ngFor="let sportello of veronaSportello">{{sportello.descrizione}}
                    </mat-radio-button>
                    <!-- <mat-radio-button class="mb-2 mr-2" value="SPORTELLOSX">Sportello Sx</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="SPORTELLOCENTRO">Sportello Centro</mat-radio-button>
                                <mat-radio-button class="mr-2" value="SPORTELLODX">Sportello Dx</mat-radio-button> -->
                </mat-radio-group>
                <hr />
                <div>Seduta</div>
                <mat-radio-group [hidden]="!viewSedutaVerona" (ngModelChange)="removeCodeSedVerona($event)"
                    labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                    [(ngModel)]="form.sections.vascaVerona.veronaseduta">
                    <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                    <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
                </mat-radio-group>
                <div class="smallFont text-danger mb-2"
                    *ngIf="form.sections.vascaVerona.dimensions && (form.sections.vascaVerona.veronaseduta == undefined || form.sections.vascaVerona.veronaseduta == null)">
                    Selezione obbligatoria
                </div>

                <mat-radio-group [disabled]="!form.sections.vascaVerona.sportello"
                    [hidden]="!viewSedutaVerona || !form.sections.vascaVerona.veronaseduta"
                    (ngModelChange)="setCodeSedVerona($event)" labelPosition="after" class="inline" color="primary"
                    aria-label="Tipo" [(ngModel)]="form.sections.vascaVerona.seduta">
                    <mat-radio-button class="mb-2 mr-2" value="SEDUTASX">Seduta Sx</mat-radio-button>
                    <mat-radio-button class="mr-2" value="SEDUTADX">Seduta Dx</mat-radio-button>
                </mat-radio-group>
                <div class="smallFont text-danger"
                    *ngIf="form.sections.vascaVerona.veronaseduta && !form.sections.vascaVerona.seduta">
                    Selezione obbligatoria
                </div>
            </div>


        </div>

    </div>





    <div class="col-12">

        <div id="headingOne">
            <h5 class="mb-0">
                <div class="text-center blueBg">
                    VASCA EMOTION
                </div>
            </h5>
        </div>

        <div class="blueCont"
            [ngClass]="{'onlyRedBorder': form.sections.vascaFirenze.dimensions && (!form.sections.vascaFirenze.sportello || (form.sections.vascaFirenze.hydro== null || form.sections.vascaFirenze.hydro==undefined) || !form.sections.vascaFirenze.misurapassaggioporta)}">
            <div class="">
                <mat-radio-group (ngModelChange)="setCodeFirenze($event)" labelPosition="after" class="inline"
                    color="primary" aria-label="Tipo" [(ngModel)]="form.sections.vascaFirenze.dimensions">
                    <mat-radio-button class="mb-2 mr-2" [value]="dim.id" *ngFor="let dim of listDimVascaFirenze">
                        {{dim.descrizione}}</mat-radio-button>
                    <!-- <mat-radio-button class="mb-2 mr-2" value="VASCA.VERONA.100X70">100X70</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="VASCA.VERONA.120X70">120X70</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="VASCA.VERONA.150X70">150X70</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="VASCA.VERONA.170X70">170X70</mat-radio-button> -->
                    <span class="undo material-icons" matTooltip="Cancella selezione"
                        *ngIf="form.sections.vascaFirenze.dimensions" (click)="form.sections.vascaFirenze = {}">
                        backspace
                    </span>
                </mat-radio-group>
                <hr />
                <mat-radio-group [disabled]="!form.sections.vascaFirenze.dimensions"
                    (ngModelChange)="setCodeSpFirenze($event)" labelPosition="after" class="inline" color="primary"
                    aria-label="Tipo" [(ngModel)]="form.sections.vascaFirenze.sportello">
                    <mat-radio-button class="mb-2 mr-2" [value]="sportello.id"
                        *ngFor="let sportello of firenzeSportello">{{sportello.descrizione}}
                    </mat-radio-button>
                    <!-- <mat-radio-button class="mb-2 mr-2" value="SPORTELLOSX">Sportello Sx</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="SPORTELLOCENTRO">Sportello Centro</mat-radio-button>
                                <mat-radio-button class="mr-2" value="SPORTELLODX">Sportello Dx</mat-radio-button> -->
                </mat-radio-group>
                <div class="smallFont text-danger"
                    *ngIf="form.sections.vascaFirenze.dimensions && !form.sections.vascaFirenze.sportello">
                    Selezione obbligatoria
                </div>
                <hr />
                <section class="example-section">
                    <mat-checkbox color="primary" class="mb-2 mr-2"
                        [checked]="form.sections.vascaFirenze.firenzepannellosx"
                        [(ngModel)]="form.sections.vascaFirenze.firenzepannellosx">Pannello sx
                    </mat-checkbox>
                    <mat-checkbox color="primary" class="mb-2 mr-2"
                        [checked]="form.sections.vascaFirenze.firenzepannellocentro"
                        [(ngModel)]="form.sections.vascaFirenze.firenzepannellocentro">Pannello centro
                    </mat-checkbox>
                    <mat-checkbox color="primary" class="mb-2 mr-2"
                        [checked]="form.sections.vascaFirenze.firenzepannellodx"
                        [(ngModel)]="form.sections.vascaFirenze.firenzepannellodx">Pannello dx
                    </mat-checkbox>
                </section>
                <hr />
                <span class="mr-2">Pannello attrezzato </span>
                <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="firenzepannelloattr"
                    [(ngModel)]="form.sections.vascaFirenze.firenzepannelloattr">
                    <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                    <mat-radio-button class="mb-2 mr-2" [value]="false">No</mat-radio-button>
                </mat-radio-group>
                <!--
                            <mat-radio-group [disabled]="!form.sections.vascaFirenze.sportello" (ngModelChange)="setCodePnFirenze($event)" labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.vascaFirenze.pannello">
                                <mat-radio-button class="mb-2 mr-2" [value]="sportello.id"  *ngFor="let sportello of firenzePannello">{{sportello.descrizione}}</mat-radio-button>	
                                <mat-radio-button class="mb-2 mr-2" value="PANNELLISX">Pannello Sx</mat-radio-button>
                                <mat-radio-button class="mb-2 mr-2" value="PANNELLIN">Nessun pannello</mat-radio-button>
                                <mat-radio-button class="mr-2" value="PANNELLIDX">Pannello Dx</mat-radio-button>
                            </mat-radio-group>
                            -->
                <hr />
                <mat-radio-group (ngModelChange)="setCodeIFirenze($event)" labelPosition="after" class="inline"
                    color="primary" aria-label="Tipo" [(ngModel)]="form.sections.vascaFirenze.hydro">
                    <mat-radio-button class="mb-2 mr-2" [value]="true">Con idromassaggio
                    </mat-radio-button>
                    <mat-radio-button class="mb-2 mr-2" [value]="false">Senza idromassaggio
                    </mat-radio-button>
                </mat-radio-group>
                <div class="smallFont text-danger"
                    *ngIf="form.sections.vascaFirenze.dimensions && (!form.sections.vascaFirenze.sportello || (form.sections.vascaFirenze.hydro== null || form.sections.vascaFirenze.hydro==undefined))">
                    Selezione obbligatoria
                </div>
                <hr />
                <div style="max-width:280px">
                    <app-input-number (onNumChange)="form.sections.vascaFirenze.misurapassaggioporta = $event"
                        [step]="1" [num]="form.sections.vascaFirenze.misurapassaggioporta" [decimal]="true"
                        [payment]="false" [small]="false" label="Misura passaggio porta cm:"></app-input-number>
                    <div class="smallFont text-danger"
                        *ngIf="form.sections.vascaFirenze.dimensions && !form.sections.vascaFirenze.misurapassaggioporta">
                        Campo obbligatorio
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>