<div id="accordionSecond" >
    <div>
      <div  id="headingOne"  class="text-center {{classBg}}">
        <h5 class="mb-0 row">
            <div class="col-6 text-right">
                {{brand === 'bagniHelp75' ? 'CASSETTA WC' : 'NUOVA CASSETTA WC'}}
                <mat-hint class="text-danger">*</mat-hint>
               </div>
               <div class="col-6 text-left"><mat-radio-group labelPosition="after" class="inline" color="{{brand === 'bagno-pratico' ? 'accent' : 'primary'}}"
                [(ngModel)]="optionNuovaCassetta.selected"
                (ngModelChange)="showDiv($event)">
                <mat-radio-button class="mb-2 mr-2" [value]="true">SI</mat-radio-button>
                <mat-radio-button class="mb-2 mr-2" [value]="false">NO</mat-radio-button>
            </mat-radio-group></div>
        

        
        </h5>
      </div>
  
      <div *ngIf="isVisible" id="collapseSecond" aria-labelledby="headingOne" data-parent="#accordionSecond">
        <div >
  
          

<div class="{{classContent}}" [ngClass]="{'onlyRedBorder':!formController.checkColor(form.sections.nuoviProdotti.nuovaCassettaWc , tipoCassettaWc) || !formController.checkType(form.sections.nuoviProdotti.nuovaCassettaWc , tipoCassettaWc)}">
    <div class="">
        <div class="">
            
                <div class="row">
                    <div class="col-lg-6">
						<mat-radio-group class="d-block" labelPosition="after" class="" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.nuoviProdotti.nuovaCassettaWc.modelId" (ngModelChange)="changeWcModel($event)">
                    		<mat-radio-button class="d-block mb-2 mr-2" [value]="cassettaWc.id"  *ngFor="let cassettaWc of tipoCassettaWc">
                                {{cassettaWc.descrizione}}
	                    		<div class="labelNote text-danger">
	                                {{cassettaWc.note}}
	                            </div>

                    		</mat-radio-button>
                    	
                            <span class="undo material-icons" matTooltip="Cancella selezione" *ngIf="form.sections.nuoviProdotti.nuovaCassettaWc.modelId" (click)="form.sections.nuoviProdotti.nuovaCassettaWc = {}">
                               backspace
                            </span>
                       </mat-radio-group>

                       <hr/>
                    </div>
                 

                    <div class="col-lg-6" *ngIf="brand !== 'bagniHelp75'">
                        <div class="mb-2">
                            <mat-checkbox color="primary" *ngFor="let tipo of tipoMonoliteWc" class="d-block mb-2" [checked]="form.sections.nuoviProdotti.nuovaCassettaWc.cassettamonolitewc" [(ngModel)]="form.sections.nuoviProdotti.nuovaCassettaWc.cassettamonolitewc">{{tipo.descrizione}}</mat-checkbox>
                            <mat-checkbox color="primary" *ngFor="let tipo of tipoMonoliteBidet" class="d-block" [checked]="form.sections.nuoviProdotti.nuovaCassettaWc.cassettamonolitebidet" [(ngModel)]="form.sections.nuoviProdotti.nuovaCassettaWc.cassettamonolitebidet">{{tipo.descrizione}}</mat-checkbox>
                        </div>
                        <div class="mb-2" *ngIf="form.sections.nuoviProdotti.nuovaCassettaWc.modelId === colorePlaccawc[0].parentId">
                            <mat-radio-group [disabled]="!form.sections.nuoviProdotti.nuovaCassettaWc.modelId" labelPosition="after" class="inline" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.nuoviProdotti.nuovaCassettaWc.placcaColorId" required>
                                <mat-radio-button class="mb-2 mr-2" [value]="colorePlacca.id"  *ngFor="let colorePlacca of colorePlaccawc">{{colorePlacca.descrizione}}</mat-radio-button>
                                
                                <span *ngIf="form.sections.nuoviProdotti.nuovaCassettaWc.placcaColorId || form.sections.nuoviProdotti.nuovaCassettaWc.placcaTypeId" class="undo material-icons" matTooltip="Cancella selezione" (click)="removePlacca()">
                                    backspace
                                </span>                               
                            </mat-radio-group>
                            <mat-hint class="text-danger" *ngIf="!formController.checkColor(form.sections.nuoviProdotti.nuovaCassettaWc, tipoCassettaWc)">Colore richiesto</mat-hint>
                        </div>
                        <hr/>
                        <div [ngClass]="{'mb-2': form.sections.nuoviProdotti.nuovaCassettaWc.placcaColorId || form.sections.nuoviProdotti.nuovaCassettaWc.placcaTypeId}" *ngIf="form.sections.nuoviProdotti.nuovaCassettaWc.modelId === colorePlaccawc[0].parentId">
                            <mat-radio-group [disabled]="!form.sections.nuoviProdotti.nuovaCassettaWc.modelId" labelPosition="after" class="d-block" color="primary" aria-label="Tipo" [(ngModel)]="form.sections.nuoviProdotti.nuovaCassettaWc.placcaTypeId">
                            	<mat-radio-button class="mb-2 mr-2" [value]="placcaWc.id"  *ngFor="let placcaWc of tipoPlaccawc">{{placcaWc.descrizione}}</mat-radio-button>
                                <!-- <mat-radio-button class="mb-2 mr-2" value="tonda">Placca tonda</mat-radio-button>
                                <mat-radio-button class="mr-2" value="quadrata">Placca quadrata</mat-radio-button> -->

                            </mat-radio-group>
                            <mat-hint class="text-danger" *ngIf="!formController.checkType(form.sections.nuoviProdotti.nuovaCassettaWc, tipoCassettaWc)">Tipo placca richiesto</mat-hint>
                        </div>
                    </div>
                </div>

           
        </div>
    </div>


</div>

  
        </div>
      </div>
    </div>
    
    </div>
  
  
  


