import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { NewOrderComponent } from './new-order/new-order.component';
import { MaterialModule } from '../material/material.module';
import { ArielModule } from './ariel/ariel.module';
import { BagniItalianiModule } from './bagni-italiani/bagni-italiani.module';
import { CreateOrderComponent } from '../shared/create-order/create-order.component';
import { MatSortModule } from '@angular/material/sort';
import { ZucchettiModule } from './zucchetti/zucchetti.module';
import { CristalBoxModule } from './cristalbox/cristalbox.module';
import it from '@angular/common/locales/it';
import { MondialModule } from './mondial/mondial.module';

registerLocaleData(it);
@NgModule({
  declarations: [
    NewOrderComponent,
    CreateOrderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ArielModule,
    BagniItalianiModule,
    ZucchettiModule,
    CristalBoxModule,
    MatSortModule,
    MondialModule
  ],
  exports: [
    NewOrderComponent,
    CreateOrderComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "it-IT" }, //replace "en-US" with your locale
    //otherProviders...
  ]
})
export class SellerModule { }
