import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnagraficaDTO, AnagraficaForm } from 'src/app/modules/classes/anagrafica';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { Ordine } from 'src/app/modules/classes/ordine';
import { BrandService } from 'src/app/services/brand/brand.service';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AnagraficaService } from '../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { DatiTecnici, DatiTecniciDTO, PagamentoDTO } from '../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { MontascaleDto } from 'src/app/modules/classes/zucchetti/montascale';
import { MontascaleService } from 'src/app/services/montascale/montascale.service';
import { DisegnoTecnico } from 'src/app/modules/classes/disegno-tecnico';
import { MontascaleMisureDto } from 'src/app/modules/classes/zucchetti/montascaleMisure';
import { DatiTecniciAttService } from '../../bagni-italiani/main-bagni/services/datiTecniciAtt/datiTecniciAtt.service';
import { MontascaleMisureService } from 'src/app/services/montascale-misure/montascale-misure.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { constants } from '../../../classes/constants';
import { DisegnoTecnicoService } from 'src/app/services/disegnoTecnico/disegno-tecnico.service';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
declare var jQuery: any;
import * as $ from 'jquery';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
@Component({
  selector: 'app-montascale',
  templateUrl: './montascale.component.html',
  styleUrls: ['./montascale.component.scss']
})
export class MontascaleComponent implements OnInit {

  validAnagrafica: boolean = false;
  preventivoFound: boolean = true;
  showAnagrafica: boolean = false;
  openSign: boolean = false;
  checkContractNumber: boolean = false;
  showModalPrint: boolean;

  idCliente: number;
  currentStep: number = 0;
  timeLeft: any = 60;

  form: any;
  obj1: any;
  obj2: any;
  error: any;
  originalForm: any; 
  isLoaded: boolean = false;
  isFinanziamentoPayment: boolean = false;

  totalSteps: number[];
  trascodifiche: Trascodifica[];
  listFinanziarie: Trascodifica[];
  attivitaLavList: Trascodifica[] = [];
  datiTecnici: DatiTecniciDTO;
  rilievoMisureSchiena: MontascaleMisureDto;
  
  idOptionalHomeGlideTrascodifiche: number[] = [];  
  idOptionalFlowXOptSedileTrascodifiche: number[] = []; 
  idOptionalFlowXVarieTrascodifiche: number[] = []; 
  ordine: Ordine;
  anagrafica: AnagraficaDTO;

  finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/zucchetti-logo_1.jpg', product: 'montascale', brand: 'Zucchetti'}

  //utilizzata per verificare se almeno uno dei valori nelle proprietà di un oggetto è diverso da null e undefined.
  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');

  constructor(
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private anagraficaService: AnagraficaService,
    private orderService: OrdersService,
    private formController: FormControllerService,
    private brandService: BrandService,
    private paymentService: PaymentService,
    private optionsAvailableService: OptionsAvailableService,
    private datiTecniciService: DatiTecniciAttService,
    private montascaleService: MontascaleService,
    private montascaleMisureService: MontascaleMisureService,
    private disegnoService: DisegnoTecnicoService,
    private allegatoService: AllegatoService,
  ) {
    this.validAnagrafica = false;
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get('id')),
      anagrafica: new AnagraficaForm(),
      sections: {
        montascale: new MontascaleDto(),
        montascaleMisure: new MontascaleMisureDto(),
        homeGlideOptionsAvailable: [],
        flowXSedileOptionsAvailable: [],
        flowXVarieOptionsAvailable: [],
        datiTecnici: new DatiTecnici(),
        disegno: [new DisegnoTecnico()],
        pagamento: [new PagamentoDTO()],
        finanziamentoSections: {
					finanziamento: new Finanziamento(),
					finAnagrafica: new FinAnagrafica(),
					finDatiOccupazione: new FinDatiOccupazione(),
					finAnagraficaGarante: new FinAnagrafica(),
					finDatiOccupazioneGarante: new FinDatiOccupazione(),
					isAllegatoSaved: false
				},
      },
      ordine: null
    }
    this.totalSteps = Array(12).fill(1).map((x, i) => i); // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
  }

  ngOnInit(): void {
    
    this.originalForm = JSON.parse(JSON.stringify(this.form));
    if (localStorage.getItem('id_client') !== null) {
      this.idCliente = Number(localStorage.getItem('id_client'));
      this.form.anagrafica.anagrafica.id = this.idCliente;
    }

    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    this.getOrder(this.form.idOrder);
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
      localStorage.setItem('id_product', res.prodottoId);

      if (res.clienteId !== undefined && res.clienteId !== null) {
        localStorage.setItem('id_client', res.clienteId);
        this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
      }

      localStorage.setItem('id_order', res.idordine);
    });

    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);

      this.trascodifiche.forEach(el => {
        switch(el.codice){
          case constants.OPTIONAL_HOME_GLIDE: {
            this.idOptionalHomeGlideTrascodifiche.push(el.id);
          }
          break;
          case constants.OPTIONAL_FLOW_X_OPT_SEDILE: {            
            this.idOptionalFlowXOptSedileTrascodifiche.push(el.id);
          }
          break;
          case constants.OPTIONAL_FLOW_X_VARIE: {
            this.idOptionalFlowXVarieTrascodifiche.push(el.id);
          }
          break;
        }
      })
      this.getMontascale();
      this.getHomeGlideOptions();
      this.getFlowXSedileOptions();
      this.getFlowXVarieOptions();
    });
    this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
    this.isLoaded = true;
  }

  saveModule(): void {
    $('body').removeClass('noScr');
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (this.currentStep === 0) {
      // Step salvataggio anagrafica
      this.anagrafica = this.form.anagrafica.anagrafica;
      if (this.anagrafica.id) {
        this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
        }, error => {
          console.error('Error => ', error);
          this.openSnackBar('Errore in fase di salvataggio del modulo');
        });
      } else {
        this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.form.anagrafica.anagrafica.id = res.id;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
        }, error => {
          console.error('Error => ', error);
          this.openSnackBar('Errore in fase di salvataggio del modulo');
        });
      }

        const saveHomeGlideOptionsPromise = new Promise<void>((resolve, reject) => {
          if (!this.form.sections.homeGlideOptionsAvailable.length) {
            // Primo popolamento dei record HOME GLIDE a false
            this.trascodifiche.find(el => {
              if (el.codice === constants.OPTIONAL_HOME_GLIDE) {
                let option: OptionsAvailable = {
                  id: null,
                  ordineId: this.form.idOrder,
                  typeId: el.id,
                  selected: false
                };
        
                this.optionsAvailableService.save(option).subscribe(
                  () => resolve(),
                  error => reject(error)
                );
              }
            });
          } else {
            resolve();
          }
        });
      
        Promise.all([saveHomeGlideOptionsPromise]).then(() => {
          this.getHomeGlideOptions();
        })
        .catch(error => {
          console.error('Errore durante il salvataggio delle opzioni HOME GLIDE:', error);
        });
    } else if (this.currentStep === 1) {
      for (let i = 0; i < this.form.sections.homeGlideOptionsAvailable.length; i++) {
          this.optionsAvailableService.update(this.form.sections.homeGlideOptionsAvailable[i]).subscribe();
      }

      const saveOptionSedileFlowX = new Promise<void>((resolve, reject) => {
        if (!this.form.sections.flowXSedileOptionsAvailable.length) {
          // Primo popolamento dei record OPTION SEDILE a false
          this.trascodifiche.find(el => {
            if (el.codice === constants.OPTIONAL_FLOW_X_OPT_SEDILE) {
              let option: OptionsAvailable = {
                id: null,
                ordineId: this.form.idOrder,
                typeId: el.id,
                selected: false
              };
      
              this.optionsAvailableService.save(option).subscribe(
                () => resolve(),
                error => reject(error)
              );
            }
          });
        } else {
          resolve();
        }
      });
    
      Promise.all([saveOptionSedileFlowX]).then(() => {
        this.getFlowXSedileOptions();
      })
      .catch(error => {
        console.error('Errore durante il salvataggio delle OPTIONAL SEDILE', error);
      });

      const saveVarieSedileFlowX = new Promise<void>((resolve, reject) => {
        if (!this.form.sections.flowXVarieOptionsAvailable.length) {
          // Primo popolamento dei record VARIE SEDILE a false
          this.trascodifiche.find(el => {
            if (el.codice === constants.OPTIONAL_FLOW_X_VARIE) {
              let option: OptionsAvailable = {
                id: null,
                ordineId: this.form.idOrder,
                typeId: el.id,
                selected: false,
              };
      
              this.optionsAvailableService.save(option).subscribe(
                () => resolve(),
                error => reject(error)
              );
            }
          });
        } else {
          resolve();
        }
      });
    
      Promise.all([saveVarieSedileFlowX]).then(() => {
        this.getFlowXVarieOptions();
      })
      .catch(error => {
        console.error('Errore durante il salvataggio delle OPTIONAL VARIE SEDILE', error);
      });
    } else if (this.currentStep === 2) {
      for (let i = 0; i < this.form.sections.flowXSedileOptionsAvailable.length; i++) {
        this.optionsAvailableService.update(this.form.sections.flowXSedileOptionsAvailable[i]).subscribe();
      }

      for(let i = 0; i < this.form.sections.flowXVarieOptionsAvailable.length; i++){
        this.optionsAvailableService.update(this.form.sections.flowXVarieOptionsAvailable[i]).subscribe();
      }

      this.form.sections.montascale.ordineId = this.form.idOrder;
        if (this.form.sections.montascale.id === null || this.form.sections.montascale.id === undefined) {
          this.montascaleService.save(this.form.sections.montascale).subscribe(res =>{
            this.form.sections.montascale = res;
          });
      } else {
          this.montascaleService.update(this.form.sections.montascale).subscribe();
      }
    } else if (this.currentStep === 3) {
        if (this.form.sections.montascale.id !== null || this.form.sections.montascale.id !== undefined) {
          this.montascaleService.update(this.form.sections.montascale).subscribe();
        }
    } else if (this.currentStep === 4) {
        if (this.form.sections.montascale.id !== null || this.form.sections.montascale.id !== undefined) {
          this.montascaleService.update(this.form.sections.montascale).subscribe();
        }
    }
      else if (this.currentStep === 5) {
        // Step salvataggio dati tecnici
        this.datiTecnici = this.formController.mappingFormToDatiTecniciDTO(this.form.sections.datiTecnici, new DatiTecniciDTO());
        if (this.hasValues(this.datiTecnici)) {
          this.datiTecnici.ordineId = this.form.idOrder;
          if (this.datiTecnici.id === null || this.datiTecnici.id === undefined) {
            this.datiTecniciService.save(JSON.stringify(this.datiTecnici)).subscribe(res => {
              // this.openSnackBar('Modulo salvato correttamente');
            }, error => {
              console.error('Error => ', error);
              this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
            });
          } else {
            this.datiTecniciService.update(JSON.stringify(this.datiTecnici)).subscribe(res => {
              // this.openSnackBar('Modulo aggiornato correttamente');
            }, error => {
              console.error('Error => ', error);
              this.formController.openSnackBar('Errore in fase di aggiornamento del modulo');
            }
         );
        }
      }
    } else if(this.currentStep === 6){
      this.rilievoMisureSchiena = this.form.sections.montascaleMisure;
      if (this.hasValues(this.rilievoMisureSchiena)) {
        this.rilievoMisureSchiena.ordineId = this.form.idOrder;
        if (this.rilievoMisureSchiena.id === null || this.rilievoMisureSchiena.id === undefined) {
          this.montascaleMisureService.save(this.rilievoMisureSchiena).subscribe(res => {
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
          });
        } else {
          this.montascaleMisureService.update(this.rilievoMisureSchiena).subscribe(res => {
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar('Errore in fase di aggiornamento del modulo');
          }
       );
      }
     }
    } else if(this.currentStep === 7){
      this.form.sections.disegno[0].ordineId = this.form.idOrder;
			if (this.form.sections.disegno[0].id === undefined) {
				this.disegnoService.save(JSON.stringify(this.form.sections.disegno[0])).subscribe();
			} else {
				this.disegnoService.update(JSON.stringify(this.form.sections.disegno[0])).subscribe();
			}
    }else if(this.currentStep === 8){
      // Step salvataggio pagamento
      if(this.hasValues(this.form.sections.pagamento[0])){
        this.form.sections.pagamento[0].ordineId = this.form.idOrder;
        const payment = this.form.sections.pagamento[0];
        if (payment.id === undefined || payment.id === null) {
          this.paymentService.save(JSON.stringify(payment)).subscribe((res:any) => {
            this.form.sections.pagamento[0] = res;
          });
        } else {
          this.paymentService.update(JSON.stringify(payment)).subscribe((res:any) => {
            this.form.sections.pagamento[0] = res;
            // se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
            if(res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
              this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
                allegati.forEach((allegato:any) => {
                  if(allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)){
                    this.allegatoService.delete(allegato.id).subscribe();
                  }
                })
              })
            }
          });
        }
      }
    }else if (this.currentStep === 9){
      //Step salvataggio privacy
      this.orderService.update(JSON.stringify( this.form.ordine)).subscribe();
    } else if(this.currentStep === 10) {
      this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
        this.anagrafica = res;
        // this.openSnackBar('Modulo salvato correttamente');
        this.ordine.clienteId = res.id;
        this.ordine.cognome = res.cognome;
        this.ordine.nome = res.nome;
      }, error => {
        console.error('Error => ', error);
        this.openSnackBar('Errore in fase di salvataggio del modulo');
      });			
    }
    this.setStep(this.currentStep + 1);
  }

  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
    if (this.obj1 !== this.obj2) {
      this.checkAnagrafica();
      this.originalForm = JSON.parse(JSON.stringify(this.form));
    }
  }

  private getMontascale(): void {
    this.montascaleService.findByKey(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      if(res && res.length){
        this.form.sections.montascale = res[0];
      }
    })
  }

  private getHomeGlideOptions():void{ 
		this.optionsAvailableService.findByKey(' ', 'typeId.in=' + this.idOptionalHomeGlideTrascodifiche.join(',') + '&ordineId.equals=' + this.form.idOrder).subscribe((res) => {
      this.form.sections.homeGlideOptionsAvailable = res;
		});
	}

  private getFlowXSedileOptions(): void {
    this.optionsAvailableService.findByKey(' ', 'typeId.in=' + this.idOptionalFlowXOptSedileTrascodifiche.join(',') + '&ordineId.equals=' + this.form.idOrder).subscribe((res) => {
      this.form.sections.flowXSedileOptionsAvailable = res;
		});
  }

  private getFlowXVarieOptions(): void {
    this.optionsAvailableService.findByKey(' ', 'typeId.in=' + this.idOptionalFlowXVarieTrascodifiche.join(',') + '&ordineId.equals=' + this.form.idOrder).subscribe((res) => {
      this.form.sections.flowXVarieOptionsAvailable = res;
		});
  }

  validPrivacy(): boolean {
    if(this.form.ordine === undefined || this.form.ordine === null){
     return false;
    } 
     if (!this.form.ordine.accettprivacy || !this.form.ordine.accettprivacynd || !this.form.ordine.accettprivacyrd) {
       return false;
     } else
       return true;
   }

  ngAfterViewInit(): void {
    console.log('origina form', this.originalForm);
  }

  setStep(num): void {
    this.currentStep = num;
    if (this.currentStep > 0){
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    if(this.form.sections.pagamento[0]!.tipopagsaldoId){
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if( tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(12).fill(1).map((x, i) => i);
			}else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(11).fill(1).map((x, i) => i);
			}
		}

    this.scrollTop();
  }

  stepController():boolean {
    let disabled = false;

    if(!this.validAnagrafica && this.currentStep === 0){
      disabled = true;
    }
    if(!this.validAnagrafica && this.currentStep === 4){
      disabled = true;
    }
    if(!this.formController.validRilievoMisureSchinaEsedile(this.form) && this.currentStep === 6){
      disabled = true;
    }
    if(this.currentStep === 8 && !this.formController.validPagamento(this.form, true)){
      disabled = true;
    }
    if(this.currentStep === 9 && !this.validPrivacy()){
      disabled = true;
    }
    if(!this.validAnagrafica && this.currentStep === 10){
      disabled = true;
    }
    if (this.currentStep === 11) {
          disabled =  this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie );
    }
   return disabled;
  }

  scrollTop(): void {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }

  openSnackBar(text): void {
    this.snackbar.open(text, 'Ok', {
      duration: 4000
    });
  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  convertTrascodifica(id: number) {
    const matchingTrascodifica = this.trascodifiche.find(el => el.id === id);
    if (matchingTrascodifica) {
      return matchingTrascodifica.descrizione;
    }
  
    return ''; 
  }

  onHomeGlideOptionsChange(index: number): void {
    this.form.sections.homeGlideOptionsAvailable[index].selected = !this.form.sections.homeGlideOptionsAvailable[index].selected;
  }
  
  returnIdOrdine(): string {
    return localStorage.getItem('id_order');
  }

  togglePrintPreview(show: boolean): void {
    this.showModalPrint = show;
    jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#printModal').modal('toggle');
  }

  startTimer(): any {
    const intervalId = setInterval(() => {
      if (this.timeLeft == 0 ){
    clearInterval(intervalId);
    // richiamo api per invio email
    this.orderService.sendEmail(this.form.idOrder).subscribe();
    jQuery('#timeoutModal').modal({backdrop: 'static', keyboard: false });
    jQuery('#timeoutModal').modal('toggle');

    setTimeout(function(){
      jQuery('#timeoutModal').modal('hide');
    }, 10000);

    setTimeout( () => {
           this.formController.goToDashboard();
      }, 11000);
    }
      if (this.timeLeft > 0) {
        this.timeLeft--;
      }/* else {
        this.timeLeft = 10;
      }*/
    }, 1000);
    return intervalId;
  }

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

  reload(value: boolean) {
    this.openSign = value;
  }
}
