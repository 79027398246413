<div class="pt-5 text-center mb-4">
</div>

<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 6 && openSign" (click)="setStep(currentStep-1)"
            class="btnMainColor btnColor" mat-flat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button [disabled]="stepController()" class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">
            Avanti >
        </button>
        <div>
            <div class="smallFont text-danger"
                *ngIf="(!validAnagrafica && currentStep === 0) || (currentStep === 2 && !this.formController.validPagamento(this.form))">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 3 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>

    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
    <div class="col-6 text-right">
    </div>
</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" class="" src="assets/img/loghi/ARIEL.jpg" />
            </span>
            Modulo d'ordine fotovoltaico Ariel
        </h4>
    </div>
    <hr />
    <div class="moduleContainer mb-3">
        <div id="toPrint">
            <div class="" *ngIf="currentStep == 0">
                <app-anagrafica-sh [brand]="'fotovoltaicoAriel'" [validAnagrafica]="validAnagrafica"
                    [form]="form"></app-anagrafica-sh>
            </div>

            <div class="" *ngIf="currentStep == 1">
                <div class="text-center mb-3">
                </div>
                <div>
                    <app-tipologia-prodotto [form]="form" [trascodifiche]="trascodifiche"></app-tipologia-prodotto>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 2">
                <div class="text-center mb-3">
                </div>
                <div>
                    <app-pay-one [form]="form" [brand]="'fotovoltaicoAriel'" [prodotto]="'fotovoltaico'"
                        [trascodifiche]="trascodifiche"></app-pay-one>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 3">
                <div class="text-center mb-3">
                </div>
                <div>
                    <app-privacy [form]="form" [brand]="'fotovoltaicoAriel'"></app-privacy>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 4">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div>

            <div class="" *ngIf="currentStep == 5 && isFinanziamentoPayment">
                <app-finanziamento [form]="form" [listFinanziarie]="listFinanziarie" [productColor]="'#F5B448'" [pdfDetails]="finanziamentoPdfHeader"></app-finanziamento>
            </div>

            <ng-container *ngIf="currentStep == 5 && !isFinanziamentoPayment; else nextStepWithFinance">
                <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
            </ng-container>
            
            <ng-template #nextStepWithFinance>
                <ng-container *ngIf="currentStep == 6">
                    <ng-container *ngTemplateOutlet="signatureAndPrint"></ng-container>
                </ng-container>
            </ng-template>
            
            <ng-template #signatureAndPrint>
                <div class="lastStep">
                    <div class="text-center">
                        <div class="digitalSignatureButtons" *ngIf="openSign" style="height: 124px">
                            <div class="signButton" (click)="edit()">
                                <span class="material-icons d-block">edit</span>
                                <span class="d-block mb-2">Modifica Contratto</span>
                            </div>
                        </div>
                        <div class="" *ngIf="form.idOrder">
                            <app-print-fotovoltaico [anagrafica]="anagrafica" [idOrder]="form.idOrder"
                                [trascodifiche]="trascodifiche" [form]="form"
                                (reload)="reload($event)">
                            </app-print-fotovoltaico>                        
                        </div>
                    </div>
                </div>
            </ng-template>

        </div>
    </div>
</div>

<div class="row buttonRow">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 6 && openSign" (click)="setStep(currentStep-1)"
            class="btnMainColor btnColor" mat-flat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button [disabled]="stepController()" class="btnMainColor btnColor" mat-flat-button (click)="saveModule()">
            Avanti >
        </button>
        <div>
            <div class="smallFont text-danger"
                *ngIf="(!validAnagrafica && currentStep === 0) || (currentStep === 2 && !this.formController.validPagamento(this.form))">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 3 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length">
    </div>
</div>

<div class="stepper">
    <div class="row">
        <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
            <div class="stepNumber" [ngClass]="
            {
            'doneStep': currentStep > step,
            'currentStep': currentStep == step,
            'errorStep': (!validAnagrafica && currentStep === step) ||(currentStep === step && !validPrivacy()) || (currentStep === step && !this.formController.validPagamento(this.form))
            }"> 
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="{'currentStep': currentStep == totalSteps.length}">
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="errorModal" tabindex="-1">
    <app-error-modal [error]="error"></app-error-modal>
</div>

<div class="modal fade" id="confirmModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Avviso selezione pannelli fotovoltaico</h3>
                <button type="button" class="close" (click)="openConfirmPannelli(false)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Non hai selezionato nemmeno un elmento, vuoi procedere?
                </div>
            </div>
            <div class="modal-footer">
                <button mat-button (click)="openConfirmPannelli(false)">Annulla</button>
                <button mat-button (click)="openConfirmPannelli(true)">Avanti ></button>
            </div>
        </div>
    </div>
</div>