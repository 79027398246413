import { Component, Input, OnInit } from '@angular/core';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';
import { RemailService } from '../../../services/remail.service';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { BoxdocciaService } from '../../../../bagni-italiani/main-bagni/services/boxdoccia/boxdoccia.service';
import { BoxdocciaDTO } from '../../../../../classes/remail/boxdoccia-dto';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { constants } from 'src/app/modules/classes/constants';
@Component({
  selector: 'app-boxdoccia',
  templateUrl: './boxdoccia.component.html',
  styleUrls: ['./boxdoccia.component.scss']
})
export class BoxdocciaComponent implements OnInit {
  
  collapse:boolean=false;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() brand:string;
  boxes: Trascodifica[];
  cristalli: Trascodifica[];
  ingressi: Trascodifica[];
  profili: Trascodifica[];
  isVisible  = false;
  options: Trascodifica[];
  optionBoxDoccia: OptionsAvailable = {id:undefined, typeId:undefined,ordineId:undefined, selected:false};
  listOptionsOrder: OptionsAvailable[];
  classBg: string = 'blueBg';
  classContent: string = 'blueCont';
  classButton: string = 'azur';

  constructor(
      public formController: FormControllerService,
      private remailServ: RemailService,
      private boxdocciaService: BoxdocciaService,
      private optionsService: OptionsAvailableService
  ) { }

  ngOnInit(): void {
    if(this.brand === 'bagno-pratico'){
      this.classBg = 'bagnoPraticoBg';
      this.classContent = 'bagnoPraticoCont'; 
      this.classButton = 'praticoButton';
    }else if(this.brand === 'bagniHelp75'){
      this.classBg = 'help75Bg';
      this.classContent = 'hepl75Cont';
      this.classButton = 'ochre';
    }

    this.boxes = this.formController.getTrascodificaByCodice(constants.COD_BOX_DOCCIA_MODELLO,this.trascodifiche);  
    this.options = this.formController.getTrascodificaByCodice(constants.TYPE_OPTIONS,this.trascodifiche).filter(op => op.descrizione === constants.TYPE_NUOVO_BOX_DOCCIA);
    this.getBox();
    this.getOption();
  }

  getBox(): void {
    this.form.sections.boxdoccia = [];
    this.boxdocciaService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
        this.form.sections.boxdoccia = res;
        for (let i = 0; i < res.length; i++) {
            this.form.sections.boxdoccia[i].listCristalli = this.trascodifiche.filter(
                tr => (tr.codice === constants.COD_BOX_DOCCIA_CRISTALLI) && (tr.parentId === res[i].modelloId)
            );
            this.form.sections.boxdoccia[i].listProfili = this.trascodifiche.filter(
                tr => (tr.codice === constants.COD_BOX_DOCCIA_PROFILO) && (tr.parentId === res[i].modelloId)
            );
            this.form.sections.boxdoccia[i].listIngressi = this.formController.getTrascodificaByCodice(constants.COD_BOX_DOCCIA_INGRESSO, this.trascodifiche) || [];
            this.updateListIngressi(res[i].modelloId, i);
        }
    });
}

  addBox(): void {
    const newBox = new BoxdocciaDTO();
    newBox.listIngressi = [];
    this.form.sections.boxdoccia.push(newBox);
    const i = this.form.sections.boxdoccia.length - 1;
    this.form.sections.boxdoccia[i].listIngressi = this.formController.getTrascodificaByCodice(constants.COD_BOX_DOCCIA_INGRESSO,this.trascodifiche)
  }

  removeModel(i): void {
    if (this.form.sections.boxdoccia[i].id !== undefined) {
        this.boxdocciaService.delete(this.form.sections.boxdoccia[i].id).subscribe();
    }
    this.form.sections.boxdoccia.splice(i, 1);
  }

  updateListIngressi(modelloId, i): void {
    const modelDescrizione = this.formController.getTrascodificaById(modelloId, this.trascodifiche)?.descrizione;

    if(modelDescrizione){
      // https://trackysat.atlassian.net/browse/GDLO-296 aggiunta modello nel commento task GDLO-310
      if (modelDescrizione !== constants.MODELLO_ARUBA && modelDescrizione !== constants.MODELLO_AMERIKA && modelDescrizione !== constants.MODELLO_AMERIKA_IMP) {
        let index = this.form.sections.boxdoccia[i].listIngressi.findIndex(
            (el: Trascodifica) => el.descrizione === 'CENTRO' && el.codice === constants.COD_BOX_DOCCIA_INGRESSO
        );
        if (index > -1) {
            this.form.sections.boxdoccia[i].listIngressi.splice(index, 1);
        }
     } else {
        this.form.sections.boxdoccia[i].listIngressi = this.formController.getTrascodificaByCodice(constants.COD_BOX_DOCCIA_INGRESSO,this.trascodifiche)
     }
    }
  }

  checkModel(event, i): void {
    this.form.sections.boxdoccia[i].listCristalli = this.trascodifiche.filter(
        tr => (tr.codice === constants.COD_BOX_DOCCIA_CRISTALLI) && (tr.parentId === event)
    );

    let model = this.trascodifiche.find((el: Trascodifica) => el.id === event);
      if (model) {
        this.updateListIngressi(model.id, i);
      }

    this.form.sections.boxdoccia[i].ingressoId = null;
    this.form.sections.boxdoccia[i].profiloId = null;
    this.form.sections.boxdoccia[i].cristalliId = null;

  }
  
  checkProf(event , i): void {
    this.form.sections.boxdoccia[i].listProfili = this.trascodifiche.filter(
        tr => (tr.codice === constants.COD_BOX_DOCCIA_PROFILO) && (tr.parentId === event));
  }

  showDiv(value){    
    if(value ){
      this.isVisible = true;
    }else{
      this.isVisible = false;
    }
     
    this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0].id)[0].selected = value;
  
  }


  getOption(){
    this.optionsService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => { 
        if(res !== undefined){
            this.listOptionsOrder = res;
            let optionBoxDoccia = res.filter(op => op.typeId === this.options[0].id)  
          if(optionBoxDoccia !== undefined && optionBoxDoccia.length > 0){
            this.isVisible = optionBoxDoccia[0].selected;
            this.form.sections.optionsAvailable.push(optionBoxDoccia[0])
            this.form.sections.optionsAvailable=this.form.sections.optionsAvailable.filter((res:any)=> res.id!=undefined && res.id!=null)
            this.optionBoxDoccia = optionBoxDoccia[0];
          }else{
            this.isVisible = false;
            this.optionBoxDoccia = new OptionsAvailable();
            this.optionBoxDoccia.ordineId = this.form.idOrder;
            this.optionBoxDoccia.typeId = this.options[0].id; 
            this.optionBoxDoccia.selected = undefined;           
            this.form.sections.optionsAvailable.push(this.optionBoxDoccia);
          }
        }
    });
  }
}
