<div id="headingOne">
    <h5 class="mb-0">
        <div class="text-center bagnoPraticoBg">
            PORTA
        </div>

    </h5>
</div>

<div class="bagnoPraticoCont">
    <div class="row originalRow">
        <div class="col-lg-6">
            <div class="row originalRow">
                <div class="col-12">
                    <div>MISURE PORTA ATTUALE:</div>
                    <div>
                        <app-input-number (onNumChange)="form.sections.porta.lunghezza = $event" [step]="0.1"
                            [num]="form.sections.porta.lunghezza" [decimal]="true" [payment]="false" [small]="false"
                            label="Lunghezza cm (luce interna):"></app-input-number>
                    </div>
                </div>
            </div>

            <div class="row originalRow">
                <div class="col-12">
                    <div>
                        <app-input-number (onNumChange)="form.sections.porta.altezza = $event" [step]="0.1"
                            [num]="form.sections.porta.altezza" [decimal]="true" [payment]="false" [small]="false"
                            label="Altezza cm:"></app-input-number>
                    </div>
                </div>
            </div>

            <div class="row originalRow">
                <div class="col-12">
                    <div>
                        <app-input-number (onNumChange)="form.sections.porta.spessore = $event" [step]="0.1"
                            [num]="form.sections.porta.spessore" [decimal]="true" [payment]="false" [small]="false"
                            label="Spess. muro cm:"></app-input-number>
                    </div>
                </div>
            </div>
            <div class="verticalHr"></div>
        </div>
        <div class="col-lg-6">
            <div class="mb-1">NUOVA PORTA: <span class="text-danger">75 cm</span></div>
            <div class="row originalRow">
                <div class="col-lg-12">
                    <div class="mb-1 d-inline-block">COLORE:</div>
                    <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                        [(ngModel)]="form.sections.porta.coloreId">
                        <mat-radio-button class="mb-2 mr-2" [value]="colore.id" *ngFor="let colore of listColore">
                            {{colore.descrizione}}</mat-radio-button>
                        <span class="undo material-icons" matTooltip="Cancella selezione"
                            *ngIf="form.sections.porta.coloreId" (click)="form.sections.porta.coloreId = null">
                            backspace
                        </span>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row originalRow">
                <div class="col-lg-12">
                    <div class="mb-1 d-inline-block">APERTURA:</div>
                    <mat-radio-group labelPosition="after" class="inline" color="primary" aria-label="Tipo"
                        [(ngModel)]="form.sections.porta.aperturaId">
                        <mat-radio-button class="mb-2 mr-2" [value]="apertura.id" *ngFor="let apertura of listApertura">
                            {{apertura.descrizione}}</mat-radio-button>
                        <span class="undo material-icons" matTooltip="Cancella selezione"
                            *ngIf="form.sections.porta.aperturaId" (click)="form.sections.porta.aperturaId = null">
                            backspace
                        </span>
                    </mat-radio-group>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row originalRow">
    <div class="col-lg-12">
        <div class="bagnoPraticoCont mb-2">
            <mat-form-field class="full-width">
                <mat-label>Note libere</mat-label>
                <textarea matInput placeholder="Note libere"
                    [(ngModel)]="form.sections.porta.note"></textarea>
                <button *ngIf="form.sections.porta.note" matSuffix mat-icon-button
                    aria-label="Clear" (click)="form.sections.porta.note=''">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>
</div>