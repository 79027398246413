import { Component, Input, OnInit } from '@angular/core';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { BoxdocciaDTO } from '../../main-bagni/classes/dati-tecnici';
import { BoxdocciaService } from '../../main-bagni/services/boxdoccia/boxdoccia.service';

@Component({
  selector: 'app-box-doccia-pratico',
  templateUrl: './box-doccia-pratico.component.html',
  styleUrls: ['./box-doccia-pratico.component.scss']
})
export class BoxDocciaPraticoComponent implements OnInit {

  collapse: boolean = false;
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  @Input() brand: string;
  boxes: Trascodifica[];
  cristalli: Trascodifica[];
  ingressi: Trascodifica[];
  profili: Trascodifica[];
  isVisible = false;
  options: Trascodifica[];
  optionBoxDoccia: OptionsAvailable = { id: undefined, typeId: undefined, ordineId: undefined, selected: false };
  listOptionsOrder: OptionsAvailable[];
  classBg: string = 'bagnoPraticoBg';
  classContent: string = 'bagnoPraticoCont';
  classButton: string = 'praticoButton';
  
  constructor(
    public formController: FormControllerService,
    private boxdocciaService: BoxdocciaService,
    private optionsService: OptionsAvailableService
  ) { }

  ngOnInit(): void {
    this.boxes = this.formController.getTrascodificaByCodice('BOX_DOCCIA_MODELLO', this.trascodifiche);
    this.options = this.formController.getTrascodificaByCodice('TYPE_OPTIONS', this.trascodifiche).filter(op => op.descrizione === 'NUOVO BOX DOCCIA');
    this.getBox();
    this.getOption();
  }

  getBox(): void {
    this.form.sections.boxdoccia = [];
    this.boxdocciaService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      this.form.sections.boxdoccia = res;
      for (var i = 0; i < res.length; i++) {
        this.form.sections.boxdoccia[i].listCristalli = this.trascodifiche.filter(
          tr => (tr.codice === 'BOX_DOCCIA_CRISTALLI') && (tr.parentId === res[i].modelloId));
        this.form.sections.boxdoccia[i].listProfili = this.trascodifiche.filter(
          tr => (tr.codice === 'BOX_DOCCIA_PROFILO') && (tr.parentId === res[i].modelloId));
        this.form.sections.boxdoccia[i].listLarghezze = this.trascodifiche.filter(
          tr => (tr.codice === 'BOX_DOCCIA_LARGHEZZA') && (tr.parentId === res[i].modelloId));

        this.form.sections.boxdoccia[i].listIngressi = this.formController.getTrascodificaByCodice('BOX_DOCCIA_INGRESSO', this.trascodifiche);
      }
    });
  }

  addBox(): void {
    const newBox = new BoxdocciaDTO();
    this.form.sections.boxdoccia.push(newBox);
  }

  removeModel(i): void {
    if (this.form.sections.boxdoccia[i].id !== undefined) {
      this.boxdocciaService.delete(this.form.sections.boxdoccia[i].id).subscribe();
    }
    this.form.sections.boxdoccia.splice(i, 1);
  }

  checkModel(event, i): void {
    this.form.sections.boxdoccia[i].listCristalli = this.trascodifiche.filter(
      tr => (tr.codice === 'BOX_DOCCIA_CRISTALLI') && (tr.parentId === event));
  }

  checkProf(event, i): void {
    this.form.sections.boxdoccia[i].listProfili = this.trascodifiche.filter(
      tr => (tr.codice === 'BOX_DOCCIA_PROFILO') && (tr.parentId === event));
  }

  checkLarg(event, i): void {
    this.form.sections.boxdoccia[i].listLarghezze = this.trascodifiche.filter(
      tr => (tr.codice === 'BOX_DOCCIA_LARGHEZZA') && (tr.parentId === event));
  }

  checkIngr(event, i): void {
    this.form.sections.boxdoccia[i].listIngressi = this.trascodifiche.filter(
      tr => (tr.codice === 'BOX_DOCCIA_INGRESSO'));
  }

  showDiv(value) {
    if (value) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }

    this.form.sections.optionsAvailable.filter(op => op.typeId === this.options[0].id)[0].selected = value;

  }


  getOption() {
    this.optionsService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(res => {
      if (res !== undefined) {
        this.listOptionsOrder = res;
        let optionBoxDoccia = res.filter(op => op.typeId === this.options[0].id)
        if (optionBoxDoccia !== undefined && optionBoxDoccia.length > 0) {
          this.isVisible = optionBoxDoccia[0].selected;
          this.form.sections.optionsAvailable.push(optionBoxDoccia[0])
          this.form.sections.optionsAvailable = this.form.sections.optionsAvailable.filter((res: any) => res.id != undefined && res.id != null)
          this.optionBoxDoccia = optionBoxDoccia[0];
        } else {
          this.isVisible = false;
          this.optionBoxDoccia = new OptionsAvailable();
          this.optionBoxDoccia.ordineId = this.form.idOrder;
          this.optionBoxDoccia.typeId = this.options[0].id;
          this.optionBoxDoccia.selected = undefined;
          this.form.sections.optionsAvailable.push(this.optionBoxDoccia);
        }
      }
    });

  }

}
