import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  isTest: boolean;
  apiUrl: string;
  constructor(
      public router: Router,
      public http: HttpClient,
  ) {
    this.router = router;
    this.http = http;
    this.isTest = !environment.production;
    if (this.isTest) {
      //this.apiUrl = 'http://localhost:8080/api/';
		  this.apiUrl = 'https://admintest.ordinigdl.com/api/';
    } else {
     	this.apiUrl = 'https://admin.ordinigdl.com/api/';
    }
  }

  setHttpOptions(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('or_access_token'),
      })
    };
  }

  setApiurl(): string {
    return this.apiUrl;
  }

  handleError(error: HttpErrorResponse): any {
    let message = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      message = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error.errorMessage}`);
      if (error.error.errorMessage === 'Token is Expired') {
        this.router.navigate(['/login']);
      }
      message = error.error;
    }
    // return an observable with a user-facing error message
    return throwError(message);
  }

  getPrivacy(file): Observable<any> {
    return this.http.get<any>(file).pipe(
        retry(0),
        catchError(this.handleError)
    );
  }
}
