import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnagraficaForm } from 'src/app/modules/classes/anagrafica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { Accessori, BoxdocciaDTO, DatiTecnici, DatiTecniciDTO, NuovaCassettaWc, Nuovarubinetteria, NuoviAccessoriDTO, NuoviDati, NuoviDatiDTO, NuoviProdotti, NuoviSanDTO, Omaggio, OmaggioDTO, OpereIdrauliche, OpereIdraulicheDTO, OpereIdraulicheSostDTO, PagamentoDTO, PiattoEffettoPietra, RivestimentiDTO, SanitariDTO, Sections, SingleBox, SoffittoPareti, TermoarrediDTO, TermosifoniArredi } from '../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { AnagraficaService } from '../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { OrdersService } from 'src/app/services/orders/orders.service';
import { BrandService } from 'src/app/services/brand/brand.service';
import { DatiTecniciAttService } from '../main-bagni/services/datiTecniciAtt/datiTecniciAtt.service';
import { RivestimentiService } from '../main-bagni/services/rivestimenti/rivestimenti.service';
import { SanitariService } from '../main-bagni/services/sanitari/sanitari.service';
import { OptionsAvailable } from 'src/app/modules/classes/options-available';
import { OptionsAvailableService } from 'src/app/services/optionsAvailable/options-available.service';
import { OpereIdraulicheService } from '../main-bagni/services/opereIdrauliche/opereIdrauliche.service';
import { TermoarrediService } from '../main-bagni/services/termoarredi/termoarredi.service';
import { PortaService } from 'src/app/services/porta/porta.service';
import { NuoviDatiTecniciService } from '../main-bagni/services/nuovi-dati-tecnici/nuovi-dati-tecnici.service';
import { OmaggioService } from '../main-bagni/services/omaggio/omaggio.service';
import { BoxdocciaService } from '../main-bagni/services/boxdoccia/boxdoccia.service';
import { NuoviAccessoriService } from '../main-bagni/services/nuoviAccessori/nuoviAccessori.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { kitSicurezza } from 'src/app/modules/classes/kitSicurezza';
import { SicurezzaService } from 'src/app/services/sicurezza/sicurezza.service';
import { environment } from 'src/environments/environment';
import { constants } from 'src/app/modules/classes/constants';
import { FinAnagrafica, Finanziamento, FinDatiOccupazione } from 'src/app/modules/classes/finanziamento/finanziamento-dto';
import { AllegatoService } from 'src/app/services/allegato/allegato.service';
import { Porta } from 'src/app/modules/classes/porta';
import * as $ from 'jquery';
declare var jQuery: any;

@Component({
  selector: 'app-bagno-pratico',
  templateUrl: './bagno-pratico.component.html',
  styleUrls: ['./bagno-pratico.component.scss']
})
export class BagnoPraticoComponent implements OnInit {

  listFinanziarie: Trascodifica[];
	attivitaLavList: Trascodifica[] = [];

  isFinanziamentoPayment: boolean = false;

  finanziamentoPdfHeader: { logoUrl: string, product: string, brand: string, } = {
		logoUrl: 'assets/img/loghi/logoPratico.jpg', product: 'Pratico', brand: 'Bagno'}

  constructor(
      public formController: FormControllerService,
      private route: ActivatedRoute,
      private anagraficaService: AnagraficaService,
      private changeDetector: ChangeDetectorRef,
      private orderService: OrdersService,
      private brandService: BrandService,
      private datiTecniciService: DatiTecniciAttService,
      private rivestimentiService: RivestimentiService,
      private sanitariService: SanitariService,
      private optionService: OptionsAvailableService,
      private opIdraulicheService: OpereIdraulicheService,
      private termoarrediService: TermoarrediService,
      private portaService: PortaService,
      private nuoviDatiTecService: NuoviDatiTecniciService,
      private omaggioService: OmaggioService,
      private boxdocciaService: BoxdocciaService,
      private nuoviAccessoriService: NuoviAccessoriService,
      private paymentService: PaymentService,
      private kitSicurezzaService: SicurezzaService,
      private allegatoService: AllegatoService,
  ) {
    this.currentStep = 0;
    this.printContent = '';
    this.validAnagrafica = false;
    this.showModalPrint = false;
    
    this.form = {
      idOrder: Number(this.route.snapshot.paramMap.get('id')),
      idGdlOrder: '',
      anagrafica: new AnagraficaForm(),
      sections: {
        datiTecnici: new DatiTecnici(),
        rivestimenti: new RivestimentiDTO(),
        nuoviProdotti: new NuoviProdotti(),
        opereIdrauliche: new OpereIdrauliche(),
        opereIdraulicheSost: new OpereIdraulicheSostDTO(),
        opereIdraulicheSostToDelete: new OpereIdraulicheSostDTO(),
        soffittoPareti: new SoffittoPareti(),
        termosifoniArredi: [new TermosifoniArredi()],
        termosifoniArrediToDelete: [new TermosifoniArredi()],
        piattoEffettoPietra: new PiattoEffettoPietra(),
        porta: new Porta(),
        nuoviDati: new NuoviDati(),
        omaggio: [new Omaggio()],
        boxdoccia: [new BoxdocciaDTO()],
        nuoviAccessori: [new Accessori()],
        pagamento: [new PagamentoDTO()],
        kitSicurezza: [new kitSicurezza()],
        nuoviSanitariDto: [new NuoviSanDTO()],
        finanziamentoSections: {
					finanziamento: new Finanziamento(),
					finAnagrafica: new FinAnagrafica(),
					finDatiOccupazione: new FinDatiOccupazione(),
					finAnagraficaGarante: new FinAnagrafica(),
					finDatiOccupazioneGarante: new FinDatiOccupazione(),
					isAllegatoSaved: false
				},
        optionsAvailable: [] = [],
      },
      ordine: null,
    };
    this.totalSteps = Array(10).fill(1).map((x, i) => i);  // Attenzione, gestione dinamica nel metodo ( setStep() ) della parte relativa al finanziamento, che mostra lo step solo quando viene spuntato nel pagamento.
  }

  printContent: any;
  form: any;
  currentStep: number;
  validAnagrafica: boolean;
  showModalPrint: boolean;
  mobileAttivo = true;
  totalSteps: number[];
  trascodifiche: Trascodifica[];
  confirmBoxDoccia = false;
  error: any;
  showFrame = false;
  ordine: any;
  idCliente: number;
  obj1: any;
  obj2: any;
  originalForm: any;
  anagrafica: any;
  generatingPdf = false;
  datiTecnici: DatiTecniciDTO;
  currentPrinting = 0;
  pdf: any;
  integratedSignature = environment.integratedSignature;
  sendingPdf = false;
  openSign = false;
  member: any;
  urlFrame = '';

  timeLeft: any = 60;
  hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  getOrder(idOrder): void {
    this.orderService.getSingleOrder(idOrder).subscribe(res => {
      this.ordine = res;
      this.form.ordine = res;
      localStorage.setItem('id_product', res.prodottoId);

      if (res.clienteId !== undefined && res.clienteId !== null) {
        localStorage.setItem('id_client', res.clienteId);
        this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
      }

      localStorage.setItem('id_order', res.idordine);
    });
    // richiamo il serivizio di trascodifica che mi restituisce i dati per popolare i form
    this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
      this.trascodifiche = res;
      this.listFinanziarie = this.trascodifiche.filter((res: Trascodifica) => res.codice.includes(constants.CODICE_NOME_FINANZIARIE) && res.valido == true);
    });
    this.brandService.getDataByCodice(constants.CODICE_FIN_ATTIVITA_LAV).toPromise().then(res => this.attivitaLavList = res);
  }

  ngOnInit(): void {
    this.idCliente = Number(localStorage.getItem('id_client'));
    if (localStorage.getItem('id_client') !== null) {
      this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
    }
    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }
    this.getOrder(this.form.idOrder);

  }

  ngDoCheck(): void {
    this.obj1 = JSON.stringify(this.originalForm);
    this.obj2 = JSON.stringify(this.form);
    if (this.obj1 !== this.obj2) {
      this.checkAnagrafica();
      this.originalForm = JSON.parse(JSON.stringify(this.form));
    }
  }

  setStep(num): void {
    this.currentStep = num;
    if (num === 6) {
      this.setPrint();
    }
    if (this.currentStep > 0) {
      $('.imgModulo').addClass('yesShow');
    } else {
      $('.imgModulo').removeClass('yesShow');
    }

    if(this.form.sections.pagamento[0]!.tipopagsaldoId){
			let tipoSaldo = this.formController.getTrascodificaById(this.form.sections.pagamento[0]!.tipopagsaldoId, this.trascodifiche);
			if( tipoSaldo && tipoSaldo.id && tipoSaldo.descrizione.toLocaleUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
				this.isFinanziamentoPayment = true;
				this.totalSteps = Array(10).fill(1).map((x, i) => i);
			}else {
				this.isFinanziamentoPayment = false;
				this.totalSteps = Array(9).fill(1).map((x, i) => i);
			}
		}

    this.scrollTop();
  }

  scrollTop(): void {
    $('html, body').animate({ scrollTop: 0 }, 300);
  }

  toggleJson(): void {
    $('.formJson').toggleClass('visibleJson');
  }

  setPrint(): void {
    this.printContent = document.getElementById('toPrint').innerHTML;
  }

  stepController(): boolean {
    let disabled = false;
    if (!this.validAnagrafica && this.currentStep === 0) {
      disabled = true;
    }

    if (!this.formController.validTecnic(this.form.sections.datiTecnici) && this.currentStep === 1) {
      disabled = true;
    }

    if (this.currentStep === 2 && !this.validMobBagno()) {
      disabled = true;
    }

    if (this.currentStep === 3 && (!this.validNuovaCassetta() || !this.formController.validOption(this.form.sections.optionsAvailable, 'NUOVA CASSETTA WC', this.trascodifiche))) {
      disabled = true;
    }

    if (this.currentStep === 3 && (!this.validNuovaRubinetteria() || !this.formController.validOption(this.form.sections.optionsAvailable, 'NUOVA RUBINETTERIA', this.trascodifiche))) {
      disabled = true;
    }

    if (this.currentStep === 4 && (!this.validPiattoPietra() || !this.formController.validOption(this.form.sections.optionsAvailable, 'NUOVO PIATTO DOCCIA', this.trascodifiche))) {
      disabled = true;
    }

    if (this.currentStep === 5 && (!this.formController.validOmaggio(this.form) ||
      !this.formController.validOption(this.form.sections.optionsAvailable, 'NUOVO BOX DOCCIA', this.trascodifiche))) {
      disabled = true;
    }

    if (this.currentStep === 6 && !this.formController.validPagamento(this.form)) {
      disabled = true;
    }

    if (this.currentStep === 7 && !this.formController.validPrivacy(this.form)) {
      disabled = true;
    }

    if (!this.validAnagrafica && this.currentStep === 8) {
      disabled = true;
    }

    if (this.currentStep === 3 && ( !this.formController.validOption(this.form.sections.optionsAvailable, 'NUOVI SANITARI', this.trascodifiche) || !this.formController.validNuoviSan(this.form))) {
      disabled = true;
    }

    if (this.currentStep === 5 && ( !this.formController.validOption(this.form.sections.optionsAvailable, 'NUOVO BOX DOCCIA', this.trascodifiche) || !this.formController.validNuovoBoxDoccia(this.form))) {
      disabled = true;
    }

		  if (this.currentStep === 9) {
				disabled = this.formController.validStepFinanziamento(this.form, this.attivitaLavList, this.listFinanziarie );
		  }
		 return disabled;
		}


  saveModule(value: boolean): void {
    $('body').removeClass('noScr');
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    if (this.currentStep === 0) {
      // Step salvataggio anagrafica
      this.anagrafica = this.form.anagrafica.anagrafica;
      if (this.anagrafica.id) {
        this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
        }, error => {
          console.error('Error => ', error);
          this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
        });
      } else {
        this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
          this.ordine.clienteId = res.id;
          this.ordine.cognome = res.cognome;
          this.ordine.nome = res.nome;
          this.form.anagrafica.anagrafica.id = res.id;
          this.orderService.update(JSON.stringify(this.ordine)).subscribe();
        }, error => {
          console.error('Error => ', error);
          this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
        });
      }
    } else if (this.currentStep === 1) {
      // Step salvataggio dati tecnici
      this.datiTecnici = this.formController.mappingFormToDatiTecniciDTO(this.form.sections.datiTecnici, new DatiTecniciDTO());
      if (this.hasValues(this.datiTecnici)) {
        this.datiTecnici.ordineId = this.form.idOrder;
        if (this.datiTecnici.id === null || this.datiTecnici.id === undefined) {
          this.datiTecniciService.save(JSON.stringify(this.datiTecnici)).subscribe(res => {
            // this.openSnackBar('Modulo salvato correttamente');
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
          });
        } else {
          this.datiTecniciService.update(JSON.stringify(this.datiTecnici)).subscribe(res => {
            // this.openSnackBar('Modulo aggiornato correttamente');
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar('Errore in fase di aggiornamento del modulo');
          }
         );
        }
      }
    } else if (this.currentStep === 2) {
      // Step salvataggio rivestimenti
      if (this.hasValues(this.form.sections.rivestimenti)) {
        this.form.sections.rivestimenti.ordineId = this.form.idOrder;
        if (this.form.sections.rivestimenti.id === null || this.form.sections.rivestimenti.id === undefined) {
          this.rivestimentiService.save(JSON.stringify(this.form.sections.rivestimenti)).subscribe(res => {
            // this.openSnackBar('Modulo salvato correttamente');
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
          });
        } else {
          this.rivestimentiService.update(JSON.stringify(this.form.sections.rivestimenti)).subscribe(res => {
            // this.openSnackBar('Modulo aggiornato correttamente');
          }, error => {
            console.error('Error => ', error);
            this.formController.openSnackBar('Errore in fase di aggiornamento del modulo');
          });
        }
      }

      // controllo se il toogle mobileBagni � a true
      if (this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.isCliente) {
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.descrizione;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colore;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colore2;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.numeroVasche;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.vascaMob;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili;
        delete this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.note;
      }
      const sanitari = this.formController.mappingFormToSanitariDTO(this.form.sections.nuoviProdotti, new SanitariDTO(), this.form.idOrder);
      if (this.hasValues(sanitari)) {
        sanitari.idordineId = this.form.idOrder;
        if (sanitari.id === null || sanitari.id === undefined) {
          this.sanitariService.save(JSON.stringify(sanitari)).subscribe();
        } else {
          this.sanitariService.update(JSON.stringify(sanitari)).subscribe();
        }
      }
    } else if (this.currentStep === 3) {
      // Step salvataggio sanitari
      let saveBoxNuoviSan: boolean;
      let saveNuovaCassetta: boolean;
      let saveNuovaRubinetteria: boolean;
      // Salvo o aggiorno l'opzione nuovo sanitari per l'ordine
      for (let i = 0; i < this.form.sections.optionsAvailable.length; i++) {
        const option: OptionsAvailable = this.form.sections.optionsAvailable[i];

        if (saveBoxNuoviSan == undefined) {
          saveBoxNuoviSan = this.searchBool(option, 'NUOVI SANITARI');
        }

        if (saveNuovaCassetta == undefined) {
          saveNuovaCassetta = this.searchBool(option, 'NUOVA CASSETTA WC');
        }

        if (saveNuovaRubinetteria == undefined) {
          saveNuovaRubinetteria = this.searchBool(option, 'NUOVA RUBINETTERIA');
        }
      }

      if (!saveNuovaCassetta) {
        this.form.sections.nuoviProdotti.nuovaCassettaWc = new NuovaCassettaWc();
      }

      if (!saveNuovaRubinetteria){
        this.form.sections.nuoviProdotti.nuovaRubinetteria = new Nuovarubinetteria();
      }

      const sanitari = this.formController.mappingFormToSanitariDTO(this.form.sections.nuoviProdotti, new SanitariDTO(), this.form.idOrder);
      if (this.hasValues(sanitari)) {
        sanitari.idordineId = this.form.idOrder;
        if (sanitari.id === null || sanitari.id === undefined) {
          this.sanitariService.save(JSON.stringify(sanitari)).subscribe();
        } else {
          this.sanitariService.update(JSON.stringify(sanitari)).subscribe();
        }
      }


      // step salvataggio nuovi sanitari
      for (let i = 0; i < this.form.sections.nuoviSanitariDto.length; i++) {
        const san = this.form.sections.nuoviSanitariDto[i];
        san.ordineId = this.form.idOrder;
        if (!saveBoxNuoviSan) {
          // se ho selezionato no per il box nuovi sanitari effettuo la delete di eventuali oggetti precendentemente salvati
          this.sanitariService.deleteNuoviSan(san.id).subscribe();
        } else {
          if (san.id === undefined || san.id === null) {
            this.sanitariService.saveNuoviSan(san).subscribe();
          } else {
            this.sanitariService.updateNuoviSan(san).subscribe();
          }
        }
      }

    } else if (this.currentStep === 4) {

      let saveBoxPiattoD: boolean;
      // Salvo o aggiorno l'opzione nuovo piatto doccia per l'ordine
      for (let i = 0; i < this.form.sections.optionsAvailable.length; i++) {
        const option: OptionsAvailable = this.form.sections.optionsAvailable[i];
        if (saveBoxPiattoD === undefined) {
          saveBoxPiattoD = this.searchBool(option, 'NUOVO PIATTO DOCCIA');
        }
      }

      // Step salvataggio form opere idrauliche
      const opIdrauliche = this.formController.mappingFormToOpereIdraulicheDto(this.form.sections, new OpereIdraulicheDTO(), saveBoxPiattoD);
      if (this.hasValues(opIdrauliche)) {
        opIdrauliche.ordineId = this.form.idOrder;
        if (opIdrauliche.id === null || opIdrauliche.id === undefined) {
          this.opIdraulicheService.save(JSON.stringify(opIdrauliche)).subscribe();
        } else {
          this.opIdraulicheService.update(JSON.stringify(opIdrauliche)).subscribe();
        }
      }

      // step salvataggio opere idrauliche sost
      for (let i = 0; i < this.form.sections.opereIdraulicheSost.length; i++) {
        const opSost: OpereIdraulicheSostDTO = new OpereIdraulicheSostDTO();
        opSost.id = this.form.sections.opereIdraulicheSost[i].id;
        opSost.tipologiaId = this.form.sections.opereIdraulicheSost[i].tipologiaId;
        opSost.ordineId = this.form.idOrder;
        if (opSost.id === undefined || opSost.id === null) {
          this.opIdraulicheService.saveSost(opSost).subscribe();
        }
      }

      // Step delete unchecked idrauliche sost
      for (let i = 0; i < this.form.sections.opereIdraulicheSostToDelete.length; i++) {
        if (this.form.sections.opereIdraulicheSostToDelete[i].id !== undefined && this.form.sections.opereIdraulicheSostToDelete[i].id !== null) {
          this.opIdraulicheService.deleteSost(this.form.sections.opereIdraulicheSostToDelete[i].id).subscribe();
        }
      }


      // Step salvataggio termoarredi
      for (let i = 0; i < this.form.sections.termosifoniArredi.length; i++) {
        const term: TermoarrediDTO = this.mappingFormToTermoarrediDTO(this.form.sections.termosifoniArredi[i], new TermoarrediDTO());
        if (term.id === undefined || term.id === null) {
          this.termoarrediService.save(term).subscribe();
        }
      }

      // Step delete unchecked termoarredi
      for (let i = 0; i < this.form.sections.termosifoniArrediToDelete.length; i++) {
        if (this.form.sections.termosifoniArrediToDelete[i].id !== undefined && this.form.sections.termosifoniArrediToDelete[i].id !== null) {
          this.termoarrediService.delete(this.form.sections.termosifoniArrediToDelete[i].id).subscribe();
        }

      }

      // Step salvataggio porta
      if (this.hasValues(this.form.sections.porta)) {
        this.form.sections.porta.ordineId = this.form.idOrder;
        if (this.form.sections.porta.id === null || this.form.sections.porta.id === undefined) {
          this.portaService.save(JSON.stringify(this.form.sections.porta)).subscribe();
        } else {
          this.portaService.update(JSON.stringify(this.form.sections.porta)).subscribe();
        }
      }


    } else if (this.currentStep === 5) {

      // Step salvataggio nuovi dati tecnici
      const nuoviDatiTec = this.formController.mappingFormToNuoviDatiTecDTO(this.form.sections.nuoviDati, new NuoviDatiDTO(), this.form.idOrder);

      if (this.hasValues(nuoviDatiTec)) {
        nuoviDatiTec.ordineId = this.form.idOrder;
        if (nuoviDatiTec.id === undefined || nuoviDatiTec.id === null) {
          this.nuoviDatiTecService.save(JSON.stringify(nuoviDatiTec)).subscribe();
        } else {
          this.nuoviDatiTecService.update(JSON.stringify(nuoviDatiTec)).subscribe();
        }
      }

      // Step salvataggio omaggio
      for (let i = 0; i < this.form.sections.omaggio.length; i++) {
        const omaggio: OmaggioDTO = this.mappingFormToOmaggioDTO(this.form.sections.omaggio[i], new OmaggioDTO());
        if (omaggio.id === undefined || omaggio.id === null) {
          this.omaggioService.save(JSON.stringify(omaggio)).subscribe();
        } else {
          this.omaggioService.update(JSON.stringify(omaggio)).subscribe();
        }
      }

      // Step salvataggio nuovi box doccia
      let saveBoxDoccia: boolean;
      // Salvo o aggiorno l'opzione vasca per l'ordine
      for (let i = 0; i < this.form.sections.optionsAvailable.length; i++) {
        const option: OptionsAvailable = this.form.sections.optionsAvailable[i];
        if (saveBoxDoccia == undefined) {
          saveBoxDoccia = this.searchBool(option, 'NUOVO BOX DOCCIA');
        }
      }

      for (let i = 0; i < this.form.sections.boxdoccia.length; i++) {
        const box = this.form.sections.boxdoccia[i];
        if (saveBoxDoccia !== undefined) {
          if (!saveBoxDoccia) {
            // se non è stato selezionato il box doccia elimino i vecchi box precedentemente aggiunti al contratto
            this.boxdocciaService.delete(box.id).subscribe();
          } else {
            if (this.hasValues(box)) {
              box.ordineId = this.form.idOrder;
              if (box.id === undefined || box.id === null) {
                this.boxdocciaService.save(JSON.stringify(box)).subscribe();
              } else {
                this.boxdocciaService.update(JSON.stringify(box)).subscribe();
              }
            }
          }
        }
      }

      // Step salvataggio nuovi accessori
      for (let i = 0; i < this.form.sections.nuoviAccessori.length; i++) {
        const nuoviAcc: NuoviAccessoriDTO = this.formController.mappingFormToNuoviAccessoriDTO(this.form.sections.nuoviAccessori[i], new NuoviAccessoriDTO, this.form.idOrder);
        if (nuoviAcc.id === undefined || nuoviAcc.id === null) {
          this.nuoviAccessoriService.save(nuoviAcc).subscribe();
        }
      }

      // Step delete unchecked nuovi accessori
      for (let i = 0; i < this.form.sections.nuoviAccessoriToDelete.length; i++) {
        if (this.form.sections.nuoviAccessoriToDelete[i].id !== undefined && this.form.sections.nuoviAccessoriToDelete[i].id !== null) {
          this.nuoviAccessoriService.delete(this.form.sections.nuoviAccessoriToDelete[i].id).subscribe();
        }
      }


      // Step salvataggio kit sicurezza
      for (let i = 0; i < this.form.sections.kitSicurezza.length; i++) {
        if (this.form.sections.kitSicurezza[i].id === undefined || this.form.sections.kitSicurezza[i].id === null) {
          this.form.sections.kitSicurezza[i].ordineId = this.form.idOrder;
          this.kitSicurezzaService.save(this.form.sections.kitSicurezza[i]).subscribe();
        }
      }

      // Step delete unchecked kit sicurezza
      for (let i = 0; i < this.form.sections.kitSicurezzaToDelete.length; i++) {
        if (this.form.sections.kitSicurezzaToDelete[i].id !== undefined && this.form.sections.kitSicurezzaToDelete[i].id !== null) {
          this.kitSicurezzaService.delete(this.form.sections.kitSicurezzaToDelete[i].id).subscribe();
        }
      }
    } else if (this.currentStep === 6) {
      // Step salvataggio pagamento
      const hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== 'undefined');

      if (hasValues(this.form.sections.pagamento[0])) {
        this.form.sections.pagamento[0].ordineId = this.form.idOrder;
        if (this.form.sections.pagamento[0].id === undefined || this.form.sections.pagamento[0].id === null) {
          this.paymentService.save(JSON.stringify(this.form.sections.pagamento[0])).subscribe((res: any) => {
            this.form.sections.pagamento[0] = res;
          });
        } else {
          this.paymentService.update(JSON.stringify(this.form.sections.pagamento[0])).subscribe((res: any) => {
            this.form.sections.pagamento[0] = res;
            // se già presente un allegato finanziamento, ma cambio selezione cancella l'allegato.
            if(res.ordineId && res.tipopagsaldoDescrizione && !res.tipopagsaldoDescrizione.toUpperCase().includes(constants.FINANZIAMENTO_DESCRIONE_TRASC)){
              this.allegatoService.getAllegati(res.ordineId).subscribe((allegati: any[]) => {
                allegati.forEach((allegato:any) => {
                  if(allegato.tipoDescrizione.includes(constants.TYPE_DOC_FINANZIAMENTO_STEP)){
                    this.allegatoService.delete(allegato.id).subscribe();
                  }
                })
              })
            }
          });
        }
      }
    } else if (this.currentStep === 7) {
      // Step salvataggio privacy
      this.orderService.update(JSON.stringify(this.form.ordine)).subscribe();
    } else if (this.currentStep === 8) {
      this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
        // this.openSnackBar('Modulo salvato correttamente');
        this.ordine.clienteId = res.id;
        this.ordine.cognome = res.cognome;
        this.ordine.nome = res.nome;
      }, error => {
        console.error('Error => ', error);
        this.formController.openSnackBar('Errore in fase di salvataggio del modulo');
      });
    }

    this.setStep(this.currentStep + 1);
  }

  openConfirmBoxDoccia(value): void {
    this.confirmBoxDoccia = value;
    jQuery('#confirmModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#confirmModal').modal('toggle');
    if (this.confirmBoxDoccia) {
      this.saveModule(true);
    }
  }

  public returnIdOrdine(): string {
    return localStorage.getItem('id_order');
  }

  togglePrintPreview(show: boolean): void {
    this.showModalPrint = show;
    jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
    jQuery('#printModal').modal('toggle');
  }

  checkAnagrafica(): boolean {
    this.validAnagrafica = this.formController.checkAnagrafica(this.form);
    return this.validAnagrafica;
  }

  validMobBagno(): boolean {
    if (!this.mobileAttivo) {
      const tipiCol = this.formController.getTrascodificaByCodice('TIPO_COLONNA_MOB', this.trascodifiche);
      const tras = tipiCol.filter((c: any) => c.descrizione === 'Nessuna colonna');
      if (!this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.vascaMob ||
        (this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile && this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.colonnaPensile !== tras[0].id)) {
        if (!this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.vascaMob || !this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili || this.form.sections.nuoviProdotti.nuoviSanitari.mobileBagno.quantitaPensili === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  toggleChange(event): void {
    this.mobileAttivo = event;
  }

  validNuovaCassetta(): boolean {
    const tipoCassettaWc = this.formController.getTrascodificaByCodice('TIPO_CASSETTA_WC', this.trascodifiche);
    const tipo = tipoCassettaWc.filter(t => t.descrizione === 'Cassetta wc ad incasso GEBERIT');
    if (this.form.sections.nuoviProdotti.nuovaCassettaWc.modelId && tipo && this.form.sections.nuoviProdotti.nuovaCassettaWc.modelId == tipo[0].id) {
      if (this.form.sections.nuoviProdotti.nuovaCassettaWc.placcaColorId && this.form.sections.nuoviProdotti.nuovaCassettaWc.placcaTypeId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  searchBool(option: any, type: any) {
    const optionTrascodifica = this.formController.getTrascodificaByCodice(constants.TYPE_OPTIONS, this.trascodifiche).filter(op => (op.descrizione === type));
    if (optionTrascodifica.find((res: any) => res.id === option.typeId)) {
      if (option.id) {
        // update option
        this.optionService.update(option).subscribe();
      } else {
        // insert option
        this.optionService.save(option).subscribe();
      }

      if (option.selected == true) {
        return true;
      } else if (option.selected == false) {
        return false;
      }
    }
  }

  validNuovaRubinetteria(): boolean {
    if (this.form.sections.nuoviProdotti.nuovaRubinetteria.id) {
      const serie = this.trascodifiche.filter(
        tr => (tr.codice === 'SERIE_RUBINETTERIA') && (tr.id === this.form.sections.nuoviProdotti.nuovaRubinetteria.id));
      // verifico se ho selezionato la serie light
      // questa verifica non funziona perchè serie rubinetterai torna qualsiasi serie
      // serieLight.length > 0
      // controllo direttamente id di serie light
      if (this.form.sections.nuoviProdotti.nuovaRubinetteria.id === 1497) {
        // verifico se � stato selezionato il colore
        if (!this.form.sections.nuoviProdotti.nuovaRubinetteria.rubbicolId) {
          return false;
        }
      }
      // check serie grohe
      if (serie !== undefined && serie[0].descrizione === 'SERIE GROHE') {
        if (this.form.sections.nuoviProdotti.nuovaRubinetteria.lavabo || this.form.sections.nuoviProdotti.nuovaRubinetteria.bidet
          || this.form.sections.nuoviProdotti.nuovaRubinetteria.doccia || this.form.sections.nuoviProdotti.nuovaRubinetteria.vasca) {
          return true;
        } else {
          return false;
        }
      }

      if (
        (
          this.form.sections.nuoviProdotti.nuovaRubinetteria.subLavaboId ||
          this.form.sections.nuoviProdotti.nuovaRubinetteria.subBidetId
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public mappingFormToTermoarrediDTO(form: TermosifoniArredi, dto: TermoarrediDTO): TermoarrediDTO {
    dto.id = form.id;
    dto.ordineId = this.form.idOrder;
    dto.tipologiaId = form.tipologiaId;

    return dto;
  }

  validPiattoPietra(): boolean {
    if (this.form.sections.piattoEffettoPietra.coloreId) {
      if (this.form.sections.piattoEffettoPietra.dimensioneId) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public mappingFormToOmaggioDTO(form: Omaggio, dto: OmaggioDTO): OmaggioDTO {
    dto.id = form.id;
    dto.ordineId = this.form.idOrder;
    dto.tipologiaId = form.tipologiaId;
    dto.quantita = form.quantita;

    return dto;
  }

  startTimer(): any {
     const intervalId = setInterval(() => {
       if (this.timeLeft == 0 ){
     clearInterval(intervalId);
     // richiamo api per invio email
     this.orderService.sendEmail(this.form.idOrder).subscribe();
     jQuery('#timeoutModal').modal({backdrop: 'static', keyboard: false });
     jQuery('#timeoutModal').modal('toggle');

     setTimeout(function(){
       jQuery('#timeoutModal').modal('hide');
     }, 10000);

     setTimeout( () => {
            this.formController.goToDashboard();
       }, 11000);
     }
       if (this.timeLeft > 0) {
         this.timeLeft--;
       }/* else {
         this.timeLeft = 10;
       }*/
     }, 1000);
     return intervalId;
   }


   edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

  reload(value: boolean) {
    this.openSign = value;
  }
}
