<div [ngClass]="{'d-inline-block':small, 'onlyRedBorder':num < 0}">

    <mat-form-field class="d-inline-block" [ngClass]="{'smallFieldNum': small, 'customSmalNumber': !small}">
        <button style="background-color: #eee;margin-right: 8px;" matPrefix mat-icon-button
                [disabled]="num == 0"
                (click)="updateNumber(decimal, false)">
            <mat-icon>remove</mat-icon>
        </button>
        <mat-label>{{label}}</mat-label>
        <input matInput type="number" min="0" [step]="step" [(ngModel)]="num" [ngModelOptions]="{ updateOn: 'blur' }" (ngModelChange)="emit()">
        <button matSuffix mat-icon-button (click)="updateNumber(decimal, true)" style="background-color: #eee;">
            <mat-icon>add</mat-icon>
        </button>
    </mat-form-field>

</div>


