import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { constants } from '../../../../classes/constants';

@Component({
  selector: 'app-arrivo-in-basso-della-guida',
  templateUrl: './arrivo-in-basso-della-guida.component.html',
  styleUrls: ['./arrivo-in-basso-della-guida.component.scss']
})
export class ArrivoInBassoDellaGuidaComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: any;
  listTipoArrivoInBassoTrasc: Trascodifica[] = [];
  listFirstLineArrivoInBassoTrasc: Trascodifica[] = [];
  listSecondLineArrivoInBassoTrasc: Trascodifica[] = [];
  listThirdLineArrivoInBassoTrasc: Trascodifica[] = [];
  constructor(private formController:FormControllerService) { }

  ngOnInit(): void {
    this.listTipoArrivoInBassoTrasc = this.formController.getTrascodificaByCodice(constants.MONTASCALE_TIPO_ARRIVO_BASSO_GUIDA , this.trascodifiche);

    this.listTipoArrivoInBassoTrasc.forEach((el, index) => {
      if(index <= 3){
        //inserisco i primi 4 elementi
        this.listFirstLineArrivoInBassoTrasc.push(el);  
      }else if(index > 3 && index <= 6){
        //inserisco i prossimi 3 elementi
        this.listSecondLineArrivoInBassoTrasc.push(el);
      }else if(index > 6 && index <= 10){
        //inserisco gli ultimo 3 elementi
        this.listThirdLineArrivoInBassoTrasc.push(el);
      }
    });
  }

  showIsInput(element: Trascodifica): boolean {
    const allowedDescriptions = [
      constants.MONTASCALE_DRITTO_SUL_PIANEROTTOLO,
      constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ,
      constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_VERTIC
    ];
  
    return allowedDescriptions.includes(element.descrizione);
  }

  showMeasurementInput(element: Trascodifica): string {
    const allowedDescriptions = [
      constants.MONTASCALE_DRITTO_SUL_PIANEROTTOLO,,
      constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ,
      constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_VERTIC
    ];
  
    if(allowedDescriptions.includes(element.descrizione)){
      if(element.descrizione === constants.MONTASCALE_DRITTO_SUL_PIANEROTTOLO){
        return 'mm';
      }else if(element.descrizione === constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_ORIZZ){
        return '°';
      }else if(element.descrizione === constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_VERTIC){
        return '°';
      }else{
        return '';
      }
    }
  }

  filtedUnderBoxDescription(element: Trascodifica): string {
    const allowedDescriptions = [
      constants.MONTASCALE_DRITTO_SUL_PIANEROTTOLO,
      constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_90_VERTICALE,
      constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_180_VERTICALE,
      constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_VERTIC
    ];

    if(allowedDescriptions.includes(element.descrizione)){
      if( element.descrizione === constants.MONTASCALE_DRITTO_SUL_PIANEROTTOLO){
          return 'Prolungamento minimo 550/600 mm';
      }else if( element.descrizione === constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_90_VERTICALE 
                      || element.descrizione === constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_180_VERTICALE 
                      || element.descrizione === constants.MONTASCALE_CON_CURVA_DI_PARCHEGGIO_A_VERTIC){
          return 'Guida soggetta a possibili innalzamenti';
      }else{
          return '';
      }
    }
  }

}