import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { constants } from 'src/app/modules/classes/constants';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';

@Component({
  selector: 'app-promo-bagni',
  templateUrl: './promo-bagni.component.html',
  styleUrls: ['./promo-bagni.component.scss']
})
export class PromoBagniComponent implements OnInit {

  @Input()
  form: any;

  @Input() trascodifiche:Trascodifica[];

  @Input()
  brand: string;
  
  availablePromo:Trascodifica[];

  constructor(
    private formControllerService: FormControllerService
  ) { }

  ngOnInit(): void {
    let idProduct = Number(localStorage.getItem("id_product"));
    if(idProduct !== null || idProduct !== undefined){
      this.availablePromo = this.trascodifiche.filter(trascodifica => trascodifica.codice === constants.COD_PROMO && trascodifica.prodottoId === idProduct)
    }
  }

  onChange(transcodifica: any): void {
    this.form.ordine.promoId = transcodifica.value;
  }

  returnPromoDesc(): string {
    if(this.form.ordine.promoId){
      return this.formControllerService.getTrascodificaById(this.form.ordine.promoId, this.trascodifiche)?.descrizione || '';
    }else{
      return '';
    }
  }
}
