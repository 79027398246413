import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Trascodifica } from '../../../../../classes/trascodifica';
import { BrandService } from '../../../../../../services/brand/brand.service';
import { StufeService } from '../services/stufe.service';
import { FormControllerService } from '../../../../../../services/controller/form-controller.service';

@Component({
  selector: 'app-accessori-stufa',
  templateUrl: './accessori-stufa.component.html',
  styleUrls: ['./accessori-stufa.component.scss']
})
export class AccessoriStufaComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche: Trascodifica[];
  tipiPuffer: Trascodifica[];
  accStufa: any;
  constructor(
      private brandService: BrandService,
      private stufeService: StufeService,
      private formController: FormControllerService
  ) { }

  ngOnInit(): void {
    this.getTranscodifiche();
    this.form.sections.accStufa = {
      installazionestand: false,
      accessotettobotola: false,
      ponteggio: false,
      cestaidr: false,
      scambiatore: false,
      aspiracenere: false,
      modulowifi: false,
      pelletomaggio: false,
    };
    this.getAccStufa();
  }

  getAccStufa(): void {
    this.stufeService.getAccStufa(this.form.idOrder , 'ordineId.equals=').subscribe(res => {
      this.accStufa = res[0];
      if (this.accStufa) {
        this.form.sections.accStufa = this.accStufa;
      }
    });
  }

  getTranscodifiche(): void {
		this.tipiPuffer = this.formController.getTrascodificaByCodice('TIPO_PUFFER',this.trascodifiche);
  }

}
