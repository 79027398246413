<div class="row">
    <div class="col-lg-6">
        <div
            [ngClass]="{'anagraficaContainer': brand === 'ariel', 'paymentBorderBlue': brand === 'bagni' || brand === 'remail' , 'hepl75Cont': brand === 'bagniHelp75' , 'fotovoltaicoCont': brand === 'fotovoltaicoAriel', 'paymentPratico' : brand === 'bagno-pratico', 'montascaleCont': brand === 'zucchetti', 'cristalBoxCont': brand === 'cristalBox' }">
            <div class="font-weight-bold mb-3">PAGAMENTO</div>
            <div class="">
                <div class="d-inline-block">
                    <app-payment-input-number
                        (onNumChange)="form.sections.pagamento[stepPagamento].totaleordine = $event; totaleOrdineTofix($event); calcTotaleDovuto(form.sections.pagamento[stepPagamento]?.totaleordine , null);"
                        [step]="0.1" [num]="form.sections.pagamento[stepPagamento]?.totaleordine" [decimal]="true"
                        [payment]="true" label="Totale ordine €"></app-payment-input-number>
                    <div class="smallFont text-danger mb-3"
                        *ngIf="!form.sections.pagamento[stepPagamento]?.totaleordine">
                        Campo obbligatorio
                    </div>

                    <label id="example-radio-group-label4">Inclusa IVA</label>
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-button"
                        color="primary" [(ngModel)]="form.sections.pagamento[stepPagamento].percivaId" required>
                        <mat-radio-button class="mr-2 d-inline-block" [value]="iva.id"
                            *ngFor="let iva of tipiIva">{{iva.descrizione}}</mat-radio-button>
                        <mat-hint class="smallFont text-danger"
                            *ngIf="!form.sections.pagamento[stepPagamento].percivaId">Campo obbligatorio</mat-hint>
                        <span class="undo material-icons" matTooltip="Cancella selezione"
                            *ngIf="form.sections.pagamento[stepPagamento]?.percivaId"
                            (click)="form.sections.pagamento[stepPagamento].percivaId = null">
                            backspace
                        </span>
                    </mat-radio-group>
                    

                    <!--TIPO DETRAZIONI-->
                    <mat-radio-group color="primary" aria-labelledby="example-radio-group-label6" *ngIf="prodotto !== 'depuratore' && prodotto !== 'montascale'"
                        class="example-radio-button" [(ngModel)]="form.sections.pagamento[stepPagamento]!.tipodetrazioneId">
                        <mat-radio-button class="mr-2 d-inline-block" [value]="tipoDet.id"
                            *ngFor="let tipoDet of tipiDetrazione" (change)="checkDetrazione($event)">{{tipoDet.descrizione}}</mat-radio-button>

                        <div class="smallFont text-danger mb-3"
                            *ngIf="!form.sections.pagamento[stepPagamento]?.tipodetrazioneId">
                            Campo obbligatorio
                        </div>
                    </mat-radio-group>

                    <div class="smallFont text-danger mb-3"
                        *ngIf="prodotto === 'depuratore'">
                        Questo prodotto non beneficia di detrazioni fiscali 
                    </div>

                    <app-payment-input-number
                        (onNumChange)="form.sections.pagamento[stepPagamento].totaledovuto = $event; totaleDovutoTofix($event); calcSaldo($event,form.sections.pagamento[stepPagamento]?.caparraconf);"
                        [step]="0.1" [num]="form.sections.pagamento[stepPagamento]?.totaledovuto" [decimal]="true"
                        [payment]="true" label="Totale dovuto dal cliente €"></app-payment-input-number>
 
                    <div class="smallFont text-danger mb-3"
                        *ngIf="!form.sections.pagamento[stepPagamento]?.totaledovuto">
                        Campo obbligatorio
                    </div>
 
                    <div>
                        <app-payment-input-number
                            (onNumChange)="form.sections.pagamento[stepPagamento].caparraconf = $event; CaparraTofix($event);calcSaldo(form.sections.pagamento[stepPagamento]?.totaledovuto,$event)"
                            [step]="0.1" [num]="form.sections.pagamento[stepPagamento]?.caparraconf" [decimal]="true"
                            [payment]="true"
                            label="Caparra confirmatoria € {{ brand === 'bagniHelp75' ? '(minimo 30% del totale ordine)' : ''}}"
                            [isValid] = "isCaparraConfIsValid(stepPagamento, brand)"
                            ></app-payment-input-number>
                    </div> 
                    <div class="smallFont text-danger mb-3"
                        *ngIf="form.sections.pagamento[stepPagamento]?.caparraconf == undefined">
                        Campo obbligatorio
                    </div>

                    <label id="example-radio-group-label6">Metodo di pagamento</label>
                    <mat-radio-group color="primary" aria-labelledby="example-radio-group-label6"
                        class="example-radio-button" [(ngModel)]="form.sections.pagamento[stepPagamento]!.metodopagId">
                        <mat-radio-button class="mr-2 d-inline-block" [value]="pagamento.id"
                            *ngFor="let pagamento of tipiPagamento">{{pagamento.descrizione}}</mat-radio-button>

                        <span class="undo material-icons" matTooltip="Cancella selezione"
                            *ngIf="form.sections.pagamento[stepPagamento]?.metodopagId"
                            (click)="form.sections.pagamento[stepPagamento].metodopagId = ''">
                            backspace
                        </span>
                        <div class="smallFont text-danger mb-3"
                            *ngIf="!form.sections.pagamento[stepPagamento]?.metodopagId && form.sections.pagamento[stepPagamento]?.caparraconf > 0">
                            Campo obbligatorio
                        </div>
                    </mat-radio-group>

                    <app-payment-input-number
                        (onNumChange)="form.sections.pagamento[stepPagamento].saldoinizlav = $event; SaldoTofix($event)"
                        [step]="0.1" [num]="form.sections.pagamento[stepPagamento]?.saldoinizlav" [decimal]="true"
                        [payment]="true"
                        label="{{brand === 'bagniHelp75'  ? '* Saldo prima dei lavori* €' : '* Saldo €'}}"></app-payment-input-number>
                    <div class="smallFont text-danger mb-3"
                        *ngIf="form.sections.pagamento[stepPagamento]?.saldoinizlav == undefined">
                        Campo obbligatorio
                    </div>

                    <label id="example-radio-group-label8">Tipo di saldo</label>
                    <mat-radio-group color="primary" aria-labelledby="example-radio-group-label7"
                        class="example-radio-button mb-2"
                        [(ngModel)]="form.sections.pagamento[stepPagamento]!.tipopagsaldoId">
                        <mat-radio-button class="mr-2 d-inline-block" [value]="saldo.id"
                            *ngFor="let saldo of tipiSaldo">{{saldo.descrizione}}</mat-radio-button>

                        <span class="undo material-icons" matTooltip="Cancella selezione"
                            *ngIf="form.sections.pagamento[stepPagamento]?.tipopagsaldoId"
                            (click)="form.sections.pagamento[stepPagamento].tipopagsaldoId = ''">
                            backspace
                        </span>
                    </mat-radio-group>
                    <div class="smallFont text-danger mb-3"
                        *ngIf="!form.sections.pagamento[stepPagamento]?.tipopagsaldoId">
                        Campo obbligatorio
                    </div>

                    <mat-checkbox *ngIf="prodotto !== 'montascale' && prodotto !== 'depuratore'" [(ngModel)]="form.sections.pagamento[stepPagamento]!.fatturaimmediata"
                        class="d-inline-block" color="primary">Fattura immediata</mat-checkbox>

                    <div *ngIf="brand === 'remail' || brand === 'cristalBox'">
                        <div *ngIf="typeSaldo?.descrizione=== 'CENTAX' && typeSaldo?.codice=== 'TIPOPAGSALDO'">
                            <label id="example-radio-group-label7">Accettazione richiesta</label>
                            <mat-radio-group labelPosition="after" class="mb-2" color="primary" aria-label="Tipo"
                                [(ngModel)]="form.sections.pagamento[stepPagamento]!.accettazrich">
                                <mat-radio-button class="mb-2 mr-2" [value]="true">Si</mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <mat-form-field class="full-width" appearance="fill">
                            <mat-label>Note del pagamento</mat-label>
                            <textarea matInput [(ngModel)]="form.sections.pagamento[stepPagamento]!.note"></textarea>
                        </mat-form-field>
                    </div>

                    <div *ngIf="prodotto === 'montascale' || prodotto === 'depuratore'">
                        <mat-form-field class="full-width" appearance="fill">
                            <mat-label>Note del pagamento</mat-label>
                            <textarea matInput [(ngModel)]="form.sections.pagamento[stepPagamento]!.note"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-center text-danger mt-4" *ngIf="brand === 'bagniHelp75' || prodotto === 'pompaCalore' || prodotto === 'montascale' || brand === 'fotovoltaicoAriel'">
    * IL SALDO DEVE ESSERE CORRISPOSTO ENTRO 20 GIORNI DECORRENTI DALLA DATA DI SOTTOSCRIZIONE DEL CONTRATTO
    <!--*IL SALDO DEVE ESSERE CORRISPOSTO ENTRO 15 (QUINDICI) GIORNI LAVORATIVI PRIMA DELLA DATA DI INSTALLAZIONE-->
</div>