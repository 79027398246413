import { AfterViewInit, Component, DoCheck, OnInit } from '@angular/core';
import { Signature } from '../../../../classes/signature';
import { AnagraficaDTO } from '../../../bagni-italiani/main-bagni/classes/anagrafica';
import { Trascodifica } from '../../../../classes/trascodifica';
import { Ordine } from '../../../../classes/ordine';
import { FormControllerService } from '../../../../../services/controller/form-controller.service';
import { UserService } from '../../../../../services/user/user.service';
import { AnagraficaService } from '../../../bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { BrandService } from '../../../../../services/brand/brand.service';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../../../../../services/orders/orders.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentService } from '../../../../../services/payment/payment.service';
import { PagamentoDTO } from '../../../bagni-italiani/main-bagni/classes/dati-tecnici';
import { CaldaiaService } from './services/caldaia.service';
import { DettagliocaldaiaDTO } from '../../../../classes/caldaie/dettagliocaldaia-dto';
import { DisegnocaldaiaDTO } from '../../../../classes/caldaie/disegnocaldaia-dto';
import { AnagraficaForm } from '../../../../classes/anagrafica';
import { AbitazioneDTO } from '../../ariel-main/classes/abitazione-dto';
import { AbitazioneService } from '../../ariel-main/services/abitazione.service';
import { AccessoricaldaiaDTO } from '../../../../classes/caldaie/accessoricaldaia-dto';
import { OmaggioService } from '../../../bagni-italiani/main-bagni/services/omaggio/omaggio.service';
import { OmaggioDto } from '../../../../classes/omaggio-dto';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery';

declare var jQuery: any;
@Component({
	selector: 'app-caldaia',
	templateUrl: './caldaia.component.html',
	styleUrls: ['./caldaia.component.scss']
})
export class CaldaiaComponent implements OnInit, DoCheck, AfterViewInit {
	
	generatingPdf = false;
	showFrame = false;
	openSign:boolean=false;
	sendingPdf : boolean = false;
	showModalPrint: boolean = false;
	confirmCaldaia: boolean = false;
	validAnagrafica: boolean = false;

	currentStep:number = 0;
	valueProgBar = 0;
	currentPrinting = 0;
	idCliente: number;

	urlFrame:string = "";

	formDiffer: any;
	originalForm: any;
	obj1: any;
	obj2: any;
	printContent: any;
	form: any;
	pdf: any;
	error:any;
	member:any;
	signature: Signature;
	
	anagrafica: AnagraficaDTO;
	trascodifiche: Trascodifica[];
	totalSteps: number[];
	ordine: Ordine;

	integratedSignature = environment.integratedSignature;
	hasValues = (obj) => Object.values(obj).some(v => v !== null && typeof v !== "undefined");

	constructor(
		public formController: FormControllerService,
		public userService: UserService,
		private anagraficaService: AnagraficaService,
		private brandService: BrandService,
		private route: ActivatedRoute,
		private orderService: OrdersService,
		private snackbar: MatSnackBar,
		private paymentService: PaymentService,
		private caldaiaService: CaldaiaService,
		private abitazioneService: AbitazioneService,
		private omaggioService: OmaggioService,
	) {
		this.showModalPrint = false;
		this.validAnagrafica = false;
		this.form = {
			idOrder: Number(this.route.snapshot.paramMap.get('id')),
			anagrafica: new AnagraficaForm(),
			sections: {
				caldaie: [],
				dettaglioCaldaia: new DettagliocaldaiaDTO(),
				abitazione: new AbitazioneDTO(),
				pagamento: [new PagamentoDTO()],
				disegnoCaldaia: new DisegnocaldaiaDTO(),
				accessori: new AccessoricaldaiaDTO(),
				ordine: null,
				omaggio : [new OmaggioDto()],
			}
		};
		this.totalSteps = Array(8).fill(1).map((x, i) => i); // [0,1,2,3,4]
	}
	
	getOrder(idOrder): void {
		this.orderService.getSingleOrder(idOrder).subscribe(res => {
			this.ordine = res;
			this.form.ordine = res;
			localStorage.setItem('id_product', res.prodottoId);
			if(res.clienteId !== undefined  && res.clienteId !== null){
				localStorage.setItem('id_client', res.clienteId);	
				this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));
			}
			
			localStorage.setItem('id_order', res.idordine);
		});
		this.brandService.getDataByProduct(Number(localStorage.getItem('id_product'))).subscribe(res => {
			this.trascodifiche = res;
		});
	}
	
	ngOnInit(): void {
		this.originalForm = JSON.parse(JSON.stringify(this.form));
		
		if(localStorage.getItem('id_client') !== null ){
			this.form.anagrafica.anagrafica.id = Number(localStorage.getItem('id_client'));	
		}
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}
		this.getOrder(this.form.idOrder);
	}

	ngDoCheck(): void {
		this.obj1 = JSON.stringify(this.originalForm);
		this.obj2 = JSON.stringify(this.form);
		if (this.obj1 !== this.obj2) {
			this.checkAnagrafica();
			this.originalForm = JSON.parse(JSON.stringify(this.form));
		}
	}

	ngAfterViewInit(): void {
		console.log('origina form', this.originalForm);
	}

	setStep(num): void {
		this.currentStep = num;
		if (this.currentStep > 0) {
			$('.imgModulo').addClass('yesShow');
		} else {
			$('.imgModulo').removeClass('yesShow');
		}
		this.scrollTop();
	}

	checkAnagrafica(): boolean {
		this.validAnagrafica = this.formController.checkAnagrafica(this.form);
		return this.validAnagrafica;
	}

	validCannaFumaria(): boolean {
		if (this.form.sections.accessori.accessotetto == null) {
			return false;
		}

		if (this.form.sections.accessori.accessotetto == false && !this.form.sections.accessori.cestaidr && !this.form.sections.accessori.ponteggio){
			return false;
		}

		if(this.form.sections.accessori.valvolatermo == null){
			return false;
		}
		return true;
	}

	getOrdine(): void {
		this.orderService.getSingleOrder(this.form.idOrder).subscribe(res => {
			this.form.anagrafica.infoContratto.consulente = res.venditoreLogin;
			this.form.anagrafica.agente = res.venditoreLogin;
		}, error => {
			console.error('Error => ', error);
		});
	}

	scrollTop(): void {
		$('html, body').animate({ scrollTop: 0 }, 300);
	}

	saveModule(value): void {
		$('body').removeClass('noScr');
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
		if (this.currentStep === 0) {
			// Step salvataggio anagrafica
			this.anagrafica = this.form.anagrafica.anagrafica;
			if(this.anagrafica.id){
				this.anagraficaService.update(JSON.stringify(this.anagrafica)).subscribe(res => {
					// this.openSnackBar('Modulo salvato correttamente');
						this.ordine.clienteId = res.id;
						this.ordine.cognome = res.cognome;
						this.ordine.nome = res.nome;
						this.orderService.update(JSON.stringify(this.ordine)).subscribe();
					}, error => {
						console.error('Error => ', error);
						this.openSnackBar('Errore in fase di salvataggio del modulo');
					});				
			}else{
				this.anagraficaService.save(JSON.stringify(this.anagrafica)).subscribe(res => {
					// this.openSnackBar('Modulo salvato correttamente');
						this.ordine.clienteId = res.id;
						this.ordine.cognome = res.cognome;
						this.ordine.nome = res.nome;
						this.form.anagrafica.anagrafica.id = res.id;
						this.orderService.update(JSON.stringify(this.ordine)).subscribe();
					}, error => {
						console.error('Error => ', error);
						this.openSnackBar('Errore in fase di salvataggio del modulo');
					});	
			}				
		} else if (this.currentStep === 1) {
			this.confirmCaldaia = value;
			if (!this.confirmCaldaia && this.moreThanOneCaldaia()){
				this.openConfirmCaldaia(false);
			} else {
				this.confirmCaldaia = true;
			}
			if (this.confirmCaldaia){
				// Step salvataggio caldaie
				const savingCaldaie = [];
				this.form.sections.caldaie.forEach(r => {
					if (r.checked) {
						savingCaldaie.push(r);
					}
					if (!r.checked && r.id !== undefined) {
						this.removeCaldaia(r.id);
					}
				});
				savingCaldaie.forEach(s => {
					delete s.checked;
					delete s.coloreDescrizione;
					if (s.id !== undefined) {
						this.updateCaldaia(s);
					} else {
						this.saveCaldaia(s);
					}
				});
				
				if (this.hasValues(this.form.sections.dettaglioCaldaia)) {
					this.form.sections.dettaglioCaldaia.ordineId = this.form.idOrder;
					if (this.form.sections.dettaglioCaldaia.id) {
						this.updateDettaglio(this.form.sections.dettaglioCaldaia);
					} else {
						this.saveDettaglio(this.form.sections.dettaglioCaldaia);
					}
				}
			}
		} else if (this.currentStep === 2) {
			
			// Step salvataggio disegno caldaia
			if (this.hasValues(this.form.sections.disegnoCaldaia)) {
				this.form.sections.disegnoCaldaia.ordineId = this.form.idOrder;
				if (this.form.sections.disegnoCaldaia.id) {
					this.updateDisegno(this.form.sections.disegnoCaldaia);
				} else {
					this.saveDisegno(this.form.sections.disegnoCaldaia);
				}
			}

			// Step salvataggio dati abitazione
			if(this.hasValues(this.form.sections.abitazione)){
				this.form.sections.abitazione.ordineId = this.form.idOrder;
				if (this.form.sections.abitazione.id) {
					this.abitazioneService.update(JSON.stringify(this.form.sections.abitazione)).subscribe();
				} else {
					this.abitazioneService.save(JSON.stringify(this.form.sections.abitazione)).subscribe();
				}
			}			
		} else if (this.currentStep === 3) {
			// Step salvataggio accessori caldaia
			if(this.hasValues(this.form.sections.accessori)){
				this.form.sections.accessori.ordineId = this.form.idOrder;
				if (this.form.sections.accessori.id) {
					this.updateAccessori(this.form.sections.accessori);
				} else {
					this.saveAccessori(this.form.sections.accessori);
				}
			}
		} else if (this.currentStep === 4){
			//Step salvataggio omaggioDTO
			for(var i= 0; i < this.form.sections.omaggio.length; i++){
				var omaggio:OmaggioDto= this.form.sections.omaggio[i];
				omaggio.ordineId = this.form.idOrder;
				if( omaggio.id === undefined ||  omaggio.id === null){
					this.omaggioService.save(JSON.stringify( omaggio)).subscribe();
				}else{
					this.omaggioService.update(JSON.stringify( omaggio)).subscribe();
				}
			}
			
		}else if (this.currentStep === 5) {
			// Step salvataggio pagamento
			if(this.hasValues(this.form.sections.pagamento[0])){
				this.form.sections.pagamento[0].ordineId = this.form.idOrder;
				const payment = this.form.sections.pagamento[0];
				if (payment.id === undefined || payment.id === null) {
					this.paymentService.save(JSON.stringify(payment)).subscribe();
				} else {
					this.paymentService.update(JSON.stringify(payment)).subscribe();
				}
			}
		}else if (this.currentStep === 6){
			//Step salvataggio privacy
			this.orderService.update(JSON.stringify( this.form.ordine)).subscribe();
		} else if(this.currentStep === 7) {
			this.anagraficaService.update(JSON.stringify(this.form.anagrafica.anagrafica)).subscribe(res => {
				// this.openSnackBar('Modulo salvato correttamente');
				this.ordine.clienteId = res.id;
				this.ordine.cognome = res.cognome;
				this.ordine.nome = res.nome;
			}, error => {
				console.error('Error => ', error);
				this.openSnackBar('Errore in fase di salvataggio del modulo');
			});			
		}
		
		if (this.confirmCaldaia && this.currentStep === 1){
			this.setStep(this.currentStep + 1);
		} else if (!this.confirmCaldaia && this.currentStep === 1) {
			console.log('stop');
		} else {
			this.setStep(this.currentStep + 1);
		}
	}
	
	moreThanOneCaldaia(): boolean {
		let count = 0;
		this.form.sections.caldaie.forEach(r => {
			if (r.checked) {
				count += 1;
			}
		});
		if (count > 1) {
			return true;
		} else {
			return false;
		}
	}
	
	openConfirmCaldaia(value): void{
		this.confirmCaldaia = value;
		jQuery('#confirmModal').modal({backdrop: 'static', keyboard: false});
		jQuery('#confirmModal').modal('toggle');
		if (this.confirmCaldaia) {
			this.saveModule(true);
		}
	}

	saveCaldaia(caldaia): void {
		this.caldaiaService.save(JSON.stringify(caldaia)).subscribe(res => {
			// this.openSnackBar('Modulo salvato correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di salvataggio del modulo');
		});
	}

	updateCaldaia(caldaia): void {
		this.caldaiaService.update(JSON.stringify(caldaia)).subscribe(res => {
			// this.openSnackBar('Modulo aggiornato correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di aggiornamento del modulo');
		}
		);
	}

	saveDettaglio(dettaglio): void {
		this.caldaiaService.saveDettaglio(JSON.stringify(dettaglio)).subscribe(res => {
			// this.openSnackBar('Modulo salvato correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di salvataggio del modulo');
		});
	}

	updateDettaglio(dettaglio): void {
		this.caldaiaService.updateDettaglio(JSON.stringify(dettaglio)).subscribe(res => {
			// this.openSnackBar('Modulo aggiornato correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di aggiornamento del modulo');
		}
		);
	}

	saveDisegno(disegno): void {
		this.caldaiaService.saveDisegno(JSON.stringify(disegno)).subscribe(res => {
			// this.openSnackBar('Modulo salvato correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di salvataggio del modulo');
		});
	}

	updateDisegno(disegno): void {
		this.caldaiaService.updateDisegno(JSON.stringify(disegno)).subscribe(res => {
			// this.openSnackBar('Modulo aggiornato correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di aggiornamento del modulo');
		}
		);
	}

	saveAccessori(accessori): void {
		this.caldaiaService.saveAccessori(JSON.stringify(accessori)).subscribe(res => {
			// this.openSnackBar('Modulo salvato correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di salvataggio del modulo');
		});
	}

	updateAccessori(accessori): void {
		this.caldaiaService.updateAccessori(JSON.stringify(accessori)).subscribe(res => {
			// this.openSnackBar('Modulo aggiornato correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di aggiornamento del modulo');
		}
		);
	}

	removeCaldaia(id): void {
		this.caldaiaService.delete(JSON.stringify(id)).subscribe(res => {
			// this.openSnackBar('Stufa rimossa correttamente');
		}, error => {
			console.error('Error => ', error);
			// this.openSnackBar('Errore in fase di aggiornamento del modulo');
		}
		);
	}

	openSnackBar(text): void {
		this.snackbar.open(text, 'Ok', {
			duration: 4000
		});
	}
	
  	togglePrintPreview(show:boolean): void {
		this.showModalPrint = show;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			this.ordine = res;
			jQuery('#printModal').modal({ backdrop: 'static', keyboard: false });
			jQuery('#printModal').modal('toggle');
	    })
  	}	
	
	returnIdOrdine(): string {
		return localStorage.getItem('id_order');
	}
	
  	validCaldaie(): boolean {
    	let valid = true;
    		if (this.form.sections.caldaie.length > 0) {
      			for (let i = 0; i < this.form.sections.caldaie.length; i++) {
        			if (valid) {
          				if (this.form.sections.caldaie[i].checked && !this.form.sections.caldaie[i].tipologiaId) {
            				valid = false;
          				} else if (this.form.sections.caldaie[i].checked && this.form.sections.caldaie[i].tipologiaId && this.form.sections.caldaie[i].modcaldcondecowdId && !this.form.sections.caldaie[i].coloreId){
            				valid = false;
          			}else{
				  valid = true;
		  		}
        	}
      	 }
       }
      return valid;
 	}

	validDatiAbitazione(): boolean {
		if (
			this.form.sections.abitazione.pianointerv === undefined ||
			this.form.sections.abitazione.pianointerv == null ||
			this.form.sections.abitazione.pianistabile === undefined ||
			this.form.sections.abitazione.pianistabile == null ||
			this.form.sections.abitazione.mq === undefined ||
			this.form.sections.abitazione.mq == null ||
			this.form.sections.abitazione.mq < 1 ||
			!this.form.sections.abitazione.cannafumariaId
		) {
			return false;
		} else {
			return true;
		}
	}
	
	validDettagliInstallazione(): boolean{
		if (!this.form.sections.accessori.installazioneId ||
			!this.form.sections.accessori.uscitafumiId ||
			!this.form.sections.accessori.prelievoariaId ||
			!this.form.sections.accessori.scaricocondId){
			return false;
		} else {
			return true;
		}
	}
	
	validDisegnoCaldaia(): boolean {
		if (this.form.sections.disegnoCaldaia.tipoId) {
			if (this.form.sections.disegnoCaldaia.tipoId === 1341) {
				delete this.form.sections.disegnoCaldaia.altezza;
				delete this.form.sections.disegnoCaldaia.larghezza;
				delete this.form.sections.disegnoCaldaia.profondita;
				return true;
			} else {
				if (this.form.sections.disegnoCaldaia.altezza === undefined ||
					this.form.sections.disegnoCaldaia.altezza == null ||
					this.form.sections.disegnoCaldaia.altezza < 0.1 ||
					this.form.sections.disegnoCaldaia.larghezza === undefined ||
					this.form.sections.disegnoCaldaia.larghezza == null ||
					this.form.sections.disegnoCaldaia.larghezza < 0.1 ||
					this.form.sections.disegnoCaldaia.profondita === undefined ||
					this.form.sections.disegnoCaldaia.profondita == null ||
					this.form.sections.disegnoCaldaia.profondita < 0.1
				){
					return false;
				} else {
					return true;
				}
			}
		} else {
			return false;
		}
	}
	
	validPrivacy(): boolean {
		if(!this.form.ordine.accettprivacy || !this.form.ordine.accettprivacynd || !this.form.ordine.accettprivacyrd){
			return false;
		}else
			return true;
	}
	
	validAccessori(): boolean {
		if (this.form.sections.accessori.cronowifi && !this.form.sections.accessori.numcronowifi){
			return false;
		}
		if (this.form.sections.accessori.cronostand && !this.form.sections.accessori.numcronostand){
			return false;
		}
		return true;
	}
	
	validPagamento(): boolean {
		if (!this.form.sections.pagamento.totaleordine || !this.form.sections.pagamento.totaledovuto || !this.form.sections.pagamento.caparraconf || !this.form.sections.pagamento.metodopagId || !this.form.sections.pagamento.saldoinizlav || !this.form.sections.pagamento.tipopagsaldoId) {
			return false;
		}
		return true;
	}
	
	closeSignPdf(){
		jQuery('#signModal').modal({backdrop: 'static', keyboard: false});
		jQuery('#signModal').modal('toggle');
	}

	edit() {
		this.currentStep = 0;
		this.openSign = false;
		this.orderService.getSingleOrder(this.form.idOrder).subscribe((res) => {
			res.statopraticaCodice = "A";
			res.statopraticaId = 2;
			this.orderService.update(JSON.stringify(res)).subscribe( res => {
				this.getOrder(this.form.idOrder);
			});
		})
	}

	reload(value: boolean) {
		this.openSign = value;
	}
}