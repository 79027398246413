import { Component, Input, OnInit } from '@angular/core';
import { Trascodifica } from 'src/app/modules/classes/trascodifica';
import { FormControllerService } from 'src/app/services/controller/form-controller.service';
import { PortaService } from 'src/app/services/porta/porta.service';

@Component({
  selector: 'app-form-porta',
  templateUrl: './form-porta.component.html',
  styleUrls: ['./form-porta.component.scss']
})
export class FormPortaComponent implements OnInit {
  @Input() form: any;
  @Input() trascodifiche: any;
  listColore: Trascodifica[];
  listApertura: Trascodifica[];
  constructor(private formController:FormControllerService, private portaService: PortaService) { }

  ngOnInit(): void {
    this.listColore = this.formController.getTrascodificaByCodice('COLORE_PORTA', this.trascodifiche);
    this.listApertura = this.formController.getTrascodificaByCodice('APERTURA_PORTA', this.trascodifiche);

    this.portaService.findByKey(this.form.idOrder,'ordineId.equals=').subscribe(res => {
      if(res[0] !== undefined)
        this.form.sections.porta = res[0];
    }) ;
    
  }

}
