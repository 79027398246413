<div class="pt-5 text-center mb-4">
</div>

<div class="row buttonRow mb-3">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 9 && openSign" (click)="setStep(currentStep-1)"
            class="'btnMainColor' {{(currentStep==0 || currentStep==1 || currentStep==2|| currentStep==4|| currentStep==5|| currentStep==6|| currentStep==7|| currentStep==8|| currentStep==9) ? 'btnColorGreen':'btnColorBlue'}}"
            mat-flat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button
            [disabled]="generatingPdf ||  (stepController()) || (!validMurettoSx() && currentStep === 1) || (!validMurettoDx() && currentStep === 1) || (!validMurettoDietro() && currentStep === 1) || (!validMurettoDavanti() && currentStep === 1) || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO BOX DOCCIA' , this.trascodifiche)) 
                || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO PIATTO DOCCIA' , this.trascodifiche))
                || (currentStep === 7 && !validPrivacy()) || (currentStep === 6 && !this.formController.validPagamento(this.form))
                || (currentStep === 3 && !formController.validOption(this.form.sections.optionsAvailable,'VASCA' , this.trascodifiche)) 
                || (currentStep === 4 && !this.formController.validOmaggio(this.form))"
            class="'btnMainColor' {{(currentStep==0 ||currentStep==1 || currentStep==2|| currentStep==4|| currentStep==5|| currentStep==6|| currentStep==7|| currentStep==8|| currentStep==9) ? 'btnColorGreen':'btnColorBlue'}}"
            mat-flat-button (click)="saveModule()">Avanti ></button>
        <div>
            <div class="smallFont text-danger"
                *ngIf="(stepController()) || (!validMurettoSx() && currentStep === 1) || (!validMurettoDx() && currentStep === 1) || (!validMurettoDietro() && currentStep === 1) || (!validMurettoDavanti() && currentStep === 1) || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO BOX DOCCIA' , this.trascodifiche))
                || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO PIATTO DOCCIA' , this.trascodifiche))  || (currentStep === 6 && !this.formController.validPagamento(this.form))
                || (currentStep === 3 && !formController.validOption(this.form.sections.optionsAvailable,'VASCA' , this.trascodifiche)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 7 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>
</div>

<div class="formContainer">
    <div class="text-center mb-4">
        <h4>
            <span class="imgModulo">
                <img alt="" class="" src="assets/img/loghi/cristalbox.jpg" />
            </span>
            Modulo d'ordine vasca in doccia Cristalbox
        </h4>
    </div>
    <hr />
    <div class="moduleContainer mb-3">
        <div id="toPrint">
            <div class="" *ngIf="currentStep == 0">
                <app-anagrafica-sh [brand]="'cristalbox'" [validAnagrafica]="validAnagrafica" [form]="form">
                </app-anagrafica-sh>
            </div>

            <div class="" *ngIf="currentStep == 1">
                <div class="text-center mb-3">

                </div>
                <div>
                    <app-promo-bagni [brand]="'cristalbox'" [form]="form" [trascodifiche]="trascodifiche"></app-promo-bagni>
                    <cristalbox-datitecnicivasca [form]="form" [trascodifiche]="trascodifiche"></cristalbox-datitecnicivasca>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 2">
                <div class="text-center mb-3">

                </div>
                <div>
                    <div class="row originalRow">
                        <div class="col-lg-6">
                            <cristalbox-nuovidatitecnici [form]="form" [trascodifiche]="trascodifiche"></cristalbox-nuovidatitecnici>
                            <crisalbox-doccia [form]="form" [trascodifiche]="trascodifiche"></crisalbox-doccia>
                            <br>
                        </div>
                        <div class="col-lg-6">
                            <cristalbox-nuovopiattodoccia [form]="form" [trascodifiche]="trascodifiche">
                            </cristalbox-nuovopiattodoccia>
                        </div>
                    </div>

                    <div class="row originalRow">
                        <div class="col-lg-6">
                            <cristalbox-gres [form]="form" [trascodifiche]="trascodifiche"></cristalbox-gres>
                        </div>
                        <div class="col-lg-6">
                            <cristalbox-miscelatori [form]="form" [trascodifiche]="trascodifiche"></cristalbox-miscelatori>
                        </div>
                    </div>

                    <div class="row originalRow">
                        <div class="col-lg-12">
                            <div class="anagraficaContainer">
                                <mat-form-field class="full-width" appearance="fill">
                                    <mat-label>Note</mat-label>
                                    <textarea matInput [(ngModel)]="form.ordine.note"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 3">
                <div class="text-center mb-3">

                </div>
                <div class="row originalRow">

                    <div class="col-12">
                        <cristalbox-vasca [form]="form" [trascodifiche]="trascodifiche"></cristalbox-vasca>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 4">
                <div class="text-center mb-3">

                </div>
                <div class="row">
                    <div class="col-12">
                        <app-disegno-tecnico [form]="form" [brand]="'cristalBox'"></app-disegno-tecnico>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <cristalbox-omaggio [form]="form" [trascodifiche]="trascodifiche"></cristalbox-omaggio>

                        <div class="anagraficaContainer">
                            <mat-form-field class="full-width" appearance="fill">
                                <mat-label>Note Libere</mat-label>
                                <textarea matInput [(ngModel)]="form.sections.piattoDoccia.notelibere"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 5">
                <div class="text-center mb-3">

                </div>
                <div class="row">
                    <div class="col-12">
                        <cristalbox-accessori [form]="form" [trascodifiche]="trascodifiche" [brand]="'crisalBox'"></cristalbox-accessori>
                    </div>
                </div>
            </div>

            <div class="" *ngIf="currentStep == 6">
                <div class="text-center mb-3">
                </div>
                <div>
                    <app-pay-one [form]="form" [brand]="'cristalBox'" [trascodifiche]="trascodifiche"></app-pay-one>
                </div>
            </div>

            <div class="lastStep" *ngIf="currentStep == 7">
                <app-privacy [form]="form" [brand]="'vasca'"></app-privacy>
            </div>

            <div *ngIf="currentStep == 8">
                <app-contacts-check [validAnagrafica]="validAnagrafica" [form]="form"></app-contacts-check>
            </div>

            <div class="lastStep" *ngIf="currentStep == 9">
                <div class="text-center">
                    <div class="digitalSignatureButtons" *ngIf="currentStep == 9 && openSign" style="height: 124px">
                        <div class="signButton" (click)="edit()">
                            <span class="material-icons d-block">
                                edit
                            </span>
                            <span class="d-block mb-2">Modifica Contratto</span>
                        </div>
                    </div>
                    <div class="" *ngIf="form.idOrder">
                        <cristalbox-vasca-in-doccia-print [trascodifiche]="trascodifiche" [form]="form"
                            (reload)="reload($event)"></cristalbox-vasca-in-doccia-print>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row buttonRow">
    <div class="col-6">
        <button *ngIf="currentStep>0" [disabled]="currentStep == 9 && openSign" (click)="setStep(currentStep-1)"
            class="'btnMainColor' {{(currentStep==0 ||currentStep==1 || currentStep==2|| currentStep==4|| currentStep==5|| currentStep==6|| currentStep==7|| currentStep==8|| currentStep==9) ? 'btnColorGreen':'btnColorBlue'}}"
            mat-flat-button>
            < Indietro</button>
    </div>
    <div class="col-6 text-right" *ngIf="currentStep < totalSteps.length">
        <button
            [disabled]="generatingPdf ||  (stepController()) || (!validMurettoSx() && currentStep === 1) || (!validMurettoDx() && currentStep === 1) || (!validMurettoDietro() && currentStep === 1) || (!validMurettoDavanti() && currentStep === 1) || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO BOX DOCCIA' , this.trascodifiche)) 
            || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO PIATTO DOCCIA' , this.trascodifiche)) 
            || (currentStep === 7 && !validPrivacy()) || (currentStep === 6 && !this.formController.validPagamento(this.form))
            || (currentStep === 3 && !formController.validOption(this.form.sections.optionsAvailable,'VASCA' , this.trascodifiche)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))"
            class="'btnMainColor' {{(currentStep==0 ||currentStep==1 || currentStep==2|| currentStep==4|| currentStep==5|| currentStep==6|| currentStep==7|| currentStep==8|| currentStep==9) ? 'btnColorGreen':'btnColorBlue'}}"
            mat-flat-button (click)="saveModule()">Avanti ></button>
        <div>
            <div class="smallFont text-danger"
                *ngIf="(stepController()) || (!validMurettoSx() && currentStep === 1) || (!validMurettoDx() && currentStep === 1) || (!validMurettoDietro() && currentStep === 1) || (!validMurettoDavanti() && currentStep === 1) || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO BOX DOCCIA' , this.trascodifiche))
                || (currentStep === 2 && !formController.validOption(this.form.sections.optionsAvailable,'NUOVO PIATTO DOCCIA' , this.trascodifiche))  || (currentStep === 6 && !this.formController.validPagamento(this.form))
                || (currentStep === 3 && !formController.validOption(this.form.sections.optionsAvailable,'VASCA' , this.trascodifiche)) || (currentStep === 4 && !this.formController.validOmaggio(this.form))">
                Compila tutti i campi obbligatori
            </div>
            <div class="smallFont text-danger" *ngIf="(currentStep === 7 && !validPrivacy())">
                &egrave; necessario accettare tutte le informative per procedere alla firma
            </div>
        </div>
    </div>
    <div class="col-6" *ngIf="currentStep < totalSteps.length"></div>

</div>

<div class="stepper">
    <div class="row">
        <div class="stepCol align-self-center" *ngFor="let step of totalSteps">
            <div class="stepNumber" [ngClass]="
            {
            'doneStep': currentStep > step,
            'currentStep': currentStep == step,
            'errorStep': (!validAnagrafica && currentStep === step) || (!validMurettoSx() && currentStep === step) || (!validMurettoDx() && currentStep === step) || (!validMurettoDietro() && currentStep === step) || (!validMurettoDavanti() && currentStep === step) || (!validAccessori() && currentStep === step) || (currentStep === step && !validPrivacy())
            }">
                <span class="number">{{step+1}}</span>
            </div>
        </div>

        <div class="stepCol align-self-center">
            <div class="stepNumber" [ngClass]="
            {
            'currentStep': currentStep == totalSteps.length
            }">
                <span class="number">
                    <span class="material-icons">
                        check
                    </span>
                </span>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="errorModal" tabindex="-1">
    <app-error-modal [error]="error"></app-error-modal>
</div>

<div class="modal fade" id="timeoutModal" tabindex="-1">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">Tempo limite generazione contratto</h3>
                <button type="button" class="close" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Richiesta presa in carico, il nostro Customer Care provveder&agrave; ad inviare al cliente il
                    contratto per la firma.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mt-5  fidexPdf" *ngIf="trascodifiche && generatingPdf">
    <vasca-in-doccia-print [trascodifiche]="trascodifiche" [form]="form"></vasca-in-doccia-print>
</div>