import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormControllerService } from '../../../services/controller/form-controller.service';
import { UserService } from '../../../services/user/user.service';
import { AnagraficaService } from '../../seller/bagni-italiani/main-bagni/services/anagrafica/anagrafica.service';
import { MainComponent } from '../../../main/main.component';
import { OrdersService } from '../../../services/orders/orders.service';

@Component({
  selector: 'app-contacts-check',
  templateUrl: './contacts-check.component.html',
  styleUrls: ['./contacts-check.component.scss']
})
export class ContactsCheckComponent implements OnInit {
  @Input() form: any;
  @Input() validAnagrafica: any;
  constructor(
      public formController: FormControllerService,
      public userService: UserService,
      private anagraficaService: AnagraficaService,
      private mainCompo: MainComponent,
      private orderService: OrdersService,
  ) { }

  ngOnInit(): void {
    this.getAnagrafica();
  }

  getAnagrafica(): void {
    if(this.form.anagrafica.anagrafica.id !== undefined){
      // recupero i dati del cliente
      this.anagraficaService.findById(this.form.anagrafica.anagrafica.id).subscribe(res => {
        this.form.anagrafica.anagrafica = res;
        this.formController.checkCAP(this.form);
      });
    }
  }

}
