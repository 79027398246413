import { Component, Input, OnInit } from '@angular/core';
import { constants } from '../../../../../classes/constants';
import { DepuratoreService } from 'src/app/services/depuratore/depuratore.service';

@Component({
  selector: 'app-modello-depuratore',
  templateUrl: './modello-depuratore.component.html',
  styleUrls: ['./modello-depuratore.component.scss']
})
export class ModelloDepuratoreComponent implements OnInit {

  @Input() form: any;
  @Input() trascodifiche: any;
  depuratoreTrasc:any;
  depuratoreOptionChoice: boolean = false;

  constructor(
    private depuratoreService: DepuratoreService
  ) { }

  ngOnInit(): void {
    this.initializeDepuratore();
  }

  private initializeDepuratore(): void {
    this.depuratoreService.findByKey(this.form.idOrder, 'ordineId.equals=').subscribe(async res => {
      if (res[0]) {
        this.form.sections.depuratore = res[0];
        this.depuratoreTrasc = await this.trascodifiche.find(el => el.id === this.form.sections.depuratore.tipologiaId);
        console.log(this.depuratoreTrasc)
        if(this.depuratoreTrasc.descrizione === constants.DEPURATORE_ACQUA_BUONA_ARIEL && this.depuratoreTrasc.codice === constants.COD_MODELLO_DEPURATORE){
          this.depuratoreOptionChoice = false;
        }else if(this.depuratoreTrasc.codice === constants.COD_DEPURATORE_ACQUA_BUONA_ARIEL_ABB){
          this.depuratoreOptionChoice = true;
        }
      } else {
        this.setDepuratore();
      }
    });
  }

  setDepuratore(): void {
    if (this.depuratoreOptionChoice) {
      const depuratoreAbbinato = this.trascodifiche.find(el => el.codice === constants.COD_DEPURATORE_ACQUA_BUONA_ARIEL_ABB && el.descrizione === constants.DEPURATORE_ACQUA_BUONA_ARIEL);
      if (depuratoreAbbinato) {
        this.form.sections.depuratore.tipologiaId = depuratoreAbbinato.id;
      }
    }else{
      this.depuratoreTrasc = this.trascodifiche.find(el => el.descrizione === constants.DEPURATORE_ACQUA_BUONA_ARIEL && el.codice === constants.COD_MODELLO_DEPURATORE);
      this.form.sections.depuratore.tipologiaId = this.depuratoreTrasc.id;
    }
  }
}